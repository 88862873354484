<template>
  <router-link to="/">
    <div v-if="errorText" @click="errorText = ''">
      <div class="errorLayer">
        <p>{{ errorText }}</p>
        <div class="errorLayer__button default-button">Zurück zur Übersicht</div>
      </div>
      <div class="errorLayer__background"></div>
      <div class="errorLayer__backgroundBlur"></div>
    </div>
  </router-link>
</template>

<script lang="ts">
import { emitter } from '@/bus';

export default {
  name: 'errorLayer',
  data() {
    return {
      errorText: '',
    };
  },
  created() {
    emitter.on('showErrorLayer', (e) => {
      this.errorText = e;
    });
  },
};
</script>
