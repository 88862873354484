<template>
  <ion-page>
    <error-layer />
    <div id="main" :class="cssClasses" class="saveArea" @contextmenu="handleContextMenu">
      <!-- Conditional outlet with the sole purpose of displaying the login page -->
      <ion-router-outlet v-if="!userLoggedIn && isLoginPage" :key="$route.path" />

      <div v-if="userLoggedIn">
        <!-- !hideTabBar && !hideNavBar -->
        <options-bar v-if="!hideTabBar" :match-select-url="tickerSelectURL" :hide-nav-bar="hideNavBar" title="" />
        <ion-tabs class="ios_saveArea">
          <!-- Main router outlet that starts displaying as soon as we're not on the login page anymore -->
          <ion-router-outlet v-show="!isLoginPage" :key="$route.path" />
          <ion-tab-bar v-show="!hideTabBar && clubSelected" slot="bottom">
            <ion-tab-button href="/create" tab="create">
              <app-icon name="pulse" size="medium" />
              <ion-label>Create</ion-label>
            </ion-tab-button>
            <ion-tab-button :href="matchSelectURL" tab="match" v-if="renderComponent">
              <app-icon v-if="!isActiveTicker" name="scoreboard" size="medium" />
              <span v-else-if="liveTickerMatch" class="tabButtonLiveTickerMatch" v-html="liveTickerMatch" />
              <ion-label>Ticker</ion-label>
            </ion-tab-button>
            <ion-tab-button v-if="authStore.userHasPermission('media_uploadinbox')" href="/cloud" tab="cloud">
              <app-icon name="cloud" />
              <ion-label>Cloud</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
          <div class="get-app" v-if="!isNative && isAndroid">
            Verwende unsere Android
            <b
              ><a href="https://play.google.com/store/apps/details?id=football.leagues.creator" target="_blank"
                >App</a
              ></b
            >!
          </div>
          <div class="get-app" v-if="!isNative && isIphone">
            Verwende unsere iPhone
            <b><a href="https://apps.apple.com/de/app/leagues-creator/id6446194942" target="_blank">App</a></b
            >!
          </div>
          <ion-menu class="side-menu pointer-all" content-id="main" menu-id="app-menu" side="end">
            <ion-header>
              <ion-toolbar color="primary" class="hidden">
                <ion-icon slot="end" :icon="closeOutline" class="close-icon" @click="closeMenu" />
              </ion-toolbar>
              <div class="flex side-menu-header">
                <router-link class="flex items-center w-full" to="/profile">
                  <div class="profile">
                    <div class="profile__image" :style="userImage"></div>
                  </div>
                  <div class="user-email w-full">
                    <small>Hallo</small>
                    <b>{{ userEmail }}</b>
                  </div>
                </router-link>
              </div>
              <hr class="opacity-30" />
            </ion-header>
            <ion-content class="relative">
              <ion-list>
                <router-link v-if="filteredTeams.length === 0" to="/create/" @click="closeMenu">
                  <ion-item>
                    <ion-icon slot="start" :icon="createOutline" color="white"></ion-icon>
                    <ion-card-title>Create</ion-card-title>
                  </ion-item>
                </router-link>
                <router-link class="menu-item" to="/auto-audios/" @click="closeMenu" v-if="mediaAutoAudioPermission">
                  <ion-item>
                    <ion-icon slot="start" :icon="micOutline" color="white" />
                    <ion-card-title>Tonkabine</ion-card-title>
                  </ion-item>
                </router-link>
                <router-link class="menu-item" to="/user-create/" @click="closeMenu" v-if="userCreatePermission">
                  <ion-item>
                    <ion-icon slot="start" :icon="personAddOutline" color="white" />
                    <ion-card-title>User einladen</ion-card-title>
                  </ion-item>
                </router-link>
                <!--v-if="useAuthStore().userHasPermission('club_manage')"-->
                <router-link class="menu-item" to="/club-members/" @click="closeMenu" v-if="userCreatePermission">
                  <ion-item>
                    <ion-icon slot="start" :icon="lockOpenOutline" color="white" />
                    <ion-card-title>Creator Management</ion-card-title>
                  </ion-item>
                </router-link>
                <router-link class="menu-item" to="/player/" @click="closeMenu">
                  <ion-item>
                    <ion-icon slot="start" :icon="personOutline" color="white" />
                    <ion-card-title>Spieler</ion-card-title>
                  </ion-item>
                </router-link>
                <router-link class="menu-item" to="/venue/" @click="closeMenu">
                  <ion-item>
                    <ion-icon slot="start" :icon="locationOutline" color="white" />
                    <ion-card-title> Stadien </ion-card-title>
                  </ion-item>
                </router-link>
                <router-link class="menu-item" to="/competition" @click="closeMenu">
                  <ion-item>
                    <ion-icon slot="start" :icon="ribbonOutline" color="white" />
                    <ion-card-title>Wettbewerbe</ion-card-title>
                  </ion-item>
                </router-link>
                <router-link class="menu-item" to="/club/" @click="closeMenu">
                  <ion-item>
                    <ion-icon slot="start" :icon="shieldOutline" color="white" />
                    <ion-card-title>Club</ion-card-title>
                  </ion-item>
                </router-link>
                <router-link class="menu-item" to="/squad/" @click="closeMenu">
                  <ion-item>
                    <ion-icon slot="start" :icon="peopleOutline" color="white" />
                    <ion-card-title>Kader</ion-card-title>
                  </ion-item>
                </router-link>
                <router-link v-if="admin" class="menu-item" to="/statistics/" @click="closeMenu">
                  <ion-item>
                    <ion-icon slot="start" :icon="analyticsOutline" color="white" />
                    <ion-card-title>Livestream Statistiken</ion-card-title>
                  </ion-item>
                </router-link>
                <div class="menu-break"></div>
                <a href="https://www.leagues.football/imprint" target="_blank">
                  <ion-item>
                    <ion-icon slot="start" :icon="documentTextOutline" color="white" />
                    <ion-card-title>Impressum</ion-card-title>
                  </ion-item>
                </a>
                <a href="https://www.leagues.football/datenschutz" target="_blank">
                  <ion-item>
                    <ion-icon slot="start" :icon="documentLockOutline" color="white" />
                    <ion-card-title>Datenschutz</ion-card-title>
                  </ion-item>
                </a>
                <a
                  @click="changeEnvironment"
                  v-if="admin"
                  id="app-menu"
                  :class="[{ match: matchReporter }, { 'fixed-add-button': fixedAddButton }]"
                >
                  <ion-item>
                    <ion-icon slot="start" :icon="codeOutline" color="white" />
                    <ion-card-title>Umgebung: {{ environmentText }}</ion-card-title>
                  </ion-item>
                </a>
              </ion-list>
            </ion-content>
          </ion-menu>
        </ion-tabs>
        <upload-queue />
        <post-banner />
        <button
          @click="changeEnvironment"
          v-if="admin && matchReporter"
          id="app-menu"
          :class="[{ match: matchReporter }, { 'fixed-add-button': fixedAddButton }]"
          :style="environmentStyle"
          class="environment-button"
        >
          {{ environmentText }}
        </button>
      </div>
      <missing-user-information v-if="userLoggedIn && authStoreUserData" />
      <svgsprite />
      <switch-team />
    </div>
  </ion-page>
  <micro-interaction />
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { computed, nextTick, onBeforeUnmount, onMounted, Ref, ref, watch, watchEffect } from 'vue';
import { useAppStore } from '@/store/app';
import { useAuthStore } from '@/store/auth';
import { useLivetickerStore } from '@/store/liveticker';
import { usePermissionsStore } from '@/store/permissionManagement';
import { emitter } from './bus';
import UploadQueue from '@/components/uploadProgress/UploadQueue.vue';
import Svgsprite from '@/components/layouts/SvgSprite.vue';
import 'firebase/database';
import apiRequest from '@/services/apiRequest';
import { SendIntent } from 'send-intent';
import {
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToolbar,
  menuController,
} from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import postBanner from '@/components/matchReporter/PostBanner.vue';
import {
  closeOutline,
  codeOutline,
  createOutline,
  documentLockOutline,
  documentTextOutline,
  locationOutline,
  micOutline,
  peopleOutline,
  personOutline,
  shieldOutline,
  lockOpenOutline,
  personAddOutline,
  ribbonOutline,
  analyticsOutline,
} from 'ionicons/icons';
import MicroInteraction from '@/components/layouts/MicroInteraction.vue';
import ErrorLayer from '@/components/layouts/ErrorLayer.vue';
import SwitchTeam from '@/components/switchTeam/SwitchTeam.vue';
import type { AppEnvironment } from './store/app';
import OptionsBar from '@/components/OptionsBar.vue';
import MissingUserInformation from '@/components/account/MissingUserInformation.vue';

const userAgent = ref(window.navigator.userAgent);
const router = useRouter();
const renderComponent = ref(true);
const badgeImage = ref('');
const hideNavBar = ref(false);
const hideTabBar = ref(false);
const timelineWriteInterval = ref(undefined) as Ref<undefined | ReturnType<typeof setInterval>>;
const mediaAutoAudioPermission = ref(false);
const userCreatePermission = ref(false);
const authStoreUserData = ref(false);

const appStore = useAppStore();
const authStore = useAuthStore();

const isNative = computed(() => appStore.isNative);
const isIphone = computed(() => /iPhone/i.test(userAgent.value));
const isAndroid = computed(() => /Android/i.test(userAgent.value));

const isLoginPage = computed(() => router.currentRoute.value.name === 'login');

watchEffect(() => {
  if (!(authStore.isUserAuthenticated && !!authStore.accessToken) && !isLoginPage.value) {
    router.push('/login');
  }
});

const liveTickerMatch = computed(() => {
  const livetickerStore = useLivetickerStore();
  if (!livetickerStore.homeTeam || !livetickerStore.guestTeam) {
    return undefined;
  }
  return `<img src="${livetickerStore.homeTeam.badgeUrl}"  alt="icon"/>:<img src="${livetickerStore.guestTeam.badgeUrl}" alt="icon"/>`;
});

const isActiveTicker = computed(() => {
  const livetickerStore = useLivetickerStore();
  const authStore = useAuthStore();

  return (
    livetickerStore.checkKickoffTime() && livetickerStore.url && authStore && authStore.userSettings.activeClub?.isTeam
  );
});

const tickerSelectURL = computed(() => {
  if (useAuthStore() && useAuthStore().userSettings.activeClub?.isTeam) {
    return '/match/teams/' + selectedTimelineUid.value;
  } else {
    return '/match/teams/choiceTeam';
  }
});

const matchSelectURL = computed(() => {
  const livetickerStore = useLivetickerStore();
  if (livetickerStore.url && useAuthStore().userSettings.activeClub?.isTeam && livetickerStore.checkKickoffTime()) {
    return '/match/teams/' + selectedTimelineUid.value + '/match-select/' + livetickerStore.url;
  } else if (useAuthStore() && useAuthStore().userSettings.activeClub?.isTeam) {
    return '/match/teams/' + selectedTimelineUid.value;
  } else {
    return '/match/teams/choiceTeam';
  }
});

const shouldPreventContextMenu = computed(() => window.location.hostname !== 'localhost');

const userImage = computed(() =>
  authStore.profile
    ? 'background-image: url(' +
      authStore.profile.avatarThumbnailUrl +
      '?cache=' +
      new Date().getUTCMilliseconds() +
      ');'
    : undefined,
);

const userLoggedIn = computed(() => !!authStore.accessToken && authStore.isUserAuthenticated);

const cssClasses = computed(() => {
  let activeTickerClass = '';
  if (isActiveTicker.value) {
    activeTickerClass = 'isActiveTicker';
  }
  if (
    router.currentRoute.value.matched[0] &&
    router.currentRoute.value.matched[0].path === '/teams/:teamid/match/:matchid'
  ) {
    return `p-4 md:p-8 min-h-screen flex flex-col justify-between ${activeTickerClass}`;
  }
  return `p-4 md:p-8 md:max-w-3xl md:mx-auto min-h-screen flex flex-col justify-between ${activeTickerClass}`;
});

const admin = computed(() =>
  authStore.userEmail ? authStore.userEmail.toLowerCase().endsWith('@leagues.football') : false,
);

const activeClub = computed(() => authStore.userSettings.activeClub?.uid);

const timelineWrite = computed(() => {
  if (useAuthStore() && useAuthStore().permissions && useAuthStore().permissions.timeline_write) {
    return useAuthStore().permissions.timeline_write;
  } else {
    return '';
  }
});

const clubOrAllowedTimelinesChanged = computed(() => ({
  timelineWrite: timelineWrite.value,
  activeClub: activeClub.value,
}));

const filteredTeams = computed(() => {
  const activeClubUid = useAuthStore().activeClubUid;
  return appStore.filteredTimelines.filter((e: any) => e.uid !== activeClubUid);
});

const clubSelected = computed(() => appStore.clubSelected);

const selectedTimelineUid = computed(() => useAuthStore().selectedTimelineUid ?? '');

const environmentText = computed(() => {
  if (appStore.environment === 'live') {
    return 'L';
  } else {
    return 'D';
  }
});

const environmentStyle = computed(() => {
  if (appStore.environment === 'live') {
    return 'background-color: red;';
  } else {
    return 'background-color: blue;';
  }
});
const matchReporter = computed(() => router.currentRoute.value.name === 'matchid');
const fixedAddButton = computed(
  () => router.currentRoute.value.name === 'cloud' || router.currentRoute.value.name === 'autoAudio',
);

const userEmail = computed(() => authStore.profile?.handle || useAuthStore().userEmail);

onMounted(() => {
  emitter.on('switchTeam', () => {
    renderComponent.value = false;
    nextTick(() => (renderComponent.value = true));
  });
  emitter.on('changeBadge', (e) => {
    badgeImage.value = e;
  });

  getLocalStorageRoute();

  appStore.isNative = Capacitor.isNativePlatform();

  // Sendintent wenn die App geschlossen ist bevor man die Datei teilt
  SendIntent.checkSendIntentReceived().then((result: any) => {
    if (result.type) {
      router.push('/create');
      if (result.type.includes('image')) {
        emitter.emit('sendIntentReceived-image', result);
      } else if (result.type.includes('video')) {
        emitter.emit('sendIntentReceived-video', result);
      } else if (result.type.includes('audio')) {
        emitter.emit('sendIntentReceived-audio', result);
      }
    }
  });
  // Sendintent wenn die App schon läuft wenn man die Datei teilt
  window.addEventListener('sendIntentReceived', () =>
    SendIntent.checkSendIntentReceived().then((result: any) => {
      if (result.type) {
        router.push('/create');
        if (result.type.includes('image')) {
          emitter.emit('sendIntentReceived-image', result);
        } else if (result.type.includes('video')) {
          emitter.emit('sendIntentReceived-video', result);
        } else if (result.type.includes('audio')) {
          emitter.emit('sendIntentReceived-audio', result);
        }
      }
    }),
  );
});

onBeforeUnmount(() => emitter.all.delete('changeBadge'));

watch(
  userLoggedIn,
  async (val) => {
    if (val) {
      const authStore = useAuthStore();
      if (!localStorage.getItem('environment')) {
        appStore.environment = 'live';
      } else {
        appStore.environment = (localStorage.getItem('environment') as AppEnvironment) || 'live';
      }

      setTimeout(async () => {
        await fetchClubId();
        const url = 'profiles/my';
        const result = await apiRequest.methods.get(url, 'profiles');
        if (result.error) {
          console.error('Error fetching player:', result.error);
        } else {
          authStore.profile = result.data;
          authStoreUserData.value = true;
        }
      }, 500);
    } else {
      clearInterval(timelineWriteInterval.value);
    }
  },
  { immediate: true },
);

watch(
  router.currentRoute,
  (to) => {
    hideNavBar.value = false;
    hideTabBar.value = false;
    if (to.name === 'matchid') {
      hideNavBar.value = true;
    }
    if (to.name === 'pageSwitchClub') {
      hideTabBar.value = true;
    }
  },
  { immediate: true },
);

watch(
  clubOrAllowedTimelinesChanged,
  async () => {
    const authStore = useAuthStore();
    if (authStore.userSettings.activeClub) {
      if (authStore.userSettings.activeClub.acronym) {
        authStore.userSettings.activeClub.shortenedName = authStore.userSettings.activeClub.acronym + ' (Club)';
      } else {
        authStore.userSettings.activeClub.shortenedName = authStore.userSettings.activeClub.name;
      }
      const timelines = [] as string[];
      if (authStore.userSettings.activeClub.uid) {
        timelines.push(authStore.userSettings.activeClub.uid);
        if (authStore.userSettings.activeClub.clubTeams) {
          authStore.userSettings.activeClub.clubTeams.forEach((team: any) => {
            team.shortenedName = team.acronym + ' (Team)';
            if (
              team.playsInLeagueLeagues &&
              team.playsInLeagueLeagues.length > 0 &&
              team.playsInLeagueLeagues[0].shortcode
            ) {
              team.shortenedName = team.playsInLeagueLeagues[0].shortcode + ' (Team)';
            }
            timelines.push(team);
          });
        }
      }
      const filteredTimelinesArray = [] as any;
      timelines.forEach((timeline: any) => {
        if (
          authStore.permissions.timeline_write.includes(timeline.uid) ||
          authStore.permissions.timeline_write.includes('00000000-0000-0000-0000-000000000000')
        ) {
          filteredTimelinesArray.push(timeline);
        }
      });
      appStore.filteredTimelines = filteredTimelinesArray;

      if (authStore.userSettings?.activeClub?.uid) {
        mediaAutoAudioPermission.value = await authStore.userHasPermissionForEntity(
          'media_autoaudio',
          authStore.userSettings.activeClub.uid,
        );
        userCreatePermission.value = await authStore.userHasPermissionForEntity(
          'user_create',
          authStore.userSettings.activeClub.uid,
        );
      }
    }
  },
  { deep: true },
);

async function assignedClub() {
  usePermissionsStore().assignedClub().then();
  /*

  const roles = await apiRequest.methods.get('/roles/list', 'profiles')
  if(roles){
    const myTimeline = roles.data[0].entityId;
    const myTeamURL = `api/data/teams/${myTimeline}`;
    const myTeam = await apiRequest.methods.get(myTeamURL, 'cloud');
    const clubID = myTeam.data.clubUids[0];

    const myClubURL = `api/data/clubs/${clubID}`;
    const myClub = await apiRequest.methods.get(myClubURL, 'cloud');

    const authStore = useAuthStore();
    authStore.userSettings.activeClub = myClub.data;
    authStore.userSettings.activeClub.clubId = clubID;
    authStore.userSettings.selectedTimeline = myTeam.data;
    authStore.userSettings.selectedTimeline.uid = myTimeline;
    await authStore.getAllPermissions();
    await authStore.userHasAccessTimeline(myTimeline);

    const permssionStore = usePermissionsStore();
    permssionStore.addTimeline(myTeam.data);


    }

*/

  //      this.router.push('/switchclub');
  //  this.router.push('/switchclub');
  //  appStore.clubSelected = false;
}

async function fetchClubId() {
  const response = await apiRequest.methods.get('api/profiles/settings/get?key=userSettings', 'cloud');

  if (response.error) {
    await assignedClub();
    return;
  }

  if (response.data.activeClub && response.data.selectedTimeline) {
    await getClubData(response.data.activeClub, response.data.selectedTimeline);
  } else {
    await assignedClub();
  }
}

function handleContextMenu(event: any) {
  if (shouldPreventContextMenu.value) {
    event.preventDefault();
  }
}

async function getClubData(clubId: string, selectedTimelineUid: string) {
  const authStore = useAuthStore();
  if (selectedTimelineUid === '9096d124-4413-42e4-b6e1-6152be8ae6ac') {
    //RLSW Timeline, derzeit können wir eigentlich nur Teams und keine Competition Timelines auswählen
    const team = {
      uid: '9096d124-4413-42e4-b6e1-6152be8ae6ac',
      acronym: 'RLSW',
      name: 'Regionalliga Südwest',
      fullName: 'Regionalliga Südwest',
      color: '#003366',
      badgeUrl: 'https://cdn.leagues.network/competitions/9096d124-4413-42e4-b6e1-6152be8ae6ac/badge.png',
      website: '',
      clubType: 'Domestic',
      inactive: false,
      foundingYear: 2006,
      properties: {},
      clubId: '',
      countryUid: '',
      isTeam: false,
    } as const;

    authStore.userSettings.activeClub = {
      ...team,
      clubId: team.uid,
      shortenedName: team.acronym ? team.acronym + ' (Team)' : team.name,
      clubTeams: [],
    };
    authStore.userSettings.selectedTimeline = team;
    authStore.userSettings.selectedTimeline.uid = selectedTimelineUid;
    await authStore.ensureUserHasAccessToEntity(selectedTimelineUid);
  } else {
    try {
      const response = await apiRequest.methods.get(`api/data/clubs/${clubId}`, 'cloud');

      if (response.data.uid !== selectedTimelineUid) {
        const thisTimeline = await apiRequest.methods.get(`api/data/teams/${selectedTimelineUid}`, 'cloud');
        response.data.badgeUrl = thisTimeline.data.badgeUrl;
        response.data.isTeam = true;
      } else {
        response.data.isTeam = false;
      }
      if (response.data) {
        authStore.userSettings.activeClub = response.data;
        authStore.userSettings.activeClub!.clubId = clubId;
        authStore.userSettings.selectedTimeline = response.data;
        authStore.userSettings.selectedTimeline.uid = selectedTimelineUid;
        await authStore.ensureUserHasAccessToEntity(selectedTimelineUid);
      } else {
        await assignedClub();
      }
    } catch (error) {
      console.error(error);
      await router.push('/switchclub');
    }
  }

  await assignedClub();
}

function getLocalStorageRoute() {
  const itemStr = localStorage.getItem('route');
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr) as { expiry: number; value: string };
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem('route');
    return null;
  }
  router.replace(item.value);
}

function changeEnvironment() {
  const environment = appStore.environment;
  if (environment === 'live') {
    appStore.updateEnvironment('dev');
  } else {
    appStore.updateEnvironment('live');
  }
  window.location.reload();
}

function closeMenu() {
  menuController.close();
}
</script>

<style lang="scss" scoped>
img {
  max-width: 10%;
}
.profile--image {
  max-width: unset;
  width: 24px;
  height: 24px;
}
ion-router-outlet {
  overflow-y: auto;
}

ion-menu::part(container) {
  backdrop-filter: blur(20px);
  background: linear-gradient(145deg, rgba(47, 47, 47, 0.5) 0%, rgba(23, 23, 23, 50.5) 100%);
  box-shadow:
    rgba(0, 0, 0, 0.25) 0 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.get-app {
  background: #1f1f1f;
  z-index: 10;
  position: relative;
  text-align: center;
  border-top: 3px solid #00d4d6;
  padding: 5px;
  font-size: 12px;

  a {
    color: var(--ion-color-primary, #3880ff);
  }
}

ion-tab-bar {
  background: #1a1a1a;
  overflow: hidden;

  ion-tab-button {
    --background: transparent;
    border-top: 1px solid transparent;
    position: relative;

    .app-icon,
    ion-label {
      opacity: 0.5;
    }

    ion-label {
      font-size: 12px;
      margin-top: 5px;
    }

    &.tab-selected {
      .app-icon,
      ion-label {
        opacity: 1;
      }

      &:after {
        content: '';
        background: radial-gradient(circle, rgba(0, 212, 214, 0.3) 0%, rgba(255, 255, 255, 0) 50%);
        position: absolute;
        width: 100%;
        height: 100%;
        transform: scaleY(2);
        top: -50%;
        left: 0;
      }

      &:before {
        content: '';
        background: radial-gradient(circle, #00d4d6 0%, rgba(0, 0, 0, 0) 80%);
        height: 1px;
        position: absolute;
        width: 100%;
        top: -1px;
        left: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.tabButtonLiveTickerMatch {
  font-weight: bold;
  font-size: 1.5rem;
  color: #fff;

  .tab-selected & {
    color: #000000;
  }

  img {
    width: 25px;
    margin: 0 2px 0 5px;
    display: inline-block;
    filter: drop-shadow(1px 1px 0px #fff);
    &:first-child {
      margin: 0 7px;
    }
  }
}
</style>
