const cardTypes = [
  {
    name: 'Match: Announcement',
    template: 'MatchAnnouncement',
    translation: 'Spielankündigung',
    requiredFields: [
      {
        // Ich checke Anhand der homeId, ob der User schon ein Match ausgewählt hat
        field: 'homeId',
        name: 'Match',
      },
      {
        field: 'title',
        name: 'Titel',
      },
    ],
  },
  {
    name: 'Match: Welcome',
    template: 'MatchWelcome',
    translation: 'Willkommen',
    requiredFields: [
      {
        field: 'homeId',
        name: 'Match',
      },
      {
        field: 'title',
        name: 'Titel',
      },
    ],
  },
  {
    name: 'Match: Kickoff',
    template: 'MatchKickoff',
    translation: 'Anstoß',
    requiredFields: [
      {
        field: 'homeId',
        name: 'Match',
      },
      {
        field: 'type',
        name: 'Typ',
      },
      {
        field: 'title',
        name: 'Titel',
      },
    ],
  },
  {
    name: 'Match: Halftime',
    template: 'MatchHalftime',
    translation: 'Halbzeit',
    requiredFields: [
      {
        field: 'homeId',
        name: 'Match',
      },
      {
        field: 'minute',
        name: 'Minute',
      },
      {
        field: 'homeScore',
        name: 'Heimtore',
      },
      {
        field: 'guestScore',
        name: 'Gasttore',
      },
    ],
  },
  {
    name: 'Match: Goal',
    template: 'MatchGoal',
    translation: 'Tor',
    requiredFields: [
      {
        field: 'homeId',
        name: 'Match',
      },
      {
        field: 'minute',
        name: 'Minute',
      },
      {
        field: 'playerId',
        name: 'Spieler',
      },
      {
        field: 'homeScore',
        name: 'Heimtore',
      },
      {
        field: 'guestScore',
        name: 'Gasttore',
      },
    ],
  },
  {
    name: 'Match: Chance Missed',
    template: 'MatchChanceMissed',
    translation: 'Verpasste Chance',
    requiredFields: [
      {
        field: 'homeId',
        name: 'Match',
      },
      {
        field: 'minute',
        name: 'Minute',
      },
      {
        field: 'playerId',
        name: 'Spieler',
      },
    ],
  },
  {
    name: 'Match: Card',
    template: 'MatchCard',
    translation: 'Strafkarte',
    requiredFields: [
      {
        field: 'homeId',
        name: 'Match',
      },
      {
        field: 'event',
        name: 'Karten',
      },
      {
        field: 'minute',
        name: 'Minute',
      },
      {
        field: 'playerId',
        name: 'Spieler',
      },
    ],
  },
  {
    name: 'Match: Substitution',
    template: 'MatchSubstitution',
    translation: 'Wechsel',
    requiredFields: [
      {
        field: 'homeId',
        name: 'Match',
      },
      {
        field: 'minute',
        name: 'Minute',
      },
      {
        field: 'playerId',
        name: 'Auswechselspieler',
      },
      {
        field: 'substituteId',
        name: 'Einwechselspieler',
      },
    ],
  },
  {
    name: 'Match: End',
    template: 'MatchEnd',
    translation: 'Spielende',
    requiredFields: [
      {
        field: 'homeId',
        name: 'Match',
      },
      {
        field: 'minute',
        name: 'Minute',
      },
      {
        field: 'homeScore',
        name: 'Heimtore',
      },
      {
        field: 'guestScore',
        name: 'Gasttore',
      },
    ],
  },
  {
    name: 'Match: Commentary',
    template: 'MatchCommentary',
    translation: 'Audio-Kommentar',
    requiredFields: [
      {
        field: 'homeId',
        name: 'Match',
      },
      {
        field: 'audioUrl',
        name: 'Audio',
      },
      {
        field: 'event',
        name: 'Event',
      },
      {
        field: 'class',
        name: 'Class',
      },
    ],
  },
  {
    name: 'Match: Video',
    template: 'MatchVideo',
    translation: 'Video',
    requiredFields: [
      {
        field: 'homeId',
        name: 'Match',
      },
      {
        field: 'videoUrl',
        name: 'Video',
      },
      {
        field: 'event',
        name: 'Event',
      },
      {
        field: 'class',
        name: 'Class',
      },
    ],
  },
  {
    name: 'Match: Image',
    template: 'MatchImage',
    translation: 'Bild',
    requiredFields: [
      {
        field: 'imageUrl',
        name: 'Bild',
      },
      {
        field: 'event',
        name: 'Event',
      },
      {
        field: 'class',
        name: 'Class',
      },
    ],
  },
  {
    name: 'Match: News',
    template: 'MatchNews',
    translation: 'Nachrichten',
    requiredFields: [
      {
        field: 'keyvisualUrl',
        name: 'Hintergrundbild',
      },
      {
        field: 'body',
        name: 'Textkörper',
      },
      {
        field: 'event',
        name: 'Event',
      },
    ],
  },
  {
    name: 'Match: Embedded Content',
    template: 'Embed',
    translation: 'Eingefügter Content',
    requiredFields: [
      {
        field: 'url',
        name: 'URL',
      },
      {
        field: 'event',
        name: 'Event',
      },
      {
        field: 'class',
        name: 'Klasse',
      },
    ],
  },
  {
    name: 'Card',
    template: 'CardForm',
    translation: 'Karte',
    requiredFields: [],
  },
  {
    name: 'MatchLineup',
    template: 'matchLineup',
    translation: 'Aufstellung',
    requiredFields: [],
  },
] as const;

export { cardTypes };
