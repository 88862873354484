<template>
  <div class="scoreboard pl-4 pr-4 relative">
    <!--HOME-->
    <div
      class="scorebar scorebar-home rotatable"
      :class="{ rotateElement: toggleHomeScore }"
      :style="[clubColor(homeTeam.color), { 'transition-delay': toggleHomeScore ? '0s' : '0.3s' }]"
    >
      <div class="flex wrapper" @click="toggleHomeScore = !toggleHomeScore">
        <club-badge :acronym="homeTeam?.acronym" :image="homeTeam?.badgeUrl" styles="display: inline-block;" />
        <div class="goals">{{ live.homeScore }}</div>
      </div>
      <button-match-event
        v-if="phase % 2"
        cardType="MatchGoal"
        class="button-goal-event"
        event="goal"
        nextAction="post"
        status="home"
        template="MatchGoal"
      >
        <template v-slot:goal>
          <div class="add-goal">
            <app-icon size="large" name="add-square" fill="#fff"></app-icon>
            TOR
          </div>
        </template>
      </button-match-event>
      <div @click="toggleDrawer()" v-if="phase % 2" class="button-goal-event hidden">
        <div class="add-goal">
          <app-icon size="large" name="add-square" fill="#fff"></app-icon>
          TOR
        </div>
      </div>
      <draggable-bottom-drawer class="alternate hidden">
        <template #headline> Tor </template>
        <match-goals></match-goals>
      </draggable-bottom-drawer>
      <ion-icon :icon="ellipsisVertical" />
    </div>
    <div
      class="rotatable rotated-element flex-row-reverse"
      :class="{ phasePlay: phase % 2, rotated: !toggleHomeScore }"
      :style="[clubColor(homeTeam.color), { 'transition-delay': toggleHomeScore ? '0.3s' : '0s' }]"
      @click="toggleHomeScore = !toggleHomeScore"
    >
      <div class="goals">{{ live.homeScore }}</div>
      <div class="pulsminus-wrapper">
        <button class="plusminus" type="button" @click.stop="homeScore++">+</button>
        <button class="plusminus" type="button" @click.stop="decrementScore('home')">-</button>
      </div>
    </div>
    <!--GUEST-->
    <div
      class="scorebar scorebar-guest rotatable"
      :class="{ rotateElement: toggleGuestScore }"
      :style="[clubColor(guestTeam.color), { 'transition-delay': toggleGuestScore ? '0s' : '0.3s' }]"
    >
      <div class="flex wrapper" @click="toggleGuestScore = !toggleGuestScore">
        <div class="goals">{{ live.guestScore }}</div>
        <club-badge :acronym="guestTeam?.acronym" :image="guestTeam?.badgeUrl" styles="display: inline-block;" />
      </div>
      <button-match-event
        v-if="phase % 2"
        cardType="MatchGoal"
        class="button-goal-event guest"
        event="goal"
        nextAction="post"
        status="guest"
        template="MatchGoal"
      >
        <template v-slot:goal>
          <div class="add-goal">
            <app-icon size="large" name="add-square" fill="#fff"></app-icon>
            TOR
          </div>
        </template>
      </button-match-event>
      <div @click="toggleDrawer()" v-if="phase % 2" class="button-goal-event hidden">
        <div class="add-goal">
          <app-icon size="large" name="add-square" fill="#fff"></app-icon>
          TOR
        </div>
      </div>
      <draggable-bottom-drawer class="alternate hidden">
        <template #headline> Tor </template>
        <match-goals></match-goals>
      </draggable-bottom-drawer>
      <ion-icon :icon="ellipsisVertical" />
    </div>
    <div
      class="rotatable rotated-element"
      style="right: 1rem"
      :style="[clubColor(guestTeam.color), { 'transition-delay': toggleGuestScore ? '0.3s' : '0s' }]"
      :class="{ phasePlay: phase % 2, rotated: !toggleGuestScore }"
      @click="toggleGuestScore = !toggleGuestScore"
    >
      <div class="goals">{{ match.guestScore }}</div>
      <div class="pulsminus-wrapper">
        <button class="plusminus" type="button" @click.stop="guestScore++">+</button>
        <button class="plusminus" type="button" @click.stop="decrementScore('guest')">-</button>
      </div>
    </div>
  </div>
  <ion-modal id="score-modal" class="card-details" trigger="change-score">
    <div class="change-score-wrapper">
      <label>Heimtore:</label>
      <div class="number-input-wrapper">
        <button class="plusminus" type="button" @click="decrementScore('home')">-</button>
        <input v-model="homeScore" type="number" />
        <button class="plusminus" type="button" @click="homeScore++">+</button>
      </div>
      <label>Gasttore:</label>
      <div class="number-input-wrapper">
        <button class="plusminus" type="button" @click="decrementScore('guest')">-</button>
        <input v-model="guestScore" type="number" />
        <button class="plusminus" type="button" @click="guestScore++">+</button>
      </div>
      <div class="flex justify-center pt-1">
        <ion-button id="close-modal" @click="closeModal">Bestätigen</ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import ClubBadge from '@/components/ClubBadge.vue';
import { IonButton, IonIcon, IonModal, modalController } from '@ionic/vue';
import { computed, ref, watch } from 'vue';
import { useAppStore } from '@/store/app';
import { useMatchStore } from '@/store/match';
import { useLivetickerStore } from '@/store/liveticker';
import ButtonMatchEvent from '@/components/matchReporter/ButtonMatchEvent.vue';
import MatchGoals from '@/components/matchReporter/MatchGoals.vue';
import DraggableBottomDrawer from '@/components/functionality/DraggableBottomDrawer.vue';
import { ellipsisVertical } from 'ionicons/icons';

const match = useMatchStore();
const live = useLivetickerStore();
const { guestTeam, homeTeam } = storeToRefs(match);

withDefaults(
  defineProps<{
    teams?: object;
    phase?: number;
    storedValue?: number;
  }>(),
  { phase: 0 },
);

const appStore = useAppStore();

const homeScore = computed({
  get: () => live.homeScore,
  set: (value) => {
    live.homeScore = value;
  },
});

const guestScore = computed({
  get: () => live.guestScore,
  set: (value) => {
    live.guestScore = value;
  },
});

const toggleHomeScore = ref(false);
const toggleGuestScore = ref(false);

const homeGoalsStore = computed(() => live.homeScore);
const guestGoalsStore = computed(() => live.guestScore);
watch(homeGoalsStore, (newVal) => {
  homeScore.value = newVal;
});

watch(guestGoalsStore, (newVal) => {
  guestScore.value = newVal;
});

watch(homeScore, (newVal) => {
  live.homeScore = newVal;
});

watch(guestScore, (newVal) => {
  live.guestScore = newVal;
});

const closeModal = () => modalController.dismiss();

const toggleDrawer = () => appStore.toggleBottomDrawer();

const decrementScore = (teamType: 'home' | 'guest') => {
  if (teamType === 'home' && homeScore.value > 0) {
    homeScore.value--;
  } else if (teamType === 'guest' && guestScore.value > 0) {
    guestScore.value--;
  }
};

const clubColor = (message: string): string => {
  if (message) {
    return `background: ${message}`;
  } else {
    return '';
  }
};

/*


export default defineComponent({
  name: "rowBadges",
  data() {
    return {
      homeScore: this.homeGoals || 0,
      guestScore: this.awayGoals || 0,
      ButtonMatchEvent,
      toggleHomeScore: false,
      toggleGuestScore: false,
      ellipsisVertical
    }
  },
  props: {
    teams: Object,
    phase: Number,
    storedValue: Number,
  },
  components: { ClubBadge, IonModal, IonButton, MatchGoals, DraggableBottomDrawer },
  computed: {
    clubColor(): any {
      return (message: string) => {
        if (message) {
          const str = '' + message;
          return 'background: ' + str
        } else {
          return ''
        }
      }
    },
    homeGoalsStore() {
      return useMatchStore().homeScore;
    },
    guestGoalsStore() {
      return useMatchStore().guestScore;
    }
  },
  watch: {
    homeGoalsStore(val) {
      this.homeScore = val;
    },
    guestGoalsStore(val) {
      this.guestScore = val;
    },
    homeScore(val) {
      useMatchStore().homeScore = val;
    },
    guestScore(val) {
      useMatchStore().guestScore = val;
    },
  },
  methods: {
    decrementScore() {
      if (this.homeScore > 0) {
        this.homeScore--;
      }
    },
    closeModal() {
          modalController.dismiss();
      },
      toggleDrawer() {
          useAppStore().toggleBottomDrawer()
      },
  }
})
 */
</script>

<style lang="scss" scoped>
.shadow {
  box-shadow: 0 1px 8px rgba(33, 33, 33, 0.1);
}

.row-badges {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 108px;
}

.change-score-wrapper {
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

ion-modal {
  top: 50%;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  transform: translateY(-50%);
  border-radius: 10px;
}

.number-input-wrapper {
  border: #999999 solid 1px;
}

.plusminus:first-child {
  border-right: #999999 solid 1px !important;
}

.plusminus:last-child {
  border-left: #999999 solid 1px !important;
}
</style>

<style lang="scss">
.rotatable {
  transition: 0.3s ease-in-out;
}

/*.rotatable.rotated-element {
        transition-delay: 0.3s;
    }*/

.rotated-element {
  position: absolute;
  width: calc(50% - 1.5rem);
  height: 100%;
  background: #3f84e5;
  border-radius: 0.5rem;
  padding: 1rem 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .goals {
    font-size: 64px;
    font-weight: 700;
    text-align: center;
    width: 50%;
  }

  .pulsminus-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    gap: 0.5rem;

    .plusminus {
      border: none !important;
      width: 32px;
      font-size: 32px;
      font-weight: 600;
      line-height: 1;
      backdrop-filter: brightness(0.8);
      border-radius: 0.5rem;
      overflow: hidden;
      box-shadow: #0000003d 0 3px 2px;
    }
  }
}

.phasePlay {
  max-height: calc(100% - 48px);
}

.rotated {
  transform: rotateX(90deg);
}

.rotateElement {
  transform: rotateX(90deg);
}
</style>
