<template>
  <div>
    <div class="close-toggle" v-if="handle" @click="toggle">abbrechen</div>
    <div v-if="!handle">
      <slot name="view"></slot>
    </div>
    <div v-if="handle">
      <slot name="edit"></slot>
    </div>
    <div class="edit-toggle" v-if="!handle" @click="toggle">bearbeiten</div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    edit?: boolean;
  }>(),
  { edit: false },
);
const handle = ref(true);

const toggle = () => {
  handle.value = !handle.value;
};

watch(
  () => props.edit,
  (newValue) => {
    handle.value = newValue;
  },
  { immediate: true },
);

onMounted(() => {
  handle.value = props.edit;
});
</script>

<style scoped></style>
