<template>
  <div class="fixture-management">
    <div class="teams item-wrapper">
      <ion-icon :icon="home" class="absolute opacity-50" />
      <div class="teams-wrapper" :class="{ teams__away: !isHomeMatch }">
        <div class="myTeam teams__item">
          <!-- teamID wird übergeben und das komplette Datenobject des Teams kommt zurück -->
          <team-infos v-model="thisTeam" :team-id="teamId" />
        </div>
        <div @click="changeHomeRight" class="changeHomeRightButton">
          <ion-icon :icon="swapHorizontal" />
        </div>
        <div class="opponent teams__item">
          <!-- Gegner wird eingegeben oder gesucht und das komplette Datenobject des Teams kommt zurück -->
          <team-name-pattern :initial-id="opponentID" v-model="opponent" />
        </div>
      </div>
      <ion-icon :icon="navigate" class="absolute opacity-50" style="right: 0.5rem" />
    </div>
    <div class="item-wrapper">
      <div @click="selectDateClick" class="title">Datum festlegen</div>
      <div class="item-container">
        <select-date :initialDate="startDate" v-model="match.startDate" />
      </div>
    </div>
    <div class="item-wrapper">
      <div class="title">Stadion</div>
      <div class="venue">
        <switch-view-and-edit :edit="switchVenueView">
          <template #view>
            <venue-info class="venue-info" :venue-uid="match.venueUid" />
          </template>
          <template #edit>
            <venue-pattern
              v-model="match.venueUid"
              @update:modelValue="(venueId: string | undefined) => (match.venueUid = venueId || '')"
            />
          </template>
        </switch-view-and-edit>
      </div>
    </div>
    <div class="item-wrapper">
      <div class="title">Wettbewerb</div>
      <div>
        <!--Wettbewerb auswählen-->
        <switch-view-and-edit :edit="switchVenueView">
          <template #view>
            <season-info :season-id="match.seasonUid" />
          </template>
          <template #edit>
            <season-pattern
              :initial-id="match.seasonUid"
              @update:modelValue="(value: string | undefined) => (match.seasonUid = value ?? '')"
            />
          </template>
        </switch-view-and-edit>
      </div>
    </div>
    <button-component :storage-process="isSaving" class="default-button mt-4" @click="createOrUpdateMatch">
      <span v-if="!!matchId"> Spiel bearbeiten </span>
      <span v-else> Spiel anlegen </span>
    </button-component>

    <br />

    <div v-if="matchId !== ''" @click="deleteButton" class="delete-button">
      {{ deleteButtonText }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch, watchEffect } from 'vue';
import { IonIcon } from '@ionic/vue';
import { home, navigate, swapHorizontal } from 'ionicons/icons';
import { useAppStore } from '@/store/app';
import TeamNamePattern from '@/components/helper/pattern/TeamNamePattern.vue';
import TeamInfos from '@/components/helper/uuidToString/TeamInfos.vue';
import SelectDate from '@/components/helper/SelectDate.vue';
import { v4 as uuidv4 } from 'uuid';
import apiRequest from '@/services/apiRequest';
import VenuePattern from '@/components/helper/pattern/VenuePattern.vue';
import VenueInfo from '@/components/helper/uuidToString/VenueInfo.vue';
import SwitchViewAndEdit from '@/components/helper/SwitchViewAndEdit.vue';
import SeasonInfo from '@/components/helper/uuidToString/SeasonInfo.vue';
import SeasonPattern from '@/components/helper/pattern/SeasonPattern.vue';
import ButtonComponent from '@/components/layouts/ButtonComponent.vue';
import type { Team } from '@/models/team';
import type { MatchFixture } from '@/models/match';

const props = defineProps<{
  teamId: string;
  matchId?: string;
}>();

const emit = defineEmits<{
  (e: 'updateParent', value: boolean): void;
}>();

const defaultMatch = (): Omit<
  MatchFixture,
  | 'fixtureConfiguration'
  | 'round'
  // Competition ID can automatically be determined from the season ID
  | 'competitionUid'
> => ({
  startDate: '',
  homeTeamUid: props.teamId,
  guestTeamUid: '',
  status: 'Scheduled',
  calculated: false,
  minutesPlayed: 0,
  homeScore: 0,
  guestScore: 0,
  properties: {},
  seasonUid: '',
  venueUid: '',
  name: '',
  uid: props.matchId || uuidv4(),
});

const match = ref(defaultMatch());

const isHomeMatch = ref(true);
const startDate = ref('');
const opponent = ref<Team | undefined>();
const thisTeam = ref<Team | undefined>();
const appStore = useAppStore();
const venueUid = ref('');
const selectedDate = ref(false);
const thisTeamID = ref('');
const opponentID = ref('');
const matchUUID = ref('');
const venueID = ref('');
const switchVenueView = ref(true);
const isSaving = ref(false);
const deleteButtonPhase = ref(1);
const deleteButtonText = ref('Spiel löschen');

// TODO: This seems stupid oO
// watchEffect(() => {
//   if (!props.matchId || props.matchId === '') {
//     nextTick(() => {
//       match.value.homeTeamUid = props.teamId;
//     });
//   }
// });

watch(
  () => match.value.venueUid,
  (newValue, oldValue) => {
    if (newValue && newValue != oldValue) {
      // TODO: This is probably a hack to retrieve the actual venue object...
      switchVenueView.value = true;
      nextTick(() => {
        switchVenueView.value = false;
      });
    }
  },
);

watch(opponent, () => {
  if (opponent.value && opponent.value.uid) {
    if (isHomeMatch.value) {
      match.value.guestTeamUid = opponent.value.uid;
    } else {
      match.value.homeTeamUid = opponent.value.uid;
    }
  }
});

watch(thisTeam, () => {
  if (thisTeam.value && thisTeam.value.uid) {
    if (isHomeMatch.value) {
      match.value.homeTeamUid = thisTeam.value.uid;
    } else {
      match.value.guestTeamUid = thisTeam.value.uid;
    }
  }
});

if (!props.matchId || props.matchId === '') {
  matchUUID.value = uuidv4();
} else if (props.matchId && props.matchId !== '') {
  matchUUID.value = props.matchId ?? '';
}

const homeTeam = computed(() => (isHomeMatch.value ? thisTeam.value : opponent.value));

const changeHomeRight = () => {
  isHomeMatch.value = !isHomeMatch.value;
  // Temporäre Variable für den Originalwert von guestTeamUid
  const temp = match.value.guestTeamUid;
  // Werte austauschen
  match.value.guestTeamUid = match.value.homeTeamUid;
  match.value.homeTeamUid = temp;
};

const selectDateClick = () => {
  selectedDate.value = !selectedDate.value;
};

watchEffect(() => {
  if (homeTeam.value && homeTeam.value.venueUid) {
    venueUid.value = homeTeam.value.venueUid;
  }
});

watchEffect(async () => {
  const newMatchId = props.matchId;
  if (newMatchId && newMatchId !== '') {
    const result = await apiRequest.methods.get<MatchFixture>(`api/data/fixtures/${newMatchId}`, 'cloud');
    if (result.error) {
      console.log(result.error);
    } else if (result.data) {
      match.value = result.data;

      //StartDate muss ein separater Wert sein
      startDate.value = result.data.startDate;

      // determine which team is yours and which is the opponent
      if (result.data.homeTeamUid === props.teamId) {
        thisTeamID.value = result.data.homeTeamUid;
        opponentID.value = result.data.guestTeamUid;
        isHomeMatch.value = true;
      } else {
        thisTeamID.value = result.data.guestTeamUid;
        opponentID.value = result.data.homeTeamUid;
        isHomeMatch.value = false;
      }
    }
  }
});

watch(
  () => appStore.bottomDrawer,
  (newVal) => {
    if (newVal) {
      match.value = defaultMatch();
    }
  },
);

watch(homeTeam, (newHomeTeam) => {
  if (newHomeTeam?.venueUid) {
    venueID.value = newHomeTeam.venueUid;
  }
});

watch(opponent, (newOpponent) => {
  if (!isHomeMatch.value) {
    if (newOpponent?.venueUid) {
      venueID.value = newOpponent.venueUid;
    }
  }
});

const startCountdown = () => {
  let remainingTime = 4;
  deleteButtonText.value = `Spiel wiederherstellen - ${remainingTime} Sekunden`;

  const interval = setInterval(() => {
    remainingTime--;

    if (remainingTime > 0) {
      deleteButtonText.value = `Spiel wiederherstellen - ${remainingTime} Sekunden`;
    } else {
      clearInterval(interval);
      deleteButtonPhase.value = 0; // Zurücksetzen
      deleteButtonText.value = 'Spiel gelöscht'; // Text zurücksetzen
      emit('updateParent', true);
      useAppStore().toggleBottomDrawer();
    }
  }, 1000);
};

const deleteButton = async () => {
  deleteButtonPhase.value++;
  if (deleteButtonPhase.value === 2) {
    deleteButtonText.value = 'Ja, ich möchte das Spiel wirklich löschen!';
  } else if (deleteButtonPhase.value === 3) {
    startCountdown();
  }
};

const validateForm = () => {
  const missingFields: string[] = [];
  if (match.value.homeTeamUid === '') {
    missingFields.push('Heimteam');
  }
  if (match.value.guestTeamUid === '') {
    missingFields.push('Gastteam');
  }
  if (match.value.startDate === '') {
    missingFields.push('Anstoß');
  }
  if (match.value.seasonUid === '') {
    missingFields.push('Saison');
  }
  if (match.value.venueUid === '') {
    missingFields.push('Spielort');
  }

  if (missingFields.length > 0) {
    alert(`Fehlende Informationen: ${missingFields.join(', ')}. Bitte vervollständige alle erforderlichen Angaben.`);
    return false; // Beende die Funktion, wenn Informationen fehlen
  }

  return true;
};

const matchName = computed(() => {
  const opponentName = opponent.value?.name ?? '';
  const thisTeamName = thisTeam.value?.name ?? '';

  if (match.value.homeTeamUid !== props.teamId) {
    return `${opponentName} vs ${thisTeamName}`;
  }
  return `${thisTeamName} vs ${opponentName}`;
});

watchEffect(() => {
  if (match.value.homeTeamUid === match.value.guestTeamUid) {
    console.error('HOME AND GUEST TEAM ARE EQUAL!!!');
  }
});

const createOrUpdateMatch = async () => {
  if (!validateForm()) {
    return;
  }

  isSaving.value = true;

  const matchPayload = {
    ...match.value,
    name: matchName.value,
  };

  const result = await apiRequest.methods.put(`api/data/fixtures`, 'cloud', matchPayload);
  if (result.error) {
    // handle error
    console.log(result.error);
  } else {
    // handle your response here
    console.log(result.data);
    emit('updateParent', true);
  }
  isSaving.value = false;
  useAppStore().toggleBottomDrawer();
};
</script>

<style lang="scss">
.delete-button {
  color: red !important;
  font-weight: 600;
  text-align: center;
}

.fixture-management {
  .changeHomeRightButton {
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    cursor: pointer;
  }

  .teams {
    display: flex;
    width: 100%;
    margin: 1rem 0;

    .teams-wrapper {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      width: 100%;
    }

    &__away {
      flex-direction: row-reverse !important;
    }

    &__item {
      padding: 0.5rem;
      width: 100%;

      .search-input {
        margin-top: 0.5rem;

        input {
          border-radius: 0.5rem !important;
          color: #000 !important;
        }

        &:focus-within {
          ion-icon {
            display: none;
          }

          input {
            padding-left: 0.5rem !important;
          }
        }
      }

      .teamNamePattern {
        margin: 0 !important;
      }

      @media (max-width: 769px) {
        .teamNamePattern__list {
          width: 200px;
          left: -100px;
        }
      }
    }

    .changeHomeRightButton {
      ion-icon {
        width: 2rem;
      }
    }
  }

  .selectDate {
    display: flex;
    gap: 0.5rem;

    div {
      color: var(--ion-color-primary, #3880ff);
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .inputText {
    margin-bottom: 0;
  }

  ion-datetime {
    border-radius: 0.5rem;
    margin: 0.5rem 0;
  }

  .title {
    text-transform: uppercase;
    width: 100%;
    opacity: 0.6;
  }

  .venue {
    .venue-info {
      display: flex;
      gap: 0.5rem;

      span {
        &:nth-of-type(1) {
          font-weight: 700;
        }
      }
    }
  }

  .teamNamePattern,
  .seasonPattern {
    margin: 0.5rem 0;

    ion-content {
      --background: none;

      &::part(scroll) {
        display: flex;
      }

      select {
        min-height: 42px;
      }
    }

    .inputText {
      display: flex;
      gap: 5px;
      flex-direction: row;
      flex-wrap: nowrap;

      select {
        border-radius: 0.5rem 0 0 0.5rem;
        text-align: left;
        width: 30%;
        min-width: 80px;
        padding: 5px;
      }

      div {
        width: 100%;

        .inputText__input {
          border-radius: 0 0.5rem 0.5rem 0;
          text-align: left;
          width: 100%;
        }
      }
    }
  }

  .edit-toggle,
  .close-toggle {
    cursor: pointer;
    color: var(--ion-color-primary, #3880ff);
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .item-wrapper {
    background: var(--ion-tab-bar-background, #1f1f1f);
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    position: relative;
  }
}

.draggableBottomDrawer {
  .inputText__input,
  select {
    background: #fff;
    border: 1px solid #e5e5e5;
    color: #000;
  }

  .item-wrapper {
    background: #e5e5e5;
  }

  * {
    color: black;
  }
}
</style>
