<template>
  <div :id="id" class="relative md:mb-4 match_event button-goal-event">
    <div
      v-if="counter"
      :class="{ event_expire: confirm || postThis }"
      class="buttonMatchEvent cursor-pointer mb-1 overflow-hidden"
    >
      <div class="flex" @click="click">
        <slot name="goal"></slot>
        <div
          :class="{ 'bg-primary': confirm || postThis, 'bg-white': !confirm && !postThis }"
          class="inline-block w-3/4 align-top non-goal"
        >
          <div class="relative z-10 h-full">
            <div v-if="!confirm && !postThis" class="whitespace-nowrap flex items-center h-full">
              <slot name="icon"></slot>
              <div class="match_event_text p-2">
                <slot name="name"></slot>
              </div>
            </div>
            <div v-if="confirm">
              <div class="match_event_action">Bestätigen</div>
            </div>
            <div v-if="postThis">
              <div class="match_event_action">veröffentlichen</div>
            </div>
          </div>
        </div>
        <div class="count inline-block p-2 w-1/4 text-center non-goal">
          {{ value }}
        </div>
      </div>
    </div>
  </div>

  <!--div :id="id" v-if="counter" class="match_event match_event_card" :class="{'event_expire':confirm || postThis}" @click="edit = true; removeEditButton()"-->
  <div
    :id="id"
    v-if="counter"
    class="match_event match_event_card"
    :class="{ event_expire: confirm || postThis }"
    @click="click"
  >
    <div class="match_event_score">
      <!--b>{{ value }}</b-->
    </div>
    <div class="match_event_title">
      <slot name="name"></slot>
    </div>
    <!--div class="match_event_edit">
            <div v-if="edit" class="value-edit value-edit--plus" @click="value++, edit = false">
                +
            </div>
            <div v-if="edit" class="value-edit" @click="value--, edit = false">
                -
            </div>
        </div-->
  </div>

  <div v-if="!counter" class="default-slim-button" @click="click">
    <slot name="name"></slot>
  </div>
</template>

<script>
import { emitter } from '@/bus';
import { useMatchStore } from '@/store/match';
import { ellipsisVertical, shareSocial } from 'ionicons/icons';

export default {
  name: 'buttonMatchEvent',
  props: {
    status: String,
    event: String,
    nextAction: String,
    cardType: String,
    template: String,
    storedValue: Number,
    counter: {
      type: Boolean,
      default: true,
    },
    id: String,
  },
  data() {
    return {
      value: 0,
      confirm: false,
      postThis: false,
      edit: false,
      openPostLayer: false,
      ellipsisVertical,
      shareSocial,
    };
  },
  watch: {
    value: function () {
      if (this.storedValue !== this.value) {
        this.sendEvent();
      }
    },
    storedValue(val) {
      this.value = val;
    },
  },
  methods: {
    addActiveTeam() {
      const matchStore = useMatchStore();
      matchStore.addActiveTeam(this.status);
    },
    resetClick() {
      setTimeout(
        function (self) {
          self.confirm = false;
          self.postThis = false;
        },
        5000,
        this,
      );
    },
    removeEditButton() {
      setTimeout(
        function (self) {
          self.edit = false;
        },
        2000,
        this,
      );
    },
    sendEvent() {
      const matchEvent = {};
      matchEvent['value'] = this.value;
      matchEvent['template'] = this.template;
      matchEvent['event'] = this.event;
      matchEvent['cardType'] = this.cardType;
      matchEvent['status'] = this.status;
      emitter.emit('matchEvent', matchEvent);
    },
    click() {
      //if(this.postThis || this.confirm || !this.counter){
      // TODO: Unkommentieren sobald das direkte posten von Cards und das hinzufügen von Details später via dem Cortex zur Verfügung steht
      this.addActiveTeam();
      const layer = {};
      layer['event'] = this.event;
      layer['template'] = this.template;
      layer['cardType'] = this.cardType;
      layer['status'] = this.status;
      layer['show'] = true;
      emitter.emit('openPostLayer', layer);
      this.sendEvent();
      //}
      /*if(this.nextAction === 'confirm' && !this.confirm){
        this.confirm = true;
        this.resetClick();
      }else if(!this.postThis){
        this.value++;
        this.confirm = false;
        if(this.nextAction === 'post'){
          this.postThis = true;
          this.resetClick();
        }
      }*/
    },
  },
  created() {
    if (this.storedValue > 0) {
      this.value = this.storedValue;
    }
    emitter.on('closeReporterLayer', (e) => {
      this.openPostLayer = e;
    });
    emitter.on(this.cardType + '-' + this.status + '-post', () => {
      this.value += 1;
    });
  },
  beforeUnmount() {
    emitter.all.delete('closeReporterLayer');
    emitter.all.delete(this.cardType + '-' + this.status + '-post');
  },
};
</script>
<style lang="scss">
.default-slim-button {
  margin-bottom: 1rem;

  span {
    color: inherit;
  }
}
</style>
