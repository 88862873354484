<template>
  <div :class="isPending ? 'center upload-queue-item' : 'w-full pt-1'" :key="task.id">
    <div id="wrapper" class="center" @click="toggleOverlay">
      <template v-if="!task.isCompleted">
        <svg class="progress" :data-progress="task.totalProgress" x="0px" y="0px" viewBox="0 0 80 80">
          <path class="track" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
          <path
            class="fill"
            :style="{ strokeDashoffset: (1 - task.totalProgress) * MAX_STROKE_DASHOFFSET }"
            d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0"
          />

          <text v-if="isPending" style="font-size: 17px" class="value" x="50%" y="56%">Warte...</text>
          <text v-if="!isPending && task.totalProgress !== 1" class="value" x="50%" y="60%">
            {{ progressAsPercentage(task.totalProgress) }}
          </text>
        </svg>

        <div class="thumbnail">
          <img :src="getUploadItemURL(task)" alt="Vorschau" />
        </div>
        <div :class="['overlay', { active: overlayActive }]">
          <ion-icon :icon="close" @click="removeTask(task.id)"></ion-icon>
        </div>
        <div class="upload-status">
          <span class="upload-status-text" v-if="!!task.currentProgressStep">
            {{ task.currentProgressStep.displayText }}
          </span>
          <span class="upload-status-progress" v-if="task.currentProgressStep?.extraInfo?.bytes">
            {{ formatBytes(task.currentProgressStep?.extraInfo?.loaded) }} /
            {{ formatBytes(task.currentProgressStep?.extraInfo?.total ?? 0) }}
          </span>
        </div>
      </template>
      <template v-else>
        <div class="upload-success" :class="{ succeeded: task.totalProgress === 1 }">
          <ion-icon :icon="checkmark"></ion-icon>
          <div class="success-background"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { progressAsPercentage, PublishTask, useTaskQueue } from '@leagues-football/upload-queue';
import { checkmark, close } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';
import formatBytes from '@/components/uploadProgress/formatBytes';
import { ref } from 'vue';
import { usePublishQueueAssets } from '@/store/uploadQueueAssets';

defineProps<{ task: PublishTask; isPending: boolean }>();

const overlayActive = ref(false);
const toggleOverlay = () => {
  overlayActive.value = true;
  setTimeout(() => (overlayActive.value = false), 3000);
};

const taskQueue = useTaskQueue();

const MAX_STROKE_DASHOFFSET = -219.99078369140625;

const getUploadItemURL = (uploadTask: PublishTask) => {
  const url = usePublishQueueAssets().uploadTaskThumbnails.get(uploadTask.id);

  if (!url) {
    // Some random preview image if we don't have a thumbnail
    return '../../../public/assets/icon/favicon.png';
  }

  return url;
};

const removeTask = (taskId: string) => {
  taskQueue.remove(taskId);
  document.body.classList.remove('bottomLayerIsActive');
};
</script>

<style lang="scss">
.upload-status {
  margin-top: 1px;
}
</style>
