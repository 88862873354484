import { useAuthStore } from '@/store/auth';
import apiRequest from '@/services/apiRequest';
import helper from '@/services/helper';
import { useTimelineStore } from '@/store/timeline';
import { type Ref, ref } from 'vue';
import type { TimelineEntry } from '@/models/timeline';

export function useDeleteCard(card: Ref<TimelineEntry | undefined>, onDeleted: () => Promise<unknown> | unknown) {
  const cardDeletionInProgress = ref(false);

  async function deleteCard() {
    const timelineUid = useAuthStore().selectedTimelineUid;
    if (!card.value || !timelineUid) {
      return;
    }

    cardDeletionInProgress.value = true;

    const response = await apiRequest.methods.delete(
      `/api/timelines/unplaceCard?cardId=${card.value.id}&timelineId=${timelineUid}`,
      'cloud',
      {},
    );

    if (response.error) {
      helper.methods.microInteraction(
        'Beim löschen der Mediendatei ist etwas schief gelaufen. Bitte versuche es erneut!',
      );
    } else {
      await useTimelineStore().invalidateCard(card.value.id);
      await onDeleted();
    }

    cardDeletionInProgress.value = false;
  }

  return { deleteCard, cardDeletionInProgress };
}
