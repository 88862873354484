<template>
  <div class="text-center w-full flex-1">
    <div v-if="!reverse || (reverse && (phase ?? 0) > 0)" class="button-jump-phase" style="border-radius: 0.5rem">
      <div v-if="!reverse" class="wrapper">
        <span v-if="phase === 0">
          <ion-icon :icon="playSkipForward"></ion-icon>
          Anstoß
        </span>
        <span v-else-if="phase === 1">
          <ion-icon :icon="playSkipForward"></ion-icon>
          Halbzeit
        </span>
        <span v-else-if="phase === 2">
          <ion-icon :icon="playSkipForward"></ion-icon>
          Anstoß 2.Halbzeit
        </span>
        <span v-else-if="phase === 3">
          <ion-icon :icon="playSkipForward"></ion-icon>
          Abpfiff
        </span>
        überspringen
      </div>
      <div v-else class="wrapper">
        <span v-if="phase === 1">
          <ion-icon :icon="playSkipBack"></ion-icon>
          Zurück zum Anfang
        </span>
        <span v-else-if="phase === 2">
          <ion-icon :icon="playSkipBack"></ion-icon>
          Zurück zur ersten Halbzeit
        </span>
        <span v-else-if="phase === 3">
          <ion-icon :icon="playSkipBack"></ion-icon>
          Zurück zur Halbzeitpause
        </span>
        <span v-else-if="phase === 4">
          <ion-icon :icon="playSkipBack"></ion-icon>
          Zurück zur zweiten Halbzeit
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IonIcon } from '@ionic/vue';
import { playSkipForward, playSkipBack } from 'ionicons/icons';

export default {
  name: 'buttonJumpPhase',
  components: {
    IonIcon,
  },
  props: {
    phase: Number,
    phaseConfirm: Boolean,
    reverse: { type: Boolean, default: false },
  },
  data() {
    return {
      playSkipForward,
      playSkipBack,
    };
  },
};
</script>
