<script lang="ts" setup>
import { ALL_PERMISSIONS, useAuthStore } from '@/store/auth';
import { typedObjectKeys } from '@/utils/typed-stdlib';

const authStore = useAuthStore();

const permissions = typedObjectKeys(ALL_PERMISSIONS).map((x) => {
  const perm = authStore.userHasPermission(x);
  return {
    name: ALL_PERMISSIONS[x],
    value: perm === 'global' ? '✅ Globaler Admin' : perm ? '✅' : '❌',
  };
});
</script>

<template>
  <h2 class="text-lg font-bold mt-4 mb-2">Deine Rechte bei {{ authStore.userSettings.activeClub.fullName }}</h2>
  <table class="table-auto border-spacing-4">
    <thead>
      <tr>
        <th class="text-left">Recht</th>
        <th class="text-left pl-3">Erlaubt?</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="perm in permissions" :key="perm.name">
        <td>{{ perm.name }}</td>
        <td class="pl-2">{{ perm.value }}</td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped lang="scss"></style>
