import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { useAppStore } from '@/store/app';

export const usePostDrawerStore = defineStore('postDrawer', () => {
  const viewTitle = ref('');
  const allowSwitchingPostMode = ref(true);

  const reset = () => {
    viewTitle.value = '';
    allowSwitchingPostMode.value = true;
  };

  const appStore = useAppStore();

  watch(
    () => appStore.bottomDrawerAnimating,
    (val) => {
      if (val) {
        return;
      }

      if (!useAppStore().bottomDrawer) {
        reset();
      }
    },
  );

  return { viewTitle, allowSwitchingPostMode };
});
