<!--suppress ALL -->
<template>
  <div class="w-full relative flex flex-col match-lineup h-full overflow-y-scroll pt-[75px]">
    <div @click="lineUpScan" class="default-button flex items-center justify-center gap-1 self-center leading-none">
      <ion-icon class="mb-0" :icon="scanOutline"></ion-icon>
      <span v-if="hasCameraPlugin"> Aufstellung scannen </span>
      <span v-else> Aufstellung hochladen </span>
    </div>
    <div class="relative lineup-scan-animation-wrapper" v-if="photoWebPath">
      <div class="lineup-scan" :class="{ 'end-animation': endAnimation }">
        <img style="width: 100px" :src="photoWebPath" />
      </div>
      <div v-if="!scanNotProcess && !scanNotFound && !endAnimation">
        <div class="scan-team scan-team-home">
          <span
            v-for="(player, index) in searchResultsHome"
            :key="player.uid"
            class="player player-home"
            :class="{ active: index === activeIndexHome }"
          >
            {{ player.name }}
          </span>
        </div>
        <div class="scan-team scan-team-guest">
          <span
            v-for="(player, index) in searchResultsGuest"
            :key="player.uid"
            class="player player-guest"
            :class="{ active: index === activeIndexGuest }"
          >
            {{ player.name }}
          </span>
        </div>
      </div>
      <div v-if="scanNotProcess" class="scan-error">
        Das hat leider nicht wie geplant funktioniert. Versuche es bitte erneut!
        <div class="mt-2" @click="scanNotProcess = !scanNotProcess"><b>Schließen</b></div>
      </div>
      <div v-if="scanNotFound" class="scan-error">
        Wir konnten keine Spielernamen auf deinem Aufstellungsbogen erkennen. Bitte fertige eine erneute Aufnahme der
        Aufstellung an. Achte darauf, dass alle Namen deutlich zu erkennen sind.
        <div class="mt-2" @click="scanNotFound = !scanNotFound"><b>Schließen</b></div>
      </div>
    </div>
    <ion-searchbar v-model="searchString" class="lineup-search" placeholder="Suche"></ion-searchbar>
    <ion-accordion-group class="lineup" :multiple="multipleValue" :value="['first', 'second']">
      <ion-accordion value="first">
        <ion-item class="lineup__home" slot="header" color="light">
          <ion-label>{{ homeName }}</ion-label>
        </ion-item>
        <player-list
          slot="content"
          v-if="!lineupPosted"
          :players="searchResultsHome.filter((x) => !x.isPlaying)"
          :color="homeColor"
          @click="(player) => (player.isPlaying = true)"
        />
      </ion-accordion>
      <ion-accordion value="second" :class="accordionValues">
        <ion-item class="lineup__guest" slot="header" color="light">
          <ion-label>{{ guestName }}</ion-label>
        </ion-item>
        <player-list
          slot="content"
          v-if="!lineupPosted"
          :players="searchResultsGuest.filter((x) => !x.isPlaying)"
          :color="guestColor"
          @click="(player) => (player.isPlaying = true)"
        />
      </ion-accordion>
    </ion-accordion-group>
  </div>
  <div class="onThePitch">
    <div class="onThePitch__list team-home">
      <ul slot="content">
        <li
          class="pt-4 pb-4"
          v-if="
            !searchString &&
            !searchResultsHome.some((player) => player.isPlaying) &&
            searchResultsGuest.some((player) => player.isPlaying)
          "
        >
          Es wurden noch keine Spieler für {{ homeName }} ausgewählt
        </li>
        <template v-for="player in searchResultsHome" :key="player.uid">
          <li v-if="player.isPlaying" class="onThePitch__list__item" :class="{ fallback: !homeColor }">
            <div class="lineup__fields flex justify-between">
              <label :for="player.uid" class="lineup__name">
                <input :id="player.uid" slot="end" v-model="player.isPlaying" type="checkbox" />
                <label class="flex pointer-events-none">
                  <span class="lineup__jersey">
                    {{ player?.jerseyNumber || '' }}
                  </span>
                  <span class="lineup__ellipsis" v-html="splitName(player.name).firstName"></span>
                  <span class="lineup__ellipsis font-bold" v-html="splitName(player.name).lastName"></span>
                  <span v-if="player.isCaptain" class="lineup__isCaptain">C</span>
                  <span v-if="player.isKeeper" class="lineup__isCaptain">T</span>
                  <span class="lineup__role">
                    <app-icon name="menu-vertical"></app-icon>
                    <ion-select
                      :id="'home-linup-' + splitName(player.name).lastName"
                      :multiple="true"
                      cancelText="Abbrechen"
                      class="lineup__details"
                      @ionChange="changeRole('home', player, $event)"
                    >
                      <ion-select-option value="captain">Kapitän</ion-select-option>
                      <ion-select-option value="keeper">Torwart</ion-select-option>
                    </ion-select>
                  </span>
                </label>
              </label>
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div
      v-if="
        searchResultsHome.some((player) => player.isPlaying) || searchResultsGuest.some((player) => player.isPlaying)
      "
      class="divider"
    ></div>
    <div class="onThePitch__list team-guest">
      <ul slot="content">
        <li
          class="pt-4 pb-4"
          v-if="
            !searchString &&
            !searchResultsGuest.some((player) => player.isPlaying) &&
            searchResultsHome.some((player) => player.isPlaying)
          "
        >
          Es wurden noch keine Spieler aufgestellt
        </li>
        <template v-for="player in searchResultsGuest" :key="player.uid">
          <li v-if="player.isPlaying" class="onThePitch__list__item" :class="{ fallback: !guestColor }">
            <div class="lineup__fields flex justify-between">
              <label :for="player.uid" class="lineup__name">
                <input :id="player.uid" slot="end" v-model="player.isPlaying" type="checkbox" />
                <label class="flex pointer-events-none">
                  <span class="lineup__jersey">
                    {{ player?.jerseyNumber || '' }}
                  </span>
                  <span class="lineup__ellipsis" v-html="splitName(player.name).firstName"></span>
                  <span class="lineup__ellipsis font-bold" v-html="splitName(player.name).lastName"></span>
                  <span v-if="player.isCaptain" class="lineup__isCaptain">C</span>
                  <span v-if="player.isKeeper" class="lineup__isCaptain">T</span>
                  <span class="lineup__role">
                    <app-icon name="menu-vertical"></app-icon>
                    <ion-select
                      :id="'home-linup-' + splitName(player.name).lastName"
                      :multiple="true"
                      cancelText="Abbrechen"
                      class="lineup__details"
                      @ionChange="changeRole('guest', player, $event)"
                    >
                      <ion-select-option value="captain">Kapitän</ion-select-option>
                      <ion-select-option value="keeper">Torwart</ion-select-option>
                    </ion-select>
                  </span>
                </label>
              </label>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
  <div class="match-setup">
    <div v-if="!lineupPosted" class="flex justify-center items-center w-full">
      <div class="lineupSetup lineupSetup--home">
        <template v-if="homePlayersPlaying.length > 0">
          <span>{{ homePlayersPlaying.length }} / 11</span>
          <div class="mt-1">
            <div v-if="!homeCaptainSelected" class="lineupSetup__miss">
              <!--<ion-icon :icon="closeOutline"></ion-icon>-->
              Kapitän
            </div>
            <div v-else class="lineupSetup__miss line-through">
              <!--<ion-icon :icon="checkmarkOutline"></ion-icon>-->
              Kapitän
            </div>
            <div v-if="!homeKeeperSelected" class="lineupSetup__miss">
              <!--<ion-icon :icon="closeOutline"></ion-icon>-->
              Torwart
            </div>
            <div v-else class="lineupSetup__miss line-through">
              <!--<ion-icon :icon="checkmarkOutline"></ion-icon>-->
              Torwart
            </div>
          </div>
        </template>
        <div v-if="homePlayersPlaying.length > 11" class="errorModal">
          <div>Zu viele Spieler aufgestellt!</div>
        </div>
      </div>
      <ion-button
        :class="[
          { event_expire: confirm },
          { 'bg-attention': confirm },
          { 'bg-primary': !confirm },
          { 'button-disabled': homePlayersPlaying.length === 0 && guestPlayersPlaying.length === 0 },
        ]"
        class="ion-button-fixed-width"
        @click="submit"
      >
        {{ buttonText }}
      </ion-button>
      <div class="lineupSetup lineupSetup--guest">
        <template v-if="guestPlayersPlaying.length">
          <span>{{ guestPlayersPlaying.length }} / 11</span>
          <div>
            <div v-if="!guestCaptainSelected" class="lineupSetup__miss">
              Kapitän
              <!--<ion-icon :icon="closeOutline"></ion-icon>-->
            </div>
            <div v-else class="lineupSetup__miss line-through">
              Kapitän
              <!--<ion-icon :icon="checkmarkOutline"></ion-icon>-->
            </div>
            <div v-if="!guestKeeperSelected" class="lineupSetup__miss">
              Torwart
              <!--<ion-icon :icon="closeOutline"></ion-icon>-->
            </div>
            <div v-else class="lineupSetup__miss line-through">
              Torwart
              <!--<ion-icon :icon="checkmarkOutline"></ion-icon>-->
            </div>
          </div>
        </template>
        <div v-if="guestPlayersPlaying.length > 11" class="errorModal">
          <div>Zu viele Spieler aufgestellt!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  alertController,
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
} from '@ionic/vue';
import { scanOutline } from 'ionicons/icons';
import apiRequest from '@/services/apiRequest';
import type { Player } from '@/models/player';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraDirection, CameraResultType } from '@capacitor/camera';
import { computed, onMounted, ref, Ref } from 'vue';
import { useMatchStore } from '@/store/match';
import { emitter } from '@/bus';
import { useRoute } from 'vue-router';
import PlayerList from '@/components/PlayerList.vue';
import { pickSingleFile } from '@/services/filePicker';
import { FileKind } from '@/services/filePicker/models';

type GamePlayer = Player & {
  isKeeper: boolean;
  position: string;
  goalsCount: number;
  isCaptain: boolean;
  isPlaying: boolean;
};

interface IElement {
  $type: string;
  homeId: string;
  homeLineup: any; // Replace any with your specific type if possible
  guestId: string;
  guestLineup: any; // Replace any with your specific type if possible
}

interface IStoreTeam {
  uid: string;
  clubUids: string[];
  acronym: string;
  name: string;
  badgeUrl: string;
  color: string;
}

const matchStore = useMatchStore();
const hasCameraPlugin = computed(() => Capacitor.isPluginAvailable('Camera'));

const homeLineup = computed(() => matchStore.teams.home as GamePlayer[]);
const guestLineup = computed(() => matchStore.teams.guest as GamePlayer[]);
const searchString = ref('');
//const homePlayersPlaying  = ref({});
//const guestPlayersPlaying = ref({});
const lineupPosted = ref(false);
const confirm = ref(false);
const homeName = computed(() => matchStore.homeTeam.name);
const homeColor = computed(() => matchStore.homeTeam.color);
const guestColor = computed(() => matchStore.guestTeam.color);
const guestName = computed(() => matchStore.guestTeam.name);
const multipleValue = computed(() => true);

const accordionValues = computed(() => 'first');
const buttonText = computed(() => (confirm.value ? 'Bestätigen' : 'Veröffentlichen'));
const matchId = ref('');
const route = useRoute();

const splitName = (fullName: string) => {
  const nameParts = fullName.split(' ');
  return {
    firstName: nameParts.slice(0, -1).join(' '),
    lastName: nameParts.slice(-1).join(' '),
  };
};

const sortLineupByJerseyNumber = (lineup: GamePlayer[]) => {
  if (!Array.isArray(lineup)) {
    return [];
  }
  return lineup.sort((a, b) => {
    if (a.jerseyNumber && b.jerseyNumber) {
      return Number(a.jerseyNumber) - Number(b.jerseyNumber);
    }
    if (a.jerseyNumber && !b.jerseyNumber) {
      return -1;
    }
    if (!a.jerseyNumber && b.jerseyNumber) {
      return 1;
    }
    return 0;
  });
};

const changeRole = (team: 'home' | 'guest', player: GamePlayer, event: any) => {
  // Auswahl des entsprechenden Teams
  const searchResults = team === 'home' ? searchResultsHome : searchResultsGuest;

  const playerIndex = searchResults.value.findIndex((p) => p.uid === player.uid);
  if (playerIndex !== -1) {
    // Kapitän oder Torwart ersetzen, wenn notwendig
    if (event.detail.value.includes('captain')) {
      const captainIndex = searchResults.value.findIndex((p) => p.isCaptain && p.uid !== player.uid);
      if (captainIndex !== -1) {
        searchResults.value[captainIndex].isCaptain = false;
      }
    }

    if (event.detail.value.includes('keeper')) {
      const keeperIndex = searchResults.value.findIndex((p) => p.isKeeper && p.uid !== player.uid);
      if (keeperIndex !== -1) {
        searchResults.value[keeperIndex].isKeeper = false;
      }
    }

    // Aktualisieren der Rolle des Spielers
    searchResults.value[playerIndex].isCaptain = event.detail.value.includes('captain');
    searchResults.value[playerIndex].isKeeper = event.detail.value.includes('keeper');
  }
};

const guestCaptainSelected = computed(() => {
  return guestPlayersPlaying.value.some((player) => player.isCaptain);
});

const guestKeeperSelected = computed(() => {
  return guestPlayersPlaying.value.some((player) => player.isKeeper);
});

const homeCaptainSelected = computed(() => {
  return homePlayersPlaying.value.some((player) => player.isCaptain);
});

const homeKeeperSelected = computed(() => {
  return homePlayersPlaying.value.some((player) => player.isKeeper);
});

const homePlayersPlaying = computed(() => {
  const selectedPlayers = searchResultsHome.value.filter((player) => player.isPlaying);
  return selectedPlayers.slice(0, 11);
});

const guestPlayersPlaying = computed(() => {
  const selectedPlayers = searchResultsGuest.value.filter((player) => player.isPlaying);
  return selectedPlayers.slice(0, 11);
});

const cardJSON = {
  $type: 'Card',
  backgroundImageUrl: null,
  backgroundVideoUrl: null,
  backgroundAudioUrl: null,
  elements: [
    {
      $type: 'MatchLineup',
      homeId: '',
      homeLineup: {
        clubId: '',
        teamId: '',
        acronym: '',
        text: '',
        badgeUrl: '',
        color: '',
        players: [],
      },
      guestId: '',
      guestLineup: {
        clubId: '',
        teamId: '',
        acronym: '',
        text: '',
        badgeUrl: '',
        color: '',
        players: [],
      },
    },
  ],
  id: null,
  track: 'Main',
  class: 'Match',
  event: 'Match',
  orientation: 'Portrait',
  navigation: {},
  title: null,
  tags: [],
  mediaIsInCache: true,
};

const store = ref({ homeTeam: {} as IStoreTeam, guestTeam: {} as IStoreTeam });

const setTeamInfo = (team: keyof typeof store.value, lineupType: 'home' | 'guest') => {
  const element: IElement = cardJSON.elements[0];
  const teamLineup = element[`${lineupType}Lineup` as keyof IElement];
  let storeTeam: IStoreTeam;

  if (lineupType === 'home') {
    storeTeam = matchStore.homeTeam;
  } else {
    storeTeam = matchStore.guestTeam;
  }

  if (!storeTeam || !storeTeam.clubUids || storeTeam.clubUids.length === 0) {
    console.error(`Teamdaten für '${team}' sind nicht vollständig oder fehlen.`);
    return;
  }

  element[`${lineupType}Id` as keyof IElement] = storeTeam.uid;
  teamLineup.clubId = storeTeam.clubUids[0];
  teamLineup.teamId = storeTeam.uid;
  teamLineup.acronym = storeTeam.acronym;
  teamLineup.text = storeTeam.name;
  teamLineup.badgeUrl = storeTeam.badgeUrl;
  teamLineup.color = storeTeam.color;
};
const addPlayerInfo = (player: GamePlayer, lineupType: 'home' | 'guest') => {
  const element: IElement = cardJSON.elements[0];
  if (player.isPlaying) {
    const selectedPlayer = {
      playerId: player.uid,
      text: player.name,
      number: player.jerseyNumber?.toString(),
      position: player.isKeeper ? 1 : parseInt(player.position, 10) || 0,
      cardYellow: false,
      cardRed: false,
      rating: 0,
      goals: player.goalsCount,
      captain: player.isCaptain,
    };
    element[`${lineupType}Lineup`].players.push(selectedPlayer);
  }
};
const submit = async () => {
  let message = '';

  if (homePlayersPlaying.value.length !== 11) {
    message += '- Die Spielerzahl ist im Heimteam unvollständig.<br />';
  }

  if (guestPlayersPlaying.value.length !== 11) {
    message += '- Die Spielerzahl ist im Gastteam unvollständig.<br />';
  }

  if (!homeKeeperSelected.value) {
    message += '- Im Heimteam wurde kein Torhüter ausgewählt.<br />';
  }

  if (!guestKeeperSelected.value) {
    message += '- Im Gastteam wurde kein Torhüter ausgewählt.<br />';
  }

  if (!homeCaptainSelected.value) {
    message += '- Im Heimteam wurde kein Spielführer bestimmt.<br />';
  }

  if (!guestCaptainSelected.value) {
    message += '- Im Gastteam wurde kein Spielführer bestimmt.<br />';
  }

  if (message.length > 0) {
    const alert = await alertController.create({
      header: 'Achtung!',
      cssClass: 'custom-alert',
      subHeader: 'Möchtest du die Aufstellung veröffentlichen, obwohl diese nicht komplett ist?',
      message: message,
      buttons: [
        {
          text: 'Ja',
          role: 'confirm',
          handler: () => {
            postLineup();
          },
        },
        {
          text: 'abbrechen',
          role: 'cancel',
        },
      ],
    });

    await alert.present();
  } else if (confirm.value) {
    confirm.value = false;
    await postLineup();
  } else {
    confirm.value = true;
    setTimeout(() => {
      confirm.value = false;
    }, 4500);
  }
};

const postLineup = async () => {
  setTeamInfo('homeTeam', 'home');
  setTeamInfo('guestTeam', 'guest');
  homePlayersPlaying.value.forEach((player) => addPlayerInfo(player, 'home'));
  guestPlayersPlaying.value.forEach((player) => addPlayerInfo(player, 'guest'));
  emitter.emit('postCard', cardJSON);
  lineupPosted.value = true;
};

const searchAndSortHome = () => {
  if (!Array.isArray(homeLineup.value)) {
    return [];
  }
  const filtered = homeLineup.value.filter(
    (x: any) =>
      x.name.toLowerCase().includes(searchString.value.toLowerCase()) ||
      (x.number && x.number.toString().includes(searchString.value.toLowerCase())),
  );
  return sortLineupByJerseyNumber(filtered);
};

const searchAndSortGuest = () => {
  if (!Array.isArray(guestLineup.value)) {
    return [];
  }
  const filtered = guestLineup.value.filter(
    (x: any) =>
      x.name.toLowerCase().includes(searchString.value.toLowerCase()) ||
      (x.number && x.number.toString().includes(searchString.value.toLowerCase())),
  );
  return sortLineupByJerseyNumber(filtered);
};

const searchResultsHome = computed(searchAndSortHome);
const searchResultsGuest = computed(searchAndSortGuest);

const photoWebPath = ref<string>('');

const setPlayerIsPlaying = (
  lineup: Ref<Array<{ uid: string; isPlaying?: boolean }>>,
  uid: string,
  isPlaying: boolean,
) => {
  const playerIndex = lineup.value.findIndex((player) => player.uid === uid);
  if (playerIndex !== -1) {
    lineup.value[playerIndex].isPlaying = isPlaying;
  }
};

const setGoalKeeper = (lineup: Ref<Array<{ uid: string; isKeeper?: boolean }>>, uid: string) => {
  const playerIndex = lineup.value.findIndex((player) => player.uid === uid);
  if (playerIndex !== -1) {
    lineup.value[playerIndex].isKeeper = true;
  }
};

const setCaptain = (lineup: Ref<GamePlayer[]>, uid: string) => {
  const playerIndex = lineup.value.findIndex((player) => player.uid === uid);
  if (playerIndex !== -1) {
    lineup.value[playerIndex].isCaptain = true;
  }
};

const uploadTry = ref(0);
const scanNotProcess = ref(false);
const scanNotFound = ref(false);
const endAnimation = ref(false);

const autoLineUp = (team: {
  guest: { starters: Array<{ uid: string }>; goalkeeper: string; captain: string };
  home: { starters: Array<{ uid: string }>; goalkeeper: string; captain: string };
}) => {
  if (team.home.starters.length === 0 && team.guest.starters.length === 0) {
    scanNotFound.value = true;
    return;
  }

  if (team.home.goalkeeper) {
    setGoalKeeper(homeLineup, team.home.goalkeeper);
  }
  if (team.guest.goalkeeper) {
    setGoalKeeper(homeLineup, team.guest.goalkeeper);
  }

  if (team.home.captain) {
    setCaptain(homeLineup, team.home.captain);
  }
  if (team.guest.captain) {
    setCaptain(homeLineup, team.guest.captain);
  }

  team.home.starters.forEach((starter) => {
    setPlayerIsPlaying(homeLineup, starter.uid, true);
  });
  team.guest.starters.forEach((starter) => {
    setPlayerIsPlaying(guestLineup, starter.uid, true);
  });

  endAnimation.value = true;
};

const activeIndexHome = ref(-1);
const activeIndexGuest = ref(-1);

const showPlayerNameWhenScanning = () => {
  searchResultsHome.value.forEach((player, index) => {
    const delay = Math.random() * (3000 - 2500) + 2500;
    setTimeout(
      () => {
        activeIndexHome.value = index;
      },
      delay * (index + 1),
    );
  });
  searchResultsGuest.value.forEach((player, index) => {
    const delay = Math.random() * (3000 - 2500) + 2500;
    setTimeout(
      () => {
        activeIndexGuest.value = index;
      },
      delay * (index + 1),
    );
  });
};

const lineUpUpload = async () => {
  uploadTry.value++;
  console.info(photoWebPath.value);
  const response = await fetch(photoWebPath.value);
  const blob = await response.blob();

  const result = await apiRequest.methods.postBlob(
    `/matchtools/scanLineup?fixtureId=${matchId.value}`,
    'supersub',
    blob,
    'image/jpeg',
  );

  if (result.status === 500) {
    if (uploadTry.value === 1) {
      lineUpUpload();
    } else {
      scanNotProcess.value = true;
    }
  } else {
    autoLineUp(result.data);
  }
};

const lineUpScan = async () => {
  uploadTry.value = 0;
  try {
    let photoPath: string | undefined;
    if (hasCameraPlugin.value && Capacitor.isNativePlatform()) {
      // Wenn die Kamera verfügbar ist, benutze sie, um ein Foto zu machen
      const cameraPhoto = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        direction: CameraDirection.Rear,
      });

      photoPath = cameraPhoto.webPath;
    } else {
      // Wenn die Kamera nicht verfügbar ist, verwende den FilePicker
      const pickedFile = await pickSingleFile(FileKind.Image);
      pickedFile && (photoPath = URL.createObjectURL(pickedFile.blob));
    }
    if (photoPath) {
      photoWebPath.value = photoPath;
      showPlayerNameWhenScanning();
      await lineUpUpload();
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

onMounted(async () => {
  try {
    matchId.value = Array.isArray(route.params.matchid) ? route.params.matchid[0] : route.params.matchid;
  } catch (error) {
    console.error(error);
  }
  showPlayerNameWhenScanning();
});
</script>

<style lang="scss" scoped>
:checked + label {
  .lineup__details {
    pointer-events: all;
  }

  svg {
    fill: v-bind(homeColor);
  }

  span {
    font-weight: bold;
    color: v-bind(homeColor);
  }

  .lineup__list--guest & {
    svg {
      fill: v-bind(guestColor);
    }

    span {
      font-weight: bold;
      color: v-bind(guestColor);
    }
  }

  .lineup__role {
    opacity: 1 !important;

    &:hover {
      background: #515151;
    }
  }
}

.onThePitch {
  position: fixed;
  bottom: 75px;
  background: #222428;
  width: 100%;
  max-height: calc(20vh + 10px);
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 0.5rem 0.5rem 0 0;
  margin-bottom: -10px;
  padding-top: 4px;
  padding-bottom: 12px;

  .team-name {
    font-size: 21px;
  }

  .lineup__name {
    padding: 0.3rem 0.5rem;
  }

  .divider {
    height: 3px;
    width: 100%;
    background: #fff;
    margin: 5px 0.5rem;
    opacity: 0.2;
    border-radius: 9px;
  }

  .onThePitch__list {
    display: flex;
    flex-direction: column;
    width: 100%;

    /*@media (min-width: 769px) {
      width: 45%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }*/

    &.team-home {
      li {
        span {
          color: v-bind(homeColor);
        }
      }
    }

    &.team-guest {
      li {
        span {
          color: v-bind(guestColor);
        }
      }
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  label {
    width: 100%;
    align-items: center;

    span {
      color: #fff;
    }
  }
}

.lineup-scan-animation-wrapper {
  height: 450px;
  overflow: hidden;

  .lineup-scan {
    display: inline-flex;
    padding: 1rem;
    background: #171717;
    border-radius: 9px;
    margin: 1rem;
    position: relative;
    overflow: hidden;

    &:not(.end-animation) {
      animation: pulse 7s ease infinite;

      &:after {
        content: '';
        top: 0;
        transform: translateX(100%) rotate(30deg);
        width: 100vw;
        height: 100vh;
        position: absolute;
        z-index: 1;
        animation: shine 2s infinite ease-in;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.8) 50%,
          rgba(128, 186, 232, 0) 99%,
          rgba(128, 186, 232, 0) 100%
        );
      }
    }

    img {
      z-index: 1;
      border-radius: 3px;
    }
  }

  .scan-team {
    flex-wrap: wrap;
    display: flex;
    position: absolute;
    top: 2rem;
    flex-direction: row;
    white-space: nowrap;
    width: 300%;
    column-gap: 3rem;
    height: 133px;
    animation: floating 2s infinite ease-out;

    .player {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(1);
      opacity: 0;
      font-weight: 600;

      &.active {
        transition:
          2s left ease-out,
          2s right ease-out,
          2s transform ease-out,
          2s color ease-out,
          2s text-shadow ease-out;
        transform: translateY(-50%) scale(5);
        animation: fade-out 2s ease-out forwards;
      }
    }

    .player-home {
      left: 0;

      &.active {
        left: 80vw;
      }
    }

    .player-guest {
      right: 0;

      &.active {
        right: 80vw;
      }
    }
  }

  .scan-team-guest {
    justify-content: right;
    right: 50%;
  }

  .scan-team-home {
    justify-content: left;
    left: 50%;
  }

  .scan-error {
    position: absolute;
    top: 0;
    background: #0000005e;
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
  }
}

@keyframes floating {
  0% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes shine {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }

  10% {
    transform: translateX(-100%) translateY(-100%) rotate(30deg);
  }

  100% {
    transform: translateX(100%) translateY(100%) rotate(30deg);
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.05);
  }

  40% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
