<script setup lang="ts">
import publish from '@/services/publish';
import { computed, ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import FilePickerFormContainer from '@/views/feed/FilePickerFormContainer.vue';
import { FileKind, PreviewURL } from '@/services/filePicker/models';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import PostNewsForm, { NewsPostModel } from '@/views/feed/card-forms/NewsPostForm.vue';
import type { CreatorFile } from '@/services/filePicker/CreatorFile';
import type { CloudFile } from '@/views/cloud-page/CloudFile';

const welcome = computed(() => {
  let greeting = 'Hey';
  const handle = useAuthStore().profile?.handle;
  if (handle) {
    greeting = handle;
  }
  let salutation = `${greeting}, `;
  salutation += 'was hast du zu Berichten?';
  return salutation;
});

const defaultModel = { headline: '', teaserText: '', body: '' } as const;
const newsPostModel = ref<NewsPostModel>(defaultModel);

const resetForm = () => {
  useAppStore().bottomDrawer = false;
  newsPostModel.value = defaultModel;
};

const canSubmit = computed(
  () => newsPostModel.value.headline.trim().length >= 3 && newsPostModel.value.body.trim().length >= 3,
);

const authStore = useAuthStore();

const submit = async (file: CreatorFile | CloudFile, previewUrl: PreviewURL, silentPost: boolean) => {
  const args: Parameters<typeof publish.postNews>[0] = {
    sourceText: authStore.userSettings.activeClub?.name || '',
    sourceLogoURL: authStore.userSettings.activeClub?.badgeUrl || '',
    teaser: newsPostModel.value.teaserText,
    headline: newsPostModel.value.headline,
    body: newsPostModel.value.body,
    file: file,
    thumbnailUrl: previewUrl,
    silentPost,
  };

  resetForm(); // Make sure all refs are copied before clearing them

  await publish.postNews(args);
};
</script>

<template>
  <file-picker-form-container
    :welcome-text="welcome"
    :allowed-file-kind="FileKind.Image"
    :view-title="(noFileSelected) => (noFileSelected ? 'Wähle ein Bild für deine News' : 'Schreibe einen Bericht')"
    :can-submit="canSubmit"
    @submit="submit"
  >
    <template #form>
      <post-news-form v-model="newsPostModel" />
    </template>
  </file-picker-form-container>
</template>
