<script setup lang="ts">
import { DateTime } from 'luxon';
import { dateAsLocaleString } from '@leagues-football/creator-app/src/utils/luxon-utils';
import { useMagicKeys } from '@vueuse/core';
import { watchEffect } from 'vue';
import type { MatchFixture } from '@/models/match';

const emit = defineEmits<{ (e: 'changeDate', date?: DateTime): void; (e: 'close'): void }>();

const changeDate = (date?: DateTime) => emit('changeDate', date);

defineProps<{ archive: MatchFixture[] }>();

const { escape } = useMagicKeys();
watchEffect(() => {
  if (escape.value) {
    emit('close');
  }
});
</script>

<template>
  <div>
    <div class="match-archive-item" @click="changeDate(undefined)">
      <span class="match-archive-item__date-container">
        <span class="match-archive-item__date">Heute</span>
      </span>
    </div>
    <template v-for="match in archive" :key="match.uid">
      <div class="match-archive-item" @click="changeDate(DateTime.fromISO(match.startDate))">
        <span class="match-archive-item__date-container">
          <span class="match-archive-item__date">{{ dateAsLocaleString(DateTime.fromISO(match.startDate)) }}</span>
        </span>
        <div class="match-archive-item__content">
          <div class="team">
            <img class="team-badge" :src="match.homeTeam?.badgeUrl" />
            <div class="team-name">{{ match.homeTeam?.name }}</div>
          </div>
          <div class="team-score">{{ match.homeScore }}</div>
        </div>
        <div class="match-archive-item__content">
          <div class="team">
            <img class="team-badge" :src="match.guestTeam?.badgeUrl" />
            <div class="team-name">{{ match.guestTeam?.name }}</div>
          </div>
          <div class="team-score">{{ match.guestScore }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.match-archive-item {
  cursor: pointer;
  background: linear-gradient(147deg, rgba(60, 60, 60, 1) 20%, rgba(40, 40, 40, 1) 100%);
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  &__date-container {
    .match-archive-item__date {
      font-weight: 600;
      padding: 2px 10px;
      border-radius: 20px;
      background: linear-gradient(180deg, rgba(162, 164, 171, 1) 0%, rgba(134, 136, 143, 1) 100%);
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;

    .team {
      display: flex;
      gap: 10px;
      align-items: center;

      .team-badge {
        width: 32px;
      }

      .team-name {
      }
    }

    .team-score {
      font-weight: 600;
      display: flex;
      align-items: center;
      font-weight: 600;
    }
  }
}
</style>
