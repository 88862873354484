<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons class="absolute" slot="start">
        <ion-button @click="$emit('closeCollaborationView')">
          <ion-icon class="mb-0" :icon="arrowBack"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title class="text-center">Cloud</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding collaboration-container">
    <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-list color="background-color">
      <template v-if="shownFiles.length">
        <div class="file-viewer">
          <template v-for="file in shownFiles" :key="file.id">
            <div v-if="file.contentType.includes('image')" class="file-container" @click="inspectFile(file.url)">
              <img :src="file.url" />
            </div>
            <div
              v-else-if="file.contentType.includes('audio')"
              class="file-container audio-view"
              @click="inspectFile(file.url)"
            >
              <svg>
                <use xlink:href="#audiorecorder"></use>
              </svg>
              <span class="text-overflow">{{ file.fileName }}</span>
            </div>
            <div
              v-else-if="file.contentType.includes('video')"
              class="file-container file-video"
              @click="inspectFile(file.url)"
            >
              <!-- #t=0.1 and preload="metadata", damit auf mobil ein Thumbnail angezeigt wird -->
              <video :src="file.url + '#t=0.1'" preload="metadata" />
              <div class="file-label">
                <svg
                  fill="#fff"
                  x="0px"
                  y="0px"
                  width="163.861px"
                  height="163.861px"
                  viewBox="0 0 163.861 163.861"
                  style="enable-background: new 0 0 163.861 163.861"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275 c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-else-if="!filesLoaded">
        <div class="flex justify-center">
          <skeletor height="150" width="150" />
          <skeletor height="150" width="150" />
          <skeletor height="150" width="150" />
        </div>
      </template>
      <template v-else>
        <h1 class="text-center text-xl w-full font-bold pt-2">Es stehen keine Dateien zur Verfügung</h1>
      </template>
    </ion-list>
    <ion-infinite-scroll @ionInfinite="infiniteScroll($event)">
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ion-content>
  <template v-if="fileInspected.length !== 0">
    <ion-modal :is-open="fileInspected.length !== 0" class="inspect-file">
      <ion-header>
        <ion-toolbar>
          <ion-buttons class="absolute" slot="start">
            <ion-button @click="fileInspected = ''">
              <ion-icon class="mb-0" :icon="arrowBack"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title class="text-center">Cloud</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding content-container">
        <div v-if="fileIsImage" class="modal-file modal-container">
          <div class="image-wrapper">
            <img class="w-full rounded-md preview-img" :src="fileInspected" alt="" />
          </div>
          <div>
            <div class="file-details">
              <template v-for="file in shownFiles" :key="file.id">
                <ul v-if="file.url === fileInspected">
                  <li class="text-overflow">{{ file.fileName }}</li>
                  <li>{{ file.creator }} ⦁ vor {{ formatTimestamp(file.timestamp) }}</li>
                </ul>
              </template>
              <div class="fade-out"></div>
            </div>
          </div>
          <ion-button class="block mt-4" :strong="true" @click="selectFile">Bestätigen</ion-button>
        </div>
        <div v-else class="modal-file">
          <!-- #t=0.1 and preload="metadata", damit auf mobil ein Thumbnail angezeigt wird -->
          <video class="w-full" :src="fileInspected" autoplay controls />
          <div>
            <div class="file-details">
              <template v-for="file in shownFiles" :key="file.id">
                <ul v-if="file.url === fileInspected">
                  <li class="text-overflow">{{ file.fileName }}</li>
                  <li>{{ file.creator }} ⦁ vor {{ formatTimestamp(file.timestamp) }}</li>
                </ul>
              </template>
              <div class="fade-out"></div>
            </div>
          </div>
          <ion-button class="block mt-4" :strong="true" @click="selectFile">Bestätigen</ion-button>
        </div>
      </ion-content>
    </ion-modal>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useMatchStore } from '@/store/match';
import { Skeletor } from 'vue-skeletor';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import CloudService from '@/services/cloud';
import { arrowBack } from 'ionicons/icons';

export default defineComponent({
  name: 'CollaborationView',
  data() {
    return {
      fileInspected: '',
      fileIsImage: false,
      possibleFiles: [] as any,
      shownFiles: [] as any,
      filesShown: 0,
      noMorePossibleFiles: false,
      loading: false,
      filesLoaded: false,
      arrowBack,
    };
  },
  props: {
    inboxId: String,
    contentType: String,
    style: {
      type: String,
      default: '',
    },
  },
  components: {
    IonIcon,
    Skeletor,
    IonContent,
    IonList,
    IonButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonRefresherContent,
    IonRefresher,
    IonModal,
  },
  methods: {
    addFiles(files: any, amount: number, inboxId: string) {
      this.possibleFiles = this.filteredFiles(files, inboxId);
      this.showMoreFiles(amount);
    },
    updateFileList(newFiles: any, amount: number, inboxId: string) {
      const filteredNewFiles = this.filteredFiles(newFiles, inboxId);
      const extraFiles = filteredNewFiles.length - this.possibleFiles.length;
      for (let i = extraFiles; i >= 1; i--) {
        this.possibleFiles.unshift(filteredNewFiles[i - 1]);
        this.shownFiles.unshift(filteredNewFiles[i - 1]);
      }
    },
    filteredFiles(files: any, inboxId: string) {
      const possibleFiles = [] as any;
      if (files.entries && files.entries.length) {
        files.entries.forEach((entry: any) => {
          if (entry.contentType.includes(this.contentType)) {
            possibleFiles.unshift(entry);
          }
        });
        possibleFiles.forEach((file: any) => {
          file.url = 'https://cdn.leagues.network/inbox/' + inboxId + '/' + file.fileName;
        });
      }
      return possibleFiles;
    },
    inspectFile(url: string) {
      const imageFileTypes = ['.gif', '.jpg', '.jpeg', '.png'];
      let isImage = false;

      imageFileTypes.forEach((imageFormat) => {
        if (url.includes(imageFormat)) {
          isImage = true;
        }
      });
      this.fileIsImage = isImage;
      this.fileInspected = url;
    },
    selectFile() {
      this.$emit('fileSelected', this.fileInspected);
      if (this.contentType === 'video') {
        useMatchStore().collabFile.video = this.fileInspected;
      } else if (this.contentType === 'image') {
        useMatchStore().collabFile.image = this.fileInspected;
      } else if (this.contentType === 'audio') {
        useMatchStore().collabFile.audio = this.fileInspected;
      }
      this.fileInspected = '';
      this.$emit('closeCollaborationView');
    },
    showMoreFiles(amount: number) {
      this.filesLoaded = false;
      const filesShown = this.filesShown;
      for (let i = this.filesShown; i < filesShown + amount; i++) {
        if (this.possibleFiles[i]) {
          this.filesShown++;
          this.shownFiles.push(this.possibleFiles[i]);
        } else {
          this.noMorePossibleFiles = true;
        }
      }
      this.filesLoaded = true;
    },
    handleRefresh(event: any) {
      setTimeout(async () => {
        // Any calls to load data go here
        if (this.inboxId) {
          this.filesLoaded = false;
          const files = await CloudService.getFiles(this.inboxId);
          this.filesLoaded = true;
          this.updateFileList(files, 0, this.inboxId);
        }
        event.target.complete();
      }, 500);
    },
    infiniteScroll(e: any) {
      this.showMoreFiles(CloudService.calculateNumColumns());
      setTimeout(() => e.target.complete(), 500);
    },
    async resetCloudView(inboxId: string) {
      this.possibleFiles = [];
      this.shownFiles = [];
      this.filesShown = 0;
      this.filesLoaded = false;
      const files = await CloudService.getFiles(inboxId);
      this.filesLoaded = true;
      if (files) {
        this.addFiles(files, CloudService.calculateNumColumns(), inboxId);
      }
    },
    formatTimestamp(timestamp: Date): string {
      const date: Date = new Date(timestamp);
      const dateInMilliseconds: number = date.getTime();

      const now: number = Date.now();
      const diff: number = Math.floor((now - dateInMilliseconds) / 1000);

      const offset = date.getTimezoneOffset() * 60;
      const adjustedDiff = diff + offset;

      if (adjustedDiff < 60) {
        return adjustedDiff === 1 ? 'einer Sekunde' : adjustedDiff + ' Sekunden';
      } else if (adjustedDiff < 60 * 60) {
        const minutes = Math.floor(adjustedDiff / 60);
        return minutes === 1 ? 'einer Minute' : minutes + ' Minuten';
      } else if (adjustedDiff < 60 * 60 * 24) {
        const hours = Math.floor(adjustedDiff / (60 * 60));
        return hours === 1 ? 'einer Stunde' : hours + ' Stunden';
      } else if (adjustedDiff < 60 * 60 * 24 * 30) {
        const days = Math.floor(adjustedDiff / (60 * 60 * 24));
        return days === 1 ? 'einem Tag' : days + ' Tagen';
      } else if (adjustedDiff < 60 * 60 * 24 * 30 * 12) {
        const months = Math.floor(adjustedDiff / (60 * 60 * 24 * 30));
        return months === 1 ? 'einem Monat' : months + ' Monaten';
      } else {
        const years = Math.floor(adjustedDiff / (60 * 60 * 24 * 30 * 12));
        return years === 1 ? 'einem Jahr' : years + ' Jahren';
      }
    },
  },
  watch: {
    inboxId: {
      immediate: true,
      async handler(val) {
        this.resetCloudView(val);
      },
    },
  },
});
</script>
