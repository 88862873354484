<template>
  <div
    class="draggableBottomDrawer"
    :class="{ draggableBottomDrawer__show: open, draggableBottomDrawer__large: large }"
    v-if="init"
  >
    <div class="draggableBottomDrawer__background" ref="background" @click="closeAction"></div>
    <div class="draggableBottomDrawer__position">
      <div class="draggableBottomDrawer__element" ref="calendarRef" :style="drawPosition">
        <div class="draggableBottomDrawer__close" ref="closeRef" @click="closeAction">
          <div class="draggableBottomDrawer__closeBeams"></div>
        </div>
        <div class="draggableBottomDrawer__closeButton" @click="closeAction">X</div>
        <div class="draggableBottomDrawer__content">
          <div class="draggableBottomDrawer__headline">
            <slot name="headline"></slot>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch, nextTick, watchEffect } from 'vue';
import { createGesture } from '@ionic/vue';
import { useAppStore } from '@/store/app';
import { useMagicKeys } from '@vueuse/core';

/*
Operating instructions: draggableBottomDrawer
* Binde die Komponente an einer beliebigen Stelle ein!
* Geöffnet und geschlossen wird der draggableBottomDrawer über eine Funktion im Store:
* useAppStore().toggleBottomDrawer()
* ggf. muss der Store noch geladen werden:
* import {useAppStore} from "@/store";
* */

export default defineComponent({
  name: 'draggableBottomDrawer',
  setup() {
    const open = ref(false);
    const init = ref(true);
    const swipe = ref(0);
    const closeRef = ref<Node>();
    const calendarRef = ref<HTMLDivElement>();
    const large = ref(false);

    const closeAction = () => {
      open.value = false;
      useAppStore().closeBottomDrawer();
      document.body.classList.remove('bottomLayerIsActive');
    };

    const { escape } = useMagicKeys();
    watchEffect(() => {
      if (escape.value) {
        closeAction();
      }
    });

    const dragBottomDrawer = () => {
      if (useAppStore().bottomDrawer) {
        open.value = true;
        init.value = true;
      }

      const swipeBottomDrawer = (detail: any, type: any) => {
        if (!calendarRef.value) {
          return;
        }

        let action = 'pan';
        if (type === 'end') {
          //Ein schneller Swipe, schließt den Kalender
          if (Math.abs(detail.velocityY) > 0.09) {
            action = 'jump';
          }
        }
        swipe.value = Math.round(detail.startY - detail.currentY);
        if (swipe.value > 0) {
          swipe.value = 0;
          return false;
        }
        swipe.value = swipe.value * -1;
        const half = calendarRef.value.clientHeight / 2;
        if (type === 'end' && half > swipe.value) {
          swipe.value = 0;
          return false;
        } else if ((half < swipe.value && type === 'end') || action === 'jump') {
          useAppStore().toggleBottomDrawer();
        }
      };

      nextTick(() => {
        if (closeRef.value) {
          createGesture({
            el: closeRef.value,
            gestureName: 'close-y',
            direction: 'y',
            gesturePriority: 1,
            passive: true,
            onMove: (detail) => swipeBottomDrawer(detail, 'start'),
            onEnd: (detail) => swipeBottomDrawer(detail, 'end'),
          }).enable();
        }
      });

      if (useAppStore().bottomDrawer) {
        document.body.classList.add('bottomLayerIsActive');
      } else {
        document.body.classList.remove('bottomLayerIsActive');
      }
    };

    const isOpen = computed(() => useAppStore().bottomDrawer);

    const isLarge = computed(() => useAppStore().bottomDrawerLarge);

    watch(isOpen, (newVal, oldVal) => {
      const appStore = useAppStore();
      if (newVal !== oldVal) {
        appStore.bottomDrawerAnimating = true;
      }

      open.value = newVal;
      setTimeout(() => {
        init.value = newVal;
        appStore.bottomDrawerAnimating = false;
      }, 300);
    });

    watch(isLarge, (current) => {
      large.value = current;
    });

    watch(init, (current) => {
      if (current) {
        swipe.value = 0;
        dragBottomDrawer();
        document.body.classList.add('bottomLayerIsActive');
      }
    });

    const drawPosition = computed(() => {
      const thisSwipe = swipe.value + 'px';
      return 'transform: translateY(' + thisSwipe + ')';
    });

    onMounted(() => dragBottomDrawer());

    return {
      dragBottomDrawer,
      closeRef,
      calendarRef,
      swipe,
      open,
      drawPosition,
      init,
      closeAction,
      large,
    };
  },
});
</script>
