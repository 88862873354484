<script lang="ts" setup>
import { close } from 'ionicons/icons';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/vue';
import { computed, ref } from 'vue';
import type { CloudFile } from '@/views/cloud-page/CloudFile';
import CloudFileGallery from '@/components/cloud-files/CloudFileGallery.vue';
import { FileKind } from '@/services/filePicker/models';
import { useAuthStore } from '@/store/auth';
import { getFileKindFromMimeType } from '@/services/filePicker/mime-types';

withDefaults(defineProps<{ allowedFileKind?: FileKind }>(), {
  allowedFileKind: FileKind.Any,
});

const emit = defineEmits<{
  (e: 'fileSelected', file: CloudFile, previewUrl: string): void;
  (e: 'will-dismiss'): void;
}>();

const modal = ref<{ $el: HTMLIonModalElement }>();

const closeModal = () => modal.value?.$el.dismiss();

const inboxId = computed(() => useAuthStore().selectedTimelineUid);

const getCloudURL = (fileName: string) => `https://cdn.leagues.network/inbox/${inboxId.value}/${fileName}`;

const getPreviewUrl = (fileName: string, contentType: string) => {
  const imageExt = getFileKindFromMimeType(contentType) === FileKind.Video ? '.jpg' : '';
  return `${getCloudURL(fileName)}${imageExt}`;
};

const onSelectFile = (file: CloudFile) => {
  emit('fileSelected', file, getPreviewUrl(file.fileName, file.contentType));
  closeModal();
};
</script>

<template>
  <ion-modal ref="modal" @will-dismiss="emit('will-dismiss')">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon :icon="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Datei aus der LEAGUES Cloud auswählen</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="m-2">
        <cloud-file-gallery :allowed-file-kind="allowedFileKind" @file-selected="onSelectFile" :select-mode="true" />
      </div>
    </ion-content>
  </ion-modal>
</template>

<style lang="scss" scoped>
ion-toolbar {
  ion-button {
    ion-icon {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
}
</style>
