<template>
  <div>
    <ul>
      <li v-for="player in team" :key="player.uid">
        <div @click="playerSelect(player)">
          <template v-if="'firstName' in player">{{ player.firstName }}</template>
          <template v-if="'lastName' in player">{{ player.lastName }}</template>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useMatchStore } from '@/store/match';
import { emitter } from '@/bus';
import { Player } from '@/models/player';

export default defineComponent({
  name: 'teamList',
  data() {
    return {
      team: [] as Player[],
    };
  },
  methods: {
    playerSelect(player: object) {
      emitter.emit('showTeamlist', false);
      emitter.emit('playerSelect', player);
    },
  },
  async created() {
    const matchStore = useMatchStore();
    console.error(matchStore.activeTeam);
    if (matchStore.activeTeam === 'home') {
      this.team = matchStore.teams.home;
    } else {
      this.team = matchStore.teams.guest;
    }
    //const homeTeamData: any = await TeamsService.getTeamData(teamUid);
    //console.error(typeof homeTeamData.players);
    //console.error(homeTeamData.players);
    //this.team = homeTeamData.players;
  },
});
</script>

<style scoped></style>
