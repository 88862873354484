<template>
  <div v-if="jsonData" class="teamInfos text-center">
    <club-badge v-if="jsonData && jsonData.badgeUrl" :image="jsonData.badgeUrl" class="teamInfos__badge"></club-badge>
    <div>
      {{ jsonData.fullName }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import ClubBadge from '@/components/ClubBadge.vue';
import apiRequest from '@/services/apiRequest';
import { Club } from '@/models/club';

const props = defineProps<{ teamId: string }>();
const emit = defineEmits<{ (e: 'update:modelValue', value: Club): void }>();

// Verwenden Sie das ref zur Speicherung der JSON-Daten
const jsonData = ref<Club>();

const loadData = async () => {
  try {
    if (props.teamId === '') {
      return false;
    }
    const url = 'api/data/teams/' + props.teamId;
    const result = await apiRequest.methods.get(url, 'cloud');
    if (result.error) {
      console.error('Error fetching data:', result.error);
    } else {
      jsonData.value = result.data as Club;
      emit('update:modelValue', jsonData.value);
    }
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => loadData());

watch(() => props.teamId, loadData, { immediate: true });
</script>

<style lang="scss" scoped>
.teamInfos__badge {
  display: inline-block !important;
}
</style>
