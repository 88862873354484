import { MatchElement } from '@/models/match-elements';
import { MediaElement } from '@/models/media-element';
import { HasSequence } from '@/models/feed-entry';
import { CardSegment } from '@/models/CardSegment';
import type { Brand } from '@/utils/branded-types';

import type { MatchEventId } from '@/models/match-events/MatchEvent';

export type MatchEventType =
  | 'Competition'
  | 'Match'
  | 'MatchCard'
  | 'MatchCardAdjusted'
  | 'MatchCardUpgraded'
  | 'MatchEnd'
  | 'MatchGoal'
  | 'MatchGoalCancelled'
  | 'MatchGoalConfirmed'
  | 'MatchGoalDenied'
  | 'MatchGoalDisallowed'
  | 'MatchHalftime'
  | 'MatchHalftimeKickoff'
  | 'MatchKickoff'
  | 'MatchLineup'
  | 'MatchMissedPenalty'
  | 'MatchPenaltyAwarded'
  | 'MatchPenaltyCancelled'
  | 'MatchPenaltyConfirmed'
  | 'MatchPenaltyKickAwarded'
  | 'MatchPenaltyKickMissed'
  | 'MatchRedCard'
  | 'MatchRedCardCancelled'
  | 'MatchSubstitution'
  | 'MatchVarIntervention' // VAR wird eingeschaltet / angerufen
  | 'MatchVarInterventionGoal' // VAR bestätigt das gegebene Tor
  | 'MatchVarInterventionPenaltyKick' // VAR gibt einen Strafstoß
  | 'MatchWelcome'
  | 'MatchYellowCard'
  | 'MatchYellowCardCancelled'
  | 'MatchYellowRedCard'
  | 'None';

export const MATCH_EVENT_TRANSLATIONS = {
  Competition: 'Wettbewerb',
  Match: 'Spiel',
  MatchCard: 'Karte!',
  MatchCardAdjusted: 'Kartenentscheidung korrigiert!',
  MatchCardUpgraded: 'Karte hochgestuft!',
  MatchEnd: 'Spielende!',
  MatchGoal: 'Tor!',
  MatchGoalCancelled: 'Tor rückgängig gemacht!',
  MatchGoalConfirmed: 'Tor zählt!',
  MatchGoalDenied: 'Tor vereitelt!',
  MatchGoalDisallowed: 'Tor aberkannt!',
  MatchHalftime: 'Halbzeit!',
  MatchHalftimeKickoff: 'Anstoß zur zweiten Halbzeit!',
  MatchKickoff: 'Anstoß!',
  MatchLineup: 'Die Aufstellung steht fest!',
  MatchMissedPenalty: 'Elfmeter daneben!',
  MatchPenaltyAwarded: 'Elfmeterpfiff!',
  MatchPenaltyCancelled: 'Elfmeter zurückgezogen!',
  MatchPenaltyConfirmed: 'Elfmeter bestätigt!',
  MatchPenaltyKickAwarded: 'Elfmeterpfiff!',
  MatchPenaltyKickMissed: 'Elfmeterschuss verpasst!',
  MatchRedCard: 'Rote Karte!',
  MatchRedCardCancelled: 'Rote Karte zurückgenommen!',
  MatchSubstitution: 'Spielerwechsel auf dem Feld!',
  MatchVarIntervention: 'VAR-Eingriff im Spiel!',
  MatchVarInterventionGoal: 'VAR checkt das Tor!',
  MatchVarInterventionPenaltyKick: 'VAR prüft den Elfmeter!',
  MatchWelcome: 'Willkommen zum Spiel!',
  MatchYellowCard: 'Gelbe Karte!',
  MatchYellowCardCancelled: 'Gelbe Karte annulliert!',
  MatchYellowRedCard: 'Gelb-Rote Karte!',
  None: '',
} as const satisfies Record<MatchEventType, string>;

type ClassKey = {
  guid: string;
  entityType: 'match';
  entityId: string;
};

export type MomentProperties<T extends MatchEventType> = {
  classKey: null | ClassKey;
  eventEntityId?: MatchEventId | null;
  eventEntityType: null | 'matchevent';
  expiresOn: null | string;
  reactions: { like: number; laughing: number; celebrate: number; support: number; furious: number };
  relationId: string;
  tags: [];
  title: T;
};

type EventTypeMatchingProperties<T extends MatchEventType> = {
  event: T;
  properties: MomentProperties<T>;
};

export type TimelineCardId = Brand<string, 'match-card-id'>;

export type TimelineCard = EventTypeMatchingProperties<MatchEventType> &
  HasSequence & {
    id: TimelineCardId;
    elements: Array<MatchElement | MediaElement>;
    class: 'Match';
    track: 'Main';
    moment: string;
    segments: CardSegment[];
  };
