<template>
  <div :class="{ 'clubBadge flex items-center justify-center': clubBadgeStyles }">
    <template v-if="!error">
      <template v-if="image">
        <ion-content :scrollX="false" :scrollY="false">
          <img alt="" :class="{ hidden: !showBadge }" @load="imageLoaded" :src="image" />
          <div v-if="!showBadge" class="switchClub_loading switchClub_waiting"></div>
        </ion-content>
      </template>
      <template v-else>
        <skeletor circle height="48" width="48" />
      </template>
    </template>
    <template v-if="error">
      <p class="font-bold">{{ acronym }}</p>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { Skeletor } from 'vue-skeletor';
import { ref } from 'vue';
import { IonContent } from '@ionic/vue';

const error = ref(false);
const showBadge = ref(false);

withDefaults(
  defineProps<{
    image: string;
    styles?: string;
    timelines?: string;
    imgStyles?: string;
    acronym?: string;
    clubBadgeStyles?: boolean;
  }>(),
  {
    styles: '',
    timelines: '',
    imgStyles: '',
    clubBadgeStyles: true,
  },
);

function imageLoaded() {
  showBadge.value = true;
}
</script>

<style lang="scss" scoped>
.clubBadge {
  position: relative;
  width: 58px;
  height: 58px;
  padding: 10px;
  text-align: center;
  margin-left: -10px;

  img,
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
ion-img {
  background: #ffffff !important;
  height: 50px;
  max-width: 100%;
  max-height: 100%;
  width: 50px;
  display: inline;

  img {
    height: 50px;
    max-width: 100%;
    max-height: 100%;
    width: 50px;
  }
}

.switchClub_waiting {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

ion-content {
  --background: none;
}
</style>
