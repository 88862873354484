import { generateRandomString } from '@/utils/random-utils';
import { Filesystem } from '@capacitor/filesystem';

const nativeFileSystem = {
  async renameFile(originalFilePath: string) {
    const decodedFileName = decodeURIComponent(originalFilePath);
    const containsSpace = decodedFileName.includes(' ');

    if (containsSpace) {
      const pathComponents = originalFilePath.split('/');
      const mimeType = '.' + originalFilePath.split('.').pop();
      const oldName = pathComponents.pop(); // Kann möglicherweise `undefined` sein
      const directoryPath = pathComponents.join('/'); // Hier bekommen wir den Pfad ohne Dateinamen

      if (typeof oldName === 'undefined') {
        throw new Error('Dateiname konnte nicht aus dem Pfad extrahiert werden.');
      }

      const newName = generateRandomString(5) + mimeType;

      try {
        await Filesystem.rename({
          from: directoryPath + '/' + oldName,
          to: directoryPath + '/' + newName,
          directory: undefined,
        });

        pathComponents.push(newName);
        return pathComponents.join('/');
      } catch (e: unknown) {
        if (e instanceof Error) {
          console.error('Fehler beim Umbenennen der Datei: ' + e.message);
        } else {
          console.error('Unbekannter Fehler:', e);
        }
      }
    } else {
      console.log('Der Pfad enthält keine Leerzeichen, keine Umbenennung erforderlich.');
      return originalFilePath;
    }
  },
};

export default nativeFileSystem;
