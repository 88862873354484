<script setup lang="ts">
import { onMounted, ref, withDefaults, watch } from 'vue';
import { IonButtons, IonButton, IonItem, IonSpinner, IonIcon, IonCardTitle, IonList } from '@ionic/vue';
import apiRequest from '@/services/apiRequest';
import switchTeam from '@/services/switchTeam';
import { useAuthStore } from '@/store/auth';
import { checkmark, chevronBack } from 'ionicons/icons';
import { Club } from '@/models/club';
import SwitchCreatorTeam from '@/components/switchTeam/SwitchCreatorTeam.vue';

const teamList = ref([] as any);
const props = withDefaults(defineProps<{ selectClub: Club; onlyTeams: boolean }>(), {
  onlyTeams: false,
});
const waitForResponse = ref(false);
const authStore = useAuthStore();

const imageLoaded = ref(false);
const onImageLoad = () => {
  imageLoaded.value = true;
};

const togglePopup = ref(false);
const confirmSwitch = ref(false);
const currentTeam = ref<Club | null>(null);

const selectTimeline = async (team: Club) => {
  currentTeam.value = team;
  console.log('Setting currentTeam to:', team);

  togglePopup.value = true;

  watch(
    confirmSwitch,
    async (newValue) => {
      if (newValue) {
        authStore.userSettings.activeClub!.clubId = props.selectClub.uid;
        await switchTeam.switchTeam(team);
        togglePopup.value = false;
        confirmSwitch.value = false;
        currentTeam.value = null;
      }
    },
    { immediate: true },
  );
};

const getTeams = async () => {
  console.warn(props.selectClub);
  console.warn(props.selectClub.uid);
  const result = await apiRequest.methods.get(`api/data/clubs/${props.selectClub.uid}/teams`, 'cloud');
  teamList.value = result.data;
};

const emit = defineEmits<{ (e: 'close', value: boolean) }>();

const closeAndSendValue = () => emit('close', true);

onMounted(async () => await getTeams());
</script>

<template>
  <div>
    <div>
      <ion-buttons v-if="!onlyTeams" slot="start">
        <div class="md button in-toolbar ion-activatable ion-focusable show-back-button">
          <div @click="closeAndSendValue" class="button-native">
            <span class="button-inner">
              <ion-icon
                part="icon"
                aria-hidden="true"
                role="img"
                class="md"
                :icon="chevronBack"
                color="white"
              ></ion-icon
              ><span aria-hidden="true" class="button-text">Zurück</span>
            </span>
          </div>
        </div>
      </ion-buttons>
    </div>
    <div>
      <ion-list>
        <ion-item v-if="!onlyTeams">
          <ion-card-title @click="selectTimeline(selectClub)">
            <div class="club-badge">
              <img
                v-show="imageLoaded"
                :src="selectClub.badgeUrl"
                alt=""
                @load="onImageLoad"
                style="display: inline-block"
              />
              <span class="pill">club</span>
            </div>
            {{ selectClub.fullName }}
          </ion-card-title>
          <ion-spinner v-if="waitForResponse" color="white" slot="end" name="crescent"></ion-spinner>
          <ion-button
            v-if="!waitForResponse"
            slot="end"
            fill="outline"
            @click="selectTimeline(selectClub)"
            class="list-button"
          >
            <ion-icon :icon="checkmark"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item v-for="team of teamList" :key="team.id">
          <ion-card-title @click="selectTimeline(team)">
            <div class="team-badge">
              <img
                v-if="team.badgeUrl !== ''"
                v-show="imageLoaded"
                :src="team.badgeUrl"
                alt=""
                @load="onImageLoad"
                style="display: inline-block"
              />
              <span class="pill">team</span>
            </div>
            {{ team.fullName }}
          </ion-card-title>
          <ion-spinner v-if="waitForResponse" color="white" slot="end" name="crescent"></ion-spinner>
          <ion-button
            v-if="!waitForResponse"
            slot="end"
            fill="outline"
            @click="selectTimeline(team)"
            class="list-button"
          >
            <ion-icon :icon="checkmark"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
    </div>
    <switch-creator-team
      v-if="togglePopup"
      :badgeUrl="currentTeam?.badgeUrl"
      :teamName="currentTeam?.fullName"
      @confirmSwitch="confirmSwitch = true"
    />
  </div>
</template>

<style scoped lang="scss">
.show-back-button {
  height: 44px;
  padding: 0 5px;
  display: flex;
  align-items: center;
}

.button-text {
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

ion-list {
  background: #000;
}

ion-card-title {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  word-break: break-word;
}

ion-item {
  padding: 0.5rem 0;
  margin: 0 10px;
  border-bottom: 1px solid #ffffff40;
  cursor: pointer;
  --border-style: none;
  --padding-start: 10px;
  --background: #000;

  &:hover {
    ion-card-title {
      color: var(--ion-color-primary, #3880ff);
    }
  }
}

.club-badge {
  position: relative;
  margin-right: 0.5rem;
  display: inline;

  img {
    width: 40px;
    height: 40px;
    margin: 0;
    z-index: 1;
  }

  ion-icon {
    margin: 0;
    width: 32px;
    height: 32px;
  }

  .pill {
    text-transform: uppercase;
    position: absolute;
    bottom: 2px;
    right: -9px;
    font-size: 10px;
    background: #00d4d6;
    padding: 0px 6px;
    padding-top: 1px;
    border-radius: 10px;
    font-weight: 600;
    color: #000;
    z-index: 1;
  }
}

.team-badge {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 1rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .pill {
    text-transform: uppercase;
    position: absolute;
    bottom: 2px;
    right: -9px;
    font-size: 10px;
    background: #00d4d6;
    padding: 0px 6px;
    padding-top: 1px;
    border-radius: 10px;
    font-weight: 600;
    color: #000;
    z-index: 1;
  }
}

.list-button {
  margin: 0;
  font-weight: 600;
  --border-width: 0px;
  font-size: 1rem;

  ion-icon {
    margin-bottom: 0;
  }
}

.button-inner {
  display: flex;
  align-items: center;
  font-size: 17px;

  ion-icon {
    margin-bottom: 0;
    width: 1em;
    height: 1em;
    font-size: 1.85em;
    margin: 0 -4px;
  }
}

.select-team {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 10;
  animation-name: slide-in;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

@keyframes slide-in {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}
</style>
