import mitt from 'mitt';

export const emitter = mitt();
export const useBus = () => ({ bus: emitter });

const originalEmit = emitter.emit;
emitter.emit = function (type: any, evt: any) {
  console.log(`Event emitted: ${type}`);
  originalEmit.call(this, type, evt);
};
