<template>
  <switch-creator-team
    v-if="clubReminder"
    :badgeUrl="homeBadge"
    :teamName="homeName"
    :noSwitch="noSwitch"
    @confirmSwitch="clubReminder = false"
  />
  <div
    class="saveArea flex justify-between mb-5 items-center fixed z-10 w-full left-0 md:left-1/2 md:-translate-x-1/2 top-0 optionBar"
  >
    <div class="optionBar-container">
      <!--Switch Club-->
      <div class="flex justify-center items-center font-bold cursor-pointer">
        <div @click="switchClub">
          <div class="relative">
            <club-badge
              :acronym="homeAcronym"
              :image="homeBadge + '?cache=' + new Date().getTime()"
              :styles="styleString"
              img-styles="height: 30px; width: 30px;"
            />
            <div class="switchClub_circle absolute top-0" :class="{ switchClub_loading: showSwitchLoading }"></div>
          </div>
        </div>
      </div>
      <!--App Navigation element-->
      <div v-if="!hideNavBar" class="menu-button">
        <span>Menu</span>
        <ion-menu-button></ion-menu-button>
      </div>
      <router-link :to="matchSelectUrl" v-if="hideNavBar" class="menu-button">
        <span>Zurück</span>
        <ion-icon :icon="arrowBack"></ion-icon>
      </router-link>
    </div>
  </div>
  <template v-if="showSwitchBar">
    <div
      class="switchBar__close"
      @click="
        showSwitchBar = !showSwitchBar;
        clubReminder = !clubReminder;
        noSwitch = true;
      "
    ></div>
    <div class="switchBar">
      <ul>
        <li>
          <div class="switchBar__headline">Team auswählen:</div>
        </li>
        <li class="switchBar__item" @click="switchTeamFunction(thisClub)" v-if="thisClub">
          <div class="switchBar__image">
            <img :alt="homeBadge" :src="homeBadge + '?cache=' + new Date().getTime()" />
            <span class="pill">club</span>
          </div>
          <div class="switchBar__text">
            <div class="inline-block align-text-top">
              <span v-html="thisClub.name"></span>
            </div>
          </div>
          <div class="switchBar__button">
            <div class="switchBar__dot" :class="{ switchBar__active: activeClubUid === activeClubId }"></div>
          </div>
        </li>
        <li v-for="team in teamList" :key="team.uid" @click="switchTeamFunction(team)" class="switchBar__item">
          <div class="switchBar__image">
            <img :alt="team.name" :src="team.badgeUrl + '?cache=' + new Date().getTime()" />
            <span class="pill">team</span>
          </div>
          <div class="switchBar__text">
            <div class="inline-block align-text-top">
              <span v-html="team.name"></span>
            </div>
          </div>
          <div class="switchBar__button">
            <div class="switchBar__dot" :class="{ switchBar__active: activeClubUid === team.uid }"></div>
          </div>
        </li>
        <li v-if="hasGlobalRights">
          <router-link to="/switchclub">
            <div class="default-button mt-5">Alle Vereine ansehen</div>
          </router-link>
        </li>
      </ul>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import { usePermissionsStore } from '@/store/permissionManagement';
import { IonMenuButton, IonIcon } from '@ionic/vue';
import ClubBadge from '@/components/ClubBadge.vue';
import apiRequest from '@/services/apiRequest';
import switchTeam from '@/services/switchTeam';
import router from '@/router';
import { arrowBack } from 'ionicons/icons';
import SwitchCreatorTeam from '@/components/switchTeam/SwitchCreatorTeam.vue';
import { Club } from '@/models/club';

withDefaults(
  defineProps<{
    title: string;
    matchSelectUrl: string;
    hideNavBar: boolean;
  }>(),
  { hideNavBar: false },
);

const showSwitchBar = ref(false);
const showSwitchLoading = ref(false);
const styleString = ref(
  'border: 4px solid var(--ion-color-primary, #3880ff);\n' +
    '  padding: 5px;\n' +
    '  height: 50px;\n' +
    '  width: 50px;',
);

const authStore = useAuthStore();

const permissionsStore = usePermissionsStore();
const hasGlobalRights = computed(() => permissionsStore.hasGlobalRights);
const homeBadge = computed(() => authStore.userSettings.activeClub?.badgeUrl);
const homeAcronym = computed(() => authStore.userSettings.activeClub?.acronym);
const homeName = computed(() => authStore.userSettings.activeClub?.name);

const teamList = ref([] as Array<{ uid: string; name: string; badgeUrl: string }>);
const thisClub = ref<Club>();

const activeClubUid = computed(() => authStore.userSettings.activeClub?.uid);
const activeClubId = computed(() => authStore.userSettings.activeClub?.clubId);

const clubReminder = ref(false);
const noSwitch = ref(false);

const switchTeamFunction = (team: object) => {
  clubReminder.value = true;
  showSwitchBar.value = false;
  noSwitch.value = false;
  switchTeam.switchTeam(team);
};

const switchClub = async () => {
  if (showSwitchLoading.value) {
    return;
  }
  showSwitchLoading.value = true;
  const clubId = authStore.userSettings.activeClub?.clubId;
  const url = `/api/data/clubs/${clubId}/teams`;
  if (clubId === undefined) {
    await router.push('/switchclub');
    return;
  }

  try {
    const teamListRequest = await apiRequest.methods.get(url, 'cloud');
    if (teamListRequest.data) {
      teamList.value = teamListRequest.data;
      showSwitchBar.value = true;
      showSwitchLoading.value = false;

      const clubData = await apiRequest.methods.get(`api/data/clubs/${clubId}`, 'cloud');
      if (clubData.data) {
        thisClub.value = clubData.data as Club;
      }
    } else {
      await router.push('/switchclub');
    }
  } catch (e) {
    console.error(e);
    await router.push('/switchclub');
  }
};
</script>
<style lang="scss" scoped>
.popup-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;

  .popup-container {
    margin: 1rem;
    padding: 2rem 1rem;
    border-radius: 12px;
    background: linear-gradient(135deg, #232222, #111312, #131414);
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    border: 2px solid #252525;
    text-align: center;
  }
}
.switchClub_circle {
  left: -10px;
  padding: 10px;
}
</style>
<style lang="scss">
.menu-content-open {
  pointer-events: auto !important;

  .optionBar {
    z-index: 0;
  }

  .z-fix {
    z-index: 999 !important;
  }
}

ion-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.menu-content {
  transform: none !important;
}

.options-bottom {
  position: absolute;
  bottom: 20px;

  &__svg {
    padding: 14px;
    border-radius: 100%;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.switchClub_circle {
  width: 58px;
  height: 58px;
  margin: 0 auto;
  border: solid 1px transparent;
  border-radius: 50%;
}

.switchClub_loading {
  border: solid 2px #39d4d6;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.switchBar {
  background: #1f1f1f;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 550px;
  z-index: 51;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
.switchBar__dot {
  border: solid 1px #39d4d6;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  &:after {
    width: 10px;
    height: 10px;
    content: '';
    display: block;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.switchBar__active {
  &:after {
    background: #39d4d6;
  }
}
.switchBar__item {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 0.4fr 2.3fr 0.3fr;
  grid-template-rows: 1fr;
  gap: 0 0;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 2px;
  cursor: pointer;
  align-items: center;
  grid-template-areas: 'image text button';

  &:hover {
    background: #000;
    .switchBar__button {
      .switchBar__dot:not(.switchBar__active) {
        &:after {
          background: rgba(57, 212, 214, 0.5);
        }
      }
    }
  }
}
.switchBar__image {
  grid-area: image;
  position: relative;
  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  .pill {
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 10px;
    background: #00d4d6;
    padding: 0px 6px;
    padding-top: 1px;
    border-radius: 10px;
    font-weight: 600;
    color: #000;
  }
}
.switchBar__text {
  grid-area: text;
  padding: 0 10px;
  line-height: 1;
  display: flex;
  align-items: center;

  ion-icon {
    margin-bottom: 0;
  }
}
.switchBar__button {
  grid-area: button;
  display: flex;
}
.switchBar__headline {
  font-size: 1.3rem;
  margin-bottom: 20px;
}
.switchBar__close {
  position: fixed;
  background: transparent;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  left: 0;
  top: 0;
}
.switchBar__icon {
  vertical-align: middle;
  margin-left: 5px;
}
.v-text-bottom {
  vertical-align: text-bottom;
}
.optionBar {
  padding: 0 1rem;
  background: #1a1a1a;
  background: linear-gradient(165deg, #333333 0%, #1a1a1a 100%);
  border-bottom: 1px solid #343434;
  padding-top: calc(0px + env(safe-area-inset-top));
  width: 100vw;

  .optionBar-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1500px;
    margin: 0 auto;
  }

  .menu-button {
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      color: #00d4d6;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 1px;
    }

    ion-menu-button,
    ion-icon {
      background: #00d4d6;
      border-radius: 50%;
      color: #1a1a1a;
    }

    ion-menu-button {
      width: 32px;
      height: 32px;
    }

    ion-icon {
      width: 22px;
      height: 22px;
      padding: 5px;
    }
  }
  /*@media (min-width: 768px) {
      max-width: 1536px;
    }*/

  .bottomLayerIsActive & {
    z-index: 1;
  }

  ion-icon {
    margin-bottom: 0;
    font-size: 3rem;
  }
}

.tickerPage {
  .optionBar.saveArea {
    padding: 0 1rem !important;
  }
}
</style>
