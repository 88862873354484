<template>
  <button :style="{ 'background-color': color }" class="p-3 mt-8 text-black rounded-md" @click="clickButton">
    Datei übertragen
  </button>
</template>

<script lang="ts">
export default {
  emits: ['submit'],
  props: {
    color: {
      type: String,
    },
  },
  methods: {
    clickButton() {
      this.$emit('submit');
    },
  },
};
</script>
