import { Capacitor } from '@capacitor/core';
import { WEB_ALLOWED_VIDEO_MIME_TYPES } from '@/services/filePicker/mime-types';
import { FileKind, hasKind, isSingleKind } from '@/services/filePicker/models';
import { Duration, DurationLike } from 'luxon';
import formatBytes from '@/components/uploadProgress/formatBytes';
import { getAudioDuration } from '@/utils/audio-file-utils';
import type { CreatorFile } from '@/services/filePicker/CreatorFile';

const VALID = [true, undefined] as const;
export type ValidationResult = typeof VALID | [false, string] | undefined;

export interface IValidator {
  validate(file: CreatorFile): Promise<ValidationResult>;
}

class FileKindValidator implements IValidator {
  constructor(public allowedFileKinds: FileKind) {}

  public async validate({ kind, mimeType }: CreatorFile): Promise<ValidationResult> {
    if (!mimeType) {
      throw Error('File kind validation failed. File has no mime type.');
    }

    if (!kind) {
      return [false, `Wir können mit dem Dateityp '${mimeType}' leider nicht umgehen.`];
    }

    function formatKindMsg(kind: FileKind) {
      switch (kind) {
        case FileKind.Image:
          return 'ein Bild';
        case FileKind.Video:
          return 'ein Video';
        case FileKind.Audio:
          return 'ein Audiodatei';
        default:
          throw Error(`Unknown file kind: ${kind}`);
      }
    }

    const actualKindMsg = formatKindMsg(kind);
    switch (true) {
      case hasKind(kind, this.allowedFileKinds):
        return VALID;
      case this.allowedFileKinds === (FileKind.Image | FileKind.Video):
        return [false, `Wir haben ein Bild oder Video erwartet, aber das ist ${actualKindMsg}`];
      case this.allowedFileKinds === (FileKind.Image | FileKind.Audio):
        return [false, `Wir haben ein Bild oder Audio erwartet, aber das ist ${actualKindMsg}`];
      case this.allowedFileKinds === (FileKind.Video | FileKind.Audio):
        return [false, `Wir haben ein Video oder Audio erwartet, aber das ist ${actualKindMsg}`];
      case isSingleKind(this.allowedFileKinds):
        return [false, `Wir haben ${formatKindMsg(this.allowedFileKinds)} erwartet, aber das ist ${actualKindMsg}`];
      default:
        return [false, `Wir können mit dem Dateityp '${mimeType}' leider nicht umgehen.`];
    }
  }
}

class WebOnlyMp4VideosValidator implements IValidator {
  public async validate({ kind, mimeType }: CreatorFile): Promise<ValidationResult> {
    const isWeb = !Capacitor.isNativePlatform();

    if (isWeb && kind === FileKind.Video && !WEB_ALLOWED_VIDEO_MIME_TYPES.includes(mimeType)) {
      const allowed = WEB_ALLOWED_VIDEO_MIME_TYPES.join(', ');
      return [
        false,
        `Im Browser können wir momentan leider nur den Upload von ${allowed} Dateien anbieten 🥲\n` +
          'Nutze unsere App um dieses Video hochzuladen, oder konvertiere es selbst.',
      ];
    }

    return [true, undefined];
  }
}

class MaxFileSizeValidator implements IValidator {
  constructor(public maxSizeKb?: number) {}

  public async validate({ size }: CreatorFile): Promise<ValidationResult> {
    if (!this.maxSizeKb) {
      return;
    }

    if (size > 1024 * this.maxSizeKb) {
      return [false, `Diese Datei ist zu groß. Die maximale Dateigröße beträgt ${formatBytes(this.maxSizeKb * 1024)}.`];
    }
  }
}

class NativeOrWebFileSizeValidator implements IValidator {
  constructor(
    public maxSizeWebKb: number,
    public maxSizeNativeKb: number,
  ) {}

  public async validate(file: CreatorFile): Promise<ValidationResult> {
    const isWeb = !Capacitor.isNativePlatform();

    if (isWeb) {
      const [result, message] = (await new MaxFileSizeValidator(this.maxSizeWebKb).validate(file)) ?? VALID;
      if (!result) {
        return [
          false,
          message + '\n' + 'Nutze unsere mobile App um dieses Video hochzuladen, oder komprimiere es selbst.',
        ];
      }
    } else {
      return await new MaxFileSizeValidator(this.maxSizeNativeKb).validate(file);
    }
  }
}

class MaxAudioDurationValidator implements IValidator {
  constructor(public maxDuration?: DurationLike) {}

  public async validate({ blob }: CreatorFile): Promise<ValidationResult> {
    if (!this.maxDuration) {
      return VALID;
    }

    const duration = await getAudioDuration(blob);

    const maxDuration = Duration.fromDurationLike(this.maxDuration);
    if (Duration.fromMillis(duration * 1000) > maxDuration) {
      return [false, `Die Audiodatei darf maximal ${maxDuration.as('seconds')} Sekunden lang sein.`];
    }
  }
}

export class ValidationBuilder {
  constructor(private validators: IValidator[] = []) {}

  public fileKind(kinds: FileKind): ValidationBuilder {
    this.using(new FileKindValidator(kinds));
    return this;
  }

  public webOnlyMp4Videos(): ValidationBuilder {
    this.using(new WebOnlyMp4VideosValidator());
    return this;
  }

  public maxFileSize(maxSizeKb?: number): ValidationBuilder {
    this.using(new MaxFileSizeValidator(maxSizeKb));
    return this;
  }

  public nativeOrWebFileSize(maxSizeWebKb: number, maxSizeNativeKb: number): ValidationBuilder {
    this.using(new NativeOrWebFileSizeValidator(maxSizeWebKb, maxSizeNativeKb));
    return this;
  }

  public maxAudioDuration(maxDuration?: DurationLike): ValidationBuilder {
    this.using(new MaxAudioDurationValidator(maxDuration));
    return this;
  }

  public using(...validator: IValidator[]): ValidationBuilder {
    this.validators.push(...validator);
    return this;
  }

  public chainWith(validator?: (validate: ValidationBuilder) => ValidationBuilder): ValidationBuilder {
    return validator?.(this) ?? this;
  }
}

export async function validateFileToUpload(
  file: CreatorFile,
  buildValidator: (validate: ValidationBuilder) => ValidationBuilder,
): Promise<Readonly<[true, undefined] | [false, string]>> {
  const validators = [] as IValidator[];

  buildValidator(new ValidationBuilder(validators));

  for (const validator of validators) {
    const [result, message] = (await validator.validate(file)) ?? VALID;
    if (!result) {
      return [false, message];
    }
  }
  return VALID;
}
