<template>
  <div>TOR!!!</div>
  <div>
    Torart auswählen:
    <div @click="selectGoalType('regular')">Regulär</div>
    <div @click="selectGoalType('own')">Eigentor</div>
    <div @click="selectGoalType('penalty')">Elfmeter</div>
  </div>
  <div @click="showTeamList = true">Torschützen wählen</div>
  <team-list v-if="showTeamList" />
  <pre>
    {{ playerSelect }}
  </pre>
  <div>
    Spielminute:
    <input v-model="minute" />
  </div>
  <div class="" @click="publish">Veröffentlichen</div>
  <div class="" @click="close">Verwerfen, keine Informationen zu diesem Tor hinzufügen</div>
</template>

<script lang="ts" setup>
import { emitter } from '@/bus';
import { useMatchStore } from '@/store/match';
import TeamList from '@/components/matchReporter/inputScreens/teamlist.vue';
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { useLivetickerStore } from '@/store/liveticker';
const livetickerStore = useLivetickerStore();

const showTeamList = ref(false);
const playerSelect = ref({});
const activeTeamChange = ref(false);
const goalType = ref('regular');
const minute = ref(0);

onMounted(() => {
  emitter.on('showTeamlist', (e) => {
    showTeamList.value = e;
  });
  emitter.on('playerSelect', (e) => {
    playerSelect.value = e;
  });
  getMinute();
});

onBeforeUnmount(() => {
  emitter.all.delete('showTeamlist');
  emitter.all.delete('playerSelect');
});

function close() {
  emitter.emit('openPostLayer', { show: false });
}
const publish = close;

function getMinute() {
  minute.value = livetickerStore.currentMinute;
}

function changeActiveTeam() {
  const matchStore = useMatchStore();
  const activeTeam = matchStore.activeTeam;
  const teamLocation = activeTeam === 'home' ? 'guest' : 'home';
  matchStore.addActiveTeam(teamLocation);
}

function selectGoalType(value: 'regular' | 'own' | 'penalty') {
  goalType.value = value;
  //Bei einem Eigentor wird für die Spielerliste das gegnerische Team benötigt
  if (activeTeamChange.value) {
    changeActiveTeam();
    activeTeamChange.value = false;
  }
  if (value === 'own') {
    nextTick(() => {
      changeActiveTeam();

      nextTick(() => {
        activeTeamChange.value = true;
      });
    });
  }
}
</script>
