<script lang="ts" setup generic="T extends Player">
import { IonIcon, IonLabel, IonList } from '@ionic/vue';
import { personCircleOutline } from 'ionicons/icons';
import { type Player } from '@/models/player';
import ImageLoader from '@/components/ImageLoader.vue';

defineProps<{ players: T[]; color?: string }>();

const emit = defineEmits<{ (e: 'click', player: T): void }>();

const splitName = (fullName: string) => {
  const nameParts = fullName.split(' ');
  return {
    firstName: nameParts.slice(0, -1).join(' '),
    lastName: nameParts.slice(-1).join(' '),
  };
};
</script>

<template>
  <div :class="$attrs.class">
    <ion-list class="player__list" slot="content" lines="none">
      <div
        v-for="player in players"
        :key="player.uid"
        class="item"
        :class="{ fallback: !color }"
        :data-value="JSON.stringify(player)"
        @click="emit('click', player)"
      >
        <ion-label :for="player.uid" class="lineup__name pointer-events-none">
          <image-loader
            v-if="player.profilePictureUrl"
            :src="player.profilePictureUrl"
            class="inline-block align-middle ml-1 mr-1 w-11"
          />
          <div v-else class="inline-block align-middle ml-1 mr-1 w-11 placeholder-picture">
            <ion-icon :icon="personCircleOutline"></ion-icon>
          </div>
          <span class="lineup__jersey">
            {{ player?.jerseyNumber || '' }}
          </span>
          <span class="lineup__ellipsis" v-html="splitName(player.name).firstName"></span>
          <span class="lineup__ellipsis font-bold" v-html="splitName(player.name).lastName"></span>
        </ion-label>
      </div>
    </ion-list>
  </div>
</template>

<style lang="scss" scoped>
ion-list {
  margin: 0;

  .item {
    text-align: left;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 0;

    &:nth-child(odd) {
      background: rgba(255, 255, 255, 0.09);
    }

    &:hover {
      background: #ffffff38;
    }

    ion-label {
      font-weight: 400;
      font-size: 18px;
      padding: 0.8rem;

      .placeholder-picture {
        width: 48px;
        height: 48px;

        ion-icon {
          margin: 0;
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}

.player__list {
  padding-bottom: 100px;
}

@media (min-width: 769px) {
  .player__list {
    padding-bottom: calc(20vh + 100px);
  }
}

label {
  width: 100%;
  align-items: center;

  span {
    color: #fff;
  }
}
</style>
