<template>
  <error404 v-if="showError"></error404>
  <div v-else class="relative match-reporter-view" :style="isNative ? 'background: #1e1e1e' : ''">
    <div class="floating-scrollbar">
      <!--
        0 = Vor dem Anstoß | 1 = 1.Halbzeit | 2 = Halbzeit | 3 = 2.Halbzeit | 4 = Abpfiff
        -->
      <div class="relative overflow-hidden">
        <div
          class="floating-scrollbar__content flex overflow-auto pr-05 relative w-full scrollbar-slim swiper-no-swiping"
          ref="scrollContainer"
        >
          <span
            :class="{ active: getLivetickerStore.phase === 0, phase_past: getLivetickerStore.phase > 0 }"
            class="pill"
            ref="pill0"
            @click="nextPhase(0)"
            >Anstoß</span
          >
          <span class="pill pill--shy" ref="pill4" @click="nextPhase(1, 'jump')" v-if="getLivetickerStore.phase < 1"
            >In 1.HZ springen</span
          >
          <span
            :class="{ active: getLivetickerStore.phase === 1, phase_past: getLivetickerStore.phase > 1 }"
            class="pill"
            ref="pill1"
            @click="nextPhase(2)"
            >Halbzeitpause</span
          >
          <span
            :class="{ active: getLivetickerStore.phase === 2, phase_past: getLivetickerStore.phase > 2 }"
            class="pill"
            ref="pill2"
            @click="nextPhase(3)"
            >Anstoss 2. Halbzeit</span
          >
          <span class="pill pill--shy" ref="pill5" @click="nextPhase(3, 'jump')" v-if="getLivetickerStore.phase < 3"
            >In 2.HZ springen</span
          >
          <span
            :class="{ active: getLivetickerStore.phase === 3, phase_past: getLivetickerStore.phase > 3 }"
            class="pill"
            ref="pill3"
            @click="nextPhase(4)"
            >Abpfiff</span
          >
        </div>
        <span class="overlay active" v-if="match.phaseConfirm" @click="confirmNextPhase"> BESTÄTIGEN! </span>
      </div>
    </div>
    <match-time
      :halftimeDuration="getLivetickerStore.halftimeDuration"
      :phase="getLivetickerStore.phase"
      :startDelay="startDelay"
      @changeTime="changeTime($event)"
    />
    <row-badges :teams="teams" :phase="getLivetickerStore.phase" :storedValue="match.stats.guest.goal" />
    <div class="matchReporter pl-4 pr-4">
      <button-change-phase
        v-if="getLivetickerStore.phase == 0"
        id="button-change-phase"
        class="btn-large"
        :phase="getLivetickerStore.phase"
        :phaseConfirm="match.phaseConfirm"
        @click="nextPhase(1)"
      />
      <div class="flex gap-2" style="display: none">
        <button-jump-phase :phase="getLivetickerStore.phase" @click="getLivetickerStore.phase++" />
        <button-jump-phase
          v-if="matchPhase === 1"
          :phase="getLivetickerStore.phase"
          :reverse="true"
          @click="getLivetickerStore.phase--"
        />
      </div>

      <div v-if="getLivetickerStore.phase === 0">
        <!-- VOR DEM ANPFIFF -->
        <!--Liga-->
        <ion-item class="matchReporter__item_container">
          <div class="mt-0 matchReporter__item">
            <label> Wettbewerb: </label>
            <div class="flex item-center">
              <season-info :season-id="match.seasonUid" v-model="match.competition" />
            </div>
          </div>
        </ion-item>
        <!--Halbzeit-->
        <ion-item class="matchReporter__item_container">
          <div class="matchReporter__item">
            <label> Halbzeitlänge: </label>
            <div class="flex item-center">
              <input v-model="getLivetickerStore.halftimeDuration" type="number" />
              <span class="supplement">Minuten</span>
            </div>
          </div>
        </ion-item>
        <div v-if="isNative" class="matchReporter__info">
          <div class="icon">
            <app-icon size="unset" name="swipe-left"></app-icon>
          </div>
          <div class="text">
            <label> Mannschaftsaufstellung </label>
            <span> Ziehe die App nach links. um die Mannschaften aufzustellen </span>
          </div>
        </div>
      </div>
      <div class="flex match-event-container" v-if="getLivetickerStore.phase > 0">
        <!-- WÄHREND DES SPIELS! -->
        <div class="matchReporter__game">
          <div class="match_yellow_card relative match_card hover-yellow-card">
            <button-match-event
              :storedValue="match.stats.home.cards.yellow"
              cardType="MatchYellowCard"
              event="cards.yellow"
              nextAction="post"
              status="home"
              template="MatchCard"
            >
              <template v-slot:icon>
                <div class="yellow-card card p2">
                  <app-icon size="medium" name="add-square" fill="#fff"></app-icon>
                </div>
              </template>
              <template v-slot:name>
                <span>Gelb</span>
              </template>
            </button-match-event>
          </div>
          <div class="match_red_card relative match_card hover-red-card">
            <button-match-event
              :storedValue="match.stats.home.cards.red"
              cardType="MatchRedCard"
              event="cards.red"
              nextAction="post"
              status="home"
              template="MatchCard"
            >
              <template v-slot:icon>
                <div class="red-card card p2">
                  <app-icon size="medium" name="add-square" fill="#fff"></app-icon>
                </div>
              </template>
              <template v-slot:name>
                <span>Rot</span>
              </template>
            </button-match-event>
          </div>
          <div class="match_yellow_red_card relative match_card hover-red-yellow-card">
            <button-match-event
              :storedValue="match.stats.home.cards.secondYellow"
              cardType="MatchYellowRedCard"
              event="cards.secondYellow"
              nextAction="post"
              status="home"
              template="MatchCard"
            >
              <template v-slot:icon>
                <div class="red-yellow-card card p2">
                  <app-icon size="medium" name="add-square" fill="#fff"></app-icon>
                </div>
              </template>
              <template v-slot:name>
                <span>Gelb/Rot</span>
              </template>
            </button-match-event>
          </div>
        </div>
        <div class="matchReporter__game">
          <div class="match_yellow_card relative match_card hover-yellow-card">
            <button-match-event
              :storedValue="match.stats.guest.cards.yellow"
              cardType="MatchYellowCard"
              event="cards.yellow"
              nextAction="post"
              status="guest"
              template="MatchCard"
            >
              <template v-slot:icon>
                <div class="yellow-card card p2">
                  <app-icon size="medium" name="add-square" fill="#fff"></app-icon>
                </div>
              </template>
              <template v-slot:name>
                <span>Gelb</span>
              </template>
            </button-match-event>
          </div>
          <div class="match_red_card relative match_card hover-red-card">
            <button-match-event
              :storedValue="match.stats.guest.cards.red"
              cardType="MatchRedCard"
              event="cards.red"
              nextAction="post"
              status="guest"
              template="MatchCard"
            >
              <template v-slot:icon>
                <div class="red-card card p2">
                  <app-icon size="medium" name="add-square" fill="#fff"></app-icon>
                </div>
              </template>
              <template v-slot:name>
                <span>Rot</span>
              </template>
            </button-match-event>
          </div>
          <div class="match_yellow_red_card relative match_card hover-red-yellow-card">
            <button-match-event
              :storedValue="match.stats.guest.cards.secondYellow"
              cardType="MatchYellowRedCard"
              event="cards.secondYellow"
              nextAction="post"
              status="guest"
              template="MatchCard"
            >
              <template v-slot:icon>
                <div class="red-yellow-card card p2">
                  <app-icon size="medium" name="add-square" fill="#fff"></app-icon>
                </div>
              </template>
              <template v-slot:name>
                <span>Gelb/Rot</span>
              </template>
            </button-match-event>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-drawer-wrapper" :class="{ hidden: !chooseImageOrVideo }">
      <div class="choose-image-video-container">
        <div class="choose-image-video">
          <div class="">
            <button-match-event
              :counter="false"
              cardType="MatchVideo"
              event="video"
              nextAction="post"
              status="home"
              template="MatchVideo"
            >
              <template v-slot:name>
                <span>Video</span>
              </template>
            </button-match-event>
            <button-match-event
              :counter="false"
              cardType="MatchImage"
              event="image"
              nextAction="post"
              status="home"
              template="MatchImage"
            >
              <template v-slot:name>
                <span>Bild</span>
              </template>
            </button-match-event>
          </div>
          <button
            class="reject-button mx-auto mt-8 block"
            style="color: black"
            @click="chooseImageOrVideo = !chooseImageOrVideo"
          >
            zurück
          </button>
        </div>
      </div>
    </div>
    <layer-input-screen
      :match="match"
      :postLayerOpened="postLayerOpened"
      :selected-team="userTeamId"
      class="mobile-layout"
    />
    <div v-if="isNative" class="vignette"></div>
  </div>
  <ion-modal v-if="showCollaborationView" :is-open="showCollaborationView">
    <collaboration-view
      v-if="showCollaborationView"
      :content-type="collabContentType"
      :inbox-id="selectedTimelineUid"
      @closeCollaborationView="showCollaborationView = false"
      @fileSelected="closeCollabView()"
    />
  </ion-modal>
</template>

<script lang="ts">
import { matchReporterComputedProps } from '@/composable/matchReporter/computed';
import { defineComponent, nextTick, onBeforeUnmount, onMounted, reactive, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import MatchTime from '@/components/MatchTime.vue';
import ButtonChangePhase from '@/components/matchReporter/ButtonChangePhase.vue';
import { emitter } from '@/bus';
import ButtonMatchEvent from '@/components/matchReporter/ButtonMatchEvent.vue';
import { useAppStore } from '@/store/app';
import { useAuthStore } from '@/store/auth';
import { useMatchStore } from '@/store/match';
import { useLivetickerStore } from '@/store/liveticker';
import RowBadges from '@/components/matchReporter/RowBadges.vue';
import LayerInputScreen from '@/components/matchReporter/LayerInputScreen.vue';
import ButtonJumpPhase from '@/components/matchReporter/ButtonJumpPhase.vue';
import { IonItem, IonModal, modalController } from '@ionic/vue';
import CollaborationView from '@/components/CollaborationView.vue';
import { chevronForwardOutline, closeOutline } from 'ionicons/icons';
import apiRequest from '@/services/apiRequest';
import SeasonInfo from '@/components/helper/uuidToString/SeasonInfo.vue';
import addMatchEvent from '@/services/addMatchEvent';
import Error404 from '@/components/helper/Error404.vue';
import 'firebase/database';
import type { Team } from '@/models/team';

export default defineComponent({
  name: 'matchReporter',
  components: {
    Error404,
    SeasonInfo,
    ButtonJumpPhase,
    LayerInputScreen,
    RowBadges,
    ButtonMatchEvent,
    ButtonChangePhase,
    MatchTime,
    CollaborationView,
    IonModal,
    IonItem,
  },
  setup() {
    const authStore = useAuthStore();
    const livetickerStore = useLivetickerStore();
    const chevronForwardOutlineIcon = ref(chevronForwardOutline);
    const closeOutlineIcon = ref(closeOutline);
    const chooseImageOrVideo = ref(false);
    const postLayerOpened = ref(false);
    const showCollaborationView = ref(false);
    const showMatchReporter = ref(true);
    const startDelay = ref(0);
    const matchId = ref('');
    const userTeamId = ref('');
    const collabContentType = ref('');
    const userClubId = ref('');
    const isNative = ref(useAppStore().isNative);
    const route = useRoute();
    const lastEmittedValue = ref(null); // initial value
    const showError = ref(false);

    const scrollContainer: Ref<HTMLElement | null> = ref(null);
    const pill0: Ref<HTMLElement | null> = ref(null);
    const pill1: Ref<HTMLElement | null> = ref(null);
    const pill2: Ref<HTMLElement | null> = ref(null);
    const pill3: Ref<HTMLElement | null> = ref(null);
    const pills = [pill0, pill1, pill2, pill3];

    const scrollToActivePill = () => {
      const matchPhase = matchStore.matchPhase;
      console.log(pills);
      const activePill = pills[matchPhase]?.value;
      if (activePill && scrollContainer?.value) {
        if ('scrollLeft' in scrollContainer.value) {
          scrollContainer.value.scrollLeft = activePill.offsetLeft - activePill.clientWidth / 2;
        }
      }
    };

    const match = reactive({
      phase: 0, //0 = Vor dem Anstoß | 1 = 1.Halbzeit | 2 = Halbzeit | 3 = 2.Halbzeit | 4 = Abpfiff
      phaseConfirm: false,
      noConfirmationNeeded: false,
      seasonUid: '',
      stats: {
        home: {
          goal: 0,
          substitute: 0,
          cards: {
            yellow: 0,
            secondYellow: 0,
            red: 0,
          },
          corner: 0,
          shots: 0,
          video: 0,
          image: 0,
        },
        guest: {
          goal: 0,
          substitute: 0,
          cards: {
            yellow: 0,
            secondYellow: 0,
            red: 0,
          },
          corner: 0,
          shots: 0,
          video: 0,
          image: 0,
        },
      },
      kickoff: {
        supplement: 0,
        timestamp: 'undefined',
      },
      halftimeDuration: 45,
      competition: {
        uid: '',
        name: '',
        inactive: false,
        current: false,
        startDate: '',
        endDate: '',
        predecessorUid: '',
        properties: null,
        competition: {
          uid: '',
          name: '',
          shortCode: '',
          badgeUrl: '',
          color: '',
          category: 0,
          type: '',
          website: '',
          countries: [],
          tag: [],
          properties: null,
          lastPlayed: '',
          inactive: false,
        },
      },
      date: useMatchStore().date,
      postOnBothTimelines: false,
    });

    const {
      homePlayersOnField,
      guestPlayersOnField,
      homeGoalsStore,
      guestGoalsStore,
      matchPhase,
      uploadProgress,
      matchCompetition,
      matchDoublePost,
      userFullCreator,
      selectedTimelineUid,
      getLivetickerStore,
      matchStore,
    } = matchReporterComputedProps(match);

    const teams = reactive({
      homeTeam: {
        uid: '',
        acronym: '',
        name: '',
        fullName: '',
        color: '',
        badgeUrl: '',
        website: '',
        teamType: '',
        inactive: false,
        tags: [],
        lastPlayed: '',
        properties: {},
        clubUids: [],
        countryUid: '' as string | undefined,
        venueUid: '',
        competitions: [],
      } as Team,
      guestTeam: {
        uid: '',
        acronym: '',
        name: '',
        fullName: '',
        color: '',
        badgeUrl: '',
        website: '',
        teamType: '',
        inactive: false,
        tags: [],
        lastPlayed: '',
        properties: {},
        clubUids: [],
        countryUid: '' as string | undefined,
        venueUid: '',
        competitions: [],
      } as Team,
    });

    const changeTime = (diff: number) => {
      const oldDateObj = new Date(match.kickoff.timestamp);
      const newDateObj = new Date(oldDateObj.getTime() - diff * 60000);
      match.kickoff.timestamp = JSON.stringify(newDateObj).replaceAll('"', '');
    };

    const closeCollabView = () => modalController.dismiss();

    const confirmNextPhase = () => {
      match.phaseConfirm = true;
      match.noConfirmationNeeded = true;
      nextPhase();
    };
    const confirmTemp = ref(999);
    const nextPhase = (phase = 0, action = '') => {
      if (match.phaseConfirm || match.noConfirmationNeeded || action === 'jump') {
        if (phase === 1) {
          livetickerStore.startMinuteTimer();
        } else if (phase === 2) {
          livetickerStore.stopMinuteTimer(getLivetickerStore.halftimeDuration);
        } else if (phase === 3) {
          livetickerStore.startMinuteTimer(getLivetickerStore.halftimeDuration);
        }

        if (action === '' && confirmTemp.value === 999) {
          publish(livetickerStore.phase as 0 | 1 | 2 | 3 | 4);
        }
        if (livetickerStore.phase === 0) {
          livetickerStore.setKickoff(Date.now());
          livetickerStore.setURL(matchId.value);
        }
        if (confirmTemp.value < 999) {
          livetickerStore.phase = confirmTemp.value;
        } else if (phase > 0) {
          livetickerStore.phase = phase;
        } else {
          livetickerStore.phase++;
        }

        if (phase === 2 || phase === 3) {
          useMatchStore().eventMinute = livetickerStore.halftimeDuration;
        }

        const time = new Date();
        match.kickoff.timestamp = JSON.stringify(time).replaceAll('"', '');
        match.phaseConfirm = false;
        match.noConfirmationNeeded = false;
        nextTick(() => scrollToActivePill());
      } else {
        if (livetickerStore.phase > phase) {
          confirmTemp.value = phase;
        }
        match.phaseConfirm = true;
        setTimeout(() => {
          match.phaseConfirm = false;
          confirmTemp.value = 999;
        }, 7000);
      }
    };
    const publish = async (matchPhase: 0 | 1 | 2 | 3 | 4) => {
      // let event = '';
      // let title = '';
      let $type = '';

      switch (matchPhase) {
        case 0:
          // event = 'MatchKickoff';
          // title = 'Anstoß';
          $type = 'MatchKickoff';
          break;
        case 1:
          // event = 'MatchHalftime';
          // title = 'Halbzeit';
          $type = 'MatchHalftime';
          break;
        case 2:
          // event = 'MatchHalftimeKickoff';
          // title = 'Anstoß';
          $type = 'MatchHalftimeKickoff';
          break;
        case 3:
          // event = 'MatchEnd';
          // title = 'Spielende';
          $type = 'MatchEnd';
          break;
        default:
          return;
      }

      await addMatchEvent.methods.addMatchEvent($type as Parameters<typeof addMatchEvent.methods.addMatchEvent>[0]);

      /*
      if (event === 'MatchHalftime' || event === 'MatchHalftimeKickoff') {
        await postMatchCard.methods.postMatchCard($type, event, title);
      } else {
        await addMatchEvent.methods.addMatchEvent($type);
      }

       */
    };

    watch(
      () => match.competition,
      (newCompetition) => {
        console.log(newCompetition);
        if (newCompetition) {
          matchStore.competitions.name = newCompetition.competition.name;
          matchStore.competitions.shortCode = newCompetition.competition.shortCode;
          matchStore.competitions.tag = newCompetition.competition.tag[0];
          matchStore.competitions.badgeUrl = newCompetition.competition.badgeUrl;
          matchStore.competitions.color = newCompetition.competition.color;
          matchStore.competitions.uid = newCompetition.competition.uid;
        }
      },
    );

    watch(selectedTimelineUid, (newTimelineUid) => {
      if (newTimelineUid) {
        matchStore.selectedTimelineUid = newTimelineUid;
      }
    });

    watch(userClubId, (newUserClubId) => {
      if (newUserClubId) {
        matchStore.userClubId = newUserClubId;
      }
    });

    const addMedia = () => {
      chooseImageOrVideo.value = true;
    };

    const toggleMedia = () => {
      useAppStore().toggleBottomDrawer();
      console.log('toggleMedia');
    };

    const goToCommentaryCard = () => {
      console.log('goToCommentaryCard');
      /*
                const matchStore = useMatchStore();
                matchStore.addActiveTeam('home');
                const layer = {} as any;
                layer['event'] = 'commentary';
                layer['template'] = 'MatchCommentary';
                layer['status'] = 'home';
                layer['show'] = true;
                emitter.emit('openPostLayer', layer);

                 */
    };

    const updateValues = () => {
      postLayerOpened.value = true;
      showMatchReporter.value = false;
    };

    const postLayerClosed = () => {
      postLayerOpened.value = false;
      chooseImageOrVideo.value = false;
      showMatchReporter.value = true;
    };

    const useApiData = async (url: string) => {
      try {
        const result = await apiRequest.methods.get(url, 'cloud');
        return result.data;
      } catch (e) {
        console.error(e);
        throw new Error('An error occurred while fetching data');
      }
    };

    const getTeamLists = async (side: string, teamID: string) => {
      const playerModelsCache: { [key: number]: any } = {};
      let playerModels: any[] = [];
      let playerModelsIndex = 0;
      try {
        const squadDataUrl = `api/data/teams/${teamID}/squads/ids`;
        const squadData = await useApiData(squadDataUrl);
        const squadID = squadData[0];
        if (Object.keys(squadData).length == 0) {
          return;
        } else if (Object.keys(squadData).length > 1) {
          for (const [key, value] of Object.entries(squadData)) {
            console.log(key);
            const squadUnfoldUrl = `api/data/squads/${value}/unfold?includes=Team&includes=Players&includes=Season`;
            const squadUnfoldData = await useApiData(squadUnfoldUrl);
            if (
              squadUnfoldData.season.uid === match.seasonUid &&
              Object.entries(squadUnfoldData.playerModels).length > 0
            ) {
              playerModels = squadUnfoldData.playerModels;
              break;
            } else if (Object.entries(squadUnfoldData.playerModels).length > 0) {
              playerModelsCache[playerModelsIndex] = squadUnfoldData.playerModels;
              playerModelsIndex++;
            }
            if (Object.entries(playerModels).length === 0) {
              let largestEntry: null | { key: string; value: unknown[] } = null;
              let largestSize = 0;

              for (const [key, value] of Object.entries(playerModelsCache)) {
                if (Array.isArray(value) && value.length > largestSize) {
                  largestSize = value.length;
                  largestEntry = { key, value };
                }
              }

              if (largestEntry !== null) {
                playerModels = largestEntry.value;
              }
            }
          }
        } else {
          const squadUnfoldUrl = `api/data/squads/${squadID}/unfold?includes=Team&includes=Players&includes=Season`;
          const squadUnfoldData = await useApiData(squadUnfoldUrl);
          playerModels = squadUnfoldData.playerModels;
        }

        const matchStore = useMatchStore();

        if (side === 'home') {
          matchStore.addHomeTeam(playerModels);
        } else {
          matchStore.addGuestTeam(playerModels);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const matchEventHandler = (e: any) => console.info(e);

    const setupListeners = () => {
      emitter.on('postLayerClosed', postLayerClosed);
      emitter.on('openPostLayer', updateValues);
      emitter.on('showMatchReporter', (e) => {
        showMatchReporter.value = e;
      });
      emitter.on('changeMatchCollabFile', (e) => {
        collabContentType.value = e;
        showCollaborationView.value = true;
      });

      emitter.on('matchEvent', matchEventHandler);
    };

    const fetchTeamData = async (teamId: string) => {
      const teamURL = 'api/data/teams/' + teamId;
      try {
        const teamResult = await apiRequest.methods.get(teamURL, 'cloud');
        return teamResult.data;
      } catch (e) {
        console.error(e);
        throw new Error('An error occurred while fetching team data');
      }
    };

    onMounted(async () => {
      setupListeners();

      document.body.classList.add('tickerPage');

      try {
        const teamId: string | number = Array.isArray(route.params.teamid)
          ? route.params.teamid[0]
          : route.params.teamid;
        matchId.value = Array.isArray(route.params.matchid) ? route.params.matchid[0] : route.params.matchid;

        // noinspection SuspiciousTypeOfGuard
        if (typeof teamId !== 'string' || typeof matchId.value !== 'string') {
          console.error('Team ID or Match ID is not a string');
          return;
        }

        matchStore.selectedTimelineUid = teamId;

        const livetickerStore = useLivetickerStore();
        await livetickerStore.setAndLoadMatch(matchId.value);
        const matchFixture = livetickerStore.matchFixture!;

        await livetickerStore.getMatchStatus(matchId.value);

        teams.homeTeam = matchFixture.homeTeam;
        teams.guestTeam = matchFixture.guestTeam;
        match.seasonUid = matchFixture.seasonUid;

        //Liegt der Anstoß länger als 5 Stunden zurück, zeigen wir den Abpfiff an!
        const startDateString = matchFixture.startDate;
        const startDate = new Date(startDateString);
        const now = new Date();
        const timeDifference = now.getTime() - startDate.getTime();
        const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);
        if (timeDifferenceInHours > 5) {
          livetickerStore.phase = 4;
          livetickerStore.currentMinute = 90;
          startDelay.value = 45;
        }

        const teamData = await fetchTeamData(teamId);
        userClubId.value = teamData.clubUids[0];

        if (matchFixture.homeTeam.acronym === authStore.userSettings.activeClub?.acronym) {
          userTeamId.value = matchFixture.homeTeam.uid;
        } else if (matchFixture.guestTeam.acronym === authStore.userSettings.activeClub?.acronym) {
          userTeamId.value = matchFixture.guestTeam.uid;
        }

        await Promise.all([getTeamLists('home', teams.homeTeam.uid), getTeamLists('guest', teams.guestTeam.uid)]);

        useMatchStore().matchId = matchId.value;
      } catch (error) {
        console.error(error);
      }
    });

    onBeforeUnmount(() => {
      document.body.classList.remove('tickerPage');
      matchStore.resetState();
      emitter.off('postLayerClosed', postLayerClosed);
      emitter.off('openPostLayer', updateValues);
      emitter.off('showMatchReporter', (e) => {
        showMatchReporter.value = e;
      });
      emitter.off('matchEvent', matchEventHandler);
      emitter.off('changeMatchCollabFile', (e) => {
        collabContentType.value = e;
        showCollaborationView.value = true;
      });
    });

    return {
      chevronForwardOutlineIcon,
      closeOutlineIcon,
      closeOutline,
      closeCollabView,
      nextPhase,
      confirmNextPhase,
      matchStore,
      getLivetickerStore,
      match,
      addMedia,
      chooseImageOrVideo,
      toggleMedia,
      goToCommentaryCard,
      teams,
      startDelay,
      homePlayersOnField,
      guestPlayersOnField,
      homeGoalsStore,
      guestGoalsStore,
      matchPhase,
      uploadProgress,
      matchCompetition,
      matchDoublePost,
      userFullCreator,
      selectedTimelineUid,
      lastEmittedValue,
      postLayerOpened,
      showMatchReporter,
      isNative,
      userTeamId,
      showCollaborationView,
      collabContentType,
      changeTime,
      userClubId,
      scrollContainer,
      pill0,
      pill1,
      pill2,
      pill3,
      showError,
    };
  },
});
</script>

<style lang="scss" scoped>
.font-xs {
  font-size: 0.9rem;
}

.mt-0 {
  margin-top: 0;
}

.active {
  display: flex !important;
}

.ticker-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  backdrop-filter: blur(2px);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background: #000000ad;
  padding: 2rem;
  display: none;
}

.match-reporter-view {
  margin-top: 58px;
}

.vignette {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(ellipse at top, transparent 0%, black 120%);
    mix-blend-mode: multiply;
    pointer-events: none;
  }
}
</style>
