<template>
  <div v-if="jsonData" class="teamInfos">
    <div>
      {{ jsonData.name }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import apiRequest from '@/services/apiRequest';
import { Season } from '@/models/season';
import { isAxiosError } from 'axios';
import { AxiosError } from 'axios';

export default defineComponent({
  name: 'SeasonInfo',
  props: {
    seasonId: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number, Object],
      default: null,
    },
  },
  setup(props, { emit }) {
    const jsonData = ref<Season>();
    const isLoading = ref(false);
    const error = ref<string | AxiosError | null>(null);

    const fetchData = async () => {
      // Überprüfen, ob seasonId vorhanden ist
      if (!props.seasonId) {
        return;
      }

      isLoading.value = true;
      error.value = null;

      try {
        const url = `api/data/seasons/${props.seasonId}/unfold`;
        const result = await apiRequest.methods.get(url, 'cloud');

        if (result.error) {
          error.value = isAxiosError(result.error) ? result.error.message : result.error;
        } else {
          jsonData.value = result.data;
        }
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(fetchData);
    watch(() => props.seasonId, fetchData, { immediate: true });

    watch(
      () => jsonData.value,
      (newVal) => {
        // Wann immer jsonData aktualisiert wird, aktualisiere auch modelValue
        emit('update:modelValue', newVal);
      },
    );

    return { jsonData, isLoading, error };
  },
});
</script>

<style lang="scss" scoped>
.teamInfos__badge {
  display: inline-block !important;
}
.teamInfos * {
  color: #000000;
  font-weight: 600;
}
</style>
