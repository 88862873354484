<script lang="ts" setup>
import { close } from 'ionicons/icons';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import { computed, ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import type { Player } from '@/models/player';
import { httpGet } from '@/services/apiRequestV2';

const props = defineProps<{ playerId?: string; teamId: string; matchFixtureId?: string }>();

const emit = defineEmits<{ (e: 'player-selected', player: Player): void }>();

const modal = ref<{ $el: HTMLIonModalElement }>();

const closeModal = () => modal.value?.$el.dismiss();

const onSelectPlayer = (player: Player) => {
  emit('player-selected', player);
  closeModal();
};

const {
  data: currentPlayers,
  isLoading: isCurrentPlayersLoading,
  isError: isCurrentPlayersError,
} = useQuery({
  queryKey: ['current-players', props.teamId],
  queryFn: async () => {
    const response = await httpGet<Player[]>(`/api/data/teams/${props.teamId}/current/players`, 'cloud');
    return response.data;
  },
});

const availablePlayers = computed(() =>
  currentPlayers.value?.toSorted((a, b) => a.fullName.split(' ').pop()!.localeCompare(b.fullName.split(' ').pop()!)),
);
</script>

<template>
  <ion-modal ref="modal">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon :icon="close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Spieler ändern</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="m-3">
        <ion-spinner v-if="isCurrentPlayersLoading" color="black" name="crescent" />
        <span v-else-if="isCurrentPlayersError">Fehler beim Laden der Spieler</span>
        <template v-else-if="availablePlayers">
          <ion-label
            v-for="player in availablePlayers"
            :key="player.uid"
            class="lineup__name"
            @click="onSelectPlayer(player)"
          >
            <div class="rounded-full bg-white overflow-hidden inline-block align-middle ml-1 mr-1 w-11">
              <img class="" :src="player.profilePictureUrl" />
            </div>
            <span class="lineup__jersey">{{ player.jerseyNumber }}</span>
            <span class="lineup__ellipsis font-bold">{{ player.fullName }}</span>
          </ion-label>
        </template>
      </div>
    </ion-content>
  </ion-modal>
</template>

<style lang="scss" scoped>
.lineup__name {
  padding: 0.5rem;
  border-radius: 0.5rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 1px #0ff;
  }

  &:nth-child(odd) {
    background: rgba(255, 255, 255, 0.09);
  }
}

ion-toolbar {
  ion-button {
    ion-icon {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
}
</style>
