<template>
  <div class="h-full flex flex-col">
    <div class="flex">
      <div class="player-column">
        <div class="header">
          <label>Tor</label>
          <ion-toggle></ion-toggle>
          <label>Eigentor</label>
        </div>
        <div>
          <ul class="player-list goals">
            <li class="player-item">
              <div class="player">
                <img src="https://s.hs-data.com/bilder/spieler/gross/193684.jpg" />
                <span>Kevin Dickelhuber</span>
                <b>20</b>
              </div>
            </li>
            <li class="player-item active">
              <div class="player">
                <img src="https://s.hs-data.com/bilder/spieler/gross/193684.jpg" />
                <span>Kevin Dickelhuber</span>
                <b>2</b>
              </div>
            </li>
            <li class="player-item">
              <div class="player">
                <img src="https://s.hs-data.com/bilder/spieler/gross/193684.jpg" />
                <span>Kevin Dickelhuber</span>
                <b>11</b>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="border"></div>
      <div class="player-column">
        <div class="header">
          <span>Assist</span>
        </div>
        <div>
          <ul class="player-list assists">
            <li class="player-item">
              <div class="player">
                <b>12</b>
                <span>Kevin Dickelhuber</span>
                <img src="https://s.hs-data.com/bilder/spieler/gross/193684.jpg" />
              </div>
            </li>
            <li class="player-item">
              <div class="player">
                <b>25</b>
                <span>Kevin Dickelhuber</span>
                <img src="https://s.hs-data.com/bilder/spieler/gross/193684.jpg" />
              </div>
            </li>
            <li class="player-item active">
              <div class="player">
                <b>7</b>
                <span>Kevin Dickelhuber</span>
                <img src="https://s.hs-data.com/bilder/spieler/gross/193684.jpg" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="goal-info">
      <div>12 : 2</div>
      <div class="flex gap-2"><ion-icon class="m-0" :icon="timeOutline"></ion-icon>38 + 0</div>
      <div class="submit"><ion-icon class="m-0" :icon="checkmarkOutline"></ion-icon></div>
    </div>
  </div>
</template>

<script lang="ts">
import { IonToggle, IonIcon } from '@ionic/vue';
import { timeOutline, checkmarkOutline } from 'ionicons/icons';

export default {
  name: 'matchGoals',
  data() {
    return {
      timeOutline,
      checkmarkOutline,
    };
  },
  components: {
    IonToggle,
    IonIcon,
  },
};
</script>
