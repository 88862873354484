import { CloudFile } from '@/views/cloud-page/CloudFile';
import { computed, type Ref } from 'vue';
import { FileKind, hasKind } from '@/services/filePicker/models';
import { getFileKindFromMimeType } from '@/services/filePicker/mime-types';
import { useAuthStore } from '@/store/auth';
import { useQuery } from '@tanstack/vue-query';
import { httpGet } from '@/services/apiRequestV2';

type CloudFileEntries = {
  inbox: string;
  entries: CloudFile[];
};

export function useCloudFiles(fileKindFilter: Ref<FileKind>) {
  const authStore = useAuthStore();
  const inboxId = computed(() => authStore.selectedTimelineUid);

  const {
    data: all,
    refetch,
    isLoading,
    isFetched,
  } = useQuery({
    initialData: [] as CloudFile[],
    enabled: computed(() => !!inboxId.value),
    queryKey: computed(() => ['cloudFiles', inboxId.value]),
    queryFn: async () => {
      const res = await httpGet<CloudFileEntries>(`api/inbox/entries?inbox=${inboxId.value}`, 'cloud');
      res.data.entries = res.data.entries.map((f) => ({
        ...f,
        url: `https://cdn.leagues.network/inbox/${inboxId.value}/${f.fileName}`,
      }));
      return res.data.entries;
    },
  });

  const filtered = computed(() =>
    all.value.filter((x) => hasKind(getFileKindFromMimeType(x.contentType) ?? FileKind.Any, fileKindFilter.value)),
  );

  const getPreviewUrl = (fileName: string, contentType: string) => {
    let extension = '';
    if (contentType === 'video/mp4') {
      extension = '.jpg';
    }
    return `https://cdn.leagues.network/inbox/${inboxId.value}/${fileName}${extension}`;
  };

  return {
    isLoading,
    isFetched,
    filtered,
    refetch,
    getPreviewUrl,
  };
}
