<template>
  <div class="inputText">
    <select v-if="options.length > 0" @change="selectOption" v-model="selectedOptionNumber" class="w-full mt-2">
      <option v-for="option in options" :value="option.key" :key="option.key">
        {{ option.value }}
      </option>
    </select>
    <div v-if="placeholder" class="placeholder-label" v-html="placeholder"></div>
    <div style="background: transparent !important" class="flex flex-col">
      <input
        class="inputText__input"
        v-if="allowedTypes.includes(type)"
        :type="type"
        :value="value"
        @input="(ev) => updateValue(ev.target?.value)"
        @keyup.enter="submitAction"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :data-testid="dataTestid"
      />
      <div class="remaining-text">{{ remainingCharactersText }}</div>
    </div>
    <span v-html="note"></span>
    <div v-if="submit" @click="submitAction">OK</div>
  </div>
</template>

<script lang="ts">
/*
  Operating instructions: draggableBottomDrawer
  * Binde die Komponente an einer beliebigen Stelle ein!
  Als type sind 'text', 'password', 'search', 'email', 'url', 'tel' möglich
                <input-text
                  v-model="value"
                  type="text"
                  placeholder="Name des Spielers"
                  :submit="true"
                  :enter="true"
                  @inputTextAction="handleAdditionalValue"
                  :options="['Name', 'Stadt']"
                  @selectOption="searchSelectFunction"

                />

                Per v-model wird der Inhalt übertragen

                Der Parameter submit (default = false) blendet einen Button ein
                Mit @inputTextAction="handleAdditionalValue" kann dieser in Empfang genommen werden

                Der Parameter enter (default = false) aktiviert im Input Feld die Entertaste "keyup.enter"
                wenn submit = true, dann wird die Funktion automatisch aktiviert
                Mit @inputTextAction="handleAdditionalValue" kann dieser in Empfang genommen werden

                Vor das Input Feld kann ein Options Feld gesetzt werden, die Werte müssen als Array übergeben werden
                :options="[
                    { key: 'name', value: 'Name' },
                    { key: 'cityName', value: 'Stadt' }
                  ]"
                Mit @selectOption können Sie wieder entgegengenommen werden
  * */

import { ref, watch, Ref, defineComponent, onMounted, computed } from 'vue';

interface OptionType {
  key: string;
  value: string;
}

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    note: {
      type: String,
      default: '',
    },
    submit: {
      type: Boolean,
      default: false,
    },
    switchSelectBack: {
      type: Boolean,
      default: false,
    },
    enter: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as () => OptionType[],
      default: () => [],
    },
    maxlength: {
      type: Number,
      default: 99999,
    },
    minlength: {
      type: Number,
      default: 0,
    },
    dataTestid: {
      type: String,
    },
  },
  emits: ['update:modelValue', 'inputTextAction', 'selectOption'],
  setup(
    props: {
      minlength: number;
      maxlength: number;
      enter: boolean;
      submit: boolean;
      modelValue: string;
      options: OptionType[];
      switchSelectBack: boolean;
    },
    { emit },
  ) {
    const value: Ref<string> = ref(props.modelValue);
    const selectedOptionNumber = ref(props.options[0]?.key);

    const allowedTypes: string[] = ['text', 'password', 'search', 'email', 'url', 'tel', 'color'];

    function updateValue(newValue: string) {
      value.value = newValue;
    }

    const submitAction = () => {
      emit('inputTextAction', true);
    };

    const enterAction = () => {
      if (props.enter || props.submit) {
        emit('inputTextAction', true);
      }
    };

    const selectOption = () => {
      emit('selectOption', selectedOptionNumber.value);
    };

    watch(
      () => props.modelValue,
      (newVal: string) => {
        if (newVal !== value.value) {
          value.value = newVal;
        }
      },
      { deep: true, immediate: true },
    );

    watch(
      () => props.switchSelectBack,
      (newVal) => {
        if (newVal) {
          selectedOptionNumber.value = props.options[0].key;
        }
      },
      { deep: true, immediate: true },
    );

    watch(
      value,
      (newValue: string) => {
        if (newValue !== props.modelValue) {
          emit('update:modelValue', newValue);
        }
      },
      { deep: true, immediate: true },
    );

    onMounted(() => {
      if (props.options.length > 0) {
        selectedOptionNumber.value = props.options[0].key;
      }
    });

    const remainingCharactersText = computed(() => {
      const remainingMinLength = props.minlength - value.value.length;
      const remainingMaxLength = props.maxlength - value.value.length;

      if (remainingMinLength > 0 && value.value.length > 0 && props.minlength > 0) {
        return `Du benötigst noch mindestens ${remainingMinLength} Buchstaben.`;
      }

      if (remainingMaxLength >= 0 && remainingMaxLength < props.maxlength && props.maxlength < 9999) {
        return `Du kannst noch maximal ${remainingMaxLength} Buchstaben eingeben.`;
      }

      return '';
    });

    return {
      value,
      allowedTypes,
      updateValue,
      submitAction,
      enterAction,
      selectOption,
      selectedOptionNumber,
      remainingCharactersText,
    };
  },
});
</script>
<style lang="scss" scoped>
.inputText {
  margin-bottom: 1rem;
  /*height: 40px;*/
  --keyboard-offset: 30vh;
  --overflow: hidden;
}

.inputText__input {
  padding: 4px;
  background: #3b3b3b;
}

.accordion-content {
  .remaining-text {
    font-size: 12px;
    position: absolute;
    top: 45px;
  }
}
select {
  background: #3b3b3b;
  border-radius: 0.5rem;
  padding: 0.5rem !important;
  margin-bottom: 0.5rem;
}
</style>
