<template>
  <div class="preview-slider">
    <div class="preview-slider-body" @click="emit('addMedia')" v-if="mediaElements.length === 0">
      <ion-icon class="placeholder" :icon="add"></ion-icon>
    </div>
    <div
      class="preview-slider-body"
      v-else
      @click="emit('toggleSlideshow', mediaElements, shownIndex)"
      @scroll="
        (ev: Event) => {
          const slider = ev.target as HTMLDivElement;
          const imgCount = slider.scrollWidth / mediaElements.length;
          shownIndex = Math.round(slider.scrollLeft / imgCount);
        }
      "
    >
      <template
        v-for="mediaElement in mediaElements"
        v-bind:key="mediaElement.$type === 'Video' ? mediaElement.videoUrl : mediaElement.imageUrl"
      >
        <img
          class="slider-image"
          :src="mediaElement.$type === 'Video' ? `${mediaElement.videoUrl}.jpg` : mediaElement.imageUrl"
          alt="Medienvorschau"
        />
      </template>
    </div>
    <div class="dots" v-if="mediaElements.length > 1">
      <div
        :class="[
          'dot',
          {
            active: shownIndex === i,
            'dot-small': i !== shownIndex && (i < shownIndex - 1 || i > shownIndex + 1),
          },
        ]"
        v-for="i in [...Array(mediaElements.length === 0 ? 0 : mediaElements.length).keys()]"
        v-bind:key="i"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue';
import { ImageElement, VideoElement } from '@/models/media-element';
import { add } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';

defineProps({
  mediaElements: {
    type: Object as PropType<Array<ImageElement | VideoElement>>,
    required: true,
  },
});

const shownIndex = ref(0);

const emit = defineEmits<{
  (e: 'toggleSlideshow', mediaElements: Array<ImageElement | VideoElement>, selectedIdx: number): void;
  (e: 'addMedia'): void;
}>();
</script>

<style lang="scss">
.preview-slider {
  min-height: 72px;
  height: 100%;
  min-width: 72px;
  width: 72px;
  position: relative;

  .preview-slider-body {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    height: 100%;
    align-items: center;

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      min-width: 72px;
      min-height: 72px;
      max-width: 72px;
      max-height: 72px;
      object-fit: cover;
      filter: brightness(80%);
      scroll-snap-align: start;
    }

    .video-container {
      min-width: 100%;
      min-height: 100%;
      scroll-snap-align: start;
      position: relative;

      .progress {
        position: absolute;
        top: 28px;
        left: 0;
        width: 100%;
        height: 3px;
        background: #fff;

        .progressbar {
          position: absolute;
          top: 0;
          left: 0;
          width: 30%;
          height: 100%;
          background: var(--ion-color-primary, #3880ff);
        }
      }

      video {
        scroll-snap-align: start;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .placeholder {
    opacity: 0.2;
    background: #000;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .dots {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 5px;
    gap: 3px;
    z-index: 1;
    flex-wrap: wrap;
    align-items: center;

    .dot {
      width: 6px;
      height: 6px;
      background: #fff;
      border-radius: 50%;
    }

    .dot-small {
      width: 3px;
      height: 3px;
      background: #fff;
      border-radius: 3px;
    }

    .line {
      height: 6px;
      width: 13px;
      background: #fff;
      border-radius: 5px;
    }

    .active {
      border: 2px solid #00d4d6;
      background: none;
    }
  }
}
</style>
