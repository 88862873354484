<script setup lang="ts">
import { ref, watchEffect, computed } from 'vue';
import { TimelineCard } from '@/models/timeline-card';
import DraggableBottomDrawer from '@/components/functionality/DraggableBottomDrawer.vue';
import { arrowBack, arrowForward } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';
import PostMediaForm from '@/views/feed/card-forms/PostMediaForm.vue';
import PostNewsForm from '@/views/feed/card-forms/PostNewsFormDrawer.vue';
import { usePostDrawerStore } from '@/views/feed/post-drawer-store';
import { useAppStore } from '@/store/app';

const isNative = computed(() => appStore.isNative);

const props = defineProps<{
  cardReference?: TimelineCard;
}>();

const mode = ref<undefined | 'media' | 'news'>(undefined);

const store = usePostDrawerStore();

watchEffect(() => {
  if (props.cardReference) {
    store.allowSwitchingPostMode = false;
  }
});

const appStore = useAppStore();

watchEffect(() => {
  if (appStore.bottomDrawer) {
    mode.value = 'media';
  }
});
</script>

<!--suppress HtmlUnknownTag -->
<template>
  <draggable-bottom-drawer class="feed-shortcut background-fix">
    <template #headline>
      <div class="flex justify-center flex-row items-end gap-8">
        <button class="switch-to" v-if="store.allowSwitchingPostMode && mode === 'news'" @click="mode = 'media'">
          <ion-icon :icon="arrowBack" />Media Post
        </button>
        <span>{{ store.viewTitle }}</span>
        <button class="switch-to" v-if="store.allowSwitchingPostMode && mode === 'media'" @click="mode = 'news'">
          News Post<ion-icon :icon="arrowForward" />
        </button>
      </div>
    </template>
    <div class="feed-overlay">
      <post-media-form v-if="mode === 'media'" :card-reference="cardReference" />
      <post-news-form v-if="mode === 'news'" />
      <div class="opacity-40 text-center mt-4" v-if="!isNative">
        Bitte beachte beim Hochladen von Mediendateien, dass diese in der Desktop-Version nicht komprimiert werden und
        die Dateigröße <b>35MB</b> nicht überschreiten darf.
      </div>
    </div>
  </draggable-bottom-drawer>
</template>

<style lang="scss" scoped>
.active.feed-shortcut {
  max-height: 300px;
  margin-bottom: 0;
  padding: 1rem;
}

.input-wrapper {
  position: relative;
  margin-bottom: 2rem;

  input,
  textarea {
    position: relative;
    z-index: 1;
    background: #35393f;
    width: 100%;
    border-radius: 4px;
    padding: 5px;
  }
}

.feed-shortcut {
  * {
    color: #fff !important;
  }

  .draggableBottomDrawer {
    &__element {
      background: #191e24;
    }
  }

  h2 {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .feed-shortcut-body {
    display: flex;
    justify-content: center;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tutorial {
    background: #00d4d6;
    border-radius: 9px;
    border-top-left-radius: 0;
    display: inline-block;
    padding: 5px 10px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

.feed-overlay {
  max-width: 850px;
  padding: 1rem 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  scrollbar-width: none;

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    width: 100%;
  }

  .tags {
    display: flex;
    gap: 0.5rem;
    overflow: scroll;
    flex-wrap: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    .tag {
      border: 1px solid #5b5a5a;
      padding: 5px 10px;
      border-radius: 50px;
      background: #35393f;
      color: gray;
      font-weight: 600;
      line-height: 1;
      cursor: pointer;

      &:hover {
        border-color: #fff;
      }
    }

    .active {
      background: #68707d !important;
      color: #fff !important;
    }
  }

  .feed-overlay-close {
    max-height: 36px;
    max-width: 36px;
    min-width: 36px;
    background: #35393f;
    border-radius: 50%;
    padding: 6px;
    display: flex;
    justify-content: center;
  }
}

.switch-to {
  text-transform: uppercase;
  font-weight: bold;
  opacity: 1;

  text-align: center;
  color: #00d4d6 !important;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;

  ion-icon {
    margin: 0;
    color: inherit !important;
  }
}

.form-container {
  padding: 5px;
  max-width: 500px;
}

.microphone {
  cursor: pointer;
}

@keyframes shimmer {
  0% {
    left: -200%;
    top: 0;
  }

  100% {
    left: 200%;
    top: 0;
  }
}
</style>
