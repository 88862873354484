import apiRequest from '@/services/apiRequest';
import { CloudFile } from '@/views/cloud-page/CloudFile';

export default {
  // TODO: calculateNumColumns probably doesn't work for both CloudPage and CollaborationView
  calculateNumColumns() {
    // ab 337px Bildschirmweite werden zwei Elemente pro Reihe angezeigt
    // ab 501 haben die Elemente 150 px Weite, ab 331 wird es festgelegt auf zwei pro reihe
    // 332 --> 501 haben die Elemente die Weite (width-padding(32))/3)
    // 2px wird hinzugefügt, wegen dem margin
    const height = window.innerHeight;
    const width = window.innerWidth;
    let elementWidth = 150 + 2;
    if (width > 337 && width < 501) {
      elementWidth = (width - 32) / 3 + 2;
    } else if (width <= 337) {
      elementWidth = (width - 32) / 2 + 2;
    }
    // Es werden so viele Elemente hinzugefügt, wie auf den Bildschirm vom Gerät passen + eine Reihe
    return Math.floor(height / elementWidth) * Math.floor(width / elementWidth) + Math.floor(width / elementWidth);
  },
  async getFiles(inboxId: string): Promise<false | { inbox: string; entries: CloudFile[] }> {
    const response = await apiRequest.methods.get(`api/inbox/entries?inbox=${inboxId}`, 'cloud');

    if (response.error) {
      return false;
    } else {
      return response.data;
    }
  },
};
