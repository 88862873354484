<template>
  <div class="add-file bg-primary cursor-pointer">
    <label v-if="!isNative" class="cursor-pointer" for="upload-file">
      <ion-icon :icon="add" class="add-file__icon" color="primary" />
      <input id="upload-file" ref="audio" :accept="accept" class="hidden" type="file" @change="emitFile($event)" />
    </label>
    <ion-icon v-else :icon="add" class="add-file__icon" color="primary" @click="emitFile(false)" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { add } from 'ionicons/icons';
import { useAppStore } from '@/store/app';

export default defineComponent({
  name: 'fixedAddButton',
  data() {
    return {
      isNative: useAppStore().isNative,
      add,
    };
  },
  props: {
    accept: String,
  },
  methods: {
    emitFile(file: any) {
      this.$emit('emitFile', file);
    },
  },
});
</script>

<style lang="scss" scoped>
.add-file {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 60px;
  border-radius: 50%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &__icon {
    background: black;
    border-radius: 5px;
    padding: 3px;
    --ionicon-stroke-width: 80px;
    width: 19px;
    height: 19px;
    margin-bottom: 0;
  }

  label {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
