<template>
  <div class="teamNamePattern__item">
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
  name: 'patternList',
};
</script>
