<script setup lang="ts">
const reloadPage = () => {
  if (window?.location) {
    window.location.reload();
  } else {
    console.error('Window oder Location ist nicht verfügbar');
  }
};
</script>

<template>
  <div class="error-page">
    <div class="text-2xl md:text-4xl text-center font-bold uppercase md:leading-loose">
      <div class="quote">Aus dem Hintergrund müsste Rahn schießen!</div>
      <div class="quote">Rahn schießt</div>
      <div class="quote">Daneben! Daneben! Daneben!</div>
      <hr />
      <div class="text">Ups! Das war hier ein wenig anders geplant!</div>
    </div>
    <div class="default-button" @click="reloadPage">Erneut versuchen</div>
  </div>
</template>

<style scoped lang="scss">
.quote {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 3px;

  &:before {
    content: '»';
    font-size: 3rem;
    align-self: center;

    &:nth-of-type(1) {
      opacity: 0.6;
    }

    &:nth-of-type(2) {
      opacity: 0.8;
    }
  }

  &:after {
    content: '«';
    font-size: 3rem;
    display: flex;
    align-self: center;

    &:nth-of-type(1) {
      opacity: 0.6;
    }

    &:nth-of-type(2) {
      opacity: 0.8;
    }
  }

  &:nth-of-type(1) {
    opacity: 0.6;
  }

  &:nth-of-type(2) {
    opacity: 0.8;
  }
}

hr {
  max-width: 50px;
  margin: 0 auto;
}

.text {
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 1rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.error-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 95vw;
  margin: 0 auto;

  .default-button {
    max-width: 350px;
    margin: 0 auto;
    margin-top: 1rem;
    width: 100%;
  }
}
</style>
