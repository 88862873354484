<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  modalController,
} from '@ionic/vue';
import { Skeletor } from 'vue-skeletor';
import CloudFilePreview from '@/views/cloud-page/CloudFilePreview.vue';
import { CloudFile } from '@/views/cloud-page/CloudFile';
import { getFileKindFromMimeType } from '@/services/filePicker/mime-types';
import { FileKind, hasKind, isSingleKind } from '@/services/filePicker/models';
import { useCloudFiles } from '@/components/cloud-files/useCloudFiles';
import { chevronBack, close } from 'ionicons/icons';

const props = withDefaults(
  defineProps<{ selectMode?: boolean; allowedFileKind?: FileKind; previewInModal?: boolean }>(),
  {
    selectMode: false,
    allowedFileKind: FileKind.Any,
    previewInModal: false,
  },
);

const emit = defineEmits<{ (e: 'fileSelected', cloudFile: CloudFile, previewUrl: string): unknown }>();

const selectedFile = ref<CloudFile>();

const fileKindFilter = ref<FileKind>(isSingleKind(props.allowedFileKind) ? props.allowedFileKind : FileKind.Any);

const { filtered, getPreviewUrl, refetch: refetchCloudFiles, isLoading, isFetched } = useCloudFiles(fileKindFilter);

const isOver = ref(false);
const drag = ref<HTMLLabelElement>();

onMounted(() => {
  drag.value?.addEventListener('dragover', () => {
    isOver.value = true;
  });
  drag.value?.addEventListener('dragleave', () => {
    isOver.value = false;
  });
});

const noneAvailableFindKindName = computed(() => {
  switch (fileKindFilter.value) {
    case FileKind.Video:
      return 'Videos';
    case FileKind.Audio:
      return 'Audiodateien';
    case FileKind.Image:
      return 'Bilder';
    default:
      return 'Dateien';
  }
});

const filePreviewModal = ref<{ $el: HTMLIonModalElement }>();

const closeModal = () => filePreviewModal.value?.$el.dismiss();
</script>

<template>
  <template v-if="selectedFile && previewInModal">
    <ion-modal ref="filePreviewModal" :is-open="true" @will-dismiss="selectedFile = undefined">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="closeModal()">
              <ion-icon :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title>Dateivorschau</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <div class="m-2">
          <cloud-file-preview
            :file="selectedFile"
            @reset="
              refetchCloudFiles();
              selectedFile = undefined;
            "
            :selectMode="selectMode"
            @selected="
              (cloudFile) => {
                emit('fileSelected', cloudFile, getPreviewUrl(selectedFile!.fileName, selectedFile!.contentType));
                modalController.dismiss();
              }
            "
          />
        </div>
      </ion-content>
    </ion-modal>
  </template>
  <template v-if="selectedFile && !previewInModal">
    <button class="flex flex-row items-center gap-1 mb-4" @click="selectedFile = undefined" type="button">
      <ion-icon :icon="chevronBack"></ion-icon> Zurück
    </button>
    <cloud-file-preview
      :file="selectedFile"
      @reset="
        refetchCloudFiles();
        selectedFile = undefined;
      "
      :selectMode="selectMode"
      @selected="
        (cloudFile) => {
          emit('fileSelected', cloudFile, getPreviewUrl(selectedFile!.fileName, selectedFile!.contentType));
          modalController.dismiss();
        }
      "
    />
  </template>
  <template v-if="!selectedFile || previewInModal">
    <div class="gallery-filter">
      <button
        v-if="!isSingleKind(allowedFileKind)"
        @click="fileKindFilter = FileKind.Any"
        :class="{ active: fileKindFilter === FileKind.Any }"
        type="button"
      >
        Alle
      </button>
      <button
        v-if="hasKind(allowedFileKind, FileKind.Image)"
        @click="fileKindFilter = FileKind.Image"
        :class="{ active: fileKindFilter === FileKind.Image }"
        type="button"
      >
        Bilder
      </button>
      <button
        v-if="hasKind(allowedFileKind, FileKind.Video)"
        @click="fileKindFilter = FileKind.Video"
        :class="{ active: fileKindFilter === FileKind.Video }"
        type="button"
      >
        Videos
      </button>
    </div>
    <ion-list>
      <template v-if="filtered.length > 0">
        <div class="file-viewer">
          <template v-for="file in filtered" :key="file.url">
            <div v-if="getFileKindFromMimeType(file.contentType) === FileKind.Image" class="file-container">
              <img :src="file.url" :alt="file.fileName" />
              <ion-button @click="selectedFile = file">Open</ion-button>
            </div>
            <div v-else-if="getFileKindFromMimeType(file.contentType) === FileKind.Video" class="file-container">
              <img :alt="file.fileName" :src="getPreviewUrl(file.fileName, file.contentType)" />
              <ion-button @click="selectedFile = file">Open</ion-button>
              <div class="file-label">
                <svg
                  fill="#fff"
                  x="0px"
                  y="0px"
                  width="163.861px"
                  height="163.861px"
                  viewBox="0 0 163.861 163.861"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275 c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-else-if="isLoading">
        <div class="file-viewer">
          <template v-for="n in 5" :key="n">
            <div class="file-container">
              <skeletor height="100%" width="100%" />
            </div>
          </template>
        </div>
      </template>
      <template v-else-if="isFetched">
        <h1 class="text-center text-xl w-full font-bold pt-2">
          Es stehen keine {{ noneAvailableFindKindName }} zur Verfügung
        </h1>
      </template>
    </ion-list>
  </template>
</template>

<style lang="scss">
$gallery-gap: 2px;

.file-container {
  @media (min-width: 480px) and (max-width: 767px) {
    width: calc(25% - #{$gallery-gap});
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: calc(20% - #{$gallery-gap});
  }

  @media (min-width: 1024px) and (max-width: 1199px) {
    width: calc(12.5% - #{$gallery-gap});
  }

  @media (min-width: 1200px) {
    width: calc(10% - #{$gallery-gap});
  }
}

.upload-area {
  margin-top: 0;
}

.add-file {
  label {
    padding: 0;
    border: none;
    margin: 0;
  }
}

.gallery-filter {
  margin-bottom: 1rem;
  display: flex;
  gap: 0.5rem;

  button {
    opacity: 0.5;
  }

  .active {
    opacity: 1;
  }
}

.file-viewer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: $gallery-gap;

  img,
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

ion-list,
.list-md {
  background-color: transparent;
}

.inspect-file {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  img,
  video {
    max-width: 80%;
    max-height: 80%;
    margin: auto;

    @media (min-width: 768px) {
      max-width: 70%;
      max-height: 70%;
    }
  }
}

.show-more-button {
  position: relative;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
}

.none {
  display: none !important;
}

.drop-in {
  &:before {
    content: '';
  }
}

ion-button::part(native) {
  background: none;
  box-shadow: none;

  &:after {
    background: none !important;
  }
}

.profile {
  display: flex;
  align-items: center;

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--ion-tab-bar-background, #1f1f1f);
    border-radius: 50%;
    border: 2px solid #00a0a1;
    margin-right: 0.5rem;
    height: 48px;
    width: 48px;
  }

  .profile-name {
    background: #1f1f1fbd;
    padding: 5px 10px;
    border-radius: 6px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.file-label {
  position: absolute;
  width: 100%;
  top: calc(100% - 24px - 4px);
  left: 0;
  pointer-events: none;
  background: linear-gradient(180deg, #1f1f1f00 0%, #00000082 100%);
  padding: 5px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.audio-view {
  border: 2px solid var(--ion-tab-bar-background, #1f1f1f) !important;
  position: relative;

  svg {
    height: 100%;
    width: 100%;
  }

  .text-overflow {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1px 3px;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
}

.file-container {
  aspect-ratio: 9 / 16;
  -o-object-fit: cover;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  cursor: pointer;
  width: calc(33% - #{$gallery-gap});
  border: 1px solid #0d0d0d;

  ion-button {
    bottom: 0;
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    opacity: 0;
    margin: 0;
  }

  @media (min-width: 769px) {
    width: calc(10% - #{$gallery-gap});
  }

  @media (max-width: 768px) and (min-width: 576px) {
    width: calc(20% - #{$gallery-gap});
  }
}
</style>
