<template>
  <div class="selectDate">
    {{ placeholder }} {{ formattedDate }}
    <div v-if="!disabled" @click="showDatePicker = !showDatePicker">bearbeiten</div>
  </div>
  <div v-if="showDatePicker">
    <div v-if="isMobile">
      <ion-datetime
        v-model="localDate"
        :first-day-of-week="1"
        :presentation="presentationMode"
        :prefer-wheel="true"
        size="cover"
        color="primary"
      >
        <span slot="time-label">Anstoss</span>
      </ion-datetime>
    </div>
    <div v-else>
      <ion-datetime
        :presentation="presentationMode"
        :prefer-wheel="false"
        v-model="localDate"
        :first-day-of-week="1"
        color="primary"
      >
        <span slot="time-label">Anstoss</span>
      </ion-datetime>
    </div>
    <!--<label class="switch-button">
            <input type="checkbox" @click="toggleView">
            <span class="slider round"></span>
        </label>-->
  </div>
</template>

<script lang="ts" setup>
import { IonDatetime } from '@ionic/vue';
import { computed, ref, watchEffect } from 'vue';
import { DateTime } from 'luxon';

const props = withDefaults(
  defineProps<{
    initialDate?: string;
    placeholder?: string;
    selectTime?: boolean;
    disabled?: boolean;
  }>(),
  {
    initialDate: '',
    placeholder: '',
    selectTime: true,
    disabled: false,
  },
);

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const modelValueUtc = defineModel<string>();

const utcToLocal = (dateString?: string) => {
  const parsed = dateString
    ? DateTime.fromISO(dateString, { zone: 'UTC' }).setLocale('de-DE').setZone('Europe/Berlin')
    : '';
  return parsed ? parsed.setZone('Europe/Berlin').toISO() : '';
};

const localToUtc = (dateString?: string) => {
  const parsed = dateString ? DateTime.fromISO(dateString, { zone: 'Europe/Berlin' }) : '';
  return parsed ? parsed.setZone('UTC').toISO() : '';
};

const showDatePicker = ref(false);
const localDate = ref(utcToLocal(modelValueUtc.value));

const presentationMode = computed(() => (props.selectTime ? 'date-time' : 'date'));

watchEffect(() => (localDate.value = utcToLocal(props.initialDate)));
watchEffect(() => {
  if (!localDate.value) {
    localDate.value = utcToLocal(modelValueUtc.value);
  }
});
watchEffect(() => (modelValueUtc.value = localToUtc(localDate.value)));
watchEffect(() => {
  // As last resort, when neither initialDate nor modelValueUtc are set, use the current date
  if (!localDate.value) {
    const now = DateTime.utc().setZone('Europe/Berlin');
    if (presentationMode.value === 'date') {
      const today = now.startOf('day');
      localDate.value = today.toISO();
    } else {
      // Initialize by clamping to the current hour
      localDate.value = now.startOf('hour').toISO();
    }
  }
});

const formattedDate = computed(() => {
  const date = localDate.value ?? utcToLocal(modelValueUtc.value);

  if (!date) {
    return '';
  }

  const parsed = DateTime.fromISO(date, { zone: 'Europe/Berlin' }).setLocale('de-DE');

  const formattedTime =
    presentationMode.value === 'date-time' && !parsed.startOf('day').equals(parsed)
      ? ` ${parsed.toFormat('HH:mm')}`
      : '';

  return parsed.toFormat('dd. MMMM yyyy') + formattedTime;
});
</script>

<style scoped lang="scss">
.selectDate {
  div {
    color: var(--ion-color-primary, #3880ff);
  }
}
</style>
