import { computed, Ref, ref, watch, watchEffect } from 'vue';
import { FileKind, PreviewURL } from '@/services/filePicker/models';
import { CloudFile } from '@/views/cloud-page/CloudFile';
import helper from '@/services/helper';
import { pickSingleFile } from '@/services/filePicker';
import { ALL_MIME_TYPES, getFileKindFromMimeType } from '@/services/filePicker/mime-types';
import { validateFileToUpload, ValidationBuilder } from '@/services/uploadFileValidation';
import { type CreatorFile } from '@/services/filePicker/CreatorFile';
import { generatePreviewUrl } from '@/services/filePicker/thumbnails';

type FilePickerOptions = {
  allowedFileKind: Ref<FileKind>;
  emitFileSelected?: (file: CreatorFile, thumbnail: PreviewURL) => void;
  emitCloudFileSelected?: (file: CloudFile, thumbnail: PreviewURL) => void;
  emitReset?: () => void;
  extraValidator?: Ref<undefined | ((validate: ValidationBuilder) => ValidationBuilder)>;
};

export const useFilePicker = ({
  allowedFileKind,
  emitFileSelected,
  emitCloudFileSelected,
  emitReset,
  extraValidator,
}: FilePickerOptions) => {
  const error = ref<Error | null>(null);

  const selectedFile = ref<CreatorFile | undefined>(undefined);
  const selectedCloudFile = ref<CloudFile | undefined>(undefined);

  const previewUrl = ref(null) as Ref<PreviewURL | null>;
  const hasPicked = ref(false);
  const isLoading = ref(false);

  const showCloudFilePicker = ref(false);

  watchEffect(() => {
    if (!error.value) {
      return;
    }

    selectedFile.value = undefined;
    selectedCloudFile.value = undefined;
    previewUrl.value = null;
    hasPicked.value = false;
    showCloudFilePicker.value = false;
    isLoading.value = false;
  });

  const dropzoneCaption = computed(() => {
    switch (allowedFileKind.value) {
      case FileKind.ImageOrVideo:
        return 'Klicke oder ziehe ein Foto/Video hierher, um es hochzuladen';
      case FileKind.Video:
        return 'Klicke oder ziehe ein Video hierher, um es hochzuladen';
      case FileKind.Audio:
        return 'Klicke oder ziehe eine Audiodatei hierher, um sie hochzuladen';
      case FileKind.Image:
        return 'Klicke oder ziehe ein Foto hierher, um es hochzuladen';
      case FileKind.Any:
      default:
        return 'Klicke oder ziehe eine Datei hierher, um sie hochzuladen';
    }
  });

  const uploadButtonCaption = computed(() => {
    switch (allowedFileKind.value) {
      case FileKind.Video:
        return 'Video-Upload vom Gerät';
      case FileKind.Audio:
        return 'Audio-Upload vom Gerät';
      case FileKind.Image:
        return 'Bild-Upload vom Gerät';
      case FileKind.Any:
      case FileKind.ImageOrVideo:
      default:
        return 'Upload vom Gerät';
    }
  });

  async function onFileSelected(file: CreatorFile): Promise<void> {
    error.value = null;

    const [isValid, validationMessage] = await validateFileToUpload(file, (validate) =>
      validate.fileKind(allowedFileKind.value).webOnlyMp4Videos().chainWith(extraValidator?.value),
    );

    if (!isValid) {
      helper.methods.microInteraction(validationMessage);
      return;
    }

    hasPicked.value = true;
    selectedFile.value = file;

    isLoading.value = true;

    generatePreviewUrl(selectedFile.value)
      .then((result) => (previewUrl.value = result))
      .catch((err) => {
        console.error(err);
        return (error.value = err);
      })
      .finally(() => (isLoading.value = false));
  }

  function onCloudFileSelected(file: CloudFile, thumbnailURL: string) {
    hasPicked.value = true;
    selectedCloudFile.value = file;
    previewUrl.value = thumbnailURL;
    showCloudFilePicker.value = false;
  }

  const handleChooseFileClick = async () => {
    const result = await pickSingleFile(allowedFileKind.value);
    if (!result) {
      // Only override the refs file if the file picker reported a change (and not undefined, which may happen when
      // the dialog is canceled).
      return;
    }

    await onFileSelected(result);
  };

  const fileKind = computed(() =>
    selectedFile.value
      ? selectedFile.value.kind
      : selectedCloudFile.value
        ? getFileKindFromMimeType(selectedCloudFile.value.contentType)
        : undefined,
  );
  const unknownFileType = computed(() => selectedFile.value && !fileKind.value);

  watch(previewUrl, (prev) => {
    // Once the thumbnail changes, we know the selectedFile has also been set
    if (!selectedFile.value && !selectedCloudFile.value) {
      return;
    }
    if (!prev || !fileKind.value || unknownFileType.value) {
      return;
    }

    if (selectedFile.value) {
      emitFileSelected?.(selectedFile.value, prev);
    } else if (selectedCloudFile.value) {
      emitCloudFileSelected?.(selectedCloudFile.value, prev);
    }
  });

  watch(unknownFileType, (error) => {
    if (error && selectedFile.value) {
      helper.methods.microInteraction(
        `Wir können mit dem Dateityp ${selectedFile.value.mimeType} leider nicht umgehen.\n` +
          `Die Folgenden Dateitypen sind unterstützt: ${ALL_MIME_TYPES.join(', ')}`,
      );
    }
  });

  function reset() {
    selectedFile.value = undefined;
    selectedCloudFile.value = undefined;
    isLoading.value = false;
    hasPicked.value = false;
    showCloudFilePicker.value = false;
    previewUrl.value = null;
    emitReset?.();
  }

  return {
    error,
    selectedFile,
    selectedCloudFile,
    previewUrl,
    isLoading,
    hasPicked,
    showCloudFilePicker,
    onFileSelected,
    onCloudFileSelected,
    handleChooseFileClick,
    fileKind,
    dropzoneCaption,
    uploadButtonCaption,
    reset,
  };
};
