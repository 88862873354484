import { ApiResponseV2, httpGet, httpPut } from '@/services/apiRequestV2';
import { type MutationOptions, useMutation, useQuery } from '@tanstack/vue-query';
import { computed, type Ref } from 'vue';
import { extractMatchEventId, type IHasMatchEventId } from '@/models/match-events/utils';
import type { MatchEvent, MatchEventId } from '@/models/match-events/MatchEvent';

export function useMatchEvent(cardOrId: Ref<IHasMatchEventId | MatchEventId | null | undefined>) {
  const id = computed(() => {
    if (typeof cardOrId.value === 'string') {
      return cardOrId.value as MatchEventId;
    }
    return extractMatchEventId(cardOrId.value);
  });

  return useQuery({
    enabled: computed(() => !!id.value),
    queryKey: computed(() => ['matchevent', id.value]),
    queryFn: async () => {
      const response = await httpGet<MatchEvent>(`/api/data/matchevents/${id.value}`, 'cloud');
      return response.data;
    },
  });
}

export function useUpdateMatchEvent(
  options?: Omit<MutationOptions<ApiResponseV2<any>, Error, MatchEvent, unknown>, 'mutationKey' | 'mutationFn'>,
) {
  return useMutation({
    mutationKey: ['updateMatchEvent'],
    mutationFn: async (matchEvent: MatchEvent) => await httpPut(`/api/data/matchevents`, 'cloud', matchEvent),
    ...options,
  });
}
