<script lang="ts" setup>
import { arrowForward } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';

defineProps<{
  disabled?: boolean;
}>();
const emit = defineEmits<{ (e: 'click'): void }>();
</script>

<template>
  <button class="round-default-button w-full" @click="emit('click')" :disabled="disabled ?? false">
    <ion-icon class="mb-0" :icon="arrowForward"></ion-icon>
  </button>
</template>
