<template>
  <template v-if="template === 'default'">
    <div :class="{ isRecording: recording, wasRecorded: recordedBlob }" class="audioRecorder audioRecorder--default">
      <div class="audioIcon" @click="toggleRecording">
        <svg>
          <use xlink:href="#audiorecorder"></use>
          <!--use xlink:href="#audiorecorderActive" v-else></use-->
        </svg>
        <div v-if="recordedTime" class="audioTime">{{ recordedTime }}</div>
      </div>
      <figure :class="{ isPlaying: isPlaying }" class="audioPlay">
        <div class="playpause" @click="playAudioRecord">
          <div class="playpause__icon">
            <div class="playpause__item playpause__left"></div>
            <div class="playpause__item playpause__right"></div>
          </div>
          <div class="playpause__pointer"></div>
        </div>
        <div v-if="audioTime" class="audioTime">{{ audioTime }}</div>
        <audio ref="audioRecord" :src="recordedAudio" class="hidden" controls type="audio/mpeg" />
      </figure>
    </div>
    <div v-if="test" class="text-center font-sans w-96 mx-auto rounded-lg shadow-lg border-solid border-2 p-8">
      <h2 class="font-bold text-2xl">Record Audio Message</h2>
      <div class="svg_parent">
        <icon-button
          v-if="recording"
          :class="buttonClass"
          :style="{ 'border-color': buttonColor }"
          name="stop"
          @click="toggleRecording"
        />
        <icon-button
          v-else
          :class="buttonClass"
          :style="{ 'border-color': buttonColor }"
          name="mic"
          @click="toggleRecording"
        />
      </div>
      <div>---{{ recordedTime }}---</div>
      <div class="text-sm font-bold">{{ successMessage }}</div>
      <div class="text-sm">{{ instructionMessage }}</div>
      <div class="text-sm text-red-400">{{ errorMessage }}</div>
      <figure v-if="recordedBlob" class="mt-8">
        <audio :src="recordedAudio" class="mx-auto" controls type="audio/mpeg">
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        <figcaption class="text-sm mt-2">Listen to your recording before submitting.</figcaption>
      </figure>
      <submit-button v-if="recordedBlob" :color="buttonColor" @submit="sendData" />
      <div v-if="url">
        File wurde erfolgreich übertragen:
        {{ url.data }}
      </div>
    </div>
  </template>
  <template v-else-if="template === 'fixed'">
    <div :class="{ isRecording: recording }" class="audioRecorder audioRecorder--fixed">
      <div class="audioIcon">
        <svg v-if="!recording" @click="toggleRecording">
          <use xlink:href="#audiorecorder-black"></use>
        </svg>
        <div v-else class="audioIcon__cloud" @click="postAudio">
          <ion-icon :icon="cloudUpload" />
        </div>
      </div>
      <figure :class="{ isPlaying: isPlaying }" class="audioPlay">
        <ion-icon :icon="close" @click="toggleRecording" />
      </figure>
      <div v-if="recordedTime" class="audioTime">{{ recordedTime }}</div>
    </div>
  </template>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import IconButton from './IconButton.vue';
import SubmitButton from './SubmitButton.vue';
import Recorder from '../lib/recorder';
import convertTimeMMSS from '../lib/utils';
import '../styles/app.css';
import { useAppStore } from '@/store/app';
import { useAuthStore } from '@/store/auth';
import { useMatchStore } from '@/store/match';
import axios from 'axios';
import { emitter } from '@/bus';
import { close, cloudUpload } from 'ionicons/icons';

const INSTRUCTION_MESSAGE = 'Click icon to start recording message.';
const INSTRUCTION_MESSAGE_STOP = 'Click icon again to stop recording.';
const ERROR_MESSAGE = 'Failed to use microphone. Please refresh and try again and permit the use of a microphone.';
const SUCCESS_MESSAGE = 'Successfully recorded message!';

const M4A_FORMAT = 'm4a';

export default {
  name: 'audioRecorder',
  props: {
    // in minutes
    time: { type: Number, default: 10 },
    bitRate: { type: Number, default: 192 },
    backendEndpoint: { type: String, default: '' },
    buttonColor: { type: String, default: 'green' },
    // callback functions
    afterRecording: { type: Function },
    successfulUpload: { type: Function },
    failedUpload: { type: Function },
    template: { type: String, default: 'default' },
  },
  data() {
    return {
      test: false,
      audioTime: undefined,
      isPlaying: false,
      url: undefined,
      recording: false,
      recordedAudio: null,
      recordedBlob: null,
      record: undefined,
      recorder: null,
      successMessage: null,
      errorMessage: null,
      instructionMessage: INSTRUCTION_MESSAGE,
      sampleRate: new AudioContext().sampleRate || 48000,
      cloudUpload,
      close,
    };
  },
  computed: {
    buttonClass() {
      return 'mx-auto h-14 w-14 fill-current text-black cursor-pointer rounded-50 border-2 m-4 p-2';
    },
    recordedTime() {
      if (this.time && this.recorder?.duration >= this.time * 60) {
        this.toggleRecording();
      }
      return convertTimeMMSS(this.recorder?.duration);
    },
  },
  components: {
    IconButton,
    SubmitButton,
    IonIcon,
  },
  created() {
    emitter.on('newAudioRecord', () => {
      this.recording = true;
      this.initRecorder();
    });
    emitter.on('clearAudioBlob', () => {
      this.clearAudioBlob();
    });
    emitter.on('setAudioBlob', (e) => {
      // 'Aufgenoomenes Audio' ist ein placeholder, für wenn man eine Audio für die Audio Flip Card aufnimmt
      if (e !== 'Aufgenommenes Audio') {
        this.recordedAudio = e;
        this.recordedBlob = 'audioFile';
      }
    });
  },
  beforeUnmount() {
    this.clearAudioBlob();
    emitter.all.delete('newAudioRecord');
    emitter.all.delete('clearAudioBlob');
    emitter.all.delete('setAudioBlob');
    if (this.recording) {
      this.stopRecorder();
    }
  },
  methods: {
    clearAudioBlob() {
      this.recordedAudio = null;
      this.recordedBlob = null;
      const matchStore = useMatchStore();
      matchStore.recordedBlob = null;
    },
    postAudio() {
      this.toggleRecording();
      this.$nextTick(() => {
        this.$emit('postAudio');
      });
    },
    saveTime() {
      this.audioTime = convertTimeMMSS(this.recorder?.duration);
    },
    playAudioRecord() {
      const mainTrack = this.$refs.audioRecord;
      mainTrack.load();
      mainTrack.currentTime = 0;
      let playPromise = undefined;
      this.isPlaying ? mainTrack.pause() : (playPromise = mainTrack.play());
      this.isPlaying = !this.isPlaying;

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      if (playPromise !== undefined) {
        playPromise.then(() => {
          mainTrack.onended = function () {
            self.isPlaying = false;
          };
        });
      }
    },
    fnSuccess(msg) {
      this.url = msg;
    },
    toggleRecording() {
      this.recording = !this.recording;
      if (this.recording) {
        this.initRecorder();
      } else {
        this.saveTime();
        this.$nextTick(() => {
          this.stopRecording();
        });
      }
    },
    initRecorder() {
      this.recordedBlob = null;
      this.recorder = new Recorder({
        micFailed: this.micFailed,
        bitRate: this.bitRate,
        sampleRate: this.sampleRate,
        format: M4A_FORMAT,
      });
      this.recorder.start();
      this.successMessage = null;
      this.errorMessage = null;
      this.instructionMessage = INSTRUCTION_MESSAGE_STOP;
    },
    stopRecording() {
      this.recorder.stop();
      const recordList = this.recorder.recordList();
      this.recordedAudio = recordList[0].url;
      this.recordedBlob = recordList[0].blob;
      const matchStore = useMatchStore();
      matchStore.recordedBlob = this.recordedBlob;
      matchStore.recordedUrl = this.recordedAudio;
      this.record = recordList;
      if (this.recordedAudio) {
        this.successMessage = SUCCESS_MESSAGE;
        this.instructionMessage = null;
      }
      if (this.afterRecording) {
        this.afterRecording();
      }
    },
    async sendData() {
      if (!this.recordedBlob) {
        return;
      }
      const authStore = useAuthStore();
      const blob = this.recordedBlob;
      axios.defaults.headers.post['Authorization'] = 'Bearer ' + authStore.accessToken;
      axios.defaults.headers.post['Content-Type'] = 'audio/wave';

      axios
        .post(
          'https://cloud-' +
            useAppStore().environment +
            '.leagues.football/api/inbox/blobUpload/?inbox=7767e0b8-6e9d-4f5c-a827-c90151e129f9&filename=name.m4a',
          blob,
        )
        .then((response) => {
          console.log('response');
          console.log(response);
          this.fnSuccess(response);
        })
        .catch((error) => {
          console.log(error);
          //this.fnFail(error);
        });
    },
    micFailed() {
      this.recording = false;
      this.instructionMessage = INSTRUCTION_MESSAGE;
      this.errorMessage = ERROR_MESSAGE;
    },
  },
};
</script>
<style>
.svg_parent svg {
  height: 100px;
}
</style>
<style lang="scss" scoped>
.audioRecorder {
  z-index: 3003;
  cursor: pointer;
  transition: all ease 0.8s;
  border-radius: 100%;

  &:after {
    position: absolute;
    top: 0;
    left: 80px;
    display: block;
    width: 0;
    height: 0;
    content: '';
    transition: all ease 0.3s;
    transition-delay: 0ms;
    opacity: 0;
    border: 0 dashed #fff;
  }

  .audioIcon {
    position: relative;
    z-index: 999;
    left: 0;
    transition: all ease-in-out 0.6s;
    border-radius: 100%;
  }

  .audioPlay {
    position: relative;
    z-index: 997;
    left: 0;
    overflow: hidden;
    width: 80px;
    height: 80px;
    margin: 0;
    transition: all ease-in-out 0.5s;
    border-radius: 100%;
  }

  .audioTime {
    font-size: 0.7rem;
    font-weight: bold;
    position: relative;
    bottom: 8px;
    left: 50%;
    width: 40px;
    transform: translateX(-50%);
    text-align: center;
    color: #f82843;
    background: #fff;
  }

  .playpause {
    top: -15px;
    left: 50%;
    transform: translateX(-55%);
  }

  .playpause__icon {
    top: -15px;
  }

  &--default {
    position: absolute;
    top: 160px;
    left: 50%;
    width: 80px;
    height: 80px;
    transform: translateX(-50%);
    @media (min-width: 700px) {
      top: 180px;
    }
    @media (min-width: 1024px) {
      top: 140px;
    }

    &.wasRecorded {
      position: absolute;
      top: 160px;
      left: 50%;
      width: 160px;
      height: 80px;
      transform: translateX(-50%);
      border-radius: 40px;
      background: #227aff;
      @media (min-width: 700px) {
        top: 140px;
      }

      &:after {
        z-index: 10000;
        top: 0;
        left: 80px;
        width: 1px;
        height: 80px;
        transition: all ease-in 0.3s;
        transition-delay: 0.4s;
        opacity: 1;
        border: 1px dashed #fff;
      }

      .audioPlay {
        left: 80px;
      }

      .audioIcon {
        left: 0;
      }
    }

    .audioIcon {
      width: 80px;
      height: 80px;
      background: #227aff;

      svg {
        width: 80px;
        height: 80px;
      }
    }

    &.isRecording {
      .audioIcon {
        background: rgba(248, 40, 67, 1);

        &:after {
          position: absolute;
          z-index: -1;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
          content: '';
          animation: pulse 2s infinite;
          border-radius: 100%;
          background: rgba(248, 40, 67, 1);
          box-shadow: 0 0 0 0 rgba(248, 40, 67, 1);
        }
      }
    }

    .audioPlay {
      top: -80px;
      background: #227aff;

      .audioTime {
        bottom: -55px;
        left: 50%;
        width: 40px;
        transform: translateX(-50%);
        text-align: center;
        color: #fff;
        background: transparent;
      }
    }
  }

  &--fixed {
    position: fixed;
    bottom: 160px;
    right: 15px;
    width: 60px;
    height: 60px;
    align-items: center;
    transition: all ease 0.8s;
    background: var(--ion-color-primary, #3880ff);
    display: flex;
    justify-content: flex-start;
    overflow: hidden;

    .audioIcon {
      width: 60px;
      height: 60px;

      svg {
        width: 60px;
        height: 60px;
      }

      &__cloud {
        min-width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        ion-icon {
          width: 35px;
          height: 35px;
          color: black;
        }
      }
    }

    &.isRecording {
      animation: pulse-fixed 2s infinite;
      background: var(--ion-color-danger, #eb445a);
      width: 120px;
      height: 60px;
      border-radius: 40px;

      &:after {
        z-index: 10000;
        top: 0;
        left: 60px;
        width: 1px;
        height: 60px;
        transition: all ease-in 0.3s;
        transition-delay: 0.4s;
        opacity: 1;
        border: 1px dashed #fff;
      }

      .audioIcon {
        &:after {
          position: absolute;
          z-index: -1;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
          content: '';
          border-radius: 100%;
        }
      }
    }

    .audioPlay {
      width: 60px;
      height: 60px;
      min-width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      ion-icon {
        width: 65%;
        height: 65%;
        color: black;
      }

      .audioTime {
        bottom: -55px;
        color: #fff;
        background: transparent;
      }
    }

    .audioTime {
      bottom: 0;
      color: var(--ion-color-danger, #eb445a);
      position: absolute;
    }

    .playpause__item {
      position: absolute;
      width: 120px;
      height: 120px;
      background: black;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(248, 40, 67, 0.7);
  }
  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(248, 40, 67, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(248, 40, 67, 0);
  }
}

@keyframes pulse-fixed {
  0% {
    box-shadow: 0 0 0 0 rgba(235, 68, 90, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(235, 68, 90, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(235, 68, 90, 0);
  }
}
</style>
