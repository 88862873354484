import type { PickedFile } from '@capawesome/capacitor-file-picker';

export { PickedFile };

export enum FileKind {
  Video = 1 << 0,
  Audio = 1 << 1,
  Image = 1 << 2,
  ImageOrVideo = Image | Video,
  Any = Video | Audio | Image,
}

export type SingleFileKind = FileKind.Video | FileKind.Audio | FileKind.Image; // Note: TypeScript union, not logical OR.

export function hasKind(actual: FileKind, kindsToCheck: FileKind): boolean {
  return (actual & kindsToCheck) !== 0;
}

hasKind(FileKind.Image, FileKind.ImageOrVideo); // true
hasKind(FileKind.Video, FileKind.Video | FileKind.Image); // true
hasKind(FileKind.Audio, FileKind.Video | FileKind.Image); // true

export function isSingleKind(fileKinds: FileKind): boolean {
  return (fileKinds & (fileKinds - 1)) === 0;
}

/**
 * Thumbnail URL or audio file URL
 */
export type PreviewURL = string;
