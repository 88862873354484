<script lang="ts" setup>
import { MatchCardElement, MatchElement, MatchGoalElement, MatchKickoffElement } from '@/models/match-elements';
import { football, shield, swapHorizontal } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';
import { MATCH_EVENT_TRANSLATIONS, TimelineCard } from '@/models/timeline-card';
import { MediaElement, mediaTypeTranslation } from '@/models/media-element';
import Player from '@/views/feed/Player.vue';
import { computed } from 'vue';

const props = defineProps<{ eventType: TimelineCard['event']; element: MatchElement | MediaElement }>();

const germanEventName = computed(
  () => MATCH_EVENT_TRANSLATIONS[props.eventType] || `Unbekannte Spielsituation (${props.eventType})`,
);
</script>

<template>
  <div class="pictogram">
    <div
      v-if="eventType === 'MatchEnd' || eventType === 'MatchKickoff' || eventType === 'MatchHalftimeKickoff'"
      class="chip chip-blue"
    >
      <span class="icon"><app-icon name="whistle" size="auto" /></span>
      <div class="logo" v-if="!!element">
        <img
          v-if="(element as MatchKickoffElement).homeBadgeUrl"
          :src="(element as MatchKickoffElement).homeBadgeUrl"
          alt="logo"
        />
        <div v-else class="placeholder-logo">
          <ion-icon :icon="shield" class="mb-0"></ion-icon>
        </div>
      </div>
      VS
      <div class="logo" v-if="!!element">
        <img
          v-if="(element as MatchKickoffElement).guestBadgeUrl"
          :src="(element as MatchKickoffElement).guestBadgeUrl"
          alt="logo"
        />
        <div v-else class="placeholder-logo">
          <ion-icon :icon="shield" class="mb-0"></ion-icon>
        </div>
      </div>
    </div>
    <div v-else-if="eventType === 'MatchRedCard'" class="chip chip-red">
      <span class="icon"><app-icon name="card" size="maximum" /></span>
      <player v-if="!!element" :player="element as MatchCardElement" />
    </div>
    <div v-else-if="eventType === 'MatchYellowCard'" class="chip chip-yellow">
      <span class="icon">
        <app-icon name="card" size="maximum" />
        <img class="team-badge" v-if="'teamBadgeUrl' in element" :src="element.teamBadgeUrl" />
      </span>
      <player v-if="!!element" :player="element as MatchCardElement" />
    </div>
    <div v-else-if="eventType === 'MatchYellowRedCard'" class="chip">
      <div class="chip-yellow">
        <span class="icon">
          <app-icon name="card" size="maximum" />
          <img class="team-badge" v-if="'teamBadgeUrl' in element" :src="element.teamBadgeUrl" />
        </span>
      </div>
      <div class="chip-red">
        <span class="icon">
          <app-icon name="card" size="maximum" />
          <img class="team-badge" v-if="'teamBadgeUrl' in element" :src="element.teamBadgeUrl" />
        </span>
      </div>
      <player v-if="!!element" :player="element as MatchCardElement" />
    </div>
    <div v-else-if="eventType === 'MatchGoal'" class="chip chip-green">
      <!--suppress TypeScriptValidateTypes -->
      <span class="icon">
        <ion-icon :icon="football" color="lightgreen"></ion-icon>
        <img class="team-badge" v-if="'teamBadgeUrl' in element" :src="element.teamBadgeUrl" />
      </span>
      {{ (element as MatchGoalElement)?.homeScore }}:{{ (element as MatchGoalElement)?.guestScore }}
      <player v-if="!!element" :player="element as MatchGoalElement" />
    </div>
    <div
      v-else-if="['MatchGoalDenied', 'MatchGoalCancelled', 'MatchGoalDisallowed'].includes(eventType)"
      class="chip chip-red"
    >
      <span class="icon">
        <ion-icon :icon="football"></ion-icon>
        <img class="team-badge" v-if="'teamBadgeUrl' in element" :src="element.teamBadgeUrl" />
      </span>
      <span class="text-content">
        {{ element && 'headline' in element ? element.headline : 'Tor nicht gegeben oder vereitelt' }}
      </span>
    </div>
    <div v-else-if="eventType === 'MatchSubstitution'" class="chip">
      <span class="icon chip-orange"><app-icon name="swap" /></span>
      <div class="flex items-center gap-1" v-if="!!element">
        <player
          v-if="!!element && element.$type === 'MatchSubstitution'"
          :player="{
            playerText: element.playerText,
            playerNumber: element.playerNumber,
            playerPhotoUrl: element.playerPhotoUrl,
          }"
        />
        <ion-icon class="w-4 mb-0" :icon="swapHorizontal"></ion-icon>
        <player
          v-if="!!element && element.$type === 'MatchSubstitution'"
          :player="{
            playerText: element.substituteText,
            playerNumber: element.substituteNumber,
            playerPhotoUrl: element.substitutePhotoUrl,
          }"
        />
      </div>
    </div>
    <div v-else-if="eventType === 'MatchHalftime' || element?.$type === 'MatchHalftime'" class="chip chip-blue">
      <span class="icon">
        <app-icon name="tea" size="auto" />
        <img class="team-badge" v-if="'teamBadgeUrl' in element" :src="element.teamBadgeUrl" />
      </span>
      <span v-if="element?.$type === 'MatchHalftime'">
        {{ element.homeScore }}:{{ element.guestScore }}
        zur Halbzeit
      </span>
    </div>
    <div v-else-if="element?.$type === 'MatchLineup'" class="chip">
      <div class="logo">
        <img v-if="element.homeLineup.badgeUrl" :src="element.homeLineup.badgeUrl" alt="logo" />
        <div v-else class="placeholder-logo">
          <ion-icon :icon="shield" class="mb-0"></ion-icon>
        </div>
      </div>
      VS
      <div class="logo">
        <img v-if="element.guestLineup.badgeUrl" :src="element.guestLineup.badgeUrl" alt="logo" />
        <div v-else class="placeholder-logo">
          <ion-icon :icon="shield" class="mb-0"></ion-icon>
        </div>
      </div>
      <span class="text-content">
        {{ element && 'headline' in element ? element.headline : 'Die Aufstellung steht fest!' }}
      </span>
    </div>
    <div v-else-if="element.$type === 'Video' || element.$type === 'Audio' || element.$type === 'Image'" class="chip">
      {{ element.headline ?? mediaTypeTranslation(element.$type) }}
    </div>
    <div v-else-if="eventType === 'None'" class="chip"></div>
    <div v-else :data-unknown-feed-entry-type="JSON.stringify({ eventType, element }, null, 2)">
      {{ ('headline' in element && element.headline) || germanEventName }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pictogram {
  overflow: hidden;
  font-weight: 600;
}

.chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  .text-content {
    padding: 3px 0;
    word-break: break-word;
  }

  .icon {
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    line-height: 1.2;
    display: flex;
    gap: 5px;
    align-items: center;
    position: relative;

    .team-badge {
      position: absolute;
      right: -5px;
      height: 16px;
      width: 16px !important;
      bottom: 0;
    }

    img,
    ion-icon,
    .app-icon {
      width: 24px;
      margin-bottom: 0;
    }
  }
}

.chip-blue {
  .app-icon,
  ion-icon {
    color: lightblue;

    svg {
      fill: lightblue;
    }
  }
}

.chip-orange {
  .app-icon,
  ion-icon {
    color: orange;

    svg {
      fill: orange;
    }
  }
}

.chip-yellow {
  .app-icon,
  ion-icon {
    fill: yellow;

    svg {
      fill: yellow;
    }
  }
}

.chip-red {
  .app-icon,
  ion-icon {
    fill: #ff4444;

    svg {
      fill: #ff4444;
    }
  }
}

.chip-green {
  .app-icon,
  ion-icon {
    fill: lightgreen;

    svg {
      fill: lightgreen;
    }
  }
}

.logo {
  width: 34px;
}

.placeholder-logo {
  background: #454545;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  aspect-ratio: 1;
  align-items: center;
}
</style>
