<template>
  <div v-if="Object.keys(venue).length > 0">
    <span v-if="venue.name" v-html="venue.name"></span>
    <span v-if="venue.cityName" v-html="venue.cityName"></span>
  </div>
</template>

<script lang="ts">
import apiRequest from '@/services/apiRequest';
import { defineComponent, onMounted, ref, watch } from 'vue';

export default defineComponent({
  name: 'venueInfo',
  props: {
    venueUid: String,
  },
  setup(props: { venueUid: any }) {
    const venue = ref({ name: '', cityName: '' });
    const loadVenueData = async () => {
      const url = `api/data/venues/${props.venueUid}`;
      const result = await apiRequest.methods.get(url, 'cloud');
      if (result.error) {
        console.log(result.error);
      } else {
        venue.value = result.data;
      }
    };

    onMounted(() => {
      loadVenueData();
    });

    watch(
      () => props.venueUid,
      async () => {
        await loadVenueData();
      },
    );

    return { venue };
  },
});
</script>

<style scoped></style>
