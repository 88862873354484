import type { CreatorFile } from '@/services/filePicker/CreatorFile';
import { FileKind, PreviewURL } from '@/services/filePicker/models';
import { Capacitor } from '@capacitor/core';
import { b64toBlob } from '@/utils/base64-utils';
import { VideoEditor } from '@awesome-cordova-plugins/video-editor';
import { v4 as uuidv4 } from 'uuid';
import { Filesystem, ReadFileResult } from '@capacitor/filesystem';

export async function generatePreviewUrl(file: CreatorFile): Promise<PreviewURL | null> {
  if (file.kind === FileKind.Audio) {
    return URL.createObjectURL(file.blob);
  }

  const blob = await generateThumbnailAsBlob(file);
  if (!blob) {
    return null;
  }

  // TODO: Should probaby just return a blob and move responsibility of deleting the object URL when it's no longer
  //  needed to the caller
  return URL.createObjectURL(blob);
}

//#region Private members

const VIDEO_THUMBAIL_SCREENSHOT_AT_SECOND = 2;

async function generateThumbnailAsBlob(file: CreatorFile): Promise<Blob | null> {
  switch (file.kind) {
    case FileKind.Image:
      return file.blob;
    case FileKind.Video:
      return generateVideoThumbnail(file);
    case FileKind.Audio:
      throw Error('Cannot create thumbnail for audio files');
    default:
      throw Error('Unsupported file kind');
  }
}

async function generateVideoThumbnail(file: CreatorFile): Promise<Blob | null> {
  if (Capacitor.isNativePlatform() && file.path) {
    const videoThumbnail = await createBase64VideoThumbnailNative(file.path);
    if (videoThumbnail === null) {
      // Fallback to web APIs
      return generateVideoThumbnailViaWebApis(file.blob);
    }
    return b64toBlob(videoThumbnail);
  }

  return generateVideoThumbnailViaWebApis(file.blob);
}

async function createBase64VideoThumbnailNative(filePath: string) {
  const thumbPath = await VideoEditor.createThumbnail({
    fileUri: filePath,
    outputFileName: `thumb-${uuidv4()}`,
    atTime: VIDEO_THUMBAIL_SCREENSHOT_AT_SECOND,
    quality: 100,
  });

  const result = await base64ImageFromFilePath(thumbPath);

  Filesystem.deleteFile({
    path: thumbPath,
  }).catch((err) => console.error(`Unable to clean up thumbnail file at ${thumbPath}`, err));

  return result;
}

const base64ImageFromFilePath = async (contentPath: string): Promise<string | null> => {
  const filePath = contentPath.includes('://') ? contentPath : 'file://' + contentPath;

  const file: ReadFileResult = await Filesystem.readFile({
    path: filePath,
    directory: undefined,
  });

  try {
    return file.data as string | null; // Blob is impossible because this is only ran on native
  } catch (error) {
    console.error(`Fehler beim Lesen der Datei "${filePath}":`, error);
    return null;
  }
};

// noinspection JSUnusedLocalSymbols
function generateVideoThumbnailViaWebApis(blob: Blob): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.controls = false;
    video.preload = 'metadata';
    video.muted = true;
    video.style.display = 'none';
    video.autoplay = true;
    video.playsInline = true;
    video.currentTime = VIDEO_THUMBAIL_SCREENSHOT_AT_SECOND;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const objectUrl = URL.createObjectURL(blob);

    video.addEventListener('canplay', () => {
      if (!ctx) {
        reject(new Error('2D-Kontext des Canvas ist nicht verfügbar.'));
        return;
      }

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((thumbnailBlob) => {
        resolve(thumbnailBlob);
        video.pause();
        video.src = '';
        video.load();
        URL.revokeObjectURL(objectUrl);
        video.remove();
      }, 'image/jpeg');
    });

    video.addEventListener('error', () => {
      reject(new Error('Thumbnail konnte nicht generiert werden.'));
      URL.revokeObjectURL(objectUrl);
      video.remove();
    });

    video.src = objectUrl;
  });
}
//#endregion
