<template>
  <div class="container h-full create-user p-4">
    <div class="flex flex-col h-full content-box">
      <ion-content class="ion-padding remove-scrollbar-desktop rounded-md overflow-hidden">
        <div class="player-create-menu">
          <div class="flex flex-col justify-center">
            <div class="thumbnail">
              <div>
                <ion-icon :icon="personAddOutline" color="dark" />
              </div>
              <h2>Creator einladen</h2>
            </div>
          </div>
          <div class="mb-4 p-4 bg-[#1f1f1f] rounded-md">
            <div class="font-bold">Für diese Teams darf unser neuer Creator Inhalte erstellen:</div>
            <ul class="text-sm list-disc">
              <li class="ml-[15px]">
                <span>{{ activeClubFullName }} (Club)</span>
              </li>
              <li v-for="team in teamsList" :key="team.id" class="ml-[15px]">
                <span>{{ team.fullName }}</span>
              </li>
            </ul>
          </div>
          <ion-input v-model="email" class="create-user-input" placeholder="E-Mail Adresse" />
          <!--ion-button class="select-timelines-button w-full" @click="isModalOpen = true" :disabled="globalRights">
                  <template v-if="selectedTimelines.length === 0">
                      Timeline(s) auswählen
                  </template>
                  <template v-else>
                      {{ selectedTimelines.length }} Timeline
                      <template v-if="selectedTimelines.length !== 1">
                          s
                      </template> ausgewählt
                  </template>
              </ion-button-->
          <!--ion-item class="access-all">
                  <ion-label>Zugriff auf alle Timelines</ion-label>
                  <ion-checkbox v-model="globalRights"></ion-checkbox>
              </ion-item-->
          <p v-if="error.length" class="pb-2 error">{{ error }}</p>
          <div class="create-user-button-container">
            <ion-button class="create-user-button m-auto mt-5" @click="requestCreateUser" :disabled="isDisabled">
              <ion-spinner v-if="loading" class="upload-spinner mx-auto block p-3 my-5" color="black" name="crescent" />
              <template v-else> Creator einladen </template>
            </ion-button>
          </div>
        </div>
      </ion-content>
    </div>
  </div>
</template>
<script setup lang="ts">
import apiRequest from '@/services/apiRequest';
import { computed, onMounted, ref, watch } from 'vue';
import { useAppStore } from '@/store/app';
import { useAuthStore } from '@/store/auth';
import { personAddOutline } from 'ionicons/icons';
import { IonButton, IonContent, IonIcon, IonInput, IonSpinner } from '@ionic/vue';

const email = ref('');
const error = ref('');
const loading = ref(false);
const teamsList = ref([] as any[]);

const activeClubId = computed(() => useAuthStore().userSettings.activeClub.clubId);
const activeClubFullName = computed(() => useAuthStore().userSettings.activeClub.fullName);

const isDisabled = computed(() => !email.value || loading.value || activeClubId.value === '');

const requestCreateUser = async () => {
  loading.value = true;
  const inviteURL = `users/invite?org=${activeClubFullName.value}&email=${email.value}&roleKey=creator&level=Full&entityId=${activeClubId.value}&subTimelines=true`;
  await apiRequest.methods.post(inviteURL, 'profiles', {});
  const bannerObject = {
    success: true,
    message: 'Creator wurde per E-Mail eingeladen!',
  };
  const appStore = useAppStore();
  appStore.addBanner(bannerObject);
  email.value = '';
  loading.value = false;
};
const getTimelines = async () => {
  const authStore = useAuthStore();

  const fetchTeams = async () => {
    const teams = await apiRequest.methods.get(`/api/data/clubs/${activeClubId.value}/teams`, 'cloud');
    if (teams.data) {
      teamsList.value = teams.data;
    }
  };

  watch(
    () => authStore.userSettings.activeClub.clubId,
    (newClubId) => {
      if (newClubId !== undefined) {
        fetchTeams();
      }
    },
    { immediate: true },
  );
};

onMounted(async () => {
  await getTimelines();
});
</script>
