<template>
  <div class="match-wrapper">
    <swiper ref="swiper" :breakpoints="sliderBreakpoints">
      <swiper-slide>
        <match-reporter />
      </swiper-slide>
      <swiper-slide>
        <match-lineup />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import matchReporter from '@/views/MatchReporter.vue';
import matchLineup from '@/views/MatchLineup.vue';
import { useMatchStore } from '@/store/match';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import { emitter } from '@/bus';
import { App } from '@capacitor/app';

export default defineComponent({
  name: 'matchWrapper',
  data() {
    return {
      sliderBreakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 50,
          initialSlide: 0,
          touchRatio: 0.05,
          longSwipesRatio: 1,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
          touchRatio: 0.05,
          longSwipesRatio: 1,
        },
      },
      swiperInstance: null as any,
      swiper: null as any,
      showMatchReporter: true,
      chooseImageOrVideo: false,
    };
  },
  components: {
    matchReporter,
    matchLineup,
    Swiper,
    SwiperSlide,
  },
  computed: {
    matchDataStored() {
      const matchStore = useMatchStore();
      return matchStore.teams.home;
    },
  },
  created() {
    emitter.on('handleSlideTo', (e) => {
      this.handleSlideTo(e);
    });
    App.addListener('appStateChange', async (state) => {
      if (state.isActive) {
        this.showMatchReporter = false;
        await this.$nextTick();
        this.showMatchReporter = true;
      }
    });
  },
  beforeUnmount() {
    emitter.all.delete('handleSlideTo');
  },
  methods: {
    handleSlideTo(i: number) {
      this.swiper?.slideTo(i);
    },
    goToCommentaryCard() {
      const matchStore = useMatchStore();
      matchStore.addActiveTeam('home');
      const layer = {} as any;
      layer['event'] = 'commentary';
      layer['template'] = 'MatchCommentary';
      layer['status'] = 'home';
      layer['show'] = true;
      emitter.emit('openPostLayer', layer);
    },
    addMedia() {
      this.chooseImageOrVideo = true;
    },
  },
});
</script>
<style lang="scss">
.match-wrapper {
  .swiper-wrapper {
    .swiper-slide {
      overflow: scroll;
      height: 100vh;
      align-items: flex-start;
      max-height: calc(100vh - var(--bottom-nav-height, 56px));
      /*@media (max-width: 1023px) {
          max-height: calc(100vh - var(--bottom-nav-height, 56px));
        }

        @media (min-width: 1024px) {
          max-height: calc(100vh - var(--bottom-nav-height, 56px));
        }*/

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.plt-mobileweb {
  .match-wrapper {
    .swiper-wrapper {
      .swiper-slide {
        max-height: calc(100vh - (31px + var(--bottom-nav-height, 56px)));
      }
    }
  }
}
</style>
