import { defineStore } from 'pinia';
import apiRequest from '@/services/apiRequest';
import { useAuthStore } from '@/store/auth';
import { ref } from 'vue';
import { RoleKey, RoleLevel, UserRole } from '@/store/auth';
import switchTeam from '@/services/switchTeam';

interface TimelineAccess {
  acronym: string;
  badgeUrl: string;
  clubId: string;
  clubType: string;
  color: string;
  foundingYear: number;
  fullName: string;
  inactive: boolean;
  isTeam: boolean;
  name: string;
  properties: object;
  shortenedName: string;
  uid: string;
  website: string;
}

// TODO: Probably not the best name in the grand scheme of things. Could consider calling this just a `Creator`
export type EntityUser = { id: string; email: string; handle?: string; avatarUrl?: string; roles: UserRole[] };

//TODO: Pinia State wird nicht gefunden
//TODO: Hier speichern, worauf der User Zugriff hat, wenn Beschränkung, dann den Wechsel zu /switch unterbinden
//TODO: Verfügbare Teams in der OptionBar anzeigen
export const usePermissionsStore = defineStore('permissions', () => {
  const hasGlobalRights = ref(false);
  const accessibleTimelines = ref([] as TimelineAccess[]);

  async function assignedClub() {
    let lastClub = {};
    let lastEntity = '';

    const authStore = useAuthStore();
    try {
      hasGlobalRights.value = authStore.userHasGlobalPermission('timeline_write');

      const permittedEntities = authStore.getPermittedEntities('timeline_write');
      for (const entity of permittedEntities) {
        const response = await apiRequest.methods.get(`/api/data/clubs/${entity}/unfold`, 'cloud');
        if (response && response.status && response.status < 400) {
          console.log('add activeClub 1');
          if (response.data && response.data.message === undefined) {
            accessibleTimelines.value.push(response.data);
            if (
              !authStore.userSettings.activeClub ||
              Object.keys(authStore.userSettings.activeClub).length === 0 ||
              authStore.userSettings.activeClub.shortenedName === undefined
            ) {
              authStore.userSettings.activeClub = response.data;
              authStore.userSettings.activeClub!.clubId = entity;
              lastClub = response.data;
              lastEntity = entity;
              await switchTeam.switchTeam(authStore.userSettings.activeClub);
            }
          }
        } else {
          const teamData = await apiRequest.methods.get(`/api/data/teams/${entity}`, 'cloud');
          if (teamData.data && teamData.data.message === undefined) {
            accessibleTimelines.value.push(teamData.data);
            if (
              !authStore.userSettings.selectedTimeline ||
              Object.keys(authStore.userSettings.selectedTimeline).length === 0
            ) {
              authStore.userSettings.selectedTimeline = teamData.data;
              authStore.userSettings.selectedTimeline.uid = lastEntity;
            }
          }
        }

        /*

        try {
          console.info(`/api/data/clubs/${entity}/unfold`)
          const response = await apiRequest.methods.get(`/api/data/clubs/${entity}/unfold`, 'cloud');
          console.info('response');
          console.info(response);
          console.info(response.status);
          if (response.data && response.data.message === undefined) {
            accessibleTimelines.value.push(response.data);
            if (!authStore.userSettings.activeClub || Object.keys(authStore.userSettings.activeClub).length === 0) {
              authStore.userSettings.activeClub = response.data;
              authStore.userSettings.activeClub.clubId = entity;
              lastClub = response.data;
              lastEntity = entity;
            }
          }
        } catch (error) {
          try {
            const teamData = await apiRequest.methods.get(`/api/data/teams/${entity}`, 'cloud');
            if (teamData.data && teamData.data.message === undefined) {
              accessibleTimelines.value.push(teamData.data);
              if (
                !authStore.userSettings.selectedTimeline ||
                Object.keys(authStore.userSettings.selectedTimeline).length === 0
              ) {
                authStore.userSettings.selectedTimeline = teamData.data;
                authStore.userSettings.selectedTimeline.uid = lastEntity;
              }
            }
          } catch (error: any) {
            console.error(`Entität ${entity} ist weder ein Club noch ein Team.`);
          }
        }
         */
      }

      if (
        !authStore.userSettings.selectedTimeline ||
        Object.keys(authStore.userSettings.selectedTimeline).length === 0
      ) {
        authStore.userSettings.selectedTimeline = lastClub;
        authStore.userSettings.selectedTimeline.uid = lastEntity;
      }
    } catch (error) {
      console.error('Fehler bei der Anfrage:', error);
    }

    /*

            const roles = await apiRequest.methods.get('/permissions/allowedEntities?action=timeline_write', 'profiles');
            if (roles) {
                const myTimeline = roles.data[0].entityId;
                const myTeamURL = `api/data/teams/${myTimeline}`;
                const myTeam = await apiRequest.methods.get(myTeamURL, 'cloud');
                const clubID = myTeam.data.clubUids[0];

                const myClubURL = `api/data/clubs/${clubID}`;
                const myClub = await apiRequest.methods.get(myClubURL, 'cloud');

                useAuthStore().userSettings.activeClub = myClub.data;
                useAuthStore().userSettings.activeClub.clubId = clubID;
                useAuthStore().userSettings.selectedTimeline = myTeam.data;
                useAuthStore().userSettings.selectedTimeline.uid = myTimeline;
                await this.getAllPermissions();
                await this.userHasAccessTimeline(myTimeline);
                this.addTimeline(myTeam.data);
            }

             */

    // Navigationslogik hier
    // this.router.push('/switchclub');
  }

  async function listEntityUsers(entityId: string) {
    return await apiRequest.methods.get<EntityUser[]>(`/users/list?entityId=${entityId}`, 'profiles');
  }

  async function assignUserRole(entityId: string, userId: string, roleKey: RoleKey, level?: RoleLevel) {
    if (!level || level === 'None') {
      return await apiRequest.methods.delete<EntityUser[]>(
        `/roles/revoke?userId=${userId}&roleKey=${roleKey}&entityId=${entityId}`,
        'profiles',
        {},
      );
    } else {
      return await apiRequest.methods.post<EntityUser[]>(
        `/roles/assign?userId=${userId}&roleKey=${roleKey}&level=${level}&entityId=${entityId}`,
        'profiles',
        {},
      );
    }
  }

  return {
    hasGlobalRights,
    accessibleTimelines,
    listEntityUsers,
    assignedClub,
    assignUserRole,
  };
});
