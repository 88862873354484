import { defineStore } from 'pinia';
import { Vibration } from '@awesome-cordova-plugins/vibration';

export interface User {
  uploadProgress: number;
  environment: string;
  localStorageDeactivated: boolean;
  isNative: boolean;
  filteredTimelines: any;
  clubSelected: boolean;
}

export type AppEnvironment = 'dev' | 'live' | 'tutorial';

interface State {
  uploadProgress: number;
  environment: AppEnvironment;
  localStorageDeactivated: boolean;
  isNative: boolean;
  filteredTimelines: any[]; // Change 'any' to the appropriate type
  clubSelected: boolean;
  queue: any[]; // Change 'any' to the appropriate type
  banners: any[]; // Change 'any' to the appropriate type
  bannerId: number;
  bottomDrawer: boolean;
  bottomDrawerId: string;
  bottomDrawerLarge: boolean;
  bottomDrawerAnimating: boolean;
}

type Banner = {
  id: number;
  success: boolean;
  noticeText: string;
  bannerClasses: string;
  ctaText: string;
  error?: string;
};

export const useAppStore = defineStore('appStore', {
  // convert to a function
  state: (): State => ({
    uploadProgress: 0,
    environment: 'live',
    localStorageDeactivated: false,
    isNative: true,
    filteredTimelines: [],
    clubSelected: true,
    queue: [],
    banners: [],
    bannerId: 0,
    bottomDrawer: false,
    bottomDrawerId: '',
    bottomDrawerLarge: false,
    bottomDrawerAnimating: false,
  }),
  actions: {
    closeBottomDrawer() {
      this.bottomDrawer = false;
      this.bottomDrawerLarge = false;
    },
    largeBottomDrawer() {
      this.bottomDrawerLarge = true;
    },
    toggleBottomDrawer() {
      this.bottomDrawer = !this.bottomDrawer;
      if (this.bottomDrawer) {
        this.bottomDrawerLarge = false;
      }
    },
    updateEnvironment(text: AppEnvironment) {
      localStorage.setItem('environment', text);
      this.environment = text;
    },
    postFailed(err: any, forbiddenMessage: string, generalMessage: string) {
      if (err.message !== 'canceled') {
        if (err.response && err.response.data.error === 'InvalidStream') {
          const bannerObject = {
            success: false,
            message: 'Diese Datei wurde vom Server zurückgewiesen.',
          };
          this.addBanner(bannerObject);
        } else if (err.response && (err.response.status === 403 || err.response.status === 401)) {
          const bannerObject = {
            success: false,
            message: forbiddenMessage,
          };
          this.addBanner(bannerObject);
        } else {
          const bannerObject = {
            success: false,
            message: generalMessage,
          };
          this.addBanner(bannerObject);
        }
        this.uploadProgress = 1;
      }
    },
    addBanner(e: { success: boolean; message: string; error?: string | Error }) {
      const bannerObject: Banner = {
        id: this.bannerId,
        success: e.success,
        noticeText: e.message,
        bannerClasses: '',
        ctaText: '',
        error: '',
      };
      if (e.success) {
        bannerObject.bannerClasses = 'post-banner--success';
        bannerObject.ctaText = 'LIVE';
      } else {
        bannerObject.bannerClasses = 'post-banner--failure';
        bannerObject.ctaText = 'ERROR';
        bannerObject.error = e.error instanceof Error ? e.error.message : e.error;
      }

      this.bannerId += 1;
      this.banners.push(bannerObject);
      if (!bannerObject.success && Vibration) {
        Vibration.vibrate(300);
      }

      // Klasse hinzufügen um Animation zu starten
      setTimeout(() => {
        for (let i = 0; i < this.banners.length; i++) {
          if (this.banners[i].id === bannerObject.id) {
            this.banners[i].bannerClasses += ' enter';
            break;
          }
        }
      }, 100);
      if (bannerObject.success) {
        setTimeout(() => {
          this.removeBanner(bannerObject.id);
        }, 3000);
      }
    },
    removeBanner(id: number) {
      for (let i = 0; i < this.banners.length; i++) {
        if (this.banners[i].id === id) {
          this.banners[i].bannerClasses += ' exit';
          setTimeout(() => {
            this.banners.splice(i, 1);
          }, 200);
          break;
        }
      }
    },
  },
});
