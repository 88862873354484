import { defineStore } from 'pinia';
import type { Player } from '@/models/player';
import type { MatchFixtureUnfolded } from '@/models/match';

export interface TeamDetail {
  uid: string;
  clubUid: string;
  name: string;
  acronym: string;
  badge: string;
  color: string;
  badgeUrl: string;
}

interface Team {
  uid: string;
  acronym: string;
  name: string;
  fullName: string;
  color: string;
  badgeUrl: string;
  website: string;
  teamType: string;
  inactive: boolean;
  tags: string[];
  lastPlayed: string;
  properties: Record<string, string>;
  clubUids: string[];
  countryUid: string;
  venueUid: string;
  competitions: string[];
}

interface Venue {
  uid: string;
  name: string;
  venueType: string;
  country: string;
  cityName: string;
  address: string;
  zipcode: string;
  state: string;
  latitude: number;
  longitude: number;
  capacity: number;
  surface: string;
  imageUrl: string;
  usedByNationalTeam: boolean;
  inactive: boolean;
  properties: Record<string, string>;
  countryUid: string;
  homeTurfOfUid: string;
  cityUid: string;
}

interface CollabFile {
  video: string;
  image: string;
  audio: string;
}

interface Match {
  uid: string;
  name: string;
  userClubId: string;
  selectedTimelineUid: string;
  startDate: string;
  properties: Record<string, string>;
  seasonUid: string;
  minutesPlayed: number;
  homeScore: number;
  guestScore: number;
  status: string;
  calculated: boolean;
  venueUid: string;
  matchPhase: number;
  halftimeDuration: number;
  venue: Venue;
  homeTeamUid: string;
  homeTeam: Team;
  guestTeamUid: string;
  guestTeam: Team;
  matchId: string;
  locationId: string;
  locationText: string;
  homeTeamName: string;
  awayTeamName: string;
  homeTeamIcon: string;
  awayTeamIcon: string;
  homeTeamID: string;
  awayTeamID: string;
  homeGoals: number;
  awayGoals: number;
  counter: number;
  activeTeam: 'home' | 'guest';
  eventMinute: number;
  storedTime: number;
  minute: number;
  extraTime: number;
  teams: {
    home: Player[];
    guest: Player[];
  };
  competitions: {
    name: string;
    shortCode: string;
    tag: string;
    badgeUrl: string;
    color: string;
    uid: string;
  };
  home: TeamDetail;
  guest: TeamDetail;
  recordedBlob: any;
  recordedUrl: string;
  date: string;
  homePlayersOnField: number;
  guestPlayersOnField: number;
  competition: Record<string, unknown> | null;
  collabFile: CollabFile;
  postOnBothTimelines: boolean;
}

export const useMatchStore = defineStore('useMatchStore', {
  state: (): Match => ({
    uid: '',
    name: '',
    userClubId: '',
    selectedTimelineUid: '',
    startDate: '',
    properties: {} as any,
    seasonUid: '',
    minutesPlayed: 0,
    eventMinute: 0,
    homeScore: 0,
    guestScore: 0,
    status: '',
    calculated: false,
    venueUid: '',
    matchPhase: 0,
    halftimeDuration: 45,
    venue: {
      uid: '',
      name: '',
      venueType: '',
      country: '',
      cityName: '',
      address: '',
      zipcode: '',
      state: '',
      latitude: 0,
      longitude: 0,
      capacity: 0,
      surface: '',
      imageUrl: '',
      usedByNationalTeam: false,
      inactive: false,
      properties: {} as any,
      countryUid: '',
      homeTurfOfUid: '',
      cityUid: '',
    },
    competitions: {
      name: '',
      shortCode: '',
      tag: '',
      badgeUrl: '',
      color: '',
      uid: '',
    },
    homeTeamUid: '',
    homeTeam: {
      uid: '',
      acronym: '',
      name: '',
      fullName: '',
      color: '',
      badgeUrl: '',
      website: '',
      teamType: '',
      inactive: false,
      tags: [],
      lastPlayed: '',
      properties: {} as any,
      clubUids: [],
      countryUid: '',
      venueUid: '',
      competitions: [],
    },
    guestTeamUid: '',
    guestTeam: {
      uid: '',
      acronym: '',
      name: '',
      fullName: '',
      color: '',
      badgeUrl: '',
      website: '',
      teamType: '',
      inactive: false,
      tags: [],
      lastPlayed: '',
      properties: {} as any,
      clubUids: [],
      countryUid: '',
      venueUid: '',
      competitions: [],
    },
    matchId: '',
    locationId: '',
    locationText: '',
    homeTeamName: '',
    awayTeamName: '',
    homeTeamIcon: '',
    awayTeamIcon: '',
    homeTeamID: '',
    awayTeamID: '',
    homeGoals: 0,
    awayGoals: 0,
    counter: 0,
    activeTeam: 'home',
    storedTime: 0,
    minute: 0,
    extraTime: 0,
    teams: {
      home: {} as any,
      guest: {} as any,
    },
    home: {
      uid: '',
      clubUid: '',
      name: '',
      acronym: '',
      badge: '',
      color: '',
      badgeUrl: '',
    },
    guest: {
      uid: '',
      clubUid: '',
      name: '',
      acronym: '',
      badge: '',
      color: '',
      badgeUrl: '',
    },
    recordedBlob: '',
    recordedUrl: '',
    date: '',
    homePlayersOnField: 11,
    guestPlayersOnField: 11,
    competition: null as any,
    collabFile: {
      video: '',
      image: '',
      audio: '',
    },
    postOnBothTimelines: false,
  }),
  getters: {
    doubleCount: (state) => {
      return state.counter * 2;
    },
  },
  actions: {
    reset() {
      this.counter = 0;
    },
    addOne() {
      this.counter++;
    },
    addHomeGoal() {
      this.homeGoals++;
    },
    addGuestGoal() {
      this.awayGoals++;
    },
    addGuestTeam(e: Player[]) {
      this.teams.guest = e;
    },
    addHomeTeam(e: Player[]) {
      this.teams.home = e;
    },
    addGuest(e: any) {
      this.guest = e;
    },
    addHome(e: any) {
      this.home = e;
    },
    addActiveTeam(e: any) {
      this.activeTeam = e;
    },
    setMatchData(newData: MatchFixtureUnfolded) {
      console.log(newData);
      console.log(newData.guestTeam.color);
      Object.assign(this, newData);
    },
    updateMinute(e: number) {
      this.minute = e;
    },
    resetState() {
      this.teams = { home: [], guest: [] };
      this.matchId = '';
      this.locationId = '';
      this.locationText = '';
      this.homeTeamName = '';
      this.awayTeamName = '';
      this.homeTeamIcon = '';
      this.awayTeamIcon = '';
      this.homeTeamID = '';
      this.awayTeamID = '';
      this.homeGoals = 0;
      this.awayGoals = 0;
      this.counter = 0;
      this.activeTeam = 'home';
      this.minute = 0;
      this.storedTime = 0;
      this.extraTime = 0;
      this.matchPhase = 0;
      this.halftimeDuration = 45;
      this.teams = {
        home: {} as any,
        guest: {} as any,
      };
      this.home = {
        uid: '',
        clubUid: '',
        name: '',
        acronym: '',
        badge: '',
        color: '',
        badgeUrl: '',
      };
      this.guest = {
        uid: '',
        clubUid: '',
        name: '',
        acronym: '',
        badge: '',
        color: '',
        badgeUrl: '',
      };
      this.recordedBlob = null;
      this.recordedUrl = '';
      this.date = '';
      this.homePlayersOnField = 11;
      this.guestPlayersOnField = 11;
      this.competition = null as any;
      this.postOnBothTimelines = false;
    },
  },
});
