<script lang="ts" setup>
import { ref } from 'vue';
import helper from '@/services/helper';
import { asCreatorFile, type CreatorFile } from '@/services/filePicker/CreatorFile';

withDefaults(defineProps<{ caption?: string; size?: 'sm' | 'lg' }>(), {
  size: 'lg',
});

const emit = defineEmits<{ (e: 'file-selected', file: CreatorFile); (e: 'click') }>();

const onDrop = async (ev: DragEvent) => {
  ev.preventDefault();

  const files = ev.dataTransfer?.files;
  if (!files || files.length === 0) {
    return; // No files in transfer, e.g. when dragging text into the dropzone.
  }

  if (files.length !== 1) {
    helper.methods.microInteraction('Bitte lade nur genau eine Datei hoch.');
    return;
  }

  emit('file-selected', await asCreatorFile(files[0]));
};

const isDragging = ref(false);
const isOver = ref(false);
</script>

<template>
  <div
    :class="[
      'dropzone cursor-pointer p-2',
      {
        over: isOver,
        dragging: isDragging,
      },
    ]"
    @dragover.prevent="isDragging = true"
    @dragover="isOver = true"
    @dragleave="
      isDragging = false;
      isOver = false;
    "
    @drop="
      onDrop($event);
      isDragging = false;
      isOver = false;
    "
    @click="emit('click')"
  >
    <div class="flex flex-col items-center">
      <div
        :class="[
          'text-center',
          {
            'text-md font-semibold': size === 'sm',
            'text-3xl font-bold': size === 'lg',
          },
        ]"
      >
        <template v-if="caption">{{ caption }}</template>
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropzone {
  border-style: dashed;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.2392156863);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  flex-direction: column;

  ion-icon {
    transition: 0.3s ease-in-out;
    color: var(--ion-tab-bar-background, #1f1f1f) !important;
  }
}

.dragging,
.over {
  .wrapper {
    ion-icon {
      animation: light-pulse 1s infinite;
      color: #00d4d6 !important;
    }
  }
}

.dragging {
  .dropzone {
    border-color: var(--ion-color-primary, #3880ff);
  }
}

.over.dropzone {
  border-color: var(--ion-color-primary, #3880ff);
}

.default-button-grey {
  background: #454545 !important;
}
</style>
