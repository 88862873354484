<script setup lang="ts">
import { IonInput } from '@ionic/vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const formatToParagraphs = (text: string): string => {
  // Teilt den Text an jedem Zeilenumbruch und wickelt jeden Absatz in <p>-Tags
  return text
    .split('\n')
    .map((paragraph) => `<p>${paragraph}</p>`)
    .join('');
};

const allowTags = ['b', 'em', 'strong', 'blockquote', 'i', 'u', 'p'];

const removeDisallowedHtmlTags = (text: string) => {
  // Ersetzt alle Tags, die nicht in `allowTags` sind, mit einem leeren String
  return text.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, (match, p1) => {
    // Überprüfen, ob der Tag in der Liste erlaubter Tags enthalten ist
    return allowTags.includes(p1.toLowerCase()) ? match : '';
  });
};

export type NewsPostModel = {
  headline: string;
  teaserText: string;
  body: string;
};

const model = defineModel<NewsPostModel>({
  default: { headline: '', teaserText: '', body: '' },
  required: true,
  set(v) {
    const formattedBody = formatToParagraphs(v.body);
    const cleanedBody = removeDisallowedHtmlTags(formattedBody);
    return { ...v, body: cleanedBody };
  },
});
</script>

<template>
  <div class="news-posting">
    <div class="mt-5 mb-4">
      <span>Überschrift</span>
      <ion-input type="text" v-model="model.headline" />
    </div>
    <div class="mt-5 mb-4">
      <span>Teaser</span>
      <div class="text-xs opacity-80">Unterüberschrift / Kurze Zusammenfassung</div>
      <ion-input type="text" v-model="model.teaserText" />
    </div>
    <div class="mt-5 mb-4">
      <span class="block mb-2">Was ist Passiert?</span>
      <div class="ql-wrapper">
        <quill-editor :toolbar="['bold', 'italic', 'underline']" v-model:content="model.body" contentType="html" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.news-posting {
  ion-input,
  ion-textarea {
    width: 100%;
    --background: #3b3b3b;
    border-radius: 9px;
    margin-top: 8px;
  }

  ion-textarea {
    padding: 0 5px;
    ::selection {
      background-color: #00a0a1;
      color: #121212;
    }
    &:focus {
      height: 100%;
    }
  }
  ion-input {
    border-radius: 9px;
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.ql-wrapper {
  background: #3b3b3b;
  color: #fff;
  border-radius: 9px;
  overflow: hidden;

  .ql-snow {
    border: none;
  }

  .ql-toolbar {
    .ql-formats {
      button {
        &:hover {
          backdrop-filter: brightness(0.8);
          border-radius: 3px;
          overflow: hidden;
          background: none;
        }

        &.ql-active {
          background: none;
          backdrop-filter: brightness(0.8);

          svg {
            path,
            line {
              stroke: #0ff;
            }
          }
        }

        svg {
          path,
          line {
            stroke: #e8eaed;
          }
        }
      }
    }
  }

  .ql-container {
    box-shadow: rgba(0, 0, 0, 0.2392156863) 0px -3px 12px;

    .ql-editor::before {
      color: #fff;
    }
  }
}
</style>
