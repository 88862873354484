import apiRequest from '@/services/apiRequest';
import type { AudioElement, ImageElement, VideoElement } from '@/models/media-element';
import type { AxiosRequestConfig } from 'axios';
import nativeFileSystem from '@/services/nativeFileSystem';
import { getFileExtensionForMimeType } from '@/services/filePicker/mime-types';
import { usePreprocessingQueue } from '@leagues-football/upload-queue';
import type { CreatorFile } from '@/services/filePicker/CreatorFile';
import { FileKind } from '@/services/filePicker/models';

type CardID = string;

export type MediaElementPayload =
  | Pick<AudioElement, '$type' | 'audioUrl' | 'headline'>
  | Pick<VideoElement, '$type' | 'videoUrl' | 'headline'>
  | Pick<ImageElement, '$type' | 'imageUrl' | 'headline'>;

const uploadFilesV2Service = {
  generateFileName(mimeType: string): string {
    return `file${getFileExtensionForMimeType(mimeType)}`;
  },
  requiresCompression(file: CreatorFile) {
    return file.kind === FileKind.Video && file.path !== undefined;
  },
  async enqueueCompressionTask(file: CreatorFile): Promise<string> {
    if (!file.path) {
      throw Error('File path is not defined');
    }

    // Videos können nicht konvertiert werden, wenn der Dateiname ein Leerzeichen enthält
    let filePath = file.path;

    const decodedFileName = decodeURIComponent(filePath);

    const containsSpace = decodedFileName.includes(' ');
    if (containsSpace) {
      filePath = String(await nativeFileSystem.renameFile(filePath));
    }

    return usePreprocessingQueue().enqueue(filePath);
  },
  async uploadCardAsset(
    file: CreatorFile,
    cardID: CardID,
    {
      onProgress,
      abortSignal,
    }: {
      onProgress?: AxiosRequestConfig['onUploadProgress'];
      abortSignal?: AbortSignal;
    } = {},
  ) {
    return await apiRequest.methods.postCreatorFile(`api/media/uploadCardAsset?cardId=${cardID}`, 'cloud', file, {
      onProgress,
      abortSignal,
    });
  },

  async uploadCloudFile(
    file: CreatorFile,
    inboxId: string,
    {
      onProgress,
      abortSignal,
    }: {
      onProgress?: AxiosRequestConfig['onUploadProgress'];
      abortSignal?: AbortSignal;
    } = {},
  ) {
    return await apiRequest.methods.postCreatorFile(`api/inbox/upload?inbox=${inboxId}`, 'cloud', file, {
      onProgress,
      abortSignal,
    });
  },

  async uploadCdnAsset(
    file: CreatorFile,
    entityType: 'competitions' | 'clubs' | 'teams',
    entityUid: string,
    {
      onProgress,
      abortSignal,
    }: {
      onProgress?: AxiosRequestConfig['onUploadProgress'];
      abortSignal?: AbortSignal;
    } = {},
  ) {
    if (file instanceof File) {
      return await apiRequest.methods.postFile(
        `api/media/cdnassets/upload?uid=${entityUid.trim()}&entityType=${entityType.trim()}&fileName=${encodeURIComponent(file.name)}`,
        'cloud',
        file,
        file.type,
        {
          onProgress,
          abortSignal,
        },
      );
    } else {
      if (!file.blob) {
        throw Error('No blob exists');
      }

      const filename = file.name ?? this.generateFileName(file.mimeType || file.blob.type);

      return await apiRequest.methods.postBlobAsFile<string>(
        `api/media/cdnassets/upload?uid=${entityUid.trim()}&entityType=${entityType.trim()}&fileName=${encodeURIComponent(filename)}`,
        'cloud',
        file.blob,
        filename,
        { onProgress, abortSignal, fallbackMimeType: file.mimeType },
      );
    }
  },
};

export default uploadFilesV2Service;
