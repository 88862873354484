<script setup lang="ts">
import { onBeforeUnmount, ref } from 'vue';
import { emitter } from '@/bus';

const showBanner = ref(false);
const teamObj = ref<any>(null);
const showBannerHandler = (e: any) => {
  teamObj.value = e;
  showBanner.value = true;
  setTimeout(() => {
    showBanner.value = false;
  }, 1300);
};

emitter.on('switchTeam', showBannerHandler);

onBeforeUnmount(() => {
  emitter.off('switchTeam', showBannerHandler);
});
</script>

<template>
  <div v-if="showBanner && teamObj" class="changeTeamBanner">
    <div class="changeTeamBanner__inner">
      <div class="text-white text-center py-5">Du wechselt zu:</div>
      <div class="relative changeTeamBanner__bagde inline-block">
        <div class="switchClub_circle switchClub_loading absolute"></div>
        <img class="changeTeamBanner__icon" :alt="teamObj.name" :src="teamObj.badgeUrl" />
      </div>
      <div class="text-white font-bold text-center py-5">
        {{ teamObj.name }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.changeTeamBanner {
  background: #1f1f1f;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
}
.changeTeamBanner__inner {
  width: 50vw;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: relative;
  text-align: center;
  font-weight: bold;
}
.changeTeamBanner__icon {
  width: 100px;
  height: 100px;
  object-fit: contain;
  position: relative;
  top: 5px;
  left: 5px;
}
.changeTeamBanner__bagde,
.switchClub_circle {
  width: 110px;
  height: 110px;
}
</style>
