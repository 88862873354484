<script setup lang="ts">
import { FileKind, hasKind, PreviewURL } from '@/services/filePicker/models';
import { computed, onErrorCaptured, ref, toRef } from 'vue';
import { IonSpinner } from '@ionic/vue';
import formatBytes from '@/components/uploadProgress/formatBytes';
import { Capacitor } from '@capacitor/core';
import { CloudFile } from '@/views/cloud-page/CloudFile';
import { useResizeObserver } from '@vueuse/core';
import CloudFilePickerModal from '@/components/cloud-files/CloudFilePickerModal.vue';
import { useFilePicker } from '@/components/file-picker/useFilePicker';
import DropZone from '@/components/file-picker/DropZone.vue';
import type { ValidationBuilder } from '@/services/uploadFileValidation';
import type { CreatorFile } from '@/services/filePicker/CreatorFile';

const props = withDefaults(
  defineProps<{
    showForm?: boolean;
    allowedFileKind?: FileKind;
    canUploadFromCloud?: boolean;
    validate?: (validate: ValidationBuilder) => ValidationBuilder;
  }>(),
  {
    showForm: true,
    allowedFileKind: FileKind.Any,
    canUploadFromCloud: true,
  },
);

const emit = defineEmits<{
  (e: 'fileSelected', file: CreatorFile, thumbnail: PreviewURL): unknown;
  (e: 'cloudFileSelected', file: CloudFile, thumbnail: PreviewURL): unknown;
  (e: 'cloudFilePickerActive', value: boolean): void;
}>();

const {
  error,
  selectedFile,
  selectedCloudFile,
  previewUrl,
  isLoading,
  hasPicked,
  showCloudFilePicker,
  onFileSelected,
  onCloudFileSelected,
  handleChooseFileClick,
  fileKind,
  dropzoneCaption,
  uploadButtonCaption,
} = useFilePicker({
  allowedFileKind: toRef(() => props.allowedFileKind),
  emitFileSelected: (file: CreatorFile, thumbnail: PreviewURL) => emit('fileSelected', file, thumbnail),
  emitCloudFileSelected: (file: CloudFile, thumbnail: PreviewURL) => emit('cloudFileSelected', file, thumbnail),
  extraValidator: toRef(() => props.validate),
});

onErrorCaptured((err) => {
  error.value = err;
  console.error(err);
});

const isNative = Capacitor.isNativePlatform();

const canvas = ref<HTMLCanvasElement>();

const thumbnailImageStyle = computed(() => (previewUrl.value ? `url(${previewUrl.value})` : null));

const containerRef = ref<HTMLDivElement>();
const containerMinimumHeight = ref(0);

useResizeObserver(containerRef, (entries) => {
  const entry = entries[0];
  const { height } = entry.contentRect;

  if (height > containerMinimumHeight.value) {
    containerMinimumHeight.value = height;
  }
});
</script>

<template>
  <div ref="containerRef" :style="{ minHeight: `${containerMinimumHeight}px !important` }">
    <div v-if="isLoading" class="h-full w-full flex items-center justify-center place-content-center">
      <ion-spinner v-if="isLoading" class="upload-spinner inline-block mt-8" color="black" name="dots" />
    </div>
    <template v-else>
      <div v-if="thumbnailImageStyle" class="thumbnail" :style="{ backgroundImage: thumbnailImageStyle ?? 'none' }" />
      <div v-if="selectedFile || selectedCloudFile" class="mb-2">
        <canvas ref="canvas" class="hidden"></canvas>
        <div class="upload-info-container" v-if="selectedFile?.name || selectedCloudFile?.fileName">
          <div class="bold block text-sm uploadInfo">
            <b>
              {{ selectedFile?.name || selectedCloudFile?.fileName }}
            </b>
          </div>
          <span class="uploadInfo opacity-80" v-if="selectedFile && selectedFile.size">
            {{ formatBytes(selectedFile.size, 1) }}
          </span>
        </div>
      </div>

      <template v-if="isNative">
        <div class="flex upload mb-1 mt-2 text-center flex-row flex-wrap gap-4">
          <button class="default-button" @click="handleChooseFileClick">
            <span>{{ uploadButtonCaption }}</span>
          </button>
          <button
            v-if="canUploadFromCloud"
            class="default-button default-button-grey"
            @click="showCloudFilePicker = !showCloudFilePicker"
          >
            <span>Aus der Leagues-Cloud</span>
          </button>
        </div>
      </template>

      <template v-else>
        <div :class="{ none: hasPicked }" class="file-picker">
          <div class="flex flex-col justify-center items-center gap-4 flex-wrap">
            <drop-zone @file-selected="onFileSelected" @click="handleChooseFileClick" size="lg" class="grow-1 my-3">
              <div :class="previewUrl ? 'm-2' : 'm-[2rem]'">
                <template v-if="previewUrl">
                  <div v-if="!fileKind">Unbekannter Dateityp, keine Vorschau</div>
                  <img
                    v-else-if="hasKind(fileKind, FileKind.Image | FileKind.Video)"
                    class="max-h-[200px]"
                    :src="previewUrl"
                  />
                  <audio v-else-if="hasKind(fileKind, FileKind.Audio)" :src="previewUrl" controls />
                </template>
                <template v-else>{{ dropzoneCaption }}</template>
              </div>
            </drop-zone>
            <div class="flex flex-row gap-4">
              <button class="default-button inline-block" @click="handleChooseFileClick">
                {{ uploadButtonCaption }}
              </button>
              <button
                v-if="canUploadFromCloud"
                class="default-button default-button-grey"
                @click="showCloudFilePicker = !showCloudFilePicker"
              >
                <span>Aus der Leagues-Cloud</span>
              </button>
            </div>
          </div>
        </div>

        <div v-if="selectedFile && !fileKind" class="error">
          Wir können mit diesem Dateityp {{ selectedFile.mimeType ? `('${selectedFile.mimeType}')` : '' }} leider nicht
          umgehen.
        </div>

        <div v-if="error" class="error">
          {{ error.message ? `Fehler: ${error.message}` : 'Ein unbekannter Fehler ist aufgetreten.' }}
        </div>
      </template>
    </template>
  </div>

  <cloud-file-picker-modal
    :is-open="showCloudFilePicker"
    :allowed-file-kind="allowedFileKind"
    :select-mode="true"
    @file-selected="onCloudFileSelected"
    @will-dismiss="showCloudFilePicker = false"
  />
</template>

<style scoped lang="scss">
$gallery-gap: 2px;

.leagues-cloud {
  background: linear-gradient(180deg, #00d4d6 0%, #00a0a1 100%) !important;
}

.error {
  color: var(--ion-color-danger) !important;
  margin-top: 1rem;
}

.cloud-container {
  .file-picker {
    display: block !important;
  }
}

.dual-pill-button {
  .upload {
    gap: 0.5rem;

    span {
      line-height: 1;
      font-weight: 600;
      font-size: 1.2rem;
      text-transform: uppercase;
    }

    label,
    button {
      padding: 0 !important;
      display: flex;
      border-right: 1px solid var(--ion-tab-bar-background, #1f1f1f);
      background: linear-gradient(180deg, #4f4f4f 0%, #393939 100%);
      border-radius: 24px;
      gap: 5px;
      box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    }

    button {
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 1rem 0.5rem !important;

      ion-icon {
        margin-bottom: 0;
        font-size: 2rem;
      }
    }

    .icon-wrapper {
      padding: 2px;
    }
  }

  .app-icon {
    fill: #fff;
  }
}

.upload-info-container {
  text-align: left;
  line-height: 1;

  .uploadInfo {
    font-size: 14px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

.upload-area {
  padding: 2rem;
  border-style: dashed;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.2392156863);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.3s ease-in-out;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-top: 1rem;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .area-label {
      display: flex;
      flex-direction: column;
      align-items: center;

      .main-label {
        font-weight: 600;
        font-size: 1.75rem;
        text-align: center;
      }
    }

    ion-icon {
      transition: 0.3s ease-in-out;
      color: var(--ion-tab-bar-background, #1f1f1f) !important;
    }
  }
}

.dragging,
.over {
  .wrapper {
    ion-icon {
      animation: light-pulse 1s infinite;
      color: #00d4d6 !important;
    }
  }
}

.dragging {
  .upload-area {
    border-color: var(--ion-color-primary, #3880ff);
  }
}

.over.upload-area {
  border-color: var(--ion-color-primary, #3880ff);
}

.default-button-grey {
  background: #454545 !important;
}
</style>
