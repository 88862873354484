<script setup lang="ts">
import { IonButton, IonCheckbox, IonIcon } from '@ionic/vue';
import { computed, ref, watchEffect } from 'vue';
import { removeCircle } from 'ionicons/icons';
import { FileKind, PreviewURL } from '@/services/filePicker/models';
import { CloudFile } from '@/views/cloud-page/CloudFile';
import PickedFileThumbnailContainer from '@/components/PickedFileThumbnailContainer.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import FilePicker from '@/components/file-picker/FilePicker.vue';
import { usePostDrawerStore } from '@/views/feed/post-drawer-store';
import type { CreatorFile } from '@/services/filePicker/CreatorFile';

const props = withDefaults(
  defineProps<{
    allowedFileKind: FileKind.Image | FileKind.ImageOrVideo | FileKind.Any;
    welcomeText: string;
    viewTitle: (noFileSelected: boolean) => string;
    canSubmit?: boolean;
  }>(),
  { canSubmit: true },
);

const emit = defineEmits<{
  (e: 'submit', file: CreatorFile | CloudFile, thumbnailUrl: PreviewURL, silentPost: boolean): void;
}>();

const isMediaSelected = ref(false);

watchEffect(() => (usePostDrawerStore().viewTitle = props.viewTitle(!isMediaSelected.value)));

const fileToUpload = ref<CreatorFile | undefined>(undefined);
const cloudFile = ref<CloudFile | undefined>(undefined);
const previewUrl = ref<PreviewURL | undefined>(undefined);

const chooseAnotherFileLabel = computed(() => {
  switch (props.allowedFileKind) {
    case FileKind.ImageOrVideo:
      return 'Ein anderes Video auswählen';
    case FileKind.Image:
      return 'Ein anderes Bild auswählen';
    case FileKind.Any:
    default:
      return 'Eine andere Datei auswählen';
  }
});

const reset = () => {
  // Reset
  fileToUpload.value = undefined;
  cloudFile.value = undefined;
  isMediaSelected.value = false;
};

const onChooseAnotherImage = () => {
  isMediaSelected.value = false;
  fileToUpload.value = undefined;
  previewUrl.value = undefined;
};

const silentPost = ref(false);

const submit = async () => {
  const file = fileToUpload.value ?? cloudFile.value;
  const thumb = previewUrl.value;

  if (!file || !thumb) {
    return; // This cannot happen because of v-if directives
  }

  reset(); // Make sure to copy the relevant values before resetting

  emit('submit', file, thumb, silentPost.value);
};
</script>

<template>
  <div class="mb-4 opacity-80 leading-tight tutorial inline-block place-self-start" v-show="!isMediaSelected">
    {{ welcomeText }}
  </div>
  <file-picker
    v-if="!isMediaSelected"
    :allowed-file-kind="allowedFileKind"
    @file-selected="
      (file, prev) => {
        isMediaSelected = true;
        fileToUpload = file;
        previewUrl = prev;
      }
    "
    @cloud-file-selected="
      (file, prev) => {
        isMediaSelected = true;
        cloudFile = file;
        previewUrl = prev;
      }
    "
  />

  <picked-file-thumbnail-container v-if="previewUrl" :preview-url="previewUrl" />

  <div v-show="isMediaSelected">
    <ion-button @click="onChooseAnotherImage()" size="default">
      <ion-icon :icon="removeCircle" slot="start"></ion-icon>
      {{ chooseAnotherFileLabel }}
    </ion-button>

    <slot name="form" />

    <ion-checkbox v-model="silentPost" slot="end" label-placement="end"> Keine Benachrichtigungen senden </ion-checkbox>
    <submit-button @click="submit" :disabled="!canSubmit" />
  </div>
</template>

<style scoped lang="scss">
.tutorial {
  background: #ffffff14;
  border-radius: 0 9px 9px 9px;
  display: inline-block;
  padding: 5px 10px;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}
ion-button {
  --padding-start: 0;
}

ion-icon {
  margin: 0 5px 0 0;
}
</style>
