/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { useAppStore } from '@/store/app';
import { FileKind } from './models';
import { getMimeTypesFromFileKind } from '@/services/filePicker/mime-types';
import { asCreatorFile, type CreatorFile } from '@/services/filePicker/CreatorFile';

export async function pickSingleFile(allowedFileKind?: FileKind): Promise<CreatorFile | undefined> {
  if (useAppStore().isNative) {
    const result = await FilePicker.pickMedia({
      readData: false,
      limit: 1,
    }).catch(console.error);
    if (result?.files[0]) {
      return await asCreatorFile(result.files[0]);
    }
  } else {
    const result = await FilePicker.pickFiles({
      readData: false,
      limit: 1,
      types: allowedFileKind && (getMimeTypesFromFileKind(allowedFileKind) as unknown as string[]),
    }).catch(console.error);

    if (result?.files[0]) {
      return await asCreatorFile(result.files[0]!);
    }
    return undefined;
  }
}
