import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import apiRequest from '@/services/apiRequest';
import { emitter } from '@/bus';
import router from '@/router';

export default {
  switchTeam: async function (team: any) {
    emitter.emit('switchTeam', team);
    const authStore = useAuthStore();
    const userSettingsObject = {
      activeClub: authStore.userSettings.activeClub.clubId,
      selectedTimeline: team.uid,
    };
    if (authStore.userSettings.selectedTimeline) {
      authStore.userSettings.selectedTimeline.uid = team.uid;
    }
    await apiRequest.methods.post(
      `api/profiles/settings/set?key=userSettings&data=${JSON.stringify(userSettingsObject).trim()}`,
      `cloud`,
      userSettingsObject,
    );
    authStore.userSettings.activeClub = team;
    authStore.userSettings.activeClub.clubId = userSettingsObject.activeClub;
    authStore.userSettings.activeClub.isTeam = userSettingsObject.activeClub !== team.uid;
    useAppStore().clubSelected = true;
    emitter.emit('changeBadge', authStore.userSettings.activeClub.badgeUrl);
    await router.push('/create');
  },
};
