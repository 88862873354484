type Seconds = number;

export function getAudioDuration(blob: Blob): Promise<Seconds> {
  return new Promise((resolve, reject) => {
    const audio = document.createElement('audio');
    const fileURL = URL.createObjectURL(blob);

    audio.addEventListener('loadedmetadata', () => {
      URL.revokeObjectURL(fileURL);
      resolve(audio.duration);
    });

    audio.addEventListener('error', () => {
      URL.revokeObjectURL(fileURL);
      reject(new Error('Failed to load audio metadata'));
    });

    audio.src = fileURL;
  });
}
