<script lang="ts" setup>
import { ref } from 'vue';
import { MediaElement, mediaTypeTranslation } from '@/models/media-element';

import { IonTextarea } from '@ionic/vue';

import { MaybeElementRef, onClickOutside } from '@vueuse/core';

defineProps<{ element: MediaElement }>();

const emit = defineEmits<{ (e: 'update:headline', newHeadline: string): void }>();

const isEditing = ref(false);

const inputRef = ref<{ $el: HTMLIonInputElement } | null>(null);
onClickOutside(inputRef as MaybeElementRef, () => (isEditing.value = false));
</script>

<template>
  <ion-textarea
    v-if="isEditing"
    :value="element.headline ?? ''"
    @input="(ev) => emit('update:headline', ev.target?.value)"
    auto-grow
    fill="outline"
    ref="inputRef"
    :rows="1"
  />
  <span @click="isEditing = true" class="cursor-text w-full block" v-else>
    {{ element.headline?.trim() || mediaTypeTranslation(element.$type) }}
  </span>
</template>

<style lang="scss" scoped>
ion-textarea {
  margin-top: 6px;
  margin-bottom: 6px;
  height: 100%;
  width: 100%;
  --padding-bottom: 0;
  --border-radius: 9px !important;
  --border-width: 2px !important;

  ::selection {
    background-color: #00a0a1;
    color: #121212;
  }

  &:focus {
    height: 100%;
  }
}
</style>
