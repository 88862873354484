<template>
  <div :class="iconClass" v-html="svgContent" />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

const svgContent = ref<string | undefined>(undefined);

const props = withDefaults(defineProps<{ name: string; size?: 'small' | 'medium' | 'large' | 'maximum' | 'auto' }>(), {
  size: 'medium',
});

const iconClass = computed(() => ['app-icon', props.size ? 'app-icon-' + props.size : null] as const);

onMounted(async () => {
  const iconPath = await import(`@/Assets/icons/${props.name}.svg`);
  const response = await fetch(iconPath.default);
  svgContent.value = await response.text();
});
</script>
