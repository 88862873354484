import { useAppStore } from '@/store/app';
import { useMatchStore } from '@/store/match';
import { useLivetickerStore } from '@/store/liveticker';
import apiRequest from '@/services/apiRequest';
import { v4 as uuidv4 } from 'uuid';
import type { MatchEventType } from '@/models/timeline-card';

interface MatchEvent {
  uid: string;
  fixtureUid: string;
  teamUid?: string;
  kind: string;
  info: string;
  homeScore?: number;
  guestScore?: number;
  minute: number;
  properties: object;
  playerUid?: string; // Optional property
}

interface cardObject {
  [key: string]: string | any;
  backgroundAudioUrl: any;
}

export default {
  methods: {
    showBanner(bool: boolean, error?: any) {
      let bannerObject;
      if (bool) {
        bannerObject = {
          success: true,
          message: 'Dein Liveticker Event wurde <b>veröffentlicht</b>',
        };
      } else {
        bannerObject = {
          success: false,
          message: 'Dein Liveticker Event wurde <b>NICHT</b> veröffentlicht',
          error: error,
        };
      }
      useAppStore().addBanner(bannerObject);
    },
    addMatchEvent: async function (
      type?: MatchEventType | 'End',
      playerUid?: string,
      teamUid?: string,
      cardObject: Partial<cardObject> = {},
    ) {
      const matchStore = useMatchStore();
      const livetickerStore = useLivetickerStore();

      const fixtureID = livetickerStore.fixtureID;
      if (!fixtureID) {
        console.error('Fixture ID is not set in liveticker store');
        return;
      }

      let sanitizedType = 'unknown';
      if (type) {
        if (type !== 'MatchHalftimeKickoff') {
          sanitizedType = type.replace('Match', '');
          if (type === 'End') {
            sanitizedType = 'Concluded';
          }
        } else {
          sanitizedType = type;
        }
      }

      const eventComp: MatchEvent = {
        uid: uuidv4(),
        fixtureUid: fixtureID,
        kind: sanitizedType,
        info: 'Unknown',
        homeScore: livetickerStore.homeScore,
        guestScore: livetickerStore.guestScore,
        minute: livetickerStore.postingMinute,
        properties: {},
      };

      if (playerUid || (cardObject && cardObject.player && cardObject.player.uid)) {
        eventComp.playerUid = playerUid || cardObject.player.uid;
      }
      if (teamUid || matchStore.activeTeam) {
        if (matchStore.activeTeam === 'home' && livetickerStore.homeTeam) {
          eventComp.teamUid = teamUid || livetickerStore.homeTeam.uid;
        } else if (matchStore.activeTeam === 'guest' && livetickerStore.guestTeam) {
          eventComp.teamUid = teamUid || livetickerStore.guestTeam.uid;
        }
      }

      if (sanitizedType === 'Goal' && matchStore.activeTeam && matchStore.activeTeam === 'home') {
        livetickerStore.homeScore++;
      } else if (sanitizedType === 'Goal' && matchStore.activeTeam && matchStore.activeTeam === 'guest') {
        livetickerStore.guestScore++;
      }

      eventComp.homeScore = livetickerStore.homeScore;
      eventComp.guestScore = livetickerStore.guestScore;

      try {
        await apiRequest.methods.put('api/data/matchevents', 'cloud', eventComp);
        console.log('Event erfolgreich hinzugefügt');
        this.showBanner(true);
      } catch (error) {
        this.showBanner(false, error);
        console.error('Fehler beim Hinzufügen des Events', error);
      }
    },
  },
};
