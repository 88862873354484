<template>
  <div
    v-for="(banner, i) in banners"
    :key="banner.id"
    :class="banner.bannerClasses"
    :style="isActive(i)"
    class="post-banner flex"
  >
    <div v-if="banner.error !== '' && show === 1" class="post-banner__layer cursor-pointer" @click="show++"></div>

    <div class="post-banner__wrapper">
      <div class="post-banner__cta">
        {{ banner.ctaText }}
      </div>
      <div class="post-banner__text py-2">
        <p v-if="show === 1" v-html="banner.noticeText"></p>
        <p v-if="banner.error !== '' && show === 2" v-html="banner.error"></p>
      </div>
      <div class="post-banner__exit cursor-pointer" @click="removeBanner(banner.id)">
        <svg fill="#787b7c" height="15" viewBox="0 0 15 15" width="15" xmlns="http://www.w3.org/2000/svg">
          <path
            clip-rule="evenodd"
            d="M14.0694 11.9551L9.53439 7.34836L14.066 2.73895C14.5886 2.20953 14.5886 1.35806 14.0677 0.826887L13.6467 0.398063C13.3992 0.140416 13.0607 0.00012207 12.7047 0.00012207H12.703C12.3471 0.00012207 12.0129 0.142181 11.7628 0.397181L7.22954 5.00836L2.69451 0.402475C2.17451 -0.126937 1.33764 -0.129584 0.815038 0.399828L0.393134 0.82424C0.138776 1.07748 -0.00012207 1.42159 -0.00012207 1.78247C-0.00012207 2.14512 0.139645 2.48659 0.39053 2.74071L4.9273 7.34836L0.394871 11.9578C-0.127735 12.4872 -0.127735 13.3387 0.393134 13.8716L0.81417 14.2995C1.06332 14.5572 1.40102 14.6975 1.75694 14.6975C2.11113 14.6975 2.4497 14.5537 2.69885 14.3004L7.23128 9.69012L11.7646 14.2951C12.0215 14.5589 12.3636 14.6922 12.7065 14.6922C13.0459 14.6922 13.3862 14.5607 13.6458 14.2978L14.0668 13.8707C14.322 13.6192 14.4601 13.2751 14.4601 12.9134C14.4618 12.5516 14.322 12.2101 14.0712 11.9551H14.0694Z"
            fill="#787b7c"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useAppStore } from '@/store/app';

export default {
  name: 'postBanner',
  data() {
    return {
      noticeText: 'Dein Liveticker Event wurde <b>veröffentlicht</b>',
      show: 1,
    };
  },
  computed: {
    isActive() {
      // https://codepen.io/ykadosh/pen/ZERjpRJ --> von hier genommen und angepasst
      return (index: any) => {
        const inverseIndex = this.banners.length - index - 1;
        const scale = 1 - inverseIndex * 0.05;
        const opacity = 1 - (inverseIndex / this.banners.length) * 0.1;
        const y = inverseIndex * 100 * 0.1;
        return `transform: scale(${scale}) translate(50%, ${-y}px); opacity: ${opacity};`;
      };
    },
    banners() {
      return useAppStore().banners;
    },
  },
  methods: {
    removeBanner(id: number) {
      (this as any).show = 1;
      useAppStore().removeBanner(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.post-banner__layer {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  z-index: 9;
}
.post-banner {
  position: fixed;
  z-index: 999999;
  right: 50%;
  width: 330px;
  min-height: 75px;
  transform: translateX(50%);
  border-radius: 20px;
  background: #24292b;
  display: flex;
  bottom: -100px;
  transition: all 0.4s ease-in-out;
  pointer-events: auto;
  transform-origin: right;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__cta {
    font-size: 17px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-basis: 75px;
    justify-content: center;
    height: 32px;
    margin-left: 25px;
    padding: 0 4px;
    background: rgb(254, 40, 66);

    .post-banner--success & {
      letter-spacing: 2px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 19px;
    margin-left: 10px;
    padding-right: 50px;
  }

  &__exit {
    position: absolute;
    right: 25px;
  }

  &.enter {
    bottom: 60px;
  }

  &.exit {
    bottom: -100px;
    opacity: 0;
  }
}
</style>
