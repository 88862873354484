<template>
  <div class="rounded-full bg-white w-12 overflow-hidden">
    <img v-if="leaguesImageLoaded" :class="{ hidden: !leaguesImageLoaded }" :src="src" @error="error" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'imageLoader',
  data() {
    return {
      leaguesImageLoaded: true,
    };
  },
  props: {
    src: String,
  },
  methods: {
    error: function () {
      this.leaguesImageLoaded = false;
    },
  },
});
</script>

<style scoped></style>
