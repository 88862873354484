<script lang="ts" setup>
import { MediaElement } from '@/models/media-element';
import { close } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';
import { useMagicKeys } from '@vueuse/core';
import { watchEffect } from 'vue';
import MediaView from '@/views/feed/MediaView.vue';

defineProps<{ element: MediaElement }>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { escape } = useMagicKeys();
watchEffect(() => {
  if (escape.value) {
    emit('close');
  }
});
</script>

<template>
  <div class="relative h-full flex flex-col items-center fullscreen-media-slide">
    <media-view :element="element" />
    <div v-if="element.headline">{{ element.headline }}</div>
    <div class="close-overlay" @click="emit('close')">
      <ion-icon :icon="close"></ion-icon>
    </div>
  </div>
</template>

<style lang="scss">
.fullscreen-media-slide {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10;
  padding: 2rem;
  display: flex;
  align-self: center;
  background: #000000a3;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .close-overlay {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 1.5rem;
    margin-left: -2px;
    cursor: pointer;
  }
}
</style>
