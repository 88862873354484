import { FileKind, hasKind, type SingleFileKind } from './models';
import { typedObjectEntries } from '@/utils/typed-stdlib';

export const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/mov',
  'video/quicktime',
  'video/mpeg',
  'video/avi',
  'video/x-msvideo',
] as const;

export const WEB_ALLOWED_VIDEO_MIME_TYPES = ['video/mp4'] as const;

export const AUDIO_MIME_TYPES = ['audio/mp3', 'audio/mp4', 'audio/mpeg', 'audio/m4a', 'audio/x-m4a'] as const;
export const IMAGE_MIME_TYPES = [
  'image/png',
  'image/jpeg',
  'image/svg+xml',
  'image/gif',
  'image/bmp',
  'image/webp',
  'image/heif',
  'image/heic',
] as const;
export const ALL_MIME_TYPES = [...VIDEO_MIME_TYPES, ...AUDIO_MIME_TYPES, ...IMAGE_MIME_TYPES] as const;

export type SupportedMimeType = (typeof ALL_MIME_TYPES)[number];

export function getFileKindFromMimeType(mimeType: string): SingleFileKind | undefined {
  const mimeTypeLower = mimeType.toLowerCase();
  switch (true) {
    case VIDEO_MIME_TYPES.includes(mimeTypeLower):
      return FileKind.Video;
    case AUDIO_MIME_TYPES.includes(mimeTypeLower):
      return FileKind.Audio;
    case IMAGE_MIME_TYPES.includes(mimeTypeLower):
      return FileKind.Image;
    default:
      return undefined;
  }
}

export function getMimeTypesFromFileKind(fileKind: FileKind) {
  function* yieldValues() {
    if (hasKind(fileKind, FileKind.Video)) {
      yield VIDEO_MIME_TYPES;
    }
    if (hasKind(fileKind, FileKind.Audio)) {
      yield AUDIO_MIME_TYPES;
    }
    if (hasKind(fileKind, FileKind.Image)) {
      yield IMAGE_MIME_TYPES;
    }
  }
  return Array.from(yieldValues());
}

const DEFAULT_EXTENSIONS = {
  'image/jpeg': '.jpg',
  'image/png': '.png',
  'image/gif': '.gif',
  'image/bmp': '.bmp',
  'image/webp': '.webp',
  'image/heif': '.heif',
  'image/heic': '.heic',
  'image/svg+xml': '.svg',
  'video/mov': '.mov',
  'video/quicktime': '.mov',
  'video/mpeg': '.mpeg',
  'video/mp4': '.mp4',
  'video/avi': '.avi',
  'video/x-msvideo': '.avi',
  'audio/mp3': '.mp3',
  'audio/mp4': '.mp4',
  'audio/mpeg': '.mpeg',
  'audio/m4a': '.m4a',
  'audio/x-m4a': '.m4a',
} as const satisfies Record<(typeof ALL_MIME_TYPES)[number], string>;

export function getFileExtensionForMimeType(mimeType: string): string {
  const exactMatch = DEFAULT_EXTENSIONS[mimeType];
  if (exactMatch) {
    return exactMatch;
  }

  const split = mimeType.split('/').pop();

  if (split) {
    return `.${split}`;
  }

  throw new Error(`Could not find any possible file extension for mime type '${mimeType}'.`);
}

export function getMimeTypeForFileExtension(fileNameOrExtension?: string): string | undefined {
  if (!fileNameOrExtension) {
    return undefined;
  }
  const fileExtension = ('.' + fileNameOrExtension.split('.').pop()).toLowerCase();
  const foundAsDefault = typedObjectEntries(DEFAULT_EXTENSIONS).find(([_, ext]) => ext === fileExtension)?.[0];

  if (foundAsDefault) {
    return foundAsDefault;
  }

  // Additional special cases
  switch (fileExtension) {
    case '.jpe':
      return 'image/jpeg';
    default:
      return undefined;
  }
}

export const ALLOWED_IMAGE_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp', 'heic'] as const;
