<!--suppress TypeScriptUnresolvedReference, JSUnresolvedReference -->
<template>
  <div class="radial-upload-container" v-if="anyUploadTasks">
    <span v-if="isOffline">Keine Verbindung</span>
    <div class="radial-upload-items w-full">
      <template
        v-for="completedTask in taskQueue.completedTasks.filter((x) => displayedCompletedTasks.get(x.id) === true)"
        :key="completedTask.id"
      >
        <upload-item :task="completedTask" :is-pending="false" />
      </template>

      <div class="upload-queue">
        <template v-for="activeUpload in taskQueue.inProgressTasks" :key="activeUpload.id">
          <upload-item :task="activeUpload" :is-pending="false" />
        </template>
      </div>

      <template v-if="taskQueue.pendingTasks.length > 0">
        <div class="show-more" @click="menuOpened = !menuOpened">
          <ion-icon :icon="menuOpened ? chevronUp : chevronDown"></ion-icon>
        </div>
        <div class="upload-queue" v-if="menuOpened">
          <template v-for="pendingUpload in taskQueue.pendingTasks" :key="pendingUpload.id">
            <upload-item :task="pendingUpload" :is-pending="true" />
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { IonIcon } from '@ionic/vue';
import { chevronDown, chevronUp } from 'ionicons/icons';
import { NetworkStatus, PublishTask, useNetworkStatusStore, useTaskQueue } from '@leagues-football/upload-queue';
import UploadItem from '@/components/uploadProgress/UploadItem.vue';
import { Duration, DurationLike } from 'luxon';

const menuOpened = ref(false);

const isOffline = computed(() => useNetworkStatusStore().networkStatus === NetworkStatus.Offline);
const taskQueue = useTaskQueue();

const displayedCompletedTasks = ref(new Map<PublishTask['id'], boolean>());

const KEEP_COMPLETED_TASKS_FOR: DurationLike = { seconds: 3 };

watch(
  () => useTaskQueue().completedTasks,
  (completed) => {
    completed.forEach(({ id: taskId }) => {
      if (displayedCompletedTasks.value.has(taskId)) {
        return;
      }

      displayedCompletedTasks.value.set(taskId, true);

      setTimeout(() => {
        displayedCompletedTasks.value.set(taskId, false);
      }, Duration.fromDurationLike(KEEP_COMPLETED_TASKS_FOR).as('milliseconds'));
    });
  },
);

const anyUploadTasks = computed(
  () =>
    taskQueue.inProgressTasks.length > 0 ||
    taskQueue.pendingTasks.length > 0 ||
    Array.from(displayedCompletedTasks.value.values()).some(Boolean),
);
</script>

<style lang="scss">
.in-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100%;
  justify-content: center;

  .rotate {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    border: 2px solid cyan;
    border-bottom-width: 0;
    border-left-width: 0;
    animation: 1s rotate infinite linear;
  }

  span {
    font-size: 0.8rem;
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

//Radial Concept
.radial-upload-container {
  position: absolute;
  left: 0;
  top: 50%;
  min-width: 140px;
  transform: translateY(-50%);
  padding: 0.5rem 0.5rem;
  background: #32374170;
  backdrop-filter: blur(3px);
  border-radius: 0 9px 9px 0;
  border: 2px solid #8080804d;
  border-left: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  .radial-upload-items {
    .show-more {
      display: flex;
      justify-content: center;

      ion-icon {
        margin-bottom: 0;
        transition: 0.3s ease;
      }
    }

    .upload-queue {
      margin-top: 0.1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      transition: 0.3s ease;
    }

    #wrapper {
      min-height: 70px;
    }

    #wrapper,
    .upload-queue-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .overlay {
        position: absolute;
        width: 64px;
        height: 64px;
        background: #000000c2;
        border-radius: 50%;
        display: none;
        align-items: center;
        justify-content: center;
        left: 50%;
        transform: translateX(-50%);

        ion-icon {
          margin-bottom: 0;
          transform: scale(2);
        }
      }

      .overlay.active {
        display: flex;
      }
    }

    .upload-status-text {
      font-size: 13px;
      display: block;
      text-align: center;
      width: 100%;
    }

    .upload-status-progress {
      font-size: 10px;
      display: block;
      text-align: center;
      width: 100%;
    }

    .thumbnail {
      border-radius: 50%;
      overflow: hidden;
      width: 58px;
      height: 58px;
      margin-top: -60px;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.5);
      }
    }

    .progress {
      width: 64px;
      height: 64px;

      .track,
      .fill {
        fill: rgba(0, 0, 0, 0);
        stroke-width: 3;
        transform: rotate(90deg) translate(0px, -80px);
      }

      .track {
        stroke: rgb(56, 71, 83);
      }

      .fill {
        stroke: var(--ion-color-primary, #3880ff);
        stroke-dasharray: 219.99078369140625;
        stroke-dashoffset: -219.99078369140625;
        transition: stroke-dashoffset 1s;
      }

      .value,
      .text {
        fill: #fff;
        text-anchor: middle;
        font-size: 21px;
      }

      .text {
        font-size: 12px;
      }
    }
  }

  .radial-upload {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 5px solid;
    overflow: hidden;
    position: relative;

    .progress-percent {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;
    }

    .thumbnail {
      width: 100%;
      height: 100%;
      filter: brightness(0.5);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .radial-progress-bar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 5px solid cyan;
    margin-top: -64px;
    position: relative;
  }
}

.upload-progress {
  position: fixed;
  width: 65%;
  max-width: 280px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  top: 23px;
  background: #323741;
  border-radius: 10px;
  height: 0;
  transition: all 0.5s;
  overflow: hidden;
  padding: 0 20px;

  &.match {
    top: 10px;
  }

  &.active {
    padding: 10px 15px 3px 15px;
  }

  &__bar {
    height: 10px;
    border-radius: 5px;
    max-width: 87%;
    transition: all 0.5s;

    .upload-progress-container.active {
      margin-top: 20px;
    }
  }

  &__container {
    transition: all 0.5s;
  }
}

.cancel-button {
  position: relative;
  width: 15px;
  height: 15px;
  opacity: 0.6;
  padding: 5px;
  border: 1px solid white;
  border-radius: 50%;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: 6px;
    top: 2px;
    content: ' ';
    height: 10px;
    width: 2px;
    background-color: white;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  .isDesktop & {
    width: 16px;
    height: 16px;
  }
}

.triangle_down {
  position: relative;
  top: -5px;
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid white;
  border-top: 0.2em solid white;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1em;
}

// https://codepen.io/kopstad/pen/GRgRjRg und für scss angepasst
$arrowSize: 0.5rem;

@mixin transform($transformation) {
  transform: $transformation;
  -webkit-transform: $transformation;
  -moz-transform: $transformation;
  -ms-transform: $transformation;
  -o-transform: $transformation;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

body {
  /*text-align: center;*/
  font-family: sans-serif;
  background: #f7f7f7;
}

#page {
  padding: 1rem;
}

.filter-openCloseWrap {
  position: relative;
  top: -7px;
  width: $arrowSize;
  height: $arrowSize;
  margin: auto;
  display: block;
  cursor: pointer;

  &:hover {
    .filter-openClose,
    .filter-openClose:before {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  .filter-openClose {
    width: $arrowSize;
    height: 2px;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 50% 50%;
    @include transition(all 0.2s ease-out);

    &:before {
      content: ' ';
      position: absolute;
      height: 2px;
      width: $arrowSize;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 1);
      transform-origin: 50% 50%;
      @include transition(all 0.2s ease-out);
    }
  }
}

.filter-openClose {
  @include transform(rotate(45deg) translate(calc($arrowSize/2), 0px));

  &:before {
    @include transform(rotate(-90deg) translate(- calc($arrowSize/2), - calc($arrowSize/2)));
  }
}

.no-filter .filter-openClose {
  @include transform(rotate(45deg) translate(0px, calc($arrowSize/2)));

  &:before {
    @include transform(rotate(-90deg) translate(calc($arrowSize/2), calc($arrowSize/2)));
  }
}

// https://codepen.io/jake-lee/pen/jrJYON und angepasst
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.load {
  width: 15px;
  height: 15px;
  border: solid 2px white;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.upload-success {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  /*opacity: 0;
    transform: scale(0);
    transition: 0.3s ease;
    transition-delay: 1s;*/

  ion-icon {
    margin-bottom: 0;
    color: #fff;
    position: relative;
    z-index: 1;
    opacity: 0;
    transform: scale(0);
  }

  .success-background {
    background: var(--ion-color-success, #2fdf75);
    position: absolute;
    width: 24px;
    height: 24px;
    left: 50%;
    transform: translateX(-50%) scale(0);
    border-radius: 50%;
    opacity: 0;
  }
}

.succeeded {
  /*transform: scale(2);
  opacity: 1;*/

  ion-icon {
    animation: scaleUp 0.5s forwards;
    animation-delay: 0.2s;
    animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  }

  .success-background {
    animation: fadeInOut 0.5s forwards;
    animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) scale(2.5);
    opacity: 1;
  }
}

@keyframes scaleUp {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(3);
  }
}
</style>
