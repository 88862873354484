<template>
  <div class="container match-container p-4">
    <div>
      <div v-if="teamId === 'choiceTeam'">
        <p>Mit deiner Konfiguration kannst du kein Spiel tickern. Derzeit legst du Inhalte für deinen Verein an.</p>
        <page-select-team
          v-if="authStore.userSettings.activeClub && Object.keys(authStore.userSettings.activeClub).length > 0"
          :selectClub="authStore.userSettings.activeClub"
          :onlyTeams="true"
        ></page-select-team>
      </div>
      <div v-else>
        <div v-if="shownMatches && shownMatches.length > 0" class="mt-3">
          <div
            class="select-match__other-matches cursor-pointer"
            @click="fetchMatchesUntilLastMatch"
            v-if="showPrevMatch"
            :class="{ 'select-match__pulse': loadMode }"
          >
            &uarr;
          </div>
          <template v-for="match in shownMatches" :key="match.uid">
            <div class="select-match__wrapper">
              <span class="select-match__date" v-if="match">
                {{ new Date(match?.startDate).toLocaleDateString('de-DE') }}
              </span>
              <skeletor height="15" width="100" v-else />
              <div class="select-match__edit" @click="editMatch(match?.uid)">Spiel bearbeiten</div>
            </div>
            <div :class="{ 'select-match__inThePast': !match?.isFuture }" class="select-match">
              <router-link :to="!match ? '' : linkTo(match?.uid)" class="flex justify-evenly items-center h-full">
                <!--BADGE LEFT-->
                <div class="badge-wrapper">
                  <span class="show-mobile"> {{ match?.homeTeam?.acronym || '' }}</span>
                  <span class="show-desktop"> {{ match?.homeTeam?.name || '' }}</span>
                </div>
                <!--VS-->
                <div class="versus">
                  <div class="border"></div>
                  <club-badge
                    acronym="match?.homeTeam[0]?.acronym"
                    :image="match?.homeTeam?.badgeUrl || ''"
                    class="inline-block"
                    imgStyles="height: 55px; width: 55px;"
                    styles="padding: 0; margin-left: 20px; background-color: #00FFFF;"
                  />
                  <div class="select-match__text">
                    <span>VS</span>
                  </div>
                  <club-badge
                    acronym="match?.guestTeam[0]?.acronym"
                    :image="match?.guestTeam?.badgeUrl || ''"
                    class="inline-block"
                    imgStyles="height: 55px; width: 55px;"
                    styles="padding: 0; margin-right: 20px; background-color: #00FFFF;"
                  />
                  <div class="border"></div>
                </div>
                <!--BADGE RIGHT-->
                <div class="badge-wrapper">
                  <span class="show-mobile"> {{ match?.guestTeam?.acronym || '' }}</span>
                  <span class="show-desktop"> {{ match?.guestTeam?.name || '' }} </span>
                </div>
              </router-link>
            </div>
          </template>
          <div
            id="other-matches"
            class="select-match__other-matches cursor-pointer"
            @click="fetchMatchesFromLatestMatch"
            v-if="showNextMatch"
            :class="{ 'select-match__pulse': loadMode }"
          >
            &darr;
          </div>
        </div>
        <div v-else class="mt-3">
          <div class="mt-3" v-for="index in 3" :key="index">
            <div class="select-match__wrapper">
              <span class="select-match__date"><skeletor height="15" width="100" /></span>
              <div class="select-match__edit"><skeletor height="15" width="100" /></div>
            </div>
            <div class="select-match">
              <div class="flex justify-evenly items-center h-full">
                <!--BADGE LEFT-->
                <div class="badge-wrapper">
                  <skeletor height="15" />
                </div>
                <!--VS-->
                <div class="versus">
                  <div class="border"></div>
                  <div class="clubBadge flex items-center justify-center">
                    <skeletor height="40" width="40" />
                  </div>
                  <div class="select-match__text">
                    <span>VS</span>
                  </div>
                  <div class="clubBadge flex items-center justify-center">
                    <skeletor height="40" width="40" />
                  </div>
                  <div class="border"></div>
                </div>
                <!--BADGE RIGHT-->
                <div class="badge-wrapper">
                  <skeletor height="15" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="default-button mt-4" @click="newMatch">Neues Spiel anlegen</div>
      </div>
    </div>

    <router-link v-if="clubTeams && clubTeams.length !== 1" class="opacity-50" to="/match/teams/">
      &larr; zurück
    </router-link>
    <draggable-bottom-drawer>
      <template #headline>Spielverwaltung: Anlegen & Bearbeiten</template>
      <fixture-management :teamId="teamId" :matchId="matchId" @updateParent="getMatches" />
    </draggable-bottom-drawer>
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { useAppStore } from '@/store/app';
import { useAuthStore } from '@/store/auth';
import { Skeletor } from 'vue-skeletor';
import { addDays, format, subDays } from 'date-fns';
import { computed, onMounted, ref } from 'vue';
import clubBadge from '@/components/ClubBadge.vue';
import DraggableBottomDrawer from '@/components/functionality/DraggableBottomDrawer.vue';
import FixtureManagement from '@/views/cloud-management/FixtureManagement.vue';
import apiRequest from '@/services/apiRequest';
import PageSelectTeam from '@/views/cloud-management/PageSelectTeam.vue';
import type { MatchFixtureUnfolded } from '@/models/match';

const route = useRoute();
const shownMatches = ref<MatchFixtureUnfolded[]>([]);
const teamId = route ? (route.params.teamid as string) : '';
const matchId = ref('');
const showNextMatch = ref<boolean>(true);
const showPrevMatch = ref<boolean>(true);
const loadMode = ref<boolean>(false);

const clubTeams = computed(() => useAuthStore().userSettings.activeClub?.clubTeams);

const linkTo = (message: string) => '/match/teams/' + teamId + '/match-select/' + message;

const authStore = computed(() => useAuthStore());

const newMatch = () => {
  matchId.value = '';
  useAppStore().toggleBottomDrawer();
};

const editMatch = (match: string) => {
  matchId.value = match;
  useAppStore().toggleBottomDrawer();
};

const fetchMatchesByDateRange = async (startDate: string, endDate: string, size = 20, desc = true) => {
  if (teamId === 'choiceTeam') {
    return false;
  }

  let requestURLGet = `api/data/fixtures/unfold?page=0&size=${size}&sortDesc=${desc}&teamUid=${teamId}`;

  if (startDate) {
    requestURLGet += `&from=${startDate}`;
  }

  if (endDate) {
    requestURLGet += `&to=${endDate}`;
  }

  //const requestURLGet = `api/data/fixtures/unfold?page=0&size=20&sortDesc=true&from=${startDate}&to=${endDate}&teamUid=${teamId}`;
  const result = await apiRequest.methods.get<MatchFixtureUnfolded[]>(requestURLGet, 'cloud');

  if (result.error) {
    throw result.error;
  } else {
    return result.data;
  }
};

const fetchMatchesFromLatestMatch = async () => {
  if (loadMode.value || teamId === 'choiceTeam') {
    return;
  }
  loadMode.value = true;

  // Überprüfe, ob shownMatches Werte enthält und setze das startDate entsprechend
  if (!shownMatches.value || !shownMatches.value.length) return;

  const latestMatch = shownMatches.value[shownMatches.value.length - 1]; // Da wir das neueste Spiel wollen, nehmen wir das letzte Element
  console.log(shownMatches.value);
  console.log(latestMatch);
  const dayAfterLatestMatch = addDays(new Date(latestMatch.startDate), 1);
  const startDate = format(dayAfterLatestMatch, 'yyyy-MM-dd');

  const matches = await fetchMatchesByDateRange(startDate, '', 4, false);
  if (!matches || !matches.length) {
    loadMode.value = false;
    showNextMatch.value = false;
    return;
  }

  // Da wir mit sortDesc = true arbeiten, sollten die Matches bereits in der richtigen Reihenfolge sein
  // Füge die zurückgegebenen Matches am Ende von shownMatches.value hinzu
  shownMatches.value = [...shownMatches.value, ...matches];
  loadMode.value = false;
};

const fetchMatchesUntilLastMatch = async () => {
  if (loadMode.value || teamId === 'choiceTeam') {
    return;
  }

  loadMode.value = true;
  const today = new Date();

  // Überprüfe, ob shownMatches Werte enthält und setze das endDate entsprechend
  let endDate = format(today, 'yyyy-MM-dd'); // Standardwert
  if (shownMatches.value && shownMatches.value.length) {
    const oldestMatch = shownMatches.value[0]; // Da wir das älteste Spiel wollen, nehmen wir das erste Element
    const dayBeforeOldestMatch = subDays(new Date(oldestMatch.startDate), 1);
    endDate = format(dayBeforeOldestMatch, 'yyyy-MM-dd');
  }

  const matches = await fetchMatchesByDateRange('', endDate, 4);
  if (!matches || !matches.length) {
    showPrevMatch.value = false;
    loadMode.value = false;
    return;
  }

  // Sortiere die Matches in absteigender Reihenfolge, um das neueste zuerst zu haben
  const sortedMatches = matches.sort(
    (a: any, b: any) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
  );

  // Füge die zurückgegebenen Matches an den Anfang von shownMatches.value hinzu
  shownMatches.value = [...sortedMatches, ...shownMatches.value];
  loadMode.value = false;
};

const getMatches = async () => {
  if (teamId === 'choiceTeam') {
    return;
  }

  const today = new Date();
  const startDate = format(subDays(today, 360), 'yyyy-MM-dd');
  const endDate = format(addDays(today, 14), 'yyyy-MM-dd');

  const data = await fetchMatchesByDateRange(startDate, endDate);
  if (data) {
    const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    // Sortiere Daten nach Datum
    const sortedData = data.sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

    const pastMatches = sortedData.filter((entry: any) => new Date(entry.startDate) < todayWithoutTime);
    const futureMatches = sortedData.filter((entry: any) => new Date(entry.startDate) >= todayWithoutTime);

    const lastMatch = pastMatches[pastMatches.length - 1];
    const nextMatch = futureMatches[0];
    const overNextMatch = futureMatches[1];

    shownMatches.value = [lastMatch, nextMatch, overNextMatch].filter(Boolean);
  }
};

onMounted(getMatches);
</script>

<style lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.other-matches__club {
  width: 40%;
  position: relative;
  display: inline-block;

  &:first-child:after {
    content: 'vs';
    position: relative;
    display: block;
    text-align: left;
    float: right;
    opacity: 0.5;
    font-weight: lighter;
  }
  &:not(:first-child) {
    padding-left: 5px;
  }
}

.other-matches__date {
  display: inline-block;
  opacity: 0.5;
  font-weight: lighter;
}

.select-match__pulse {
  animation: pulseAnimation 1.5s infinite;
  color: #00a0a1;
  background: var(--ion-tab-bar-background, #1f1f1f);
}
@keyframes pulseAnimation {
  0%,
  100% {
    transform: scale(0.6);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
}
</style>
