<script setup lang="ts">
import { computed } from 'vue';
import { IonIcon } from '@ionic/vue';

import { newspaperOutline } from 'ionicons/icons';
import { NewsPostCard, NewsElement } from '@/models/news-post-card';

const props = defineProps<{ news: NewsPostCard }>();

const emit = defineEmits<{
  (e: 'showDetails', ofEvent: typeof props.news): void;
}>();

const newsElement = computed(() => props.news.elements[0] as NewsElement);
</script>

<template>
  <div class="feed-item">
    <div class="wrapper flex">
      <div
        @click="emit('showDetails', news)"
        class="news-label"
        :style="{ backgroundImage: 'url(' + newsElement.keyvisualUrl + ')' }"
      >
        <ion-icon class="mb-0 text-2xl" :icon="newspaperOutline" />
      </div>
      <div class="feed-type">
        <div class="feed-overview">{{ newsElement.headline }}</div>
      </div>
    </div>
    <div class="feed-hover"></div>
  </div>
</template>

<style lang="scss" scoped>
.open-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  @media (min-width: 768px) {
    display: block;
  }
}

.feed-item {
  margin-bottom: 1rem;
  align-items: stretch;
  background: #191e24;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  min-height: 72px;

  .news-label {
    padding: 1rem 0.75rem;
    min-width: 72px;
    font-weight: 800;
    background: #373737;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    filter: contrast(0.8);
  }

  .feed-type {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-weight: 700;
    justify-content: space-between;

    .feed-overview {
      display: flex;
      align-items: center;
      padding: 0 0.8rem;
      width: 100%;
      max-width: calc(100% - 72px);

      .placeholder-logo {
        background: #454545;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        aspect-ratio: 1;
        align-items: center;
      }

      .chip {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        .text-content {
          padding: 3px 0px;
          word-break: break-word;
        }

        .icon {
          color: #fff;
          text-transform: uppercase;
          border-radius: 4px;
          line-height: 1.2;
          display: flex;
          gap: 5px;
          align-items: center;

          img,
          ion-icon,
          .app-icon {
            width: 24px;
            margin-bottom: 0;
          }
        }
      }

      .chip-blue {
        .app-icon,
        ion-icon {
          color: lightblue;

          svg {
            fill: lightblue;
          }
        }
      }

      .chip-orange {
        .app-icon,
        ion-icon {
          color: orange;

          svg {
            fill: orange;
          }
        }
      }

      .chip-yellow {
        .app-icon,
        ion-icon {
          fill: yellow;

          svg {
            fill: yellow;
          }
        }
      }

      .chip-red {
        .app-icon,
        ion-icon {
          fill: #ff4444;

          svg {
            fill: #ff4444;
          }
        }
      }

      .chip-green {
        .app-icon,
        ion-icon {
          fill: lightgreen;

          svg {
            fill: lightgreen;
          }
        }
      }
    }

    .feed-type-icon {
      min-width: 16px;
      width: 16px;
      margin-right: 8px;

      img {
        width: 100%;
      }
    }

    span {
      line-height: 1.3;
      display: flex;
      justify-content: center;
    }

    .feed-type-media {
      display: flex;
      margin-left: auto;
      gap: 0.5rem;
      min-width: 40px;

      div {
        background: #333;
        padding: 8px;
        border-radius: 9px;
        position: relative;

        img {
          width: 24px;
        }

        .progressbar {
          position: absolute;
          width: calc(100% - 16px);
          height: 3px;
          background: grey;
          border-radius: 3px;
          bottom: 4px;
          overflow: hidden;

          .progress {
            position: absolute;
            top: 28px;
            left: 0;
            width: 50%;
            height: 100%;
            background: limegreen;
          }
        }
      }
    }
  }

  .logo {
    width: 34px;
  }

  .feed-hover {
    position: absolute;
    border: 3px solid transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &:hover {
    .feed-hover {
      border-color: var(--ion-color-primary, #3880ff);
    }
  }
}

.preview-slider {
  min-height: 72px;
  height: 100%;
  min-width: 72px;
  width: 72px;
  position: relative;

  .preview-slider-body {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    height: 100%;
    align-items: center;

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      min-width: 72px;
      min-height: 72px;
      max-width: 72px;
      max-height: 72px;
      object-fit: cover;
      filter: brightness(80%);
      scroll-snap-align: start;
    }
  }

  .placeholder {
    opacity: 0.2;
    background: #000;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
</style>
