import { type SingleFileKind } from './models';
import { Capacitor } from '@capacitor/core';
import type { PickedFile } from '@capawesome/capacitor-file-picker';
import { getFileExtensionForMimeType, getFileKindFromMimeType, type SupportedMimeType } from './mime-types';
import { DateTime, Duration } from 'luxon';
import { Filesystem } from '@capacitor/filesystem';
import { b64toBlob } from '@/utils/base64-utils';

export type CreatorFile = Readonly<{
  blob: Blob;
  mimeType: string;
  kind?: SingleFileKind;

  /**
   * The user's chosen name for the file, but sanitized to be absolutely valid with name and correct file extension.
   */
  name: string;

  /**
   * The size of the file in bytes.
   */
  size: number;

  /**
   * The path to the file if native.
   */
  path?: string;

  /**
   * The last modified timestamp of the file.
   */
  lastModified?: DateTime;

  /**
   * The duration in seconds, if the file is a video.
   */
  duration?: Duration;

  /**
   * The height of the image or video in pixels.
   * Only available on Android and iOS.
   */
  height?: number;

  /**
   * The width of the image or video in pixels.
   * Only available on Android and iOS.
   */
  width?: number;
}>;

export async function asCreatorFile(file: File | PickedFile): Promise<Readonly<CreatorFile>> {
  if (file instanceof File) {
    // Chosen through the web APIs
    return {
      ...file,
      size: file.size,
      name: sanitizeFileName(file.name, file.type),
      blob: file,
      mimeType: file.type,
      kind: getFileKindFromMimeType(file.type),
      lastModified: DateTime.fromMillis(file.lastModified),
      path: undefined,
    };
  }

  if (file.path) {
    // Chosen via Capacitor FilePicker plugin on native
    return {
      ...file,
      size: file.size,
      name: sanitizeFileName(file.name, file.mimeType),
      kind: getFileKindFromMimeType(file.mimeType),
      blob: await createBlobFromNativeFilePath(file.path),
      lastModified: file.modifiedAt ? DateTime.fromMillis(file.modifiedAt, { zone: 'utc' }) : undefined,
      duration: file.duration ? Duration.fromObject({ milliseconds: file.duration }) : undefined,
      path: file.path,
    };
  }

  if (file.blob !== undefined) {
    // Chosen via Capacitor FilePicker plugin on web
    return {
      ...file,
      size: file.size,
      name: sanitizeFileName(file.name, file.mimeType),
      blob: file.blob!,
      kind: getFileKindFromMimeType(file.mimeType),
      lastModified: file.modifiedAt ? DateTime.fromMillis(file.modifiedAt, { zone: 'utc' }) : undefined,
      duration: file.duration ? Duration.fromObject({ milliseconds: file.duration }) : undefined,
      path: undefined,
    };
  }

  throw new Error('File is not valid. It is not a web standards file, has no native path, and no blob attached.');
}

export async function creatorFileFromNativeFilePath(
  nativeFilePath: string,
  mimeType: SupportedMimeType,
  withPropertiesFromOriginalFile?: CreatorFile,
): Promise<CreatorFile> {
  if (nativeFilePath === '') {
    throw new Error('Native file path was an empty string!');
  }

  const fileName = withPropertiesFromOriginalFile?.name ?? sanitizeFileName(nativeFilePath.split('/').pop()!, mimeType);

  const blob = await createBlobFromNativeFilePath(nativeFilePath);

  return {
    blob,
    mimeType,
    kind: getFileKindFromMimeType(mimeType),
    name: fileName,
    size: blob.size,
    path: nativeFilePath,
    lastModified: withPropertiesFromOriginalFile?.lastModified ?? DateTime.utc(),
    duration: withPropertiesFromOriginalFile?.duration,
    height: withPropertiesFromOriginalFile?.height,
    width: withPropertiesFromOriginalFile?.width,
  };
}

export async function createBlobFromNativeFilePath(inputFilePath: string) {
  const fileURI = Capacitor.convertFileSrc(inputFilePath);
  const response = await fetch(fileURI);

  if (response.ok) {
    return await response.blob();
  }

  console.warn(
    `Unable to fetch the file at converted path ${fileURI}. Falling back to loading the file from the file system.`,
  );

  const { data } = await readFile(inputFilePath);

  if (data instanceof Blob) {
    // Should never happen since this ought to run only on native, but just in case...
    return data;
  }

  return b64toBlob(data);
}

const readFile = async (contentPath: string) => {
  const filePath = contentPath.includes('://') ? contentPath : 'file://' + contentPath;

  return await Filesystem.readFile({
    path: filePath,
    directory: undefined,
  });
};

const sanitizeFileName = (fileName: string, mimeType: string) => {
  let ext = fileName.split('.').pop();
  if (!ext) {
    ext = getFileExtensionForMimeType(mimeType);
    console.warn(`File name '${fileName}' has no file extension! Using auto-determined extension '${ext}' instead.`);
  }

  const res = fileName.replace(/[^a-zA-Z0-9.\-_]/g, '');
  if (res.split('.')[0] === '') {
    return `file.${ext}`;
  }
  return res;
};
