<script lang="ts" setup>
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue';
import { emitter } from '@/bus';

const emit = defineEmits<{ (e: 'updateText'): void }>();

const text = ref('');
const isActive = ref(false);

const updateText = (newText: string) => {
  text.value = newText;
  isActive.value = true;

  setTimeout(() => {
    isActive.value = false;
  }, 7000);
};

onMounted(() => emitter.on('microInteraction', (e) => updateText(e)));

onBeforeMount(() => emitter.all.delete('microInteraction'));
onUnmounted(() => emit('updateText'));
</script>

<template>
  <div class="microInteraction" :class="{ 'micro-interaction-warning': isActive }">
    {{ text }}
  </div>
</template>

<style>
.microInteraction {
  position: absolute;
  z-index: 30000;
  width: calc(100% - 1rem);
  top: -150px;
  right: 0;
  left: 0;
  transition: 1s ease;
  margin: 0.5rem;
  /*background: #0a8754b5;*/
  background: var(--ion-color-primary, #3880ff);
  border-radius: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  backdrop-filter: blur(3px);
}

.micro-interaction-warning {
  top: 0 !important;
  margin-top: calc(0.5rem + env(safe-area-inset-top));
  /*background: #ff0000b5 !important;*/
}
</style>
