<script setup lang="ts">
const props = defineProps({
  badgeUrl: String,
  teamName: String,
  noSwitch: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{ (e: 'confirmSwitch', value: boolean) }>();

const confirm = () => emit('confirmSwitch', true);
</script>

<template>
  <div class="popup-background">
    <div class="popup-container">
      <img :src="props?.badgeUrl" />
      <span class="mb-4 mt-2 block" v-if="!noSwitch"
        >Du bist jetzt Content-Creator <br /><b>{{ props?.teamName }}</b></span
      >
      <span class="mb-4 mt-2 block" v-if="noSwitch"
        >Du bist <u>weiterhin</u> Content-Creator <br /><b>{{ props?.teamName }}</b></span
      >
      <div @click="confirm" class="default-button w-full">Okay</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.popup-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;

  .popup-container {
    margin: 1rem;
    padding: 2rem 1rem;
    border-radius: 12px;
    background: linear-gradient(135deg, #232222, #111312, #131414);
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    border: 2px solid #252525;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 48px;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
