import { actionSheetController, popoverController, modalController, menuController } from '@ionic/vue';

export default {
  async trigger() {
    // close action sheet

    try {
      const element = await actionSheetController.getTop();
      if (element) {
        await element.dismiss();
        return false;
      }
    } catch (error) {
      console.log(error);
    }

    // close popover
    try {
      const element = await popoverController.getTop();
      if (element) {
        await element.dismiss();
        return false;
      }
    } catch (error) {
      console.log(error);
    }

    // close modal
    try {
      const element = await modalController.getTop();
      if (element) {
        await element.dismiss();
        return false;
      }
    } catch (error) {
      console.log(error);
    }

    // close side menus
    try {
      const element = await menuController.getOpen();
      if (element !== null) {
        await menuController.close();
        //return false;
      }
    } catch (error) {
      console.log(error);
    }
    return true;
  },
};
