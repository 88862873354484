import { useAuthStore } from '@/store/auth';
import { useAppStore } from '@/store/app';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import matchSelect from '../views/MatchSelect.vue';
import matchWrapper from '../views/MatchWrapper.vue';
import UserProfile from '@/views/profile/UserProfile.vue';
import CloudPage from '@/views/cloud-page/CloudPage.vue';
import autocloseOverlaysService from '@/services/autocloseOverlaysService';
import AutoAudio from '@/views/AutoAudio.vue';
import AutoAudioList from '@/components/autoAudio/AutoAudioList.vue';
import InviteUser from '@/views/InviteUser.vue';
import CreateCreator from '@/views/CreateCreator.vue';
import CreateTab from '@/views/feed/CreateTab.vue';

const authCheck = async (to: any, from: any, next: any) => {
  const authStore = useAuthStore();

  if (!authStore.isUserAuthenticated) {
    if (to.name === 'login') {
      next();
    } else {
      next({ name: 'login' });
    }
    return;
  }

  const trigger = await autocloseOverlaysService.trigger();
  if (!trigger) {
    next(false);
    return;
  }

  if (to.name === 'login') {
    next({ name: 'createtab' });
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/PageLogin.vue'),
  },
  {
    path: '/',
    redirect: '/create',
    beforeEnter: authCheck,
  },
  {
    path: '/match/',
    redirect: '/match/teams/',
    beforeEnter: authCheck,
  },
  {
    name: 'Club',
    path: '/club',
    component: () => import('@/views/cloud-management/ClubManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'Club ID',
    path: '/club/:clubid',
    component: () => import('@/views/cloud-management/ClubManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'Club ID + Name',
    path: '/club/:clubid/:clubname',
    component: () => import('@/views/cloud-management/ClubManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'Team ID',
    path: '/teams/:teamid',
    component: () => import('@/views/cloud-management/TeamManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'venue',
    path: '/venue',
    component: () => import('@/views/cloud-management/VenueManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'venue ID',
    path: '/venue/:venueid',
    component: () => import('@/views/cloud-management/VenueManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'venue ID + Name',
    path: '/venue/:venueid/:venueName',
    component: () => import('@/views/cloud-management/VenueManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'Competition Choice',
    path: '/competition',
    component: () => import('@/views/cloud-management/CompetitionManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'Competition Create',
    path: '/competition/:competitionid/create',
    component: () => import('@/views/cloud-management/CompetitionManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'Competition ID',
    path: '/competition/:competitionid',
    component: () => import('@/views/cloud-management/CompetitionManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'Squad',
    path: '/squad',
    component: () => import('@/views/cloud-management/SquadManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'Squad ID',
    path: '/squad/:squadid',
    component: () => import('@/views/cloud-management/SquadManagement.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'player',
    path: '/player',
    component: () => import('@/views/cloud-management/PlayerCreateAndEditPage.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'playerEdit',
    path: '/player/:playerid',
    component: () => import('@/views/cloud-management/PlayerCreateAndEditPage.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'pageSwitchClub',
    path: '/switchclub',
    component: () => import('@/views/cloud-management/PageSwitchClub.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'teamid',
    path: '/match/teams/:teamid',
    component: matchSelect,
    beforeEnter: authCheck,
  },
  {
    name: 'matchid',
    path: '/match/teams/:teamid/match-select/:matchid',
    component: matchWrapper,
    beforeEnter: authCheck,
  },
  {
    name: 'createtab',
    path: '/create/',
    component: CreateTab,
    beforeEnter: authCheck,
  },
  {
    name: 'profile',
    path: '/profile/',
    component: UserProfile,
    beforeEnter: authCheck,
  },
  {
    name: 'cloud',
    path: '/cloud/',
    component: CloudPage,
    beforeEnter: authCheck,
  },
  {
    name: 'autoAudio',
    path: '/auto-audios/',
    component: AutoAudio,
    beforeEnter: authCheck,
  },
  {
    name: 'autoAudioList',
    path: '/auto-audios/:scope',
    component: AutoAudioList,
    beforeEnter: authCheck,
  },
  {
    name: 'InviteUser',
    path: '/user-create/',
    component: InviteUser,
    beforeEnter: authCheck,
  },
  {
    name: 'CreateCreator',
    path: '/create-creator/',
    component: CreateCreator,
    beforeEnter: authCheck,
  },
  {
    name: 'manageClubMembers',
    path: '/club-members/',
    component: () => import('@/views/club-members/ManageClubMembers.vue'),
    beforeEnter: authCheck,
  },
  {
    name: 'statistics',
    path: '/statistics/',
    component: () => import('@/views/StatisticsPage.vue'),
    beforeEnter: authCheck,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash };
    }
  },
});

router.afterEach((to) => {
  const appStore = useAppStore();
  appStore.closeBottomDrawer();

  const now = new Date();

  if (to.path === '/match-select' || to.path === '/match-select/' || to.path.startsWith('/profile')) {
    return;
  }

  const item = {
    value: to.fullPath,
    expiry: now.getTime() + 144e7, // Ablaufzeit in Millisekunden
  };
  localStorage.setItem('route', JSON.stringify(item));
});

export default router;
