import type { NewsPostCard } from '@/models/news-post-card';
import type { TimelineCard } from '@/models/timeline-card';
import type { MediaPostCard } from '@/models/media-post-card';

export type TimelineEntry = TimelineCard | NewsPostCard | MediaPostCard;

export function isMatchEvent(card: TimelineEntry): card is TimelineCard {
  if (card.properties?.eventEntityType === 'matchevent') {
    return true;
  }
  if (card.class !== 'Match') {
    return false;
  }
  if (card.event === 'Match') {
    return false;
  }
  return card.event.startsWith('Match');
}

export function isNewsCard(card: TimelineEntry): card is NewsPostCard {
  return card.class === 'News';
}

export function isMediaPostCard(card: TimelineEntry): card is MediaPostCard {
  return !card.properties?.eventEntityType && card.event === 'Match';
}
