<script lang="ts" setup>
import { TimelineCard, type TimelineCardId } from '@/models/timeline-card';
import { NewsPostCard } from '@/models/news-post-card';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
  ItemReorderCustomEvent,
} from '@ionic/vue';

import { add, bugOutline, checkmark, close, trashOutline } from 'ionicons/icons';
import { computed, ref, toRef, watch, watchEffect } from 'vue';
import { CardSegment } from '@/models/CardSegment';
import apiRequest from '@/services/apiRequest';
import helper from '@/services/helper';
import { useTimelineStore } from '@/store/timeline';
import MatchEventPictogram from '@/views/feed/feed-entries/MatchEventPictogram.vue';
import { MediaElement } from '@/models/media-element';
import FullScreenMediaSlide from '@/views/feed/FullScreenMediaSlide.vue';
import EditableHeadlineLabel from '@/views/feed/feed-entry-details/EditableHeadlineLabel.vue';
import PostNewsForm, { NewsPostModel } from '@/views/feed/card-forms/NewsPostForm.vue';
import MediaView from '@/views/feed/MediaView.vue';
import { cloneDeep } from 'lodash';
import { useAppStore } from '@/store/app';
import ChangePlayerModal from '@/views/feed/feed-entry-details/ChangePlayerModal.vue';
import { usePlayerChange } from '@/views/feed/feed-entry-details/usePlayerChange';
import { useCardFirstElement } from '@/models/useCardFirstElement';
import { useDeleteCard } from '@/views/feed/feed-entry-details/useDeleteCard';
import { useMatchEvent, useUpdateMatchEvent } from '@/views/feed/feed-entry-details/useMatchEvent';
import { useClipboard } from '@vueuse/core';

const emit = defineEmits<{
  (e: 'addMedia', matchMoment: TimelineCard): void;
  (e: 'cardsMerged', targetCardId: TimelineCardId): void;
}>();

const segmentDeletionInProgress = ref<string | false>(false);

const props = defineProps<{ card?: TimelineCard | NewsPostCard }>();
const { card, firstElement: element } = useCardFirstElement(toRef(() => props.card));

const previewSegmentElement = ref<MediaElement | null>(null);

const { data: matchEvent } = useMatchEvent(computed(() => props.card));

const newsPostModel = ref<NewsPostModel | undefined>();

const { changedPlayer, playerId, teamId, matchFixtureId, asElementWithUpdatedPlayer, canChangePlayer } =
  usePlayerChange(card);

const appStore = useAppStore();
const isNative = computed(() => appStore.isNative);

watchEffect(() => {
  newsPostModel.value =
    element.value?.$type === 'NewsItem'
      ? {
          body: element.value.body,
          headline: element.value.headline,
          teaserText: element.value.teaser ?? '',
        }
      : undefined;
});

const headline = ref<string>('');

watchEffect(() => {
  headline.value = element.value && 'headline' in element.value ? element.value.headline ?? '' : '';
});

async function removeSegment(id: string) {
  if (!card.value) {
    return;
  }

  const response = await apiRequest.methods.delete(
    `/api/timelines/removeCardSegment?card=${card.value.id}&segment=${id}`,
    'cloud',
    {},
  );

  if (response.error) {
    helper.methods.microInteraction(
      'Beim löschen der Mediendatei ist etwas schief gelaufen. Bitte versuche es erneut!',
    );
  } else {
    deletedSegmentIds.value.push(id);
  }
}

const modal = ref<{ $el: HTMLIonModalElement }>();

const { deleteCard, cardDeletionInProgress } = useDeleteCard(card, () => modal.value?.$el.dismiss);

const deletedSegmentIds = ref([] as string[]);
const originalSegments = computed(
  () => card.value?.segments.filter((x) => !deletedSegmentIds.value.includes(x.id)) ?? [],
);
const modifiedSegments = ref([] as CardSegment[]);

watchEffect(function resetOrderedSegments() {
  modifiedSegments.value = cloneDeep(originalSegments.value);
});

const handleReorder = (event: ItemReorderCustomEvent) => {
  // Move the item `from` -> `to` in the array
  const [itemToMove] = modifiedSegments.value.splice(event.detail.from, 1);
  modifiedSegments.value.splice(event.detail.to, 0, itemToMove);

  // Assign the new order value to each segment by its index in the new array
  modifiedSegments.value.forEach((segment, idx) => {
    segment.order = idx + 1;
  });

  event.detail.complete();
};

const isOrderChanged = computed(() => {
  const oldOrder = originalSegments.value.map((x) => x.id);
  const newOrder = modifiedSegments.value.map((x) => x.id);

  // ["a","b","c"].toString() --> "a,b,c"
  return oldOrder.toString() !== newOrder.toString();
});

const dirtySegments = computed(() =>
  modifiedSegments.value.filter(
    (segment) =>
      segment.elements[0].headline !== originalSegments.value.find((x) => x.id === segment.id)?.elements[0].headline,
  ),
);

const isNewsFormDirty = computed(
  () =>
    element.value?.$type === 'NewsItem' &&
    newsPostModel.value &&
    (newsPostModel.value.headline !== element.value.headline ||
      newsPostModel.value.teaserText !== element.value.teaser ||
      newsPostModel.value.body !== element.value.body),
);

const isHeadlineDirty = computed(
  () => element.value && 'headline' in element.value && headline.value && headline.value !== element.value.headline,
);

const isDirty = computed(
  () =>
    isOrderChanged.value ||
    dirtySegments.value.length > 0 ||
    isNewsFormDirty.value ||
    isHeadlineDirty.value ||
    changedPlayer.value,
);

const isSaving = ref(false);

const { mutate: updateMatchEvent } = useUpdateMatchEvent({
  onError(err) {
    // It's no bother to the user if updating the match event fails. The card will hopefully still be saved.
    console.error(err);
  },
});

async function saveChanges() {
  if (!card.value) {
    return;
  }

  isSaving.value = true;

  try {
    let errors = false;
    let invalidateCard = false;

    // Update order of segments
    if (isOrderChanged.value) {
      const payload = modifiedSegments.value.map((x) => x.id);
      const response = await apiRequest.methods.put(
        `/api/timelines/orderCardSegments?card=${card.value.id}`,
        'cloud',
        payload,
      );
      if (response.error) {
        helper.methods.microInteraction(
          'Das umsortieren einiger Kartensegmente ist fehlgeschlagen! Bitte starte die App neu und versuche es noch einmal!',
        );
        errors = true;
      }

      invalidateCard = true;
    }

    // Update headlines of segments
    if (dirtySegments.value.length > 0) {
      // Note: Do not try to fire these requests in parallel. The API has a race condition that will cause updates
      // to be dropped.
      const responses: Awaited<ReturnType<typeof apiRequest.methods.put>>[] = [];
      for (const segment of dirtySegments.value) {
        const res = await apiRequest.methods.put(
          `/api/timelines/updateCardSegment?card=${card.value!.id}`,
          'cloud',
          segment,
        );
        responses.push(res);
      }

      if (responses.some((x) => x.error)) {
        helper.methods.microInteraction(
          'Ein oder mehrere Kartensegmente konnten nicht gespeichert werden. Bitte versuche es erneut!',
        );
        errors = true;
      } else {
        invalidateCard = true;
      }
    }

    // Update news post
    if (isNewsFormDirty.value) {
      const payload = cloneDeep(card.value);
      const newsElement = payload.elements[0];

      if (newsElement.$type !== 'NewsItem') {
        helper.methods.microInteraction(
          'Die Textänderungen am News-Element konnten nicht gespeichert werden. Bitte versuche es erneut!',
        );
        errors = true;
      } else {
        newsElement.headline = newsPostModel.value?.headline ?? '';
        newsElement.body = newsPostModel.value?.body ?? '';
        newsElement.teaser = newsPostModel.value?.teaserText ?? '';

        await apiRequest.methods.put(`/api/timelines/updateCard`, 'cloud', payload);

        invalidateCard = true;
      }

      // Sanity check
      if (isHeadlineDirty.value || changedPlayer.value) {
        console.warn("Programming error: News posts aren't supposed to have changes in headline or player");
      }
    }
    // Prepare a single payload for all other changes
    else if (isHeadlineDirty.value || changedPlayer.value) {
      const payload = cloneDeep(card.value);
      const firstElement = payload.elements[0];

      if (isHeadlineDirty.value) {
        firstElement['headline'] = headline.value;
      }

      if (changedPlayer.value) {
        Object.assign(firstElement, asElementWithUpdatedPlayer(firstElement, changedPlayer.value));

        if (matchEvent.value) {
          updateMatchEvent({ ...matchEvent.value, playerUid: changedPlayer.value.uid });
        }
      }

      const updateCardResponse = await apiRequest.methods.put(`/api/timelines/updateCard`, 'cloud', payload);

      if (updateCardResponse.error !== null) {
        helper.methods.microInteraction('Eine Card konnte nicht gespeichert werden. Bitte versuche es erneut!');
        errors = true;
      } else {
        invalidateCard = true;
      }
    }

    if (invalidateCard) {
      await useTimelineStore().invalidateCard(card.value.id);
    }
    !errors && (await modal.value?.$el.dismiss());
  } finally {
    isSaving.value = false;
  }
}

const isChangePlayerModalOpen = ref(false);

function resetAll() {
  isChangePlayerModalOpen.value = false;
  previewSegmentElement.value = null;
}

watch(
  () => card.value,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      resetAll();
    }
  },
  { immediate: true },
);

const debugInfo = computed(() => JSON.stringify(card.value, null, 4));
const { copy } = useClipboard();
const copyDebugInfo = () => copy(debugInfo.value);
</script>

<template>
  <ion-modal ref="modal">
    <full-screen-media-slide
      v-if="!!previewSegmentElement"
      :element="previewSegmentElement"
      @close="previewSegmentElement = null"
    />
    <template v-if="!!card">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="modal?.$el.dismiss()"><ion-icon :icon="close"></ion-icon></ion-button>
          </ion-buttons>
          <ion-title class="flex items-center">
            <template v-if="element && element.$type === 'NewsItem' && !isNative">{{ element.headline }}</template>
            <template v-else-if="element && element.$type !== 'NewsItem'">
              <match-event-pictogram
                :eventType="card.event"
                :element="changedPlayer ? asElementWithUpdatedPlayer(element, changedPlayer) : element"
                :class="['font-bold', { 'cursor-pointer': canChangePlayer && teamId }]"
                @click="canChangePlayer && teamId && (isChangePlayerModalOpen = true)"
              />
              <change-player-modal
                v-if="canChangePlayer && teamId"
                :player-id="playerId"
                :team-id="teamId"
                :match-fixture-id="matchFixtureId"
                :is-open="isChangePlayerModalOpen"
                @did-dismiss="isChangePlayerModalOpen = false"
                @player-selected="
                  isChangePlayerModalOpen = false;
                  changedPlayer = $event;
                "
              />
            </template>
          </ion-title>
          <ion-buttons slot="end">
            <ion-button v-if="!isSaving" :strong="true" @click="saveChanges" :disabled="!isDirty"
              ><ion-icon :icon="checkmark" color="#fff" fill="#fff" stroke="currentColor"></ion-icon
            ></ion-button>
            <ion-spinner v-else name="crescent" />
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <div class="flex flex-col gap-4 min-h-full">
          <div v-if="element && element.$type !== 'NewsItem' && 'headline' in element" class="post-properties">
            <span class="section-header">Überschrift</span>
            <ion-textarea v-model="headline" auto-grow placeholder="Keine Überschrift" fill="outline" :rows="1" />
          </div>

          <template v-if="element?.$type === 'NewsItem' && !!newsPostModel">
            <post-news-form v-model="newsPostModel" />
          </template>

          <div v-else-if="card.segments.length > 0">
            <span class="section-header">Story editieren</span>
            <div>
              <ion-reorder-group :disabled="false" @ionItemReorder="handleReorder($event)">
                <ion-item v-for="segment in modifiedSegments" :key="segment.id">
                  <div class="img-wrapper">
                    <img
                      class="py-2 h-[80px] w-auto mr-3 cursor-pointer"
                      v-if="segment.elements[0].$type === 'Video' || segment.elements[0].$type === 'Image'"
                      @click="previewSegmentElement = segment.elements[0]"
                      :src="
                        segment.elements[0].$type === 'Video'
                          ? `${segment.elements[0].videoUrl}.jpg`
                          : segment.elements[0].imageUrl
                      "
                      alt="Medienvorschau"
                    />
                  </div>
                  <ion-label>
                    <editable-headline-label
                      v-if="segment.elements[0]"
                      :element="segment.elements[0]"
                      @update:headline="(newHeadline) => (segment.elements[0].headline = newHeadline)"
                    />
                  </ion-label>
                  <ion-alert
                    :trigger="`delete-segment-${segment.id}`"
                    header="Diese Mediendatei unwiderruflich löschen?"
                    :buttons="[
                      {
                        text: 'Abbrechen',
                        role: 'cancel',
                      },
                      {
                        text: 'Löschen',
                        cssClass: 'delete-irrevocably',
                        role: 'confirm',
                        handler: () => removeSegment(segment.id),
                      },
                    ]"
                  />
                  <ion-button :id="`delete-segment-${segment.id}`" slot="end" class="trash-icon" color="danger">
                    <ion-spinner v-if="segmentDeletionInProgress === segment.id" name="crescent" />
                    <ion-icon v-else :icon="trashOutline" />
                  </ion-button>
                  <ion-reorder slot="end"></ion-reorder>
                </ion-item>
              </ion-reorder-group>
            </div>
          </div>

          <div v-if="element && (element.$type === 'Video' || element.$type === 'Audio' || element.$type === 'Image')">
            <span class="section-header">Vorschau</span>
            <media-view :element="element" :autoplay="false" :containHeight="true" />
          </div>

          <ion-alert
            trigger="delete-card"
            header="Karte von der Timeline entfernen?"
            sub-header="Diese Aktion kann nicht rückgängig gemacht werden!"
            :buttons="[
              {
                text: 'Abbrechen',
                role: 'cancel',
              },
              {
                text: 'Entfernen',
                cssClass: 'delete-irrevocably',
                role: 'confirm',
                handler: deleteCard,
              },
            ]"
          />

          <ion-button
            v-if="card.class === 'Match'"
            id="attach-media"
            color="primary"
            fill="solid"
            size="default"
            @click="emit('addMedia', card)"
          >
            <ion-icon :icon="add" slot="start" />
            Medien hinzufügen
          </ion-button>

          <!--Merge Cards-->
          <!--<template v-if="isMediaPostCard(card) && useCardMerging(card).targetFeedEntries.value.length > 0">
            <ion-button id="merge-card-modal-trigger" fill="solid" size="default" class="default-button">
              <ion-icon :icon="gitMergeOutline" class="mr-2"></ion-icon>
              Mit anderer Card zusammenfügen
            </ion-button>
            <merge-card-modal :original-card="card" @cards-merged="emit('cardsMerged', $event)" />
          </template>-->

          <ion-button id="delete-card" fill="solid" size="default">
            <ion-icon v-if="!cardDeletionInProgress" :icon="trashOutline" slot="start" />
            <ion-spinner v-else name="crescent" />
            Card Löschen
          </ion-button>
          <div class="h-full flex flex-grow items-end justify-end">
            <ion-button id="debug">
              <ion-icon slot="icon-only" :icon="bugOutline" />
            </ion-button>
            <ion-modal trigger="debug">
              <ion-content class="ion-padding content-container">
                <button class="default-button" @click="copyDebugInfo">Kopieren</button>
                <pre class="scroll-auto whitespace-pre-wrap text-white select-text font-mono" v-html="debugInfo" />
              </ion-content>
            </ion-modal>
          </div>
        </div>
      </ion-content>
    </template>
  </ion-modal>
</template>

<style lang="scss" scoped>
.default-button {
  padding: 0 !important;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.section-header {
  margin-bottom: 0.5em;
  display: block;
}

ion-textarea {
  width: 100%;
  --padding-bottom: 0;
  --border-radius: 9px !important;
  --border-width: 2px !important;

  ::selection {
    background-color: #00a0a1;
    color: #121212;
  }

  &:focus {
    height: 100%;
  }
}

button.alert-button.delete-irrevocably {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

ion-label {
  margin-top: 0;
}

#attach-media {
  background-color: var(--ion-color-tertiary);
  border-radius: 50px;
  font-weight: 600;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  &:hover {
    opacity: 0.8;
  }

  ion-icon {
    margin: 0 8px 0 0;
  }
}

#delete-card {
  background-color: var(--ion-color-danger);
  border-radius: 50px;
  font-weight: 600;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  &:hover {
    opacity: 0.8;
  }

  ion-icon {
    margin: 0 8px 0 0;
  }
}

ion-reorder-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ion-item {
    --padding-start: 0;
    border-radius: 9px;

    ion-icon {
      margin: 0;
    }

    .img-wrapper {
      width: 48px;
      height: 48px;
      margin: 10px;
      border-radius: 9px;
      overflow: hidden;

      img {
        padding: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

ion-toolbar {
  .chip {
    text-align: center;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ion-button {
    ion-icon {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
}
</style>
