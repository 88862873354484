<template>
  <div>
    <div class="feed-item" ref="feedItem" :class="{ isPressed: pressIndicator }">
      <div class="wrapper flex">
        <div
          class="feed-time"
          :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
          @click="$emit('addMedia', moment)"
        >
          <ion-icon
            class="mb-0 text-2xl"
            v-if="firstElement && '$type' in firstElement && firstElement.$type === 'Video'"
            :icon="videocamOutline"
          />
          <ion-icon
            class="mb-0 text-2xl"
            v-if="firstElement && '$type' in firstElement && firstElement.$type === 'Image'"
            :icon="imageOutline"
          />
          <span v-else>{{ gameMinuteStr }}</span>
        </div>
        <div class="feed-type">
          <div class="feed-overview" @click="$emit('addMedia', moment)">
            <match-event-pictogram :element="firstElement" :eventType="moment.event" />
          </div>
          <div class="slider-container">
            <thumbnail-slider
              v-if="showElement"
              :mediaElements="mediaElements"
              @add-media="emit('addMedia', moment)"
              @toggle-slideshow="(elements, idx) => emit('toggleSlideshow', elements, idx)"
            />
            <div v-if="mediaElements.length !== 0" class="add-video" @click="$emit('addMedia', moment)">
              <ion-icon class="mb-0" :icon="add"></ion-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="feed-hover"></div>
      <div class="feed-settings" @click="$emit('showDetails', moment)">
        <ion-icon :icon="ellipsisVertical"></ion-icon>
      </div>
    </div>
    <available-branded-match-story :moment="props.moment"></available-branded-match-story>
  </div>
</template>

<script setup lang="ts">
import { computed, effect, ref, onMounted } from 'vue';
import { IonIcon, createGesture } from '@ionic/vue';
import { add, imageOutline, videocamOutline, ellipsisVertical } from 'ionicons/icons';
import { TimelineCard } from '@/models/timeline-card';
import apiRequest from '@/services/apiRequest';
import { Player as PlayerType } from '@/models/player';
import ThumbnailSlider from '../ThumbnailSlider.vue';
import { ImageElement, MediaElement, VideoElement } from '@/models/media-element';
import MatchEventPictogram from '@/views/feed/feed-entries/MatchEventPictogram.vue';
import AvailableBrandedMatchStory from '@/views/feed/feed-entries/AvailableBrandedMatchStory.vue';
import { formatGameMinute } from '@/models/game-timing';

const props = defineProps<{ moment: TimelineCard }>();

const firstElement = computed(() => props.moment.elements[0]);

const showElement = computed(() => !!firstElement.value);

//#region Type Guards
function isImageElement(element?: { $type: string }): element is ImageElement {
  return element?.$type === 'Image';
}
function isVideoElement(element?: { $type: string }): element is VideoElement {
  return element?.$type === 'Video';
}

function isImageOrVideoElement(element: MediaElement): element is VideoElement | ImageElement {
  return element.$type === 'Video' || element.$type === 'Image';
}

//#endregion

const mediaElements = computed(() => {
  // Take all but the first elements, and all the segments as media elements to display thumbnails for
  const elementsAndSegments = [
    ...(props.moment?.elements.slice(1) ?? []),
    ...(props.moment?.segments.flatMap((s) => s.elements) ?? []),
  ] as MediaElement[];

  // Filter out audio segments (see #428)
  return elementsAndSegments.filter(isImageOrVideoElement);
});

const backgroundImage = computed(() => {
  if (isVideoElement(firstElement?.value)) {
    return firstElement.value.videoUrl + '.jpg';
  }
  if (isImageElement(firstElement?.value)) {
    return firstElement.value.imageUrl;
  }
  return '';
});

const gameMinuteStr = computed(() => formatGameMinute(props.moment));

const players = ref(new Map<string, PlayerType>());

const getPlayer = async (playerId: string) => {
  if (!playerId) {
    return false;
  }
  try {
    const result = await apiRequest.methods.get(`api/data/players/${playerId}`, 'cloud');
    if (result.error) {
      console.error('Error fetching player:', result.error);
      return;
    }

    players.value.set(playerId, result.data);
  } catch (error) {
    console.error('Error fetching player:', error);
  }
};

effect(() => {
  if (firstElement.value && 'playerId' in firstElement.value) {
    if (!players.value.has(firstElement.value.playerId)) {
      getPlayer(firstElement.value.playerId);
    }
  }
});

const emit = defineEmits<{
  (e: 'addMedia', toEvent: typeof props.moment): void;
  (e: 'showDetails', ofEvent: typeof props.moment): void;
  (e: 'toggleSlideshow', elements: MediaElement[], selectedIdx: number): void;
}>();

const feedItem = ref<HTMLElement | null>(null);
let pressTimer: number | null = null;
const pressIndicator = ref(false);

onMounted(() => {
  if (feedItem.value) {
    const gesture = createGesture({
      el: feedItem.value,
      gestureName: 'long-press',
      threshold: 0,
      onStart: () => {
        pressTimer = window.setTimeout(() => {
          emit('showDetails', props.moment);
        }, 1000);
        pressIndicator.value = true;
      },
      onEnd: () => {
        if (pressTimer) {
          clearTimeout(pressTimer);
        }
        pressIndicator.value = false;
      },
    });

    gesture.enable();
  }
});
</script>

<style lang="scss">
.open-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  @media (min-width: 768px) {
    display: block;
  }
}

.feed-item {
  margin-bottom: 1rem;
  background: #191e24;
  border-radius: 0.5rem;
  cursor: pointer;
  position: relative;
  min-height: 72px;

  @media (min-width: 601px) {
    margin-right: 32px;
  }

  @media (max-width: 600px) {
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      pointer-events: none;
      width: 0%;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      transition: 1s cubic-bezier(0.87, 0, 0.38, 0.87) width;
      border-radius: 50%;
      aspect-ratio: 1;
      transform: translateY(-50%);
    }

    &.isPressed {
      &:after {
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
      }
    }
  }

  .feed-time {
    padding: 1rem 0.75rem;
    min-width: 72px;
    font-weight: 800;
    background: #373737;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    filter: contrast(0.8);
  }

  .feed-type {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-weight: 700;
    justify-content: space-between;

    .feed-overview {
      display: flex;
      align-items: center;
      padding: 0 0.7rem;
      width: 100%;
      max-width: calc(100% - 72px);
    }

    .feed-type-icon {
      min-width: 16px;
      width: 16px;
      margin-right: 8px;

      img {
        width: 100%;
      }
    }

    span {
      line-height: 1.3;
      display: flex;
      justify-content: center;
    }

    .feed-type-media {
      display: flex;
      margin-left: auto;
      gap: 0.5rem;
      min-width: 40px;

      div {
        background: #333;
        padding: 8px;
        border-radius: 9px;
        position: relative;

        img {
          width: 24px;
        }

        .progressbar {
          position: absolute;
          width: calc(100% - 16px);
          height: 3px;
          background: grey;
          border-radius: 3px;
          bottom: 4px;
          overflow: hidden;

          .progress {
            position: absolute;
            top: 28px;
            left: 0;
            width: 50%;
            height: 100%;
            background: limegreen;
          }
        }
      }
    }
  }

  .feed-hover {
    position: absolute;
    border: 3px solid transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  @media (min-width: 601px) {
    &:hover {
      .feed-settings {
        display: block;
      }
    }
  }

  .feed-settings {
    display: none;
    position: absolute;
    right: -32px;
    color: #fff;
    fill: #fff;
    top: 5px;
    line-height: 0;
    height: 72px;

    ion-icon {
      font-size: 2rem;
    }
  }
}

.feed-type {
  &:hover {
    ion-icon {
      color: var(--ion-color-primary, #01cdcf);
    }
  }
}

.preview-slider {
  min-height: 72px;
  height: 100%;
  min-width: 72px;
  width: 72px;
  position: relative;

  .preview-slider-body {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    height: 100%;
    align-items: center;

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      min-width: 72px;
      min-height: 72px;
      max-width: 72px;
      max-height: 72px;
      object-fit: cover;
      filter: brightness(80%);
      scroll-snap-align: start;
      border-radius: 0.5rem;
    }

    .video-container {
      min-width: 100%;
      min-height: 100%;
      scroll-snap-align: start;
      position: relative;

      .progress {
        position: absolute;
        top: 28px;
        left: 0;
        width: 100%;
        height: 3px;
        background: #fff;

        .progressbar {
          position: absolute;
          top: 0;
          left: 0;
          width: 30%;
          height: 100%;
          background: var(--ion-color-primary, #3880ff);
        }
      }

      video {
        scroll-snap-align: start;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .placeholder {
    opacity: 0.2;
    background: #000;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0.5rem;
  }
}

.slider-container {
  position: relative;

  .add-video {
    top: 0;
    right: 0;
    position: absolute;
    width: 24px;
    height: 24px;
    background: #000000cc;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    margin: 2px;
  }
}
</style>
