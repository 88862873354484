import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { MatchFixtureUnfolded } from '@/models/match';
import apiRequest from '@/services/apiRequest';
import { DateTime } from 'luxon';

export const useLivetickerStore = defineStore('liveticker', () => {
  const fixtureID = ref<string>();
  const matchFixture = ref<MatchFixtureUnfolded>();

  // Reaktive Werte
  const kickoff = ref<number | null>(null);
  const url = ref<string>();
  const currentMinute = ref(0);
  const currentSecond = ref(0);
  const currentExtraMinute = ref(0);
  const halftimeDuration = ref(45);
  const postingMinute = ref(0);
  const postingExtraMinute = ref(0);
  const homeScore = ref(0);
  const guestScore = ref(0);
  const phase = ref(0);
  const timer = ref<null | ReturnType<typeof setTimeout>>(null);

  const homeTeam = computed(() => matchFixture.value?.homeTeam);
  const guestTeam = computed(() => matchFixture.value?.guestTeam);

  // Funktion zum Laden des Spiels
  const setAndLoadMatch = async (fixtureId: string): Promise<void> => {
    if (fixtureID.value === fixtureId && matchFixture.value) {
      return;
    }
    fixtureID.value = fixtureId;

    const result = await apiRequest.methods.get<MatchFixtureUnfolded>(`api/data/fixtures/${fixtureId}/unfold`, 'cloud');
    if (result.error !== null) {
      throw result.error;
    }
    matchFixture.value = result.data;
  };

  function setKickoff(timestamp: number) {
    kickoff.value = timestamp;
  }

  function setURL(value: string) {
    url.value = value;
  }

  function checkKickoffTime() {
    return !(kickoff.value && Date.now() - kickoff.value > 2 * 60 * 60 * 1000);
  }

  async function getMatchStatus(guid: string) {
    if (!guid) {
      throw Error('missing guid');
    }
    try {
      const response = await apiRequest.methods.get(
        `api/data/fixtures/${guid}/matchevents?sortInfo=timestamp_desc`,
        'cloud',
      );
      if (response && response.data && response.data.length > 0) {
        const latestEvent = response.data[0];
        homeScore.value = latestEvent.homeScore;
        guestScore.value = latestEvent.guestScore;
        phase.value = 1;
        let hasFoundStartEvent = false;
        for (const event of response.data) {
          const kind = event.kind;
          if (!hasFoundStartEvent) {
            if (kind === 'Concluded') {
              currentMinute.value = halftimeDuration.value * 2;
              currentSecond.value = 0;
              currentExtraMinute.value = 0;
              phase.value = 4;
              hasFoundStartEvent = true;
              return;
            } else if (kind === 'MatchHalftimeKickoff') {
              currentMinute.value = halftimeDuration.value;
              currentSecond.value = 0;
              currentExtraMinute.value = 0;
              phase.value = 3;
              hasFoundStartEvent = true;
            } else if (kind === 'Halftime') {
              currentMinute.value = halftimeDuration.value;
              currentSecond.value = 0;
              currentExtraMinute.value = 0;
              phase.value = 2;
              hasFoundStartEvent = true;
              return;
            } else if (kind === 'KickOff') {
              currentMinute.value = 0;
              currentSecond.value = 0;
              currentExtraMinute.value = 0;
              phase.value = 1;
            }
          }
        }
        // Berechne die Spielminute basierend auf dem letzten Event
        const eventMinute = latestEvent.minute;
        const eventTimestamp = DateTime.fromISO(latestEvent.timestamp, { zone: 'utc' }).toLocal();
        const now = DateTime.local();

        const timeDifference = now.diff(eventTimestamp, 'minutes').minutes;
        const calculatedMinute = eventMinute + timeDifference;
        if (calculatedMinute > 90) {
          currentMinute.value = 90;
          currentExtraMinute.value = Math.floor(calculatedMinute - 90);
        } else {
          if (phase.value === 3 && latestEvent.kind === 'MatchHalftimeKickoff') {
            currentMinute.value = Math.floor(calculatedMinute + halftimeDuration.value);
          } else {
            currentMinute.value = Math.floor(calculatedMinute);
          }
          currentExtraMinute.value = 0;
        }
        if (currentExtraMinute.value > 100) {
          phase.value = 4;
          currentExtraMinute.value = 0;
        }
        const kickOffEvent = response.data.find((event: any) => event.kind === 'KickOff');
        if (kickOffEvent) {
          kickoff.value = DateTime.fromISO(kickOffEvent.timestamp, { zone: 'utc' }).toLocal().toMillis();
        }
        if (phase.value !== 2 && phase.value !== 4) {
          startMinuteTimer();
        }
      } else {
        currentMinute.value = 0;
        phase.value = 0;
      }
    } catch (error) {
      console.error('Error fetching match events:', error);
      phase.value = 0;
    }
  }

  function startMinuteTimer(setMinute = 0) {
    if (timer.value) {
      clearInterval(timer.value);
    }

    if (setMinute > 0) {
      currentMinute.value = setMinute;
    }

    timer.value = setInterval(() => {
      currentSecond.value += 1;

      if (currentSecond.value >= 60) {
        if (currentMinute.value < 90) {
          currentMinute.value += 1;
        } else {
          currentExtraMinute.value += 1;
        }
        currentSecond.value = 0;
      }
    }, 1000);
  }

  // 3. Funktion: stopMinuteTimer
  function stopMinuteTimer(getMinute = 0) {
    if (getMinute > 0) {
      currentMinute.value = getMinute;
      currentSecond.value = 0;
    }
    if (timer.value) {
      clearInterval(timer.value);
      timer.value = null;
    }
  }

  return {
    fixtureID,
    matchFixture,
    setAndLoadMatch,
    kickoff,
    url,
    homeTeam,
    guestTeam,
    currentMinute,
    currentSecond,
    currentExtraMinute,
    halftimeDuration,
    postingMinute,
    postingExtraMinute,
    homeScore,
    guestScore,
    phase,
    timer,
    setKickoff,
    setURL,
    checkKickoffTime,
    getMatchStatus,
    startMinuteTimer,
    stopMinuteTimer,
  };
});
