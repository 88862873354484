<template>
  <!--div class="machTime text-striking">
        <div :class="{'opacity-0': !showButton}" class="machTime__button inline-block cursor-pointer"
             @click="changeTime(-1)">
            -
        </div>
        <div class="flex items-baseline">
            <input v-model="getTime" class="inline-block" disabled type="text" />
            <span class="inline-block text-striking text-left w-full">Spielminute</span>
        </div>
        <div class="machTime__button inline-block cursor-pointer" @click="changeTime(1)">+</div>
    </div-->

  <div class="matchTimeRange swiper-no-swiping">
    <input
      class="matchTimeRange__range"
      v-model="currentMinute"
      ref="matchTime"
      type="range"
      id="volume"
      name="volume"
      min="0"
      max="90"
    />
    <!--div class="matchTimeRange__range"></div-->
    <div
      :style="'left: ' + percentageTimePlayedPosition"
      class="matchTimeRange__pin flex items-center gap-1"
      @Amousedown="startDragging"
      @Amousemove="moveThumb"
      @Amouseup="stopDragging"
      @Amouseleave="stopDragging"
    >
      <svg
        style="width: 13px; height: 13px; overflow: visible; opacity: 1; z-index: 1; fill: rgb(3, 3, 3)"
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path
          d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4.2 14.2L11 13V7h1.5v5.2l4.5 2.7-.8 1.3z"
        ></path>
      </svg>
      <span>{{ getTime }}</span>
    </div>
    <div class="matchTimeRange__past" :style="'width: ' + percentageTimePlayed"></div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useMatchStore } from '@/store/match';
const matchStore = useMatchStore();
import { useLivetickerStore } from '@/store/liveticker';
const livetickerStore = useLivetickerStore();

const props = defineProps({
  phase: {
    type: Number,
    default: 0,
  },
  halftimeDuration: {
    type: Number,
    default: 45,
  },
  startDelay: {
    type: Number,
    default: 0,
  },
});

defineEmits(['changeTime']);

const matchTimeWidth = ref(0);
const matchTime = ref<HTMLInputElement | null>(null);
const interval = ref<ReturnType<typeof setInterval>>();
const time = ref({
  minute: 0,
  seconds: 0,
});
const halftime = ref({
  sequence: 1,
  duration: props.halftimeDuration,
});

const setTime = () => {
  if (props.phase === 1) {
    time.value.minute = props.startDelay;
  } else if (props.phase === 2) {
    time.value.minute = halftime.value.duration;
  } else if (props.phase === 3) {
    time.value.minute = halftime.value.duration + props.startDelay;
  } else if (props.phase === 4) {
    time.value.minute = halftime.value.duration * 2;
  }
};

watch(
  () => props.phase,
  (newVal) => {
    if (newVal === 3 || newVal === 4) {
      time.value.minute = halftime.value.duration;
      time.value.seconds = 0;
      halftime.value.sequence = 2;
    }
  },
  { immediate: true, deep: true },
);

watch(
  () => props.halftimeDuration,
  () => {
    halftime.value.duration = props.halftimeDuration;
    setTime();
    if (props.phase === 2 || props.phase === 3) {
      time.value.minute = matchStore.halftimeDuration;
      matchStore.updateMinute(time.value.minute);
    }
  },
);

watch(
  () => props.startDelay,
  () => setTime(),
  { immediate: true },
);

const currentMinute = computed({
  get: () => time.value.minute,
  set: (newVal: number) => {
    time.value.minute = newVal;
  },
});

const percentageTimePlayed = computed(() => {
  const totalGameTime = halftime.value.duration * 2 * 60;
  let timePlayed = livetickerStore.currentMinute * 60 + livetickerStore.currentSecond;

  if (livetickerStore.currentMinute === 90) {
    timePlayed = 90 * 60;
  }

  let percent = (timePlayed / totalGameTime) * 100;
  if (percent > 100) {
    percent = 100;
  }

  return percent + '%';
});

const percentageTimePlayedPosition = computed(() => {
  const position = parseInt(percentageTimePlayed.value);
  let percentageTime = percentageTimePlayed.value;

  if (livetickerStore.currentMinute === 90) {
    percentageTime = '100%';
  }

  nextTick(() => {
    if (livetickerStore.currentMinute && !matchTimeWidth.value && matchTime.value) {
      matchTimeWidth.value = matchTime.value.offsetWidth;
    }
  });

  const sum = matchTimeWidth.value * (parseInt(percentageTimePlayed.value) / 100);
  if (position > 10 && sum + 100 > matchTimeWidth.value) {
    return `calc(${percentageTime} - 60px)`;
  } else {
    return percentageTimePlayed.value;
  }
});

const getTime = computed((): string => {
  const currentMinute = livetickerStore.currentMinute;
  const currentSecond = livetickerStore.currentSecond;
  const currentExtraMinute = livetickerStore.currentExtraMinute;

  let displayMinute = currentMinute.toString();
  let displaySecond = currentSecond.toString();

  if (currentSecond < 10) {
    displaySecond = '0' + currentSecond;
  }

  if (currentMinute < 10 && currentMinute !== 90) {
    displayMinute = '0' + currentMinute;
  }

  if (currentMinute === 90 && currentExtraMinute > 0) {
    return `90 + ${currentExtraMinute}:${displaySecond}`;
  }
  return `${displayMinute}:${displaySecond}`;
});

watch(
  () => useMatchStore().storedTime,
  (newStoredTime) => {
    if (newStoredTime > 0) {
      time.value.minute = newStoredTime;
    }
  },
  { immediate: true },
);

const isDragging = ref(false);

const startDragging = (_: MouseEvent) => {
  isDragging.value = true;
};

const moveThumb = (e: MouseEvent) => {
  if (isDragging.value) {
    const currentTarget = e.currentTarget as Element;
    if (currentTarget) {
      const rect = currentTarget.getBoundingClientRect();
      time.value.minute = Math.floor(((e.clientX - rect.left) / rect.width) * 90);
    }
  }
};

const stopDragging = () => {
  isDragging.value = false;
};

onMounted(() => {
  halftime.value.duration = props.halftimeDuration;
  setTime();
  interval.value = setInterval(() => {
    if (props.phase % 2 != 0) {
      time.value.seconds++;
      if (time.value.seconds > 59) {
        time.value.seconds = 0;
        time.value.minute++;
        const matchStore = useMatchStore();
        let playtime = time.value.minute;
        if (halftime.value.duration * props.phase > time.value.minute) {
          playtime = halftime.value.duration;
        }
        matchStore.updateMinute(playtime);
      }
    }
    if (props.phase === 2 || props.phase === 3) {
      const matchStore = useMatchStore();
      time.value.minute = matchStore.halftimeDuration;
      matchStore.updateMinute(time.value.minute);
    }
  }, 1000);
});
</script>

<style lang="scss" scoped>
.machTime {
  text-align: center;
  background: #1f1f1f;
  padding: 0.5rem;
  margin: 2rem 1rem 0;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;

  input {
    font-size: 1.5rem;
    display: inline-block;
    width: 100%;
    background: transparent;
    text-align: right;
  }

  span {
    padding-left: 5px;
    text-align: left;
  }

  .machTime__button {
    font-size: 1.5rem;
    padding: 0 14px;
    border-radius: 0.5rem;

    &:hover {
      background: #515151;
    }
  }
}

.matchTimeRange {
  margin: 1rem 0 3rem;
  height: 8px;
  position: relative;
  z-index: 1;
}

.matchTimeRange__range {
  width: 100%;
  background: #fff;
  border-radius: 3px;
  border: none;
  outline: none;
  color: transparent;
}

.matchTimeRange__range {
  width: 100%;
  border: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 65px;
    height: 20px;
    background: transparent;
    cursor: pointer;
    margin-top: -5px;
  }

  &::-moz-range-thumb {
    appearance: none;
    width: 65px;
    height: 20px;
    background: transparent;
    cursor: pointer;
    margin-top: -5px;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: #fff;
    border-radius: 3px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 8px;
    background: #fff;
    border-radius: 3px;
  }
}
.matchTimeRange__range,
.matchTimeRange__past {
  height: 8px;
}
.matchTimeRange__past {
  background: #00e0e0;
  position: absolute;
  left: 0;
  top: 11px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  pointer-events: none;
}
.matchTimeRange__pin {
  background: #00e0e0;
  border-radius: 20px;
  padding: 2px 5px;
  position: absolute;
  left: 0;
  top: 3px;
  pointer-events: none;
  margin-left: -10px;
  text-align: center;
  z-index: 3;
  span {
    font-size: 0.8rem;
    color: #000;
    padding: 0 2px;
  }
  span,
  svg {
    display: inline-block;
    vertical-align: center;
  }
}
</style>
