<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" class="open-menu--mobile" @click="openMenu()">
          <ion-icon slot="start" :icon="menu" />
        </ion-buttons>
        <ion-title>{{ pageTitle }}</ion-title>
        <ion-thumbnail v-if="badgeUrl" slot="end" class="badge" @click="() => router.push('/switchclub')">
          <img :src="badgeUrl" />
        </ion-thumbnail>
      </ion-toolbar>
    </ion-header>
    <slot></slot>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonPage,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  menuController,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { menu } from 'ionicons/icons';
import { emitter } from '@/bus';
import { useAuthStore } from '@/store/auth';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PageLayout',
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButtons,
    IonIcon,
    IonThumbnail,
  },
  props: {
    pageTitle: String,
  },
  methods: {
    openMenu() {
      menuController.open('app-menu');
    },
  },
  data() {
    return {
      menu,
      badgeImage: '',
      router: useRouter(),
    };
  },
  created() {
    emitter.on('changeBadge', (e) => {
      this.badgeImage = e;
    });
  },
  beforeUnmount() {
    emitter.all.delete('changeBadge');
  },
  computed: {
    badgeUrl() {
      let image = '';
      if (this.badgeImage !== '') {
        image = this.badgeImage;
      } else {
        image = useAuthStore().userSettings.activeClub?.badgeUrl || '';
      }
      return image;
    },
  },
});
</script>

<style lang="scss">
.open-menu {
  &--mobile {
    padding-left: 13px;
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}

.badge {
  @media screen and (min-width: 992px) {
    display: none;
  }
}
</style>
