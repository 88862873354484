<template>
  <div class="seasonPattern teamNamePattern">
    <input-text
      v-model="searchTerm"
      @update:model-value="searchSeason"
      placeholder="Suche"
      :switchSelectBack="switchSelectBack"
      :options="[
        { key: 'actual', value: 'Aktuell' },
        { key: 'past', value: 'Historisch' },
      ]"
      @selectOption="onSearchOptionSelected"
      data-testid="season-search"
    />
    <div v-if="seasonSearchResults.length" class="teamNamePattern__list">
      <pattern-list v-for="season in seasonSearchResults" :key="season.uid" @click="selectSeason(season)">
        <span v-if="season.name" v-html="season.name" />
      </pattern-list>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, ref } from 'vue';
import apiRequest from '@/services/apiRequest';
import { Season } from '@/models/season';
import PatternList from '@/components/helper/pattern/PatternList.vue';
import InputText from '@/components/forms/fields/InputText.vue';

const emit = defineEmits<{
  (e: 'searchInput', value: string): true;
}>();

const seasonId = defineModel<string | undefined>();
const searchTerm = ref(seasonId.value || '');

const seasonSearchResults = ref([] as Season[]);

const searchOption = ref<'actual' | 'past'>('actual');

const switchSelectBack = ref(false);

const searchSeason = async () => {
  if (searchTerm.value.length < 1) {
    seasonSearchResults.value = [];
  } else {
    const today = new Date();
    let year = today.getFullYear() - 1;
    if (today.getMonth() >= 6) {
      year = today.getFullYear();
    }
    const date = new Date(Date.UTC(year, 6, 1, 22, 0, 0));
    const dateString = date.toISOString();
    let urlParam: string;
    if (searchOption.value === 'actual') {
      urlParam = '&startTo=' + dateString;
    } else {
      urlParam = '&endTo=' + dateString;
    }
    //TODO: DATUM FERTIGSTELLEN!!!
    //Saison beginnt nicht immer am 1.7.
    //folgende Zeile wieder entfernen, wenn das Thema geklärt ist!
    urlParam = '';

    const requestURL = `api/data/seasons?page=0&size=10&name=${searchTerm.value}&inactive=false${urlParam}`;
    const result = await apiRequest.methods.get(requestURL, 'cloud');
    if (result.error) {
      emit('searchInput', searchTerm.value);
    } else {
      if (Object.keys(result.data).length) {
        emit('searchInput', '');
      } else {
        emit('searchInput', searchTerm.value);
      }
      seasonSearchResults.value = result.data;
    }
  }
  switchSelectBack.value = false;
};

const selectSeason = (season: Season) => {
  seasonId.value = season.uid;
  searchTerm.value = season.name;
  seasonSearchResults.value = [];
  switchSelectBack.value = true;
};

const onSearchOptionSelected = (e: 'actual' | 'past') => {
  searchOption.value = e;
  nextTick(() => {
    if (searchTerm.value.length >= 1) {
      searchSeason();
    }
  });
};
</script>

<style lang="scss" scoped>
input {
  padding: 2px 4px;
  border-radius: 5px;
  text-align: left;
  width: 100%;
}
.teamNamePattern {
  position: relative;
  text-align: center;
}
</style>
