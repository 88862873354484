import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Club } from '@/models/club';

export const useGhostWriter = defineStore('ghostWriter', () => {
  const fixtureUid = ref('');
  const venue = ref<{
    uid: string;
    name: string;
    venueType: string;
    country: string;
    cityName: string;
    address: string;
    zipcode: string;
    state: string;
    latitude: number;
    longitude: number;
    capacity: number;
    surface: string;
    imageUrl: string;
    usedByNationalTeam: boolean;
    inactive: boolean;
    properties: { [key: string]: string };
  }>();
  const homeTeam = ref<Pick<Club, 'uid' | 'acronym' | 'name' | 'fullName'>>();
  const guestTeam = ref<Pick<Club, 'uid' | 'acronym' | 'name' | 'fullName'>>();
  const events = {
    MatchPenaltyKickAwarded: [
      'Elfmeter zugesprochen! ⚽️🎯',
      'Jetzt gibt es Elfmeter! 🎯',
      'Chance vom Punkt! ⚽️',
      'Der Schiedsrichter zeigt auf den Punkt! 👉⚽️',
      'Elfmeter, keine Zweifel! ✅',
      'Was für eine Gelegenheit, per Elfmeter! 💥',
      'Entscheidung: Elfmeter! 🚨',
    ],
    MatchSubstitution: [
      'Spielerwechsel 🔁',
      'Frische Beine kommen ins Spiel 👟',
      'Taktischer Wechsel 🔄',
      'Zeit für einen Wechsel ⏳',
      'Der Trainer reagiert 👨‍🏫',
      'Auswechslung vorgenommen 🔀',
      'Neuer Spieler betritt den Rasen 🏃‍♂️',
    ],
    MatchRedCard: [
      'Rote Karte 🔴',
      'Foulspiel! ⚠️',
      'Freistoß! 🚫⚽️',
      'Elfmeter! ⚽️🎯',
      'Brutales Foul! 🤕',
      'Autsch! 😖',
      'Notbremse 🛑',
      'Direkt Rot! 🔴',
      'Vom Platz gestellt! 👋🔴',
      'Das Spiel ist für ihn vorbei! 🚶‍♂️🔴',
    ],
    MatchYellowRedCard: [
      'Gelb-Rote Karte 🟨🔴',
      'Zweite Gelbe, das bedeutet Rot! 🟨➡️🔴',
      'Jetzt muss er gehen, Gelb-Rot! 🚶‍♂️🟨🔴',
      'Von Gelb zu Rot 🟨🔄🔴',
      'Erneutes Foul, das gibt Gelb-Rot 🟨⚠️🔴',
      'Disziplinarische Maßnahme: Gelb-Rot 📜🟨🔴',
      'Nach Gelb kommt Rot - Gelb-Rot 🟨🚦🔴',
    ],
    MatchYellowCard: [
      'Gelbe Karte 🟨',
      'Foulspiel! ⚠️',
      'Freistoß! 🚫⚽️',
      'Elfmeter! ⚽️🎯',
      'Brutales Foul! 🤕',
      'Die 5️⃣ gelbe Karte! 😡🟨',
      'Gelb wegen meckerns! 😡🟨',
      'Unnötige gelbe Karte 🙄🟨',
      'Verwarnung mit Gelb ⚠️🟨',
      'Der Schiedsrichter greift zur Tasche: Gelb 👋🟨',
    ],
    MatchKickoff: [
      'Anstoß! ⚽️',
      'Das Spiel beginnt! 🏁',
      'Jetzt geht’s los! 🚀',
      'Der Ball rollt! ⚽️',
      'Anpfiff - das Match ist eröffnet! 📣',
      'Startschuss für das Spiel 🎉',
      'Das Runde muss ins Eckige - Anstoß! ⚽️🥅',
    ],
    MatchHalftime: [
      'Halbzeit! 🕒',
      'Pause im Spiel ⏸',
      'Zeit zum Durchatmen - Halbzeit 🧘‍♂️',
      'Die ersten 45 Minuten sind vorbei ⏳',
      'Halbzeitpfiff - Zeit für eine Analyse 📊',
      'Ende der ersten Halbzeit 🛑',
      'Teams gehen in die Kabine - Halbzeit 👥🚪',
    ],
    MatchHalftimeKickoff: [
      'Anstoß zur zweiten Halbzeit! ⚽️🕗',
      'Weiter geht’s - die zweite Hälfte beginnt 🔄',
      'Die zweite Runde startet 🔁',
      'Der Ball rollt wieder - zweite Halbzeit ⚽️',
      'Neue Chance in der zweiten Hälfte 🌟',
      'Jetzt zählt’s - Anpfiff zur zweiten Halbzeit 📣',
      'Die Entscheidung fällt - Start der zweiten Halbzeit 🎯',
    ],
    MatchGoal: [
      'Tor! 🥅⚽️',
      'Was für ein Schuss - Tor! 💥⚽️',
      'Und da zappelt es im Netz! 🥅😲',
      'Goooal! ⚽️🔊',
      'Treffer - da ist das Tor! 🎯⚽️',
      'Unhaltbar! 🚫🥅⚽️',
      'Traumtor! 💭⚽️',
    ],
    MatchEnd: [
      'Abpfiff! 🏁',
      'Das Spiel ist aus! 🛑',
      'Ende der Partie 🏟',
      'Vorbei - das war’s! 🎬',
      'Schlusspfiff - das Ergebnis steht 📢',
      'Das Match ist beendet 🏁',
      'Und damit ist Schluss - Spielende 🚪🏃‍♂️',
    ],
  };

  function setFixtureUid(uid: NonNullable<typeof fixtureUid.value>) {
    fixtureUid.value = uid;
  }
  function setVenue(venueData: NonNullable<typeof venue.value>) {
    venue.value = venueData;
  }
  function setHomeTeam(homeTeamData: NonNullable<typeof homeTeam.value>) {
    homeTeam.value = {
      uid: homeTeamData.uid,
      acronym: homeTeamData.acronym,
      name: homeTeamData.name,
      fullName: homeTeamData.fullName,
    };
  }
  function setGuestTeam(guestTeamData: NonNullable<typeof guestTeam.value>) {
    guestTeam.value = {
      uid: guestTeamData.uid,
      acronym: guestTeamData.acronym,
      name: guestTeamData.name,
      fullName: guestTeamData.fullName,
    };
  }

  return {
    fixtureUid,
    venue,
    homeTeam,
    guestTeam,
    events,
    setFixtureUid,
    setVenue,
    setHomeTeam,
    setGuestTeam,
  };
});
