<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, nextTick } from 'vue';
import { checkmark } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';
import { createGesture } from '@ionic/vue';

const props = defineProps<{ proposals: string[] }>();
const currentIndex = ref(0);
const isSwiping = ref(false);
const typewriterRef = ref<HTMLElement | null>(null);
const sentences = [
  "Mal überlegen... nein, das bringt's nicht.",
  'Einen Moment bitte... hmm, das passt nicht.',
  'Ich muss kurz nachdenken... nee, das ist nicht das Richtige.',
  'Einen Augenblick... ah, das ist es nicht.',
  'Lass mich kurz überlegen... nein, das ist es auch nicht.',
];

let currentSentenceIndex = 0;
let currentCharIndex = 0;
let timeoutId: number | null = null;
const editableDiv = ref<HTMLElement | null>(null);

const typeSentence = () => {
  if (currentSentenceIndex >= sentences.length) {
    return; // Alle Sätze wurden angezeigt
  }

  const currentSentence = sentences[currentSentenceIndex];
  if (currentCharIndex < currentSentence.length) {
    if (typewriterRef.value) {
      typewriterRef.value.innerText = currentSentence.substring(0, currentCharIndex + 1);
    }
    currentCharIndex++;
    if (props.proposals.length === 0) {
      timeoutId = window.setTimeout(typeSentence, 50);
    }
  } else {
    currentSentenceIndex++;
    currentCharIndex = 0;
    if (props.proposals.length === 0) {
      timeoutId = window.setTimeout(typeSentence, 1500); // Wartezeit zwischen den Sätzen
    }
  }
};

let gesture: any;
const setupGesture = () => {
  const gesture = createGesture({
    //@ts-expect-error Not sure how to fix this
    el: editableDiv.value,
    gestureName: 'swipe',
    threshold: 15,
    onStart: (detail) => {
      editContent(false);
      isSwiping.value = true;
      if (detail.deltaX > 0) {
        goPrevious();
      } else if (detail.deltaX < 0) {
        goNext();
      }
    },
    onEnd: () => {
      setTimeout(() => (isSwiping.value = false), 300);
    },
  });

  // Geste aktivieren
  gesture.enable();
};

watch(
  () => props.proposals.length,
  async (newLength) => {
    if (newLength > 0) {
      setupGesture();
      choiceProposal(currentIndex.value);
    }
  },
  { immediate: true },
);

onMounted(() => typeSentence());

onUnmounted(() => {
  if (gesture) {
    gesture.disable();
  }
  if (timeoutId !== null) {
    clearTimeout(timeoutId);
  }
});

const useArrows = ref(true);
const chosenPhrase = ref('');
const emit = defineEmits(['chosenPhrase', 'update:proposals']);

const choiceProposal = (currentIndex: number) => {
  chosenPhrase.value = props.proposals[currentIndex];
  emit('chosenPhrase', chosenPhrase.value);
};

const goPrevious = () => {
  isSwiping.value = false;
  editContent(false);
  if (currentIndex.value === 0) {
    currentIndex.value = props.proposals.length - 1;
  } else {
    currentIndex.value--;
  }
  choiceProposal(currentIndex.value);
};
const goNext = () => {
  isSwiping.value = false;
  editContent(false);
  if (currentIndex.value === props.proposals.length - 1) {
    currentIndex.value = 0;
  } else {
    currentIndex.value++;
  }
  choiceProposal(currentIndex.value);
};

const updateProposal = () => {
  const target = editableDiv.value;
  if (!target) {
    return;
  }
  const updatedProposals = [...props.proposals];
  updatedProposals[currentIndex.value] = target.innerText;
  const obj = {
    current: currentIndex.value,
    text: target.innerText,
  };
  emit('update:proposals', obj);
  choiceProposal(currentIndex.value);
};

const editMode = ref(false);
const editContent = async (forceEditable?: boolean) => {
  if (editableDiv.value instanceof HTMLElement) {
    const div = editableDiv.value;
    const isEditable = div.getAttribute('contenteditable') === 'true';
    if (typeof forceEditable === 'boolean') {
      div.setAttribute('contenteditable', String(forceEditable));
      editMode.value = forceEditable;
      if (forceEditable) {
        div.focus();
      }
    } else {
      await nextTick(() => {
        if (isSwiping.value) {
          return;
        }
      });
      div.setAttribute('contenteditable', String(!isEditable));
      editMode.value = !isEditable;
      if (!isEditable) {
        div.focus();
      } else {
        updateProposal();
      }
    }
  }
};
</script>

<template>
  <div v-if="props.proposals.length" class="flex justify-between mb-4 proposal">
    <button @click="goPrevious" v-if="useArrows">&larr;</button>
    <div
      ref="editableDiv"
      @click="() => editContent()"
      class="text-center relative w-full"
      :class="{ selected: !useArrows, editableDiv__active: editMode }"
      contenteditable
    >
      <div contenteditable class="w-full bg-none">
        {{ props.proposals[currentIndex] }}
      </div>
      <div class="proposal-overlay" :class="{ approval: !useArrows }"><ion-icon :icon="checkmark"></ion-icon></div>
    </div>
    <button @click="goNext" v-if="useArrows">&rarr;</button>
  </div>
  <div v-else ref="typewriterRef" class="mb-8 loading-proposal"></div>
  <div v-if="editMode" @click="() => editContent()" class="mb-8 save-edit-btn"><ion-icon :icon="checkmark" /></div>
</template>

<style scoped lang="scss">
/* Ihr Styles hier */

.bg-none {
  background: none !important;
}

.editableDiv__active {
  box-shadow: 0 0 0 2px var(--ion-color-primary, #3880ff);
}

.save-edit-btn {
  background: #00000052;
  padding: 0.5rem;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  margin-top: -0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;

  ion-icon {
    margin-bottom: 0;
  }
}

.proposal {
  font-size: 21px;
  padding: 0.5rem;
  border-radius: 12px;

  div {
    text-align: center;
    background: #00000052;
    padding: 0.5rem;
    border-radius: 12px;
  }

  /*.selected {
      border: 2px solid #00FFFF;
    }*/

  .proposal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000052;
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 3rem;

    ion-icon {
      height: 100%;
    }
  }

  .approval {
    animation: approval 3s forwards;
  }

  button {
    padding: 10px;
    background: #00000054;
    border-radius: 12px;

    &:nth-of-type(1) {
      margin-right: 0.5rem;
    }

    &:nth-last-of-type(1) {
      margin-left: 0.5rem;
    }
  }
}

.loading-proposal {
  text-align: center;
  background: #00000052;
  padding: 0.5rem;
  border-radius: 12px;
}

.round-default-button {
  background: var(--ion-color-success) !important;
}

/*.proposal-edit {
    color: var(--ion-color-primary, #3880ff) !important;
    font-size: 21px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
  }*/

@keyframes approval {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
