<script setup lang="ts">
import { arrowBack } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';

export type VenueInfo = { name: string; cityName: string; address: string };

withDefaults(
  defineProps<{
    content: string[] | VenueInfo;
    color: string;
    category: string;
    layerPill?: boolean;
  }>(),
  { layerPill: false },
);

const emit = defineEmits<{
  (e: 'closePillResults'): void;
  (e: 'updateTextareaContent', part: string): void;
}>();

const updateTextareaContent = (value: string) => {
  emit('updateTextareaContent', value);
};
const closePillResults = () => {
  emit('closePillResults');
};

const isVenueInfo = (val: string[] | VenueInfo): val is VenueInfo => !Array.isArray(val);
</script>

<template>
  <div class="pill-results player-results" :class="{ 'pill-results--layer': layerPill }">
    <div class="ion-icon-box" @click="closePillResults" @mousedown.prevent>
      <ion-icon class="mb-0" :icon="arrowBack"></ion-icon>
    </div>
    <div class="pill-wrapper" v-if="isVenueInfo(content)">
      <div
        class="pill"
        :style="`border-color: ${color}`"
        @click="updateTextareaContent(content.name)"
        @mousedown.prevent
      >
        {{ content.name }}
      </div>
      <div
        class="pill"
        :style="`border-color: ${color}`"
        @click="updateTextareaContent(content.cityName)"
        @mousedown.prevent
      >
        {{ content.cityName }}
      </div>
      <div
        class="pill"
        :style="`border-color: ${color}`"
        @click="updateTextareaContent(content.address)"
        @mousedown.prevent
      >
        {{ content.address }}
      </div>
    </div>
    <div class="pill-wrapper" v-else>
      <div
        v-for="(part, index) in content satisfies string[]"
        :key="index"
        class="pill"
        :style="`border-color: ${color}`"
        @click="updateTextareaContent(part)"
        @mousedown.prevent
      >
        {{ part }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
