<script setup lang="ts">
import { useAuthStore } from '@/store/auth';
import { onMounted, ref } from 'vue';
const authStore = useAuthStore();

const missingInformation = ref(false);
const missingInformationImage = ref(false);
const missingInformationUsername = ref(false);

const openDrawer = () => {
  setTimeout(async () => {
    if (authStore.profile?.avatarThumbnailUrl == undefined) {
      missingInformationImage.value = true;
    }
    if (!authStore.profile?.handle) {
      missingInformationUsername.value = true;
    }
    if (missingInformationImage.value || missingInformationUsername.value) {
      missingInformation.value = true;
    }
  }, 3500);
};
onMounted(async () => {
  openDrawer();
});
</script>

<template>
  <div
    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white p-10 missing-user-information"
    v-if="missingInformation"
  >
    <div class="user-profile-picture">
      <img src="../../Assets/images/contentcreator_placeholder.png" alt="" />
    </div>
    <div class="mb-8">
      <p>Hey!</p>
      <p v-if="missingInformationImage && missingInformationUsername">
        Bitte hinterlege ein <b>Profilbild</b> und deinen <b>Benutzernamen</b>, um deine Stories als Creator zu zeigen.
      </p>
      <p v-else-if="missingInformationImage">
        Bitte hinterlege ein <b>Profilbild</b>, um deine Stories als Creator zu zeigen.
      </p>
      <p v-else-if="missingInformationUsername">
        Bitte hinterlege deinen <b>Benutzernamen</b>, um deine Stories als Creator zu zeigen.
      </p>
    </div>
    <router-link to="/profile" @click="missingInformation = false" class="default-button mb-2 block">
      Mein Profil personalisieren
    </router-link>
    <div class="button block text-center" @click="missingInformation = false">Später</div>
    <div class="vignette opacity"></div>
  </div>
</template>

<style scoped lang="scss">
.missing-user-information {
  width: 90%;
  background: linear-gradient(-45deg, #111312 45%, #182234, #0ff);
  backdrop-filter: blur(3px);
  box-shadow:
    rgba(0, 0, 0, 0.25) 0 54px 55px,
    rgba(0, 0, 0, 0.12) 0 -12px 30px,
    rgba(0, 0, 0, 0.12) 0 4px 6px,
    rgba(0, 0, 0, 0.17) 0 12px 13px,
    rgba(0, 0, 0, 0.09) 0 -3px 5px;
  border-radius: 9px;
  overflow: hidden;
  max-width: 400px;

  @media (max-width: 320px) {
    padding: 2rem 1rem;
  }

  .user-profile-picture {
    width: 128px;
    height: 128px;
    margin: 0 auto 2rem;
    border-radius: 50%;
    border: 3px solid var(--ion-color-primary, #3880ff);

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      padding: 3px;
      object-fit: cover;
    }
  }

  .default-button {
    line-height: 1;
  }

  .button {
    color: var(--ion-color-primary, #3880ff);
  }

  .vignette {
    z-index: -1;
    opacity: 0.5;
  }
}
</style>
