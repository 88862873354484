<template>
  <ion-card class="h-full">
    <ion-spinner v-if="loading" class="upload-spinner mx-auto block p-3 my-5" color="black" name="crescent" />
    <ion-list v-else-if="autoAudios?.length">
      <ion-item-sliding v-for="audio in autoAudios" :key="audio.id" ref="slidesRef">
        <auto-audio-list-item
          :audio="audio"
          :media-auto-audio-protected-permission="mediaAutoAudioProtectedPermission"
          :audioActive="audioActive"
        />
      </ion-item-sliding>
    </ion-list>
    <p v-else-if="loadingFailed" class="text-center p-4">
      Das laden der Audios ist fehlgeschlagen. Probiere es später noch einmal.
    </p>
    <p v-else class="text-center p-4">Es stehen keine Audios zur Verfügung.</p>
  </ion-card>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { IonCard, IonItemSliding, IonList, IonSpinner } from '@ionic/vue';
import AutoAudioListItem from '@/components/autoAudio/AutoAudioListItem.vue';

const props = defineProps<{
  autoAudios?: any[];
  editActive?: boolean;
  mediaAutoAudioProtectedPermission?: boolean;
  loading?: boolean;
  audioActive?: boolean;
  loadingFailed?: boolean;
}>();

const slidesRef = ref();

function toggleSlides(editActive: boolean) {
  (slidesRef as any).forEach((slide: any) => {
    if (!editActive) {
      slide.$el.close();
    } else {
      slide.$el.open();
    }
  });
}

watch(
  () => props.editActive,
  (val) => toggleSlides(val),
);
</script>

<style lang="scss" scoped>
.audio-preview {
  max-height: 60%;
  max-width: 100%;
}
</style>
