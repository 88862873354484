import { getActivePinia, Pinia, Store } from 'pinia';
import type { PiniaPluginContext } from 'pinia';
import cloneDeep from 'lodash.clonedeep';

export function resetAllPiniaStores() {
  const activePinia = getActivePinia() as Pinia & { _s: Map<string, Store> };
  activePinia._s.forEach((store) => {
    try {
      store.$reset();
    } catch (err) {
      console.error(`Failed to reset store ${store.$id}`, err);
    }
  });
}

/**
 * Attaches a `$reset` handler to all setup stores. Options stores can already be reset out of the box.
 */
const piniaPluginReset = ({ store }: PiniaPluginContext) => {
  const initialState = cloneDeep(store.$state);

  if (!store._isOptionsAPI) {
    store.$reset = () => {
      store.$patch(cloneDeep(initialState));
    };
  }
};

export default piniaPluginReset;
export { piniaPluginReset };
