<script lang="ts" setup>
import { IonButtons, IonButton, IonModal, IonTitle, IonIcon, IonToolbar, IonHeader, IonContent } from '@ionic/vue';
import { downloadOutline, sparkles, close } from 'ionicons/icons';
import { ref, computed, onMounted } from 'vue';
import { Capacitor } from '@capacitor/core';
import { TimelineCard } from '@/models/timeline-card';
const hasBrandedMatchStory = ref(false);
const isNative = Capacitor.isNativePlatform();

const isOpen = ref(false);

const setOpen = (open: boolean) => (isOpen.value = open);

const props = defineProps<{ moment: TimelineCard }>();
const url = ref('');

const retryTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
const retryInterval = 45000;

const checkBrandedMatchStoryVideo = () => {
  if (isNative || !props?.moment?.id) {
    return;
  }
  const videoUrl = `https://cdn.leagues.network/sharing/${props.moment?.id}/full.mp4`;
  url.value = videoUrl;

  fetch(videoUrl, { method: 'HEAD' })
    .then((response) => {
      if (response.ok) {
        hasBrandedMatchStory.value = true;
        if (retryTimeout.value) {
          clearTimeout(retryTimeout.value);
        }
      } else {
        scheduleRetry();
      }
    })
    .catch(() => scheduleRetry());
};

const scheduleRetry = () => {
  retryTimeout.value = setTimeout(() => checkBrandedMatchStoryVideo(), retryInterval);
};

const brandedMatchStoryUrl = computed(() => `https://cdn.leagues.network/sharing/${props.moment?.id}/full.mp4`);

onMounted(() => checkBrandedMatchStoryVideo());
</script>

<template>
  <div class="branded-match-story-available" @click="setOpen(true)" v-if="hasBrandedMatchStory">
    <div>
      <ion-icon :icon="sparkles"></ion-icon>
      <span>Match Story teilen</span>
    </div>
  </div>
  <ion-modal :is-open="isOpen" typeof="cycle">
    <ion-header>
      <ion-toolbar>
        <ion-title>Match Story</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="setOpen(false)">
            <ion-icon :icon="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div class="relative h-full">
        <video controls autoplay muted playsinline class="h-full mx-auto bg-black">
          <source :src="brandedMatchStoryUrl" />
        </video>
        <div class="flex flex-col absolute bottom-20 right-4 gap-2">
          <!--div class="bg-white flex p-2 rounded-full self-center cursor-pointer">
            <ion-icon :icon="shareSocial"></ion-icon>
          </div-->
          <a :href="brandedMatchStoryUrl" download class="bg-white flex p-2 rounded-full self-center cursor-pointer">
            <ion-icon :icon="downloadOutline"></ion-icon>
          </a>
        </div>
      </div>
    </ion-content>
  </ion-modal>
</template>

<style lang="scss" scoped>
.branded-match-story-available {
  display: flex;
  justify-content: flex-end;
  margin-top: -1rem;
  margin-bottom: 1rem;
  cursor: pointer;

  @media (min-width: 601px) {
    margin-right: 2rem;
  }

  div {
    background: #00d4d6;
    padding: 7px 10px 2px 10px;
    border-radius: 0 0 0.5rem 0.5rem;
    margin-top: -5px;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    gap: 5px;
    animation-delay: 3s !important;
    animation: minimize 0.5s ease forwards;

    ion-icon {
      fill: #fff;
      color: #fff;
      min-height: 21px;
      min-width: 21px;
      height: 21px;
      width: 21px;
    }

    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }
  }
}

ion-content {
  ion-icon {
    fill: #000;
    color: #000;
    min-height: 21px;
    min-width: 21px;
    height: 21px;
    width: 21px;
  }
}

ion-modal {
  ion-title {
    font-weight: 600;
    text-transform: uppercase;
  }
}

@keyframes minimize {
  0% {
    width: 205px;
  }

  100% {
    width: 40px;
  }
}
</style>
