// in computedProps.ts
import { computed } from 'vue';
import { useAppStore } from '@/store/app';
import { useMatchStore } from '@/store/match';
import { useAuthStore } from '@/store/auth';
import { useLivetickerStore } from '@/store/liveticker';

export function matchReporterComputedProps(match: any) {
  const matchStore = useMatchStore();
  const appStore = useAppStore();
  const authStore = useAuthStore();
  const getLivetickerStore = useLivetickerStore();

  const homePlayersOnField = computed(() => {
    const playersRemoved = match.stats.home.cards.secondYellow + match.stats.home.cards.red;
    return 11 - playersRemoved;
  });

  const guestPlayersOnField = computed(() => {
    const playersRemoved = match.stats.guest.cards.secondYellow + match.stats.guest.cards.red;
    return 11 - playersRemoved;
  });

  const homeGoalsStore = computed(() => matchStore.homeGoals);

  const guestGoalsStore = computed(() => matchStore.awayGoals);

  const matchPhase = computed(() => match.phase);

  const uploadProgress = computed(() => appStore.uploadProgress);

  const matchCompetition = computed(() => match.competition);

  const matchDoublePost = computed(() => match.postOnBothTimelines);

  const userFullCreator = computed(() =>
    authStore.permissions.timeline_write.includes('00000000-0000-0000-0000-000000000000'),
  );

  const selectedTimelineUid = computed(() => {
    if (authStore.userSettings && authStore.userSettings.selectedTimeline) {
      return authStore.userSettings.selectedTimeline.uid;
    } else {
      return '';
    }
  });

  return {
    homePlayersOnField,
    guestPlayersOnField,
    homeGoalsStore,
    guestGoalsStore,
    matchPhase,
    uploadProgress,
    matchCompetition,
    matchDoublePost,
    userFullCreator,
    selectedTimelineUid,
    matchStore,
    getLivetickerStore,
  };
}
