type MediaElementBase = {
  preloading: boolean;
};

export type AudioElement = MediaElementBase & {
  $type: 'Audio';
  audioUrl: string;
  headline?: string;
  showControls: boolean;
  mode: 'Parallel';
  leadIn: number;
  leadOut: number;
  volume: number;
  effect: 'None';
};

export type VideoElement = MediaElementBase & {
  $type: 'Video';
  headline?: string;
  videoUrl: string;
  showControls: boolean;
};

export type ImageElement = MediaElementBase & {
  $type: 'Image';
  headline?: string;
  imageUrl: string;
};

export type MediaElement = AudioElement | VideoElement | ImageElement;

export function mediaTypeTranslation(mediaType: MediaElement['$type']) {
  switch (mediaType) {
    case 'Audio':
      return 'Audio';
    case 'Video':
      return 'Video';
    case 'Image':
      return 'Bild';
    default:
      return '';
  }
}
